import {
    cleanSearchKeyword,
    KEYWORDS_LENGTH_LIMIT,
    PageType,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { isiPad } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { normalizeResource } from "../../../common/api"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { applyStyles } from "../../../common/DOMutils"
import { EventRouter } from "../../../common/events"
import { isEnterKey } from "../../../common/eventsUtil"
import { isSubjectSearchToggleActive } from "../../../common/featureFlagUtil"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { pushUrlParams, replaceUrlParams } from "../../../common/urlUtil"
import { noResultsMessageUpdateEvent } from "../../components/roomlist/searchResultsMessage"
import { pageContext } from "../../interfaces/context"
import { RoomReload } from "../../roomList"
import { ResultType, SearchBlur } from "./constants"
import { InputSuggestions, tabKey } from "./inputSuggestions"
import { saveSearchHistory } from "./searchHistory"

export interface ISearchInputProps {pushSearches?: boolean,}

const RESET_PREVENT_SHOW_DELAY_MS = 400

export class SearchInput<T extends ISearchInputProps = ISearchInputProps> extends HTMLComponent<HTMLDivElement, T> {
    protected input: HTMLInputElement
    protected delete: HTMLDivElement
    protected form: HTMLFormElement
    protected suggestionsDiv: InputSuggestions
    public static onSubmit = new EventRouter<string>("onSubmit")
    protected searchKeywords = ""
    // If pushSearches is set to true (e.g. in a subclass) then submitted searches will
    // update the URL using pushUrlParams instead of replaceUrlParams
    protected pushSearches: boolean

    protected createElement(): HTMLDivElement {
        // Two dummy inputs for text/password are added in the form
        // in order to prevent chrome autofill suggestions from popping up on focus.
        const sbjSrch = isSubjectSearchToggleActive()
        const subjectSearchInputClass = sbjSrch ? "SbjSrch" : ""
        const searchPlaceholder = sbjSrch
            ? i18n.searchBarPlaceholderSbjSrch
            : i18n.searchBarPlaceholder
        return <div className={`SearchInput ${subjectSearchInputClass}`} id="SearchInput">
            <form id="filter_search_form" method="GET" action={normalizeResource("/")}
                ref={(el: HTMLFormElement) => {this.form = el}}
            >
                <input type="text" style={{ display: "none" }}/>
                <input type="password" style={{ display: "none" }}/>
                <input id="keywords" name="keywords" className="search_input" maxLength={150}
                    placeholder={searchPlaceholder} type="text" autocomplete="off"
                    ref={(el: HTMLInputElement) => { this.input = el }}
                    onFocus={(evt: FocusEvent) => {
                        if (this.isFocusInsideSearch(evt)) {
                            this.onInputFocus()
                        }
                    }}
                    onBlur={(e: FocusEvent) => {SearchBlur.fire(e)}}
                    onInput={() => { this.onInput() }}
                    onKeyDown={(evt: KeyboardEvent) => {this.inputKeyDown(evt)}}
                />
                <div className="inputDelete" ref={(el: HTMLDivElement)=> { this.delete = el }}
                    onMouseDown={(e: MouseEvent) => {
                        if (e.ctrlKey || e.metaKey || e.button === 2) {
                            return
                        }
                        this.onInputDeleteClick(e)
                    }}
                />
                {this.initSuggestions()}
            </form>
        </div>
    }

    protected initUI(props?: T): void {
        super.initUI(props)
        this.styleInputDelete()

        // Makes sure search suggestions are hidden on history navigation, both for neatness and to avoid
        // stale gendered links.
        addEventListenerPoly("popstate", window, () => {
            this.input.blur()
        })
    }

    protected initSuggestions(): InputSuggestions {
        // Init input suggestions here, so it can be overridden for to MobileInputSuggestions for MobileSearchInput.
        return <InputSuggestions
            classRef = {(ref) => {this.suggestionsDiv = ref}}
            input={this.input}
            searchInput={this}
        />
    }

    protected initData(props: T): void {
        super.initData(props)
        this.pushSearches = props.pushSearches ?? false
        this.searchKeywords = cleanSearchKeyword(new URLSearchParams(window.location.search).get("keywords") ?? "")
        replaceUrlParams(new Map([["keywords", this.searchKeywords]]))
        noResultsMessageUpdateEvent.fire(this.searchKeywords)
        this.input.value = this.searchKeywords
        SearchBlur.listen((evt: FocusEvent) => {
            if (this.isFocusInsideSearch(evt)) {
                return
            }
            window.setTimeout(() => {
                this.onInputBlur()
            }, 250)
        })
        if (this.input.value !== "") {
            this.suggestionsDiv.getSuggestionsAfterTime(false)
            saveSearchHistory(this.input.value, ResultType.History)
        }
    }

    protected inputKeyDown(e: KeyboardEvent): void {
        if (isEnterKey(e)) {
            e.preventDefault()
            this.suggestionsDiv.styleInactiveLastSug()
            if (this.suggestionsDiv.isSuggestionsNavigated()) {
                this.suggestionsDiv.getCurrentNavigatedSuggestion().element.click()
            } else {
                this.onSubmitSearchInput()
            }
        } else if (e.key === "ArrowDown" || e.key === "ArrowUp" || e.key === tabKey) {
            // Navigate suggestions on Up or Down Arrow keys.
            this.suggestionsDiv.navigateSuggestions(e)
        } else if (!e.shiftKey && e.key !== tabKey) {
            this.suggestionsDiv.styleInactiveLastSug()
            this.suggestionsDiv.resetSuggestionsNavigation()
        }
        this.styleInputDelete()
    }

    protected onInputFocus(): void {
        this.styleInputDelete()
        this.suggestionsDiv.getSuggestionsAfterTime(true)
    }

    protected onInputBlur(): void {
        this.suggestionsDiv.hideSuggestions()
    }

    protected styleInputDelete(): void {
        if (this.input.value !== "") {
            applyStyles(this.delete, { visibility: "visible", display: "block" })
        } else {
            applyStyles(this.delete, { visibility: "hidden", display: "none" })
        }
    }

    protected onInputDeleteClick(e: MouseEvent): void {
        this.input.value = ""
        this.styleInputDelete()
        // Call onInputFocus before focus event to fix for iPad caret not adjusting on width change
        if (isiPad()) {
            this.onInputFocus()
        }
        this.input.focus()
        this.suggestionsDiv.getSuggestionsAfterTime(true)
        e.preventDefault()
        e.stopImmediatePropagation()
    }

    protected showInputBar(): void {
        applyStyles(this.input, { display: "block" })
        applyStyles(this.delete, { visibility: "visible" })
    }

    protected hideInputBar(): void {
        applyStyles(this.input, { display: "none" })
        applyStyles(this.delete, { visibility: "hidden" })
        this.suggestionsDiv.hideSuggestions()
    }

    protected showHideInputBar(): void {
        if (this.input.value !== "") {
            this.showInputBar()
        } else {
            this.hideInputBar()
        }
    }

    protected onInput(): void {
        this.suggestionsDiv.getSuggestionsAfterTime(true)
        this.styleInputDelete()
    }

    public onSubmitSearchInput(): void {
        // Return if blank or all whitespace.
        const queryParams = new URLSearchParams(window.location.search)
        if (this.input.value.trim().length === 0 && !queryParams.has("keywords")) {
            return
        }
        this.searchKeywords = cleanSearchKeyword(this.input.value)
        const tagsPage = window.location.pathname.includes("/tags/")
        const discoverPage = window.location.pathname.includes("/discover/")
        const loadsNewPage = tagsPage || discoverPage

        // We don't need to update the tags/discover page state with the keywords
        // since they load a new page after search
        if (!loadsNewPage) {
            // Applying a search filter should always jump back to the first page of results.
            const newParamsMap = new Map([["keywords", this.searchKeywords], ["page", ""]])
            if (this.pushSearches) {
                // Push URL params to ensure a new history entry is created
                pushUrlParams(newParamsMap)
            } else {
                // SearchInput is being used in a context that doesn't handle history states,
                // so replace params instead to *avoid* creating a new history entry
                replaceUrlParams(newParamsMap)
            }
        }
        this.input.value = this.searchKeywords
        this.input.blur()
        this.styleInputDelete()
        this.suggestionsDiv.hideSuggestions()
        saveSearchHistory(this.searchKeywords, ResultType.History)
        this.completeSearch(!loadsNewPage)
    }

    private completeSearch(setUrlState: boolean): void {
        SearchInput.onSubmit.fire(this.searchKeywords)
        window.scrollTo(0, 0)
        // Autocomplete searches might arrive after searching, so prevent showSuggestions
        this.suggestionsDiv.preventShowOnGetSuggestions(true)
        window.setTimeout(() => {
            this.suggestionsDiv.preventShowOnGetSuggestions(false)
        }, RESET_PREVENT_SHOW_DELAY_MS)
        RoomReload.getInstance().loadRooms(() => {
            if (pageContext.current.isMobile) {
                this.styleInputDelete()
            } else {
                this.showHideInputBar()
            }

            // Update the noResultsMessage
            noResultsMessageUpdateEvent.fire(this.searchKeywords)
        })

        if (setUrlState) {
            UrlState.current.setPartialState({
                pageType: PageType.HOME,
                keywords: this.searchKeywords.slice(0, KEYWORDS_LENGTH_LIMIT),
            })
        }
    }

    private isFocusInsideSearch(evt: FocusEvent): boolean  {
        return this.element.contains(document.activeElement)
            || this.input === document.activeElement
            // This final condition is only relevant for blur events--since we only call this method
            // on focus events for the input element itself, the final case would never be reached
            || evt.relatedTarget instanceof Node && this.element.contains(evt.relatedTarget)
    }
}
