import { firstNotEmpty } from "@multimediallc/web-utils"
import { normalizeResource } from "./api"

export function replaceUrlParams(params: Map<string, string>): void {
    const query = generateUrlSearchParamQuery(params)
    if (query !== document.location.search && "replaceState" in window.history) {
        const topWindow = window.top !== null ? window.top : window
        const url = firstNotEmpty(query, topWindow.location.pathname)
        window.history.replaceState("", "", normalizeResource(url))
    }
}

export function pushUrlParams(params: Map<string, string>, state?: object): void {
    const query = generateUrlSearchParamQuery(params)
    if (query !== window.location.search && "pushState" in window.history) {
        window.history.pushState(state, "", normalizeResource(firstNotEmpty(query, window.location.pathname)))
    }
}

export function generateUrlSearchParamQuery(params: Map<string, string>): string {
    const urlParams = new URLSearchParams(window.location.search)
    params.forEach((value: string, key: string) => {
        if (value === "") {
            urlParams.delete(key)
        } else {
            urlParams.set(key, value)
        }
    })
    const search = urlParams.toString().replace(/%2C/g, ",")
    return search === "" ? "" : `?${search}`
}

export function buildQueryString(argMap: Record<string, string|number|boolean|undefined>): string {
    let result = ""
    for (const key of Object.keys(argMap)) {
        if (result !== "") {
            result += "&"
        }
        let value = argMap[key]
        if (value === undefined) {
            value = ""
        }
        result += `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    }
    return result
}

/**
 * @deprecated Use URLSearchParams instead
 */
export function parseQueryString(queryString: string): Record<string, string|undefined> {
    if (queryString.length > 0 && queryString[0] === "?") {
        queryString = queryString.slice(1)
    }
    const result: Record<string, string|undefined> = {}
    for (const entry of queryString.split("&")) {
        if (entry.includes("=")) {
            const pair = entry.split("=")
            if (pair[0].length > 0) {
                result[decodeURIComponent(pair[0])] = pair[1].length > 0 ? decodeURIComponent(pair[1]) : undefined
            }
        } else if (entry.length > 0) {
            // Treat `?param` as though it were `?param=` instead of ignoring it
            result[decodeURIComponent(entry)] = undefined
        }
    }
    return result
}
