import { EventRouter } from "./events"

export interface IUserInitiatePmNotification {
    username: string
    focus: boolean  // Whether to switch to the tab or not.
    showSupporterAlert: boolean  // Whether to show alert about supporter subscription or not.
}

export interface IUserModeratorStatus {
    isMod: boolean,
    username: string,
}

export const roomListRequest = new EventRouter<undefined>("roomListRequest")

export const playerForceMuted = new EventRouter<undefined>("playerForceMuted")

export const standardEmojiRequest = new EventRouter<HTMLElement>("standardEmoticonRequest")

export const userInitiatedPm = new EventRouter<IUserInitiatePmNotification>("userInitiatedPm")

export const mentionUser = new EventRouter<string>("mentionUser")

export const appDebuggingToggled = new EventRouter<boolean>("appDebuggingToggled")

export const getMoreHistoryMessages = new EventRouter<void>("getMoreHistoryMessages")

export const repositionChatTabContent = new EventRouter<void>("repositionChatTabContent")

export const userModeratorStatusChanged = new EventRouter<IUserModeratorStatus>("userModeratorStatusChanged")

export const removeChatursafeFlaggedMsgEvent = new EventRouter<string>("removeChatursafeFlaggedMsg")
