import { isFollowedCams } from "@multimediallc/cb-roomlist-prefetch"
import { ArgJSONMap } from "@multimediallc/web-utils"
import { RoomListSource } from "@multimediallc/web-utils/types"
import { addDeferedEventListenerPoly, removeEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { getCb } from "../../../common/api"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { EventRouter, ListenerGroup } from "../../../common/events"
import { loadRoomRequest } from "../../../common/fullvideolib/userActionEvents"
import { loadRoomRequest as mobileLoadRoomRequest } from "../../../common/mobilelib/userActionEvents"
import { printCatch } from "../../../common/promiseUtils"
import { dom } from "../../../common/tsxrender/dom"
import { updateShowLocation } from "../../advancedSearchOptions"
import { pageContext } from "../../interfaces/context"
import { isRoomAnimationEnabled, saveRoomListSourceCookie } from "../../roomList"
import type { IRoomInfo } from "./IRoomInfo"
import type { IRoomCardProps, RoomCard } from "./roomCard"
import type { RoomSourceOptionalFields } from "../../roomList"

export interface IRoomListProps {
    roomListSource: RoomListSource
    animate: boolean
    showLocation: boolean
    rooms: () => IRoomInfo[]
    hashtag?: (evt: IRoomClickDetails) => string | undefined
    sourceInfo?: (evt: IRoomClickDetails) => string | undefined
    sourceIndex?: (evt: IRoomClickDetails) => number | undefined
}

export abstract class RoomList<TRoomCard extends RoomCard, T extends object = object> extends HTMLComponent<HTMLUListElement, IRoomListProps, T> {
    roomClickEvent: EventRouter<IRoomClickDetails>
    roomBeforeClickEvent: EventRouter<IRoomClickDetails>
    rooms: TRoomCard[] = []
    protected roomListProps: IRoomListProps
    private showLocation: boolean
    protected listeners: ListenerGroup
    protected pageShowCallback: () => void

    constructor(props: IRoomListProps) {
        super(props)

        this.pageShowCallback = () => {
            this.updateFollowStars()
        }
        this.bindCachedFollowStarFix()
    }

    protected createElement(): HTMLUListElement {
        return <ul className="list"></ul>
    }

    protected initData(props: IRoomListProps): void {
        this.listeners = new ListenerGroup()
    }

    protected initUI(props: IRoomListProps): void {
        super.initUI(props)
        this.roomListProps = props
        this.roomClickEvent = new EventRouter<IRoomClickDetails>("roomClick")
        this.listeners.add(this.roomClickEvent.listen(evt => {
            const event = evt.event as MouseEvent
            if (event.ctrlKey || event.metaKey || event.shiftKey) {
                return // Avoid intercepting modifier-key clicks so rooms can still open in a new tab/window
            }
            if (pageContext.current.isMobile) {
                mobileLoadRoomRequest.fire(evt.roomInfo.room)
            } else {
                evt.event.preventDefault()
                loadRoomRequest.fire(evt.roomInfo.room)
            }
        }))
        this.roomBeforeClickEvent = new EventRouter<IRoomClickDetails>("roomBeforeClick")
        this.listeners.add(this.roomBeforeClickEvent.listen(evt => {
            this.saveRoomListSourceCookie(evt, props)
        }))
        this.updateShowLocation(props.showLocation)
        this.listeners.add(updateShowLocation.listen((enable) => this.updateShowLocation(enable)))
    }

    protected bindCachedFollowStarFix(): void {
        // Because this is used to avoid stale follow star state on bfcache restore, we skip the initial pageshow event on load
        addDeferedEventListenerPoly("pageshow", window, this.pageShowCallback)
    }

    protected unbindCachedFollowStarFix(): void {
        // It's necessary to cleanup this listener as we use more spa navigation, especially between pages that
        // instantiate multiple roomlists (e.g. private show page) which will cause a build-up of this listener quickly
        removeEventListenerPoly("pageshow", window, this.pageShowCallback)
    }

    protected saveRoomListSourceCookie(evt: IRoomClickDetails, props: IRoomListProps): void {
        const source = evt.roomInfo.sourceName === RoomListSource.Unknown ? props.roomListSource : evt.roomInfo.sourceName
        saveRoomListSourceCookie(evt.roomInfo.room, source, evt.index, this.getOptionalSourceInfo(evt, props))
    }

    protected getOptionalSourceInfo(evt: IRoomClickDetails, props: IRoomListProps): RoomSourceOptionalFields {
        return {
            sourceIndex: props.sourceIndex === undefined ? evt.roomInfo.sourcePosition : props.sourceIndex(evt),
            hashtag: props.hashtag === undefined ? undefined : props.hashtag(evt),
            sourceInfo: props.sourceInfo === undefined ? undefined : props.sourceInfo(evt),
        }
    }

    updateState(): void {
        super.updateState()
        this.resetRooms()
    }

    protected resetRooms(): void {
        this.removeAllChildren()
        this.rooms = []
        const roomInfos = this.roomListProps.rooms()
        let hasLocation = false
        roomInfos.forEach((roomInfo, index) => {
            this.appendRoomCard(index + 1, roomInfo)
            hasLocation = hasLocation || roomInfo.location !== undefined
        })
        // In e.g. the More Rooms Like This tab there isn't a showLocation checkbox to listen to, but
        // the location will be filtered out of the API results on the backend depending on the user's
        // previously-selected preferences, so showLocation is passed as `true` and whether or not it
        // gets shown becomes a function of whether any of the results actually *include* a location.
        applyShowLocationClass(this.element, hasLocation && this.showLocation)
    }

    protected appendRoomCard(index: number, roomInfo: IRoomInfo): void {
        const roomCard = this.createRoomCard({
            roomList: this, roomIndex: index,
            roomInfo: roomInfo, animate: isRoomAnimationEnabled(),
        })
        this.rooms.push(roomCard)
        this.addChild(roomCard)
    }

    protected abstract createRoomCard(roomProps: IRoomCardProps): TRoomCard

    protected updateShowLocation(enable: boolean): void {
        this.showLocation = enable
        applyShowLocationClass(this.element, enable)
    }

    protected updateFollowStars(): void {
        if (pageContext.current.loggedInUser === undefined || isFollowedCams() || this.rooms.length === 0) {
            return
        }
        const usernames = this.rooms.map((room) => { return room.getRoomName() })
        const apiUrl = `api/ts/follow/followed_usernames/?usernames=${encodeURIComponent(usernames.join(","))}`
        getCb(apiUrl).then((xhr) => {
            const data = new ArgJSONMap(xhr.responseText)
            const followedList = data.getStringList("followed_usernames")
            const followedSet = new Set(followedList)
            this.rooms.forEach((roomCard) => {
                roomCard.updateFollowStar(followedSet.has(roomCard.getRoomName()))
            })
        }).catch(printCatch)
    }

    public dispose(): void {
        this.listeners.removeAll()
        this.unbindCachedFollowStarFix()
    }
}

export interface IRoomClickDetails {
    roomInfo: IRoomInfo
    index: number
    event: UIEvent
}

export function applyShowLocationClass(element: HTMLElement, enable: boolean): void {
    if (enable) {
        element.classList.add("show-location")
    } else {
        element.classList.remove("show-location")
    }
}
