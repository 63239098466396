import { isAnonymous } from "../../../common/auth"
import { isMobileDmActive } from "../../../common/featureFlagUtil"
import { openMessagingConversation, toggleDms } from "../../../common/mobilelib/userActionEvents"
import { addPageAction } from "../../../common/newrelic"
import { addNoScroll, removeNoScroll } from "../../../common/noScroll"
import { dom } from "../../../common/tsxrender/dom"
import { ReactComponentRegistry } from "../ReactRegistry"
import { DmMessagingLandscapeModal } from "./dmMessagingLandscapeModal"
import { MessagingIcon } from "./messagingIcon"
import { getCacheBustRoute, messagingProps } from "./messagingUtil"
import type { ModalComponent } from "../../../common/modalComponent"
import type { ReactComponent } from "../ReactRegistry"

const FADE_CONTENT_CLASS = "fade-content"

export class MobileMessagingIcon extends MessagingIcon {
    private hamburgerUnreadBadgeComponent?: ReactComponent
    private hamburgerUnreadBadgeDiv: HTMLDivElement
    private landscapeModal: ModalComponent

    protected initUI(): void {
        const styles = this.getStyles()
        this.hamburgerUnreadBadgeDiv = <div style={styles.hamburgerBadgeContainer} />
        this.landscapeModal = new DmMessagingLandscapeModal()

        const hamburgerIcon = document.querySelector("#mmnav")
        if (hamburgerIcon !== null) {
            hamburgerIcon.appendChild(this.hamburgerUnreadBadgeDiv)
        }
        super.initUI()

        this.bindConversationEvents()
    }

    protected getStyles(): Record<string, CSSX.Properties> {
        const baseStyles = this.getBaseStyles()
        return {
            ...baseStyles,
            img: {
                width: "18px",
                height: "18px",
            },
            hamburgerBadgeContainer: {
                position: "absolute",
                top: "11px",
                right: "2px",
            },
        }
    }

    protected handleClick(): void {
        if(!isMobileDmActive() || isAnonymous()) {
            return
        }
        if(screen.orientation.type.includes("landscape")) {
            this.landscapeModal.show()
            return
        }
        toggleDms.fire(false)  // Close the hamburger menu
        this.handleAuthenticatedClick()
    }

    protected handleAuthenticatedClick(): void {
        if (!isAnonymous() && !this.messagingComponent && this.messagingElement) {
            document.body.classList.add(FADE_CONTENT_CLASS)

            const MessagingComponent = ReactComponentRegistry.get("Messaging")
            this.messagingComponent = new MessagingComponent({
                ...messagingProps,
                view: "mobile",
                onClose: this.handleClose,
            }, this.messagingElement)
            document.body.appendChild(this.messagingElement)
            addNoScroll()
        } else {
            this.handleShow()
        }
    }

    protected handleShow = (): void => {
        if (this.messagingElement) {
            document.body.classList.add(FADE_CONTENT_CLASS)
            this.messagingElement.style.display = "block"
            addNoScroll()
            addPageAction("DMListOpened", { "source": "mobile" })
        }
    }

    protected handleClose = (): void => {
        if (this.messagingElement) {
            document.body.classList.remove(FADE_CONTENT_CLASS)
            this.messagingElement.style.display = "none"
            removeNoScroll()
        }
    }

    protected updateUnreadBadge(unread: number): void {
        super.updateUnreadBadge(unread)
        this.handleHamburgerUnreadCountUpdate(unread)
    }

    private handleHamburgerUnreadCountUpdate(unread: number): void {
        const hamburgerIcon = document.querySelector("#mmnav")
        const userUpdatesBell = document.querySelector("#userUpdatesBellRoot")
        const hasUserUpdates = userUpdatesBell?.classList.contains("has_unseen") ?? false

        if (unread > 0) {
            this.updateHamburgerBadge(unread)
            hamburgerIcon?.classList.add("has_unseen")
        } else {
            this.updateHamburgerBadge(0)
            if (!hasUserUpdates) {
                hamburgerIcon?.classList.remove("has_unseen")
            }
        }
    }

    private updateHamburgerBadge(count: number): void {
        if (!this.hamburgerUnreadBadgeComponent) {
            const UnreadBadgeClass = ReactComponentRegistry.get("UnreadBadge")
            this.hamburgerUnreadBadgeComponent = new UnreadBadgeClass({ count }, this.hamburgerUnreadBadgeDiv)
        } else {
            this.hamburgerUnreadBadgeComponent.update({ count })
        }
    }

    private bindConversationEvents(): void {
        if (!isMobileDmActive() || isAnonymous()) {
            return
        }
        openMessagingConversation.listen((username) => {
            this.handleConversationOpen(username)
        })
    }

    private handleConversationOpen(username: string): void {
        if(!isMobileDmActive() || isAnonymous()) {
            return
        }

        if(!this.messagingComponent) {
            this.messagingElement = document.createElement("div")
            const MessagingComponent = ReactComponentRegistry.get("Messaging")
            this.messagingComponent = new MessagingComponent({
                ...messagingProps,
                view: "mobile",
                onClose: this.handleClose,
                initialEntry: `/messages/${username}`,
            }, this.messagingElement)
            document.body.appendChild(this.messagingElement)
        } else {
            this.messagingComponent.update({ initialEntry: getCacheBustRoute(`/messages/${username}`) })
        }
        this.handleShow()
    }
}
