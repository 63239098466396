import { PartType } from "@multimediallc/web-utils/types"
import type { IRoomNoticePart, IUserInfo } from "../messageInterfaces"

export function stringPart(msg: string): IRoomNoticePart {
    return { partType: PartType.message, message: msg }
}

export function userPart(userInfo: IUserInfo): IRoomNoticePart {
    return { partType: PartType.user, user: userInfo }
}

export function hashtagPart(tag: string, approved_tags: string[]): IRoomNoticePart {
    if (approved_tags.indexOf(tag.toLowerCase()) !== -1) {
        return { partType: PartType.hashtag, message: tag }
    }
    return stringPart(`#${tag}`)
}
