import { isiOS } from "@multimediallc/web-utils/modernizr"
import { maxDmInput } from "../../cb/components/pm/dmWindow"
import { DmWindowInput } from "../../cb/components/pm/dmWindowInput"
import { i18n } from "../translation"
import { dom } from "../tsxrender/dom"
import type { DmWindowInputProps } from "../../cb/components/pm/dmWindowInput"
import type { ITipRequestMessage } from "../specialoutgoingmessages"

export class MobileDmWindowInput extends DmWindowInput {
    constructor(props: DmWindowInputProps) {
        super(props)
    }

    protected initUI(props: DmWindowInputProps): void {
        const inputDivStyle: CSSX.Properties = {
            bottom: "1px",
            width: "100%",
            height: "33px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "2px",
            boxSizing: "border-box",
            display: "flex",
            position: "sticky",
        }
        const inputBarStyle: CSSX.Properties = {
            width: "274px",
            resize: "none",
            boxSizing: "border-box",
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            fontFamily: "Tahoma, Helvetica, Arial, sans-serif",
            fontSize: "16px",
            padding: "6px 0 0 6px",
        }
        const sendButtonStyle: CSSX.Properties = {
            borderRadius: "4px",
            fontSize: "12px",
            lineHeight: "14px",
            cursor: "pointer",
            padding: "4px",
        }
        const tipButtonStyle: CSSX.Properties = {
            borderRadius: "4px",
            fontSize: "12px",
            lineHeight: "14px",
            padding: "4px",
            cursor: "pointer",
            marginLeft: "4px",
            display: "none",
        }
        // const emojiButtonStyle: CSSX.Properties = {
        //    position: "relative",
        //    top: "4px",
        //    marginRight: "5px",
        //    cursor: "pointer",
        // }

        const inputButtonsDivStyle: CSSX.Properties = {
            padding: "0 3px 3px 3px",
            display: "flex",
            alignItems: "flex-end",
        }

        this.element =
            <div style={inputDivStyle} colorClass="dmWindowInput">
                <textarea
                    style={inputBarStyle}
                    className="noScrollbar"
                    placeholder={i18n.sendAMessageDesktop}
                    maxLength={maxDmInput}
                    ref={(el: HTMLTextAreaElement) => this.inputBar = el}
                    onKeyDown={(e: KeyboardEvent) => this.keyDownHandler(e)}
                    onInput={() => {
                        this.expandOrCollapseInputBar()
                        this.sendTypingIndicatorThrottled()
                    }}
                    data-testid="dm-input"
                />
                <div style={inputButtonsDivStyle} ref={(el: HTMLDivElement) => this.buttonsContainer = el}>
                    { /* <img src={`${STATIC_URL}emoji.svg`} style={emojiButtonStyle} onClick={emojiButtonClickHandler} ref={(el: HTMLImageElement) => this.emojiButton = el}/>  TODO put back */}
                    <span style={sendButtonStyle} colorClass={["sendButton", "disabled"]} onClick={() => this.submit(false)} ref={(el: HTMLSpanElement) => this.sendDmButton = el} data-testid="dm-send-button">{i18n.sendCAPS}</span>
                    <span style={tipButtonStyle} colorClass={["tipButton"]} onClick={() => this.props.showTipping()} ref={(el: HTMLSpanElement) => this.sendTipButton = el} data-testid="dm-tip-button">{i18n.tipCAPS}</span>
                </div>
            </div>
    }

    protected handleTipRequestMessage(message: ITipRequestMessage): void {
        if (this.disableTip) {
            // catch /tip messages when tip button is hidden
            return
        }
        this.props.showTipping(message.messageData.amount, message.messageData.message, isiOS())
    }

    /**
     * Override the default behavior and do nothing
     * to allow new lines in the input bar
     * @param e
     * @returns
     */
    protected handleEnterKey(e: KeyboardEvent): void {
        return
    }

    protected trueHeight(expanded: boolean): string {
        return expanded ? DmWindowInput.expandedHeight : DmWindowInput.height
    }
}
