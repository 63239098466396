export var PrivateRequestStatus;
(function (PrivateRequestStatus) {
    PrivateRequestStatus["requested"] = "requested";
    PrivateRequestStatus["declined"] = "declined";
    PrivateRequestStatus["started"] = "started";
    PrivateRequestStatus["stopped"] = "stopped";
    PrivateRequestStatus["spy_leave"] = "spy_leave";
    PrivateRequestStatus["error"] = "error";
})(PrivateRequestStatus || (PrivateRequestStatus = {}));
export var BroadcastStatus;
(function (BroadcastStatus) {
    BroadcastStatus["Offline"] = "offline";
    BroadcastStatus["Connecting"] = "connecting";
    BroadcastStatus["Streaming"] = "streaming";
})(BroadcastStatus || (BroadcastStatus = {}));
