import { gettext, interpolate, ngettext, titleCase, usernameTitleCase } from "@multimediallc/web-utils"
import { normalizeResource } from "./api"
import { Gender, parseSimpleGender } from "./genders"

// DO NOT USE BACKTICKS IN THIS FILE
// XGETTEXT DOESN'T LIKE IT

export const i18n = {
    numberOfRoomsMatchesYourFilters: (total: number, matched: number): string => {
        return interpolate(ngettext(
            "%(matched)s of the %(total)s live room matched your filters",
            "%(matched)s of the %(total)s live rooms matched your filters", total),
        { "total": total, "matched": matched }, true)
    },
    numberOfPrivateRoomsMatchesYourFilters: (total: number, matched: number): string => {
        return interpolate(ngettext(
            "%(matched)s of the %(total)s private room matched your filters",
            "%(matched)s of the %(total)s private rooms matched your filters", total),
        { "total": total, "matched": matched }, true)
    },
    numberOfHiddenRoomsMatchesYourFilters: (total: number, matched: number): string => {
        return interpolate(ngettext(
            "%(matched)s of the %(total)s community controlled room matched your filters",
            "%(matched)s of the %(total)s community controlled rooms matched your filters", total),
        { "total": total, "matched": matched }, true)
    },
    numberOfFollowedRoomsMatchesYourFilters: (total: number, matched: number): string => {
        return interpolate(ngettext(
            "%(matched)s of the %(total)s online followed room matched your filters",
            "%(matched)s of the %(total)s online followed rooms matched your filters", total),
        { "total": total, "matched": matched }, true)
    },
    numberOfExhibitionistCamsMatchesYourFilters: (total: number, matched: number): string => {
        return interpolate(ngettext(
            "%(matched)s of the %(total)s exhibitionist cam matched your filters",
            "%(matched)s of the %(total)s exhibitionist cams matched your filters", total),
        { "total": total, "matched": matched }, true)
    },
    numberOfNewCamsMatchesYourFilters: (total: number, matched: number): string => {
        return interpolate(ngettext(
            "%(matched)s of the %(total)s new cam matched your filters",
            "%(matched)s of the %(total)s new cams matched your filters", total),
        { "total": total, "matched": matched }, true)
    },
    numberOfGamingCamsMatchesYourFilters: (total: number, matched: number): string => {
        return interpolate(ngettext(
            "%(matched)s of the %(total)s gaming cam matched your filters",
            "%(matched)s of the %(total)s gaming cams matched your filters", total),
        { "total": total, "matched": matched }, true)
    },
    noRoomsMatchesYourFilters: (total: number): string => {
        return interpolate(ngettext(
            "None of the %(total)s live rooms matched your filters",
            "None of the %(total)s live rooms matched your filters", total),
        { "total": total }, true)
    },
    noPrivateRoomsMatchesYourFilters: (total: number): string => {
        return interpolate(ngettext(
            "None of the %(total)s private rooms matched your filters",
            "None of the %(total)s private rooms matched your filters", total),
        { "total": total }, true)
    },
    noFollowedRoomsMatchesYourFilters: (total: number): string => {
        return interpolate(ngettext(
            "None of the %(total)s online followed rooms matched your filters",
            "None of the %(total)s online followed rooms matched your filters", total),
        { "total": total }, true)
    },
    noHiddenRoomsMatchesYourFilters: (total: number): string => {
        return interpolate(ngettext(
            "None of the %(total)s community controlled rooms matched your filters",
            "None of the %(total)s community controlled rooms matched your filters", total),
        { "total": total }, true)
    },
    noExhibitionistCamsMatchesYourFilters: (total: number): string => {
        return interpolate(ngettext(
            "None of the %(total)s exhibitionist cams matched your filters",
            "None of the %(total)s exhibitionist cams matched your filters", total),
        { "total": total }, true)
    },
    noNewCamsMatchesYourFilters: (total: number): string => {
        return interpolate(ngettext(
            "None of the %(total)s new cams matched your filters",
            "None of the %(total)s new cams matched your filters", total),
        { "total": total }, true)
    },
    noGamingCamsMatchesYourFilters: (total: number): string => {
        return interpolate(ngettext(
            "None of the %(total)s gaming cams matched your filters",
            "None of the %(total)s gaming cams matched your filters", total),
        { "total": total }, true)
    },
    mobileDocumentTitle: (roomName: string, siteName: string, subject?: string): string => {
        return interpolate(
            gettext("%(username)s at %(sitename)s: %(subject)s"),
            { "username": usernameTitleCase(roomName), "sitename": siteName, "subject": subject },
            true,
        )
    },
    documentTitle: (roomName: string, siteName: string): string => {
        return interpolate(
            gettext("%(username)s's Room @ %(sitename)s - Chat in a Live Adult Video Chat Room Now"),
            { "username": usernameTitleCase(roomName), "sitename": siteName },
            true,
        )
    },
    tokenOrTokensText: (balance: number, titleCase: boolean): string => {
        if (titleCase) {
            return ngettext("Token", "Tokens", balance)
        }
        return ngettext("token", "tokens", balance)
    },
    tokenOrTokensTextCAPS: (balance: number): string => {
        return ngettext("TOKEN", "TOKENS", balance)
    },
    tipConfirmationMessage: (tipAmount: number): string => {
        return interpolate(gettext("Confirm tip of %(tokens)s tokens"), { "tokens": tipAmount.toLocaleString() }, true)
    },
    subjectChangeMessage: (subject: string): string => {
        return interpolate(gettext("room subject changed to \"%(subject)s\""), { "subject": subject }, true)
    },
    roomTitleMessage: (username: string): string => {
        return interpolate(gettext("%(username_title)s's Cam"), { "username_title": usernameTitleCase(username) }, true)
    },
    moderatorPromoteMessage: (promoter: string, promotee: string): string => {
        return interpolate(
            gettext("%(from)s has granted moderator privileges to %(to)s."), { "from": promoter, "to": promotee }, true)
    },
    moderatorRevokeMessage: (demoter: string, demotee: string): string => {
        return interpolate(
            gettext("%(from)s has revoked moderator privileges from %(to)s."), { "from": demoter, "to": demotee }, true)
    },
    moderatorPromoteError: (user: string): string => {
        return interpolate(
            gettext("Error granting moderator privileges to %(user)s."), { "user": user }, true)
    },
    moderatorRevokeError: (user: string): string => {
        return interpolate(
            gettext("Error revoking moderator privileges for %(user)s."), { "user": user }, true)
    },
    joinFanClubText: (modelName: string): string => {
        return interpolate(gettext("Join %(modelname)s's Fan Club"), { "modelname": titleCase(modelName) }, true)
    },
    joinFanClubWithTokensText: (modelName: string, tokens: number, months: number): string => {
        const joinMessage = i18n.joinFanClubText(modelName)
        const tokensMessage = interpolate(
            gettext("with %(tokens)s tokens for %(months)s months"), { "tokens": tokens, "months": months }, true)
        return joinMessage.concat(" ").concat(tokensMessage)
    },
    joinFanClubTextCAPS: (modelName: string): string => {
        return interpolate(gettext("JOIN %(modelname)s's FAN CLUB"), { "modelname": modelName }, true)
    },
    joinFanClubPage: (modelName: string): string => {
        return interpolate(gettext("You will be taken to Join Fan Club Page for %(modelname)s.  Click \"OK\" to proceed."),
            { "modelname": titleCase(modelName) }, true)
    },
    roomPicsText: (modelName: string): string => {
        return interpolate(gettext("%(modelname)s's Pics"), { "modelname": titleCase(modelName) }, true)
    },
    roomVidsText: (modelName: string): string => {
        return interpolate(gettext("%(modelname)s's Videos"), { "modelname": titleCase(modelName) }, true)
    },
    purchaseVideoText: (tokens: number): string => {
        return interpolate(ngettext(
            "You are about to purchase this video for %(tokens)s token",
            "You are about to purchase this video for %(tokens)s tokens", tokens),
        { "tokens": tokens }, true)
    },
    purchasePhotosText: (numPhotos: number, tokens: number): string => {
        return interpolate(gettext("You are about to purchase this photo set (%(numphotos)s %(imagestext)s) for %(tokens)s %(tokenstext)s"),
            {
                "numphotos": numPhotos, "imagestext": ngettext("image", "images",
                    numPhotos), "tokens": tokens, "tokenstext": ngettext("token", "tokens", tokens),
            }, true)
    },
    unlockPhotosText: (numPhotos: number): string => {
        return interpolate(ngettext(
            "Unlock this photo set (%(numphotos)s image)",
            "Unlock this photo set (%(numphotos)s images)", numPhotos),
        { "numphotos": numPhotos }, true)
    },
    unlockVideoText: (duration: string): string => {
        return interpolate(gettext("Unlock this video (%(duration)s)"), { "duration": duration }, true)
    },
    fanClubDisabledText: (modelName: string): string => {
        return interpolate(gettext("%(modelname)s has disabled their fan club."), { "modelname": titleCase(modelName) }, true)
    },
    silenceMessage: (usernameSileneced: string, silencer: string): string => {
        return interpolate(
            gettext("User %(username)s was silenced by %(silencer)s and their messages have been removed"),
            { "username": usernameSileneced, "silencer": silencer },
            true,
        )
    },
    userKickedAndMessagesRemovedMessage: (kickedUsername: string): string => {
        return interpolate(
            gettext("User %(username)s was kicked out of the room and their messages have been removed"),
            { "username": kickedUsername },
            true,
        )
    },
    privateShowPriceChangeMessage: (roomName: string, price: number): string => {
        return interpolate(
            gettext("%(username)s changed Private Show price to %(price)s tokens per minute"),
            { "username": roomName, "price": price },
            true,
        )
    },
    premiumPrivateShowPriceChangeMessage: (roomName: string, price: number): string => {
        if (price === 0) {
            return interpolate(
                gettext("%(username)s has disabled Premium Private Shows"),
                { "username": roomName },
                true,
            )
        }
        return interpolate(
            gettext("%(username)s changed Premium Private Show price to %(price)s tokens per minute"),
            { "username": roomName, "price": price },
            true,
        )
    },
    spyShowPriceChangeMessage: (roomName: string, price: number): string => {
        return interpolate(
            gettext("%(username)s changed Spy on Private Show price to %(price)s tokens per minute"),
            { "username": roomName, "price": price },
            true,
        )
    },
    fanClubSpyPriceChangeMessage: (roomName: string, price: number | undefined): string => {
        if (price === undefined) {
            return interpolate(
                gettext("%(username)s has disabled the Fan Club discount to Spy on Private Shows"),
                { "username": roomName },
                true,
            )
        }
        return interpolate(
            gettext("%(username)s changed Fan Club Spy on Private Show price to %(price)s tokens per minute"),
            { "username": roomName, "price": price },
            true,
        )
    },
    priceTokensPerMinuteMessage: (price: number, abbrev = false): string => {
        if (abbrev) {
            return interpolate(
                gettext("%(price)s tokens/min"),
                { "price": price },
                true,
            )
        }
        return interpolate(
            gettext("%(price)s tokens per minute"),
            { "price": price },
            true,
        )
    },
    privateShowMinimumMinuteMessage: (minMinutes: number, abbrev = false): string => {
        if (abbrev) {
            return interpolate(
                gettext("minimum %(minMinutes)s min(s)"),
                { "minMinutes": minMinutes },
                true,
            )
        }
        return interpolate(
            gettext("minimum %(minMinutes)s minute(s)"),
            { "minMinutes": minMinutes },
            true,
        )
    },
    privateShowMinimumMinuteMessageV2: (minMinutes: number): string => {
        return interpolate(
            gettext("Minimum %(minMinutes)s minute(s)"),
            { "minMinutes": minMinutes },
            true,
        )
    },
    privateShowConfirmMessage: (room: string, price: number, minMinutes: string, recordingsAllowed: boolean): string => {
        let msg = interpolate(
            gettext("Enter private chat with %(username)s?\n%(price)s tokens per minute, minimum %(minMinutes)s minute(s)\n\n"),
            {
                "username": room,
                "price": price,
                "minMinutes": minMinutes,
            },
            true,
        )
        if (recordingsAllowed) {
            msg += gettext("This broadcaster allows private show recordings, so you will receive a recorded video of this show in your collection.")
        } else {
            msg += gettext("This broadcaster does not allow private show recordings, so you will not receive a recorded video of this show in your collection.")
        }
        return msg
    },
    privateShowSpyConfirmMessage: (price: number): string => {
        return interpolate(gettext("Spy on private show for (%(price)s tokens per minute)"), { "price": price }, true)
    },
    appsRunningMessage: (username: string): string => {
        return interpolate(gettext("Broadcaster %(username)s is running these apps: "), { "username": username }, true)
    },
    usersRunningAppMessage: (appName: string): string => {
        return interpolate(gettext("Showing users running %(appName)s"), { "appName": appName }, true)
    },
    devportalMessage: (link: string): string => {
        return interpolate(
            gettext("Create or update v2 Apps in the new <a href=\"%(link)s\" target=\"_blank\" rel=\"noopener noreferrer\" data-test=\"dev-portal-link\">Developer Portal</a>."),
            { "link": normalizeResource(link) },
            true,
        )
    },
    integerMinError: (minimum: number): string => {
        return interpolate(gettext("This field is less than the minimum of %(minimum)s"), { "minimum": minimum }, true)
    },
    integerMaxError: (maximum: number): string => {
        return interpolate(gettext("This field is greater than the maximum of %(maximum)s"), { "maximum": maximum }, true)
    },
    allowToVoteMessage: (room: string, minTokensToVote: number): string => {
        return interpolate(
            gettext("After tipping %(min_tokens_to_vote)s tokens in a day, you'll be able to vote regarding your satisfaction with %(room)s."),
            { "min_tokens_to_vote": minTokensToVote, "room": titleCase(room) },
            true,
        )
    },
    confidentalVoteMessage: (roomName: string): string => {
        return interpolate(
            gettext("Your confidential vote regarding %(room)s has been recorded. You may change your vote at any time today. Thank you for your feedback."),
            { "room": titleCase(roomName) },
            true,
        )
    },
    shareCamText: (username: string): string => {
        return interpolate(gettext("Share %(username)s's Cam"), { "username": username }, true)
    },
    embedCamText: (username: string): string => {
        return interpolate(gettext("Embed %(username)s's Cam on Your Webpage"), { "username": username }, true)
    },
    conversationCautionMessage: (siteName: string): string => {
        return interpolate(gettext("Caution: The %(siteName)s Team will NEVER contact you via chat or ask for your password."), { "siteName": siteName }, true)
    },
    afterTipVoteSatisfactionText: (room: string, tokens: number): string => {
        return interpolate(gettext("Note: For tipping at least %(tokens)s tokens today, you can now vote what you think about %(room)s. Your vote is confidential. See below for the satisfaction feedback controls."), { "tokens": tokens, "room": titleCase(room) }, true)
    },
    afterTipVoteSatMobileText: (room: string, tokens: number): string => {
        return interpolate(gettext("Note: For tipping at least %(tokens)s tokens today, you can now vote what you think about %(room)s. Your vote is confidential. Open the menu for the satisfaction feedback controls."), { "tokens": tokens, "room": titleCase(room) }, true)
    },
    votedUpText: (room: string): string => {
        return interpolate(gettext("Note: Your confidential vote of thumbs up for %(room)s has been recorded. You may change your vote at any time today. Thank you for your feedback."), { "room": titleCase(room) }, true)
    },
    votedDownText: (room: string): string => {
        return interpolate(gettext("Note: Your confidential vote of thumbs down for %(room)s has been recorded. You may vote once every 90 days, and you may change your vote at any time today.  Thank you for your feedback."), { "room": titleCase(room) }, true)
    },
    votedDownTextRepeat: (room: string): string => {
        return interpolate(gettext("Note: Your confidential vote of thumbs down for %(room)s has been refreshed and will expire in 90 days. You may change your vote at any time today. Thank you for your feedback."), { "room": titleCase(room) }, true)
    },
    sureVoteDownText: (room: string): string => {
        return interpolate(gettext("Are you sure you want to rate %(room)s thumbs down?"), { "room": titleCase(room) }, true)
    },
    noSimilarRooms: (room: string): string => {
        return interpolate(gettext("Sorry, we don't have any rooms similar to %(room)s yet."), { "room": room }, true)
    },
    pmSupporterSendPm: (pmUser: string): string => {
        return interpolate(gettext("to send a pm to %(pmUser)s"), { "pmUser": pmUser }, true)
    },
    userPromotionCurrentPriceText: (price: number): string => {
        const labelText = i18n.userPromotionCurrentPriceLabel
        const tokensText = i18n.tokenOrTokensText(price, false)
        return "".concat(labelText).concat(": ").concat(String(price)).concat(" ").concat(tokensText)
    },
    userIsIgnored: (username: string): string => {
        return interpolate(gettext("%(username)s is ignored"), { "username": username }, true)
    },
    ignoreUser: (username: string): string => {
        return interpolate(gettext("Ignore %(username)s"), { "username": username }, true)
    },
    ignoringUser: (username: string): string => {
        return interpolate(gettext("Ignoring %(username)s"), { "username": username }, true)
    },
    noLongerIgnoring: (username: string): string => {
        return interpolate(gettext("No longer ignoring %(username)s"), { "username": username }, true)
    },
    removedOldestIgnore: (username: string): string => {
        return interpolate(gettext("Ignore limit reached. Removed oldest user from list: %(username)s"), { "username": username }, true)
    },
    userHasBeenReported: (username: string): string => {
        return interpolate(gettext("%(username)s has been reported"), { "username": username }, true)
    },
    createdByUsername: (username: string): string => {
        return interpolate(gettext("by %(username)s"), { "username": username }, true)
    },
    errorIgnoringUser: (username: string): string => {
        return interpolate(gettext("Error ignoring user %(username)s. Please try again in a few seconds."), { "username": username }, true)
    },
    errorUnignoringUser: (username: string): string => {
        return interpolate(gettext("Error unignoring user %(username)s. Please try again in a few seconds."), { "username": username }, true)
    },
    errorFollowingUser: (username: string): string => {
        return interpolate(gettext("Error following user %(username)s. Please try again."), { "username": username }, true)
    },
    errorUnfollowingUser: (username: string): string => {
        return interpolate(gettext("Error unfollowing user %(username)s. Please try again."), { "username": username }, true)
    },
    errorVotingForUser: (username: string): string => {
        return interpolate(gettext("Error voting for user %(username)s. Please try again."), { "username": username }, true)
    },
    transferTokensToCashWithValue: (cashAmount: string): string => {
        return interpolate(gettext("Transfer tokens to %(cashAmount)s cash."), { "cashAmount": cashAmount }, true)
    },
    transferTokensToAdvertisingWithValue: (cashAmount: string): string => {
        return interpolate(gettext("Transfer tokens to %(cashAmount)s advertising credit."), { "cashAmount": cashAmount }, true)
    },
    overSpendingLimitResolved: (tokens: number): string => {
        return interpolate(gettext("Attempted to purchase %(tokens)s tokens but spending limit exceeded. This has been resolved by an administrator."),
            { tokens: tokens }, true)
    },
    overSpendingLimitUnresolved: (tokens: number, supportEmail: string): string => {
        return interpolate(gettext("Attempted to purchase %(tokens)s tokens but spending limit exceeded. Email %(supportEmail)s ASAP to resolve."),
            { tokens: tokens, supportEmail: supportEmail }, true)
    },
    purchasePhotoSet: (setName: string, username: string): string => {
        return interpolate(gettext("Purchase Photo/Video (%(setName)s) from <a class='hrefColor' href=\"%(link)s\" rel='noopener' target='_blank'>%(username)s</a>"),
            { "setName": setName, "username": username, link: normalizeResource(`/${username}`) }, true)
    },
    sellPhotoSet: (setName: string, username: string): string => {
        return interpolate(gettext("Sell Photo/Video (%(setName)s) to <a class='hrefColor' href=\"%(link)s\" rel='noopener' target='_blank'>%(username)s</a>"),
            { "setName": setName, "username": username, link: normalizeResource(`/${username}`) }, true)
    },
    purchaseSocialMediaFrom: (name: string, username: string): string => {
        return interpolate(gettext("Purchase social media (%(name)s) from <a class='hrefColor' href=\"%(link)s\" rel='noopener' target='_blank'>%(username)s</a>"), { "name": name, "username": username, link: normalizeResource(`/${username}`) }, true)
    },
    sellSocialMediaTo: (name: string, username: string): string => {
        return interpolate(gettext("Sell Social media (%(name)s) to <a class='hrefColor' href=\"%(link)s\" rel='noopener' target='_blank'>%(username)s</a>"),
            { "name": name, "username": username, link: normalizeResource(`/${username}`) }, true)
    },
    broadcasterWelcomeMessage: (listVisibleTo: string): string => {
        return interpolate(
            gettext("Your cam is visible to %(listVisibleTo)s. Edit this in settings & privacy."),
            { "listVisibleTo": listVisibleTo },
            true,
        )
    },
    privateShowRequestNotice: (username: string): string => {
        return interpolate(
            gettext("%(username)s wants to start a private show."),
            { "username": username },
            true,
        )
    },
    premiumPrivateShowRequestNotice: (username: string): string => {
        return interpolate(
            gettext("%(username)s wants to start a premium private show. This will prevent spying."),
            { "username": username },
            true,
        )
    },
    privateShowEarningsMessage: (tokens: number): string => {
        return interpolate(
            gettext("This private show will earn you %(tokens)s tokens per minute, based on your settings."),
            { "tokens": tokens },
            true,
        )
    },
    tokensPerMinute: (tokens: number): string => {
        return interpolate(
            gettext("%(tokens)s Per Minute"),
            { "tokens": tokens },
            true,
        )
    },
    banUserConfirm: (username: string): string => {
        return interpolate(
            gettext("Ban %(username)s?"),
            { "username": username },
            true,
        )
    },
    banUserSuccess: (username: string): string => {
        return interpolate(
            gettext("User %(username)s banned from your room"),
            { "username": username },
            true,
        )
    },
    userHasTippedMe: (username: string): string => {
        return interpolate(
            gettext("%(username)s has tipped me"),
            { "username": username },
            true,
        )
    },
    tokensInPastHour: (tokens: number): string => {
        return interpolate(
            gettext("%(tokens)s in the past hour"),
            { "tokens": tokens },
            true,
        )
    },
    tokensInPastDay: (tokens: number): string => {
        return interpolate(
            gettext("%(tokens)s in the past day"),
            { "tokens": tokens },
            true,
        )
    },
    tokensInPastTwoWeeks: (tokens: number): string => {
        return interpolate(
            gettext("%(tokens)s in the past 2 weeks"),
            { "tokens": tokens },
            true,
        )
    },
    activeMembershipsMessage: (numMemberships: number): string => {
        return interpolate(ngettext(
            "You have %(numMemberships)s Active Membership",
            "You have %(numMemberships)s Active Memberships", numMemberships),
        { "numMemberships": numMemberships },
        true,
        )
    },
    monthlyCost: (cost: string): string => {
        return interpolate(
            gettext("$%(cost)s monthly"),
            { "cost": cost },
            true,
        )
    },
    automaticallySilencedUsersMessage: (numSilenced: number): string => {
        return interpolate(ngettext(
            "We have automatically silenced %(numSilenced)s user possibly linked to a user you have previously silenced;",
            "We have automatically silenced %(numSilenced)s users possibly linked to a user you have previously silenced;", numSilenced),
        { "numSilenced": numSilenced },
        true,
        )
    },
    automaticallyBannedUsersMessage: (numSilenced: number): string => {
        return interpolate(ngettext(
            "We have automatically banned %(numSilenced)s user possibly linked to a user you have previously banned;",
            "We have automatically banned %(numSilenced)s users possibly linked to a user you have previously banned;", numSilenced),
        { "numSilenced": numSilenced },
        true,
        )
    },
    currentFanClubMembersTitle: (numMembers: number): string => {
        return interpolate(ngettext(
            "%(numMembers)s Current Fan Club Member",
            "%(numMembers)s Current Fan Club Members", numMembers),
        { "numMembers": numMembers },
        true,
        )
    },
    broadcastNotificationTopBarFollowerTextPluralOld: (numFollowers: number): string => {
        return interpolate(gettext("You have %(numFollowers)s followers"), { "numFollowers": numFollowers }, true)
    },
    userPromotionToolbarInProgressText: (relativeTime: string): string => {
        return interpolate(gettext("Promoted for %(relativeTime)s"), { "relativeTime": relativeTime }, true)
    },
    idAboutToExpire: (expire: string): string => {
        return interpolate(gettext("An ID on your account is set to expire on %(expire)s. Please submit new ID for verification in order to continue earning tokens on uploaded content or while broadcasting with minimal disruption."), { "expire": expire }, true)
    },
    guestIdAboutToExpire: (username: string): string => {
        return interpolate(gettext("Your Guest %(username)s has an ID expiring soon on their account. Please let your Guest know they'll need to submit an updated ID on their personal account to keep the ability to be a Guest on your account."), { "username": username }, true)
    },
    paxumTempNotice: (): string => {
        return interpolate(
            gettext("Due to new requirements necessary for Paxum payouts, we need some additional information for you to continue to receive payments. <a href=\"%(link)s\">Please visit this page to update your info</a>."),
            { "link": normalizeResource("/affiliates/payoutinfo_paxum_temp/") },
            true,
        )
    },
    userPromotionReportText: (viewCount: number): string => {
        return interpolate(
            gettext("Your promotion is complete - %(viewCount)s people saw your room on the homepage and Discover page."),
            { "viewCount": viewCount }, true,
        )
    },
    showMyCamStart: (username: string): string => {
        return interpolate(gettext("Start Cam To Cam with %(username)s"), { "username": username }, true)
    },
    showMyCamPreviewInfo1: (username: string): string => {
        return interpolate(gettext("Your cam will only be visible to %(username)s."), { "username": username }, true)
    },
    showMyCamCurrentlySharing: (room: string): string => {
        return interpolate(
            gettext("You are currently showing your cam to %(room)s. If your cam has ended, please wait up to 1 minute to be able to broadcast again."),
            { "room": room },
            true,
        )
    },
    showMyCamCooldownAlert: (sec: number): string => {
        return interpolate(
            gettext("Please allow up to %(sec)s seconds before sharing your cam again."),
            { "sec": sec },
            true,
        )
    },
    privateConversationWithText: (username: string): string => {
        return interpolate(
            gettext("Private conversation with %(username)s"),
            { "username": username },
            true,
        )
    },
    fanclubCancelConfirmMessage: (username: string): string => {
        return interpolate(gettext("Are you sure you wish to leave %(username)s's Fan Club? You will remain a member until your currently paid month expires."),
            { "username": username },
            true,
        )
    },
    loginForPasswordPrompt: (room: string): string => {
        return interpolate(gettext("%(room)s's room now requires a password. Please log in for a password prompt."),
            { "room": room },
            true)
    },
    passwordRequiredForRoom: (room: string): string => {
        return interpolate(gettext("Password required for room %(room)s"),
            { "room": room },
            true)
    },
    authSectionInfo: (eventsUrl: string): string => {
        return interpolate(gettext("Use the <a class='hrefColor' data-testid='events-api-json-feed' target='_blank' rel='noopener' href='%(eventsUrl)s'>Events API JSON feed</a> to see your room's chat messages and events.  For more information, see the <a class='hrefColor' target='_blank' rel='noopener' style='font-size: 12px;' data-testid='events-api-documentation' href='%(documentationUrl)s'>documentation</a>."),
            { "eventsUrl": normalizeResource(eventsUrl), "documentationUrl": normalizeResource("/apps/api/docs/index.html") }, true)
    },
    authSectionInfo2: (eventsUrl: string): string => {
        return interpolate(gettext("Scan this QR code with a third party app to let it read public events in your Chaturbate room.<br/><br/>You may also provide the <a class='hrefColor' data-testid='events-api-json-feed' target='_blank' rel='noopener' href='%(eventsUrl)s'>Events API JSON Feed URL</a> directly to the third party app.<br/><br/>See the <a class='hrefColor' target='_blank' rel='noopener' style='font-size: 12px;' data-testid='events-api-documentation' href='%(documentationUrl)s'>Events API documentation</a> for more information."),
            { "eventsUrl": normalizeResource(eventsUrl), "documentationUrl": normalizeResource("/apps/api/docs/index.html") }, true)
    },
    gamesText: (siteName: string, gamesUrl: string): string => {
        return interpolate(
            gettext("Approved games can now be live streamed on %(siteName)s. Let us know which approved game you are playing below. If a game you would like to broadcast on %(siteName)s is not on the approved list, encourage the game developer to submit their game to the <a href='%(gamesUrl)s' target='_blank'>Multimedia Games site</a>."),
            { "siteName": siteName, "gamesUrl": normalizeResource(gamesUrl) },
            true,
        )
    },
    usedByText: (app: string): string => {
        return interpolate(gettext("Currently used by: %(app)s"),
            { "app": app },
            true,
        )
    },
    replaceAppConfirm: (app: string): string => {
        return interpolate(gettext("This slot is currently used by %(app)s.\nDo you want to replace it?"),
            { "app": app },
            true,
        )
    },
    noSearchResults: (text: string): string => {
        return interpolate(gettext("No search results for '%(text)s'"),
            { "text": text },
            true)
    },
    noSearchResultsMatch: (keyword: string): string => {
        return interpolate(gettext("No search results for '%(keyword)s' match your preferences, please update your search/filter options."),
            { "keyword": keyword },
            true)
    },
    searchResultsFor: (keyword: string): string => {
        return interpolate(gettext("Search results for \"%(keyword)s\""),
            { "keyword": keyword },
            true)
    },
    feedbackSentimentLabel: (siteName: string): string => {
        return interpolate(gettext("Overall, how was your %(siteName)s experience today?"),
            { "siteName": siteName },
            true)
    },
    feedbackBroadcasterLabel: (siteName: string): string => {
        return interpolate(gettext("How was your %(siteName)s broadcast experience today?"),
            { "siteName": siteName },
            true)
    },
    upgradeToSupporter: (link: string): string => {
        return interpolate(gettext("<a class=\"hrefColor\" href=\"%(link)s\">Upgrade to supporter</a> for no ads, private messaging, and more!"),
            { "link": normalizeResource(link) },
            true)
    },
    supporterToUseFeature: (source: string): string => {
        return interpolate(gettext("<a href=\"%(link)s\">Upgrade</a> to supporter to use this feature."),
            { "link": normalizeResource("/supporter/upgrade/?source=".concat(source)) },
            true)
    },
    obsBroadcastWarning: (siteName: string): string => {
        return interpolate(
            gettext("WARNING: Your broadcast is live on %(siteName)s when you are streaming from RTMP/OBS, regardless of if you are in your room."),
            { "siteName": siteName },
            true,
        )
    },
    selectGameSectionText: (siteName: string, gamesUrl: string): string => {
        return interpolate(
            gettext("If a game you would like to broadcast on %(siteName)s is not on the approved list, encourage the game developer to submit their game to the <a href=\"%(gamesUrl)s\" target=\"_new\">Multimedia Games Site </a>."),
            { "siteName": siteName, "gamesUrl": normalizeResource(gamesUrl) },
            true,
        )
    },
    downloadOBSSectionText: (siteName: string): string => {
        return interpolate(
            gettext("You can use <a href=\"%(obsLink)s\" data-testid=\"obs-download-link\" target=\"_blank\">Open Broadcaster Software</a> (OBS) to stream games and overlay with it your webcam feed. You'll need your <a class=\"streamKeyLink\" data-testid=\"stream-key-link\" href=\"%(obsLink)s\" target=\"_blank\">%(siteName)s stream key</a> to connect OBS to your stream. See a quick <a href=\"%(supportLink)s\" data-testid=\"tutorial-link\" target=\"_blank\">tutorial</a> if you need help."),
            {
                "siteName": siteName,
                "obsLink": normalizeResource("https://obsproject.com/"),
                "supportLink": normalizeResource("https://support.chaturbate.com/hc/en-us/articles/360037971952-How-do-I-set-up-OBS-/"),
            },
            true,
        )
    },
    authorizeGameSectionText: (siteName: string): string => {
        return interpolate(
            gettext("After you go live, click <a class=\"closePopoutLink\" data-testid=\"start-game-button\" href=\"#\" target=\"_blank\">here</a> to add the gaming tag to your stream. This step is required to stream licensed games on %(siteName)s."),
            { "siteName": siteName },
            true,
        )
    },
    mobileNewPrivateMessageNotice: (username: string): string => {
        return interpolate(
            gettext("New private message from %(username)s"),
            { "username": username },
            true)
    },
    newPrivateMessageNotice: (username: string): string => {
        return interpolate(
            gettext("New private message from %(username)s"),
            { "username": username },
            true)
    },
    newDirectMessageNotice: (username: string): string => {
        return interpolate(
            gettext("New direct message from %(username)s"),
            { "username": username },
            true)
    },
    newConversationMessageNotice: (username: string): string => {
        return interpolate(
            gettext("New message from %(username)s"),
            { "username": username },
            true)
    },
    sendDmToUser: (username: string): string => {
        return interpolate(
            gettext("Send DM to %(username)s"),
            { "username": username },
            true)
    },
    tipUser: (user: string): string => {
        return interpolate(
            gettext("Tip %(user)s"),
            { "user": user },
            true,
        )
    },
    sendTipToUser: (user: string): string => {
        return interpolate(
            gettext("Send Tip to %(user)s"),
            { "user": usernameTitleCase(user) },
            true,
        )
    },
    numViewerOrViewers: (viewerCount: number): string => {
        return interpolate(
            ngettext("%(viewerCount)s viewer", "%(viewerCount)s viewers", viewerCount),
            { "viewerCount": viewerCount },
            true,
        )
    },
    obsStreamClass: (resolutionTitle: string): string => {
        return interpolate(
            gettext("Your stream is classed as %(resolutionTitle)s"),
            { "resolutionTitle": resolutionTitle },
            true,
        )
    },
    obsConnectionDescription: (host: string, region: string, streamType: string): string => {
        return interpolate(
            gettext("Connected to %(host)s in region %(region)s - Using %(streamType)s"),
            { "host": host, "region": region, "streamType": streamType },
            true,
        )
    },
    obsFPS: (fps: number): string => {
        return interpolate(
            gettext("%(fps)s fps"),
            { "fps": fps },
            true,
        )
    },
    obsBitrate: (bitrate: number): string => {
        return interpolate(
            gettext("%(bitrate)s bitrate"),
            { "bitrate": bitrate },
            true,
        )
    },
    obsAlternativeStreamType: (streamType: string): string => {
        return interpolate(
            gettext("Currently broadcasting with %(streamType)s from another browser."),
            { "streamType": streamType },
            true,
        )
    },
    obsSupport: (siteName: string): string => {
        return interpolate(
            gettext("Visit <a class='hrefColor' href='https://support.chaturbate.com/hc/en-us/articles/360037971952-How-do-I-set-up-OBS-' target='_blank'>our support page</a> to get started with OBS on %(siteName)s"),
            { "siteName": siteName },
            true,
        )
    },
    signInWithSocialAuth: (name: string): string => {
        return interpolate(gettext("Sign In with %(name)s"),
            { "name": name },
            true)
    },
    createdAt: (date: Date): string => {
        return interpolate(gettext("Created at %(date)s"),
            { "date": date },
            true)
    },
    expiresOn: (date: Date): string => {
        return interpolate(gettext("Expires on %(date)s"),
            { "date": date },
            true)
    },
    followCountLong: (online: number, total: number): string => {
        return interpolate(gettext("%(online)s Online / %(total)s Following"),
            { "online": online, "total": total },
            true)
    },
    unableToLeavePrivateShow: (prevStatus: string): string => {
        return interpolate(gettext("Unable to leave private show from status: %(prevStatus)s"),
            { "prevStatus": prevStatus },
            true)
    },
    sentTipDmNotice: (num: number): string => {
        return interpolate(ngettext(
            "You tipped %(num)s token.",
            "You tipped %(num)s tokens.", num),
        { "num": num }, true)
    },
    receivedTipDmNotice: (otherUser: string, num: number): string => {
        return interpolate(ngettext(
            "%(otherUser)s tipped you %(num)s token.",
            "%(otherUser)s tipped you %(num)s tokens.", num),
        { "otherUser": otherUser, "num": num }, true)
    },
    receivedTipDmNoticeWithMessage: (otherUser: string, num: number, message: string): string => {
        return interpolate(ngettext(
            "%(otherUser)s tipped you %(num)s token. -- %(message)s",
            "%(otherUser)s tipped you %(num)s tokens. -- %(message)s", num),
        { "otherUser": otherUser, "num": num, "message": message }, true)
    },
    anonUsersCount: (anonCount: number): string => {
        return interpolate(ngettext(
            "+%(anonCount)s anonymous user",
            "+%(anonCount)s anonymous users", anonCount),
        { "anonCount": anonCount }, true)
    },
    directMessagesPageTitle: (currentUsername: string, otherUsername: string): string => {
        return interpolate(gettext("Direct Messages (%(currentUsername)s) - %(otherUsername)s"),
            { "currentUsername": currentUsername, "otherUsername": otherUsername },
            true)
    },
    todayAtTime: (time: string): string => {
        return interpolate(gettext("Today at %(time)s"),
            { "time": time },
            true)
    },
    yesterdayAtTime: (time: string): string => {
        return interpolate(gettext("Yesterday at %(time)s"),
            { "time": time },
            true)
    },
    shortcodeEnteredError: (message: string): string => {
        return interpolate(gettext("Invalid shortcode message entered: %(message)s"),
            { "message": message },
            true)
    },
    shortcodeFollowMessage: (username: string): string => {
        return interpolate(gettext("Follow %(username)s"),
            { "username": username },
            true)
    },
    shortcodeFanclubMessage: (username: string): string => {
        return interpolate(gettext("Join %(username)s's Fan Club"),
            { "username": username },
            true)
    },
    shortcodeHelpSignup: (): string => interpolate(
        gettext("Generates a clickable link with the text \"%(signupMsg)s\". Upon clicking the link, it opens the signup page using the broadcaster user's affiliate tracking tags. The shortcode is designed to always return the signup link with the broadcaster's campaign/affiliate code, regardless of who in the broadcaster's room types in that shortcode."),
        { signupMsg: i18n.joinCB },
        true,
    ),
    shortcodeHelpSupporter: (): string => interpolate(
        gettext("Generates a clickable link with the text \"%(supporterMsg)s\". Upon clicking this link, the user is directed to the supporter page to upgrade their membership."),
        { supporterMsg: i18n.becomeSupporter },
        true,
    ),
    shortcodeHelpFanclub: (username: string): string => interpolate(
        gettext("Generates a clickable link with the text \"%(fanclubMsg)s\". When the link is clicked, it opens the broadcaster's Fan Club page. If the user doesn't have a Fan Club set up, an error dialog will be displayed to explain this."),
        { fanclubMsg: i18n.shortcodeFanclubMessage(username) },
        true,
    ),
    shortcodeHelpFollow: (username: string): string => interpolate(
        gettext("Generates a clickable link with the text \"%(followMsg)s\". When a user clicks on this link, it triggers the user to follow the room. If the user is already following the room, the shortcode is designed to do nothing."),
        { followMsg: i18n.shortcodeFollowMessage(username) },
        true,
    ),
    shortcodeHelpTip: (): string => interpolate(
        gettext("Generates a clickable link with the text representing the specified amount, such as \"50 tokens.\" Upon clicking the link, it opens a tipping dialog with the given amount and message (in this case, \"%(tipExample)s\") prepopulated in the dialog. This shortcode is allowed only for broadcasters and apps."),
        { tipExample: i18n.shortcodeHelpTipExample },
        true,
    ),
    roomlistPageTitleHashtag: (tagName: string, siteName: string): string => {
        return interpolate(gettext("%(tagName)s Cams @ %(siteName)s -  Free Adult Webcams & Live Sex"), { "tagName": tagName, "siteName": siteName }, true)
    },
    roomlistPageTitleFemale: (siteName: string): string => {
        return interpolate(gettext("Free Chat with Girls - Live Cam Girls, Free Webcam Girls at %(siteName)s"), { "siteName": siteName }, true)
    },
    roomlistPageTitleMale: (siteName: string): string => {
        return interpolate(gettext("Free Chat with Men - Live Gay Cams, Free Gay Webcams at %(siteName)s"), { "siteName": siteName }, true)
    },
    roomlistPageTitleCouple: (siteName: string): string => {
        return interpolate(gettext("Free Chat with Couples - Live Cam Couples, Free Webcam Couples at %(siteName)s"), { "siteName": siteName }, true)
    },
    roomlistPageTitleTrans: (siteName: string): string => {
        return interpolate(gettext("Free Chat with Trans - Live Cam Trans, Free Webcam Trans at %(siteName)s"), { "siteName": siteName }, true)
    },
    roomlistPageTitleDefault: (siteName: string): string => {
        return interpolate(gettext("%(siteName)s - Free Adult Webcams, Live Sex, Free Sex Chat, Exhibitionist & Pornstar Free Cams"), { "siteName": siteName }, true)
    },
    roomlistPageTitlePremium: (siteName: string): string => {
        return interpolate(gettext("Private & Spy Cams @ %(siteName)s - Adult Webcams & Live Sex"), { "siteName": siteName }, true)
    },
    metaTitlePremium: (siteName: string): string => {
        return interpolate(gettext("Premium Private Cam Shows at %(siteName)s"), { "siteName": siteName }, true)
    },
    metaTitleFemale: (siteName: string): string => {
        return interpolate(gettext("Free Chat with Cam Girls at %(siteName)s!"), { "siteName": siteName }, true)
    },
    metaTitleMale: (siteName: string): string => {
        return interpolate(gettext("Free Chat with Cam Guys at %(siteName)s!"), { "siteName": siteName }, true)
    },
    metaTitleCouples: (siteName: string): string => {
        return interpolate(gettext("Free Chat with Cam Couples at %(siteName)s!"), { "siteName": siteName }, true)
    },
    metaTitleTrans: (siteName: string): string => {
        return interpolate(gettext("Free Trans Cams and Chat at %(siteName)s!"), { "siteName": siteName }, true)
    },
    metaTitleHashtag: (siteName: string, hashtag: string): string => {
        return interpolate(gettext("%(siteName)s - %(hashtag)s Live Webcams"), { "siteName": siteName, "hashtag": hashtag }, true)
    },
    metaTitleDefault: (siteName: string): string => {
        return interpolate(gettext("%(siteName)s - Free Adult Live Webcams!"), { "siteName": siteName }, true)
    },
    metaContentCardDescriptionHashtag: (hashtag: string): string => {
        return interpolate(gettext("Enjoy free %(hashtag)s webcams and live chat broadcasts from amateurs. No registration required!"), { "hashtag": hashtag }, true)
    },
    metaDescriptionFemale: (domain: string): string => {
        return interpolate(gettext("Chat with live cams girls on %(domain)s! NSFW - Uncensored chat & adult webcams"), { "domain": domain }, true)
    },
    metaDescriptionMale: (domain: string): string => {
        return interpolate(gettext("Talk with gay guys and men instantly on %(domain)s! NSFW - Uncensored chat & gay webcams"), { "domain": domain }, true)
    },
    metaDescriptionCouples: (domain: string): string => {
        return interpolate(gettext("Watch live couples chatting on %(domain)s! NSFW - Uncensored chat & adult webcams"), { "domain": domain }, true)
    },
    metaDescriptionTrans: (domain: string): string => {
        return interpolate(gettext("Enjoy wild live trans webcams on %(domain)s! NSFW - Uncensored chat & trans webcams"), { "domain": domain }, true)
    },
    metaKeywordsHashtag: (hashtag: string): string => {
        return interpolate(gettext("%(hashtag)s cams, %(hashtag)s sex, %(hashtag)s xxx, %(hashtag)s porn, %(hashtag)s webcams"), { "hashtag": hashtag }, true)
    },
    rssLinkTitle: (camCategory: string, siteName: string): string => {
        return interpolate(gettext("%(camCategory)s Cams RSS Feed for %(siteName)s"), { "camCategory": camCategory, "siteName": siteName }, true)
    },
    genderSymbolToIconTitle: (gender: string): string => {
        const GenderSymbolToTitleMap = new Map<Gender, string>([
            [Gender.All, ""],
            [Gender.Male, i18n.maleText],
            [Gender.Female, i18n.femaleText],
            [Gender.Couple, i18n.coupleText],
            [Gender.Trans, i18n.transText],
            [Gender.OldTrans, i18n.transText],
        ])
        const genderSymbol = parseSimpleGender(gender)
        return GenderSymbolToTitleMap.get(genderSymbol) ?? ""
    },
    errorLoadingDms: (username: string): string => {
        return interpolate(gettext("Error loading DM conversation with %(username)s"),
            { "username": username },
            true)
    },
    v1AppsAndBotsFooterText: (): string => {
        return interpolate(gettext("Create or update v1 Apps & Bots on the <a href='%(link)s' target='_blank' rel='noopener noreferrer'>v1 Apps & Bots page</a>."),
            { "link": normalizeResource("/apps/") },
            true)
    },
    ieSupportNotification: (): string => {
        return interpolate(gettext("We noticed you're using a web browser that we don't support. Upgrade to a recent version of <br> <a href=\"%(chromeLink)s\">Chrome</a>, <a href=\"%(firefoxLink)s\">Firefox</a>, or <a href=\"%(safariLink)s\">Safari</a>"),
            { "chromeLink": normalizeResource("https://www.google.com/chrome/"), "firefoxLink": normalizeResource("https://www.mozilla.org/firefox/"), "safariLink": normalizeResource("https://support.apple.com/downloads/safari/") },
            true)
    },
    addTFANotification: (): string => {
        return interpolate(gettext("Add an extra layer of protection to your account by setting up 2-Step Verification at the <a href=\"%(link)s\">Security Center</a>"),
            { "link": normalizeResource("/security/") },
            true)
    },
    camAndMicPermissionSupportInfo: (): string => {
        return interpolate(gettext("Visit our <a class='hrefColor' id='camAndMicInfoLink' target='_blank' href='%(link)s'>support page</a> for help enabling your devices."),
            { "link": normalizeResource("https://support.chaturbate.com/hc/en-us/articles/360040862791/") },
            true)
    },
    enableAgeVerificationToReceiveTokens: (): string => {
        return interpolate(gettext("You must <a class=\"hrefColor\" target=\"_blank\" href=\"%(link)s\"> submit age verification </a> to enable the ability to receive tokens while broadcasting."),
            { "link": normalizeResource("/accounts/age_verification/") },
            true)
    },
    mustBeAgeVerifiedToAddSocialMedia: (): string => {
        return interpolate(gettext("You must be <a href=\"%(link)s\">age verified</a> to add new social media"),
            { "link": normalizeResource("/accounts/age_verification/") },
            true)
    },
    mustBeAgeVerifiedToUploadContent: (): string => {
        return interpolate(gettext("You must purchase tokens or be <a href=\"%(link)s\">age verified</a> to add new photos and videos"),
            { "link": normalizeResource("/accounts/age_verification/") },
            true)
    },
    errorUploadMediaLimit: (limit: number): string => {
        return interpolate(ngettext(
            "Too many files selected. Only %(limit)s file may be uploaded at a time.",
            "Too many files selected. Only %(limit)s files may be uploaded at a time.", limit),
        { "limit": limit }, true)
    },
    inactiveGuestNotice: (): string => {
        return interpolate(gettext("One of your linked Guests has become inactive. <a href=\"%(link)s\">Review your Guests</a> now."),
            { "link": normalizeResource("/accounts/age_verification/") },
            true)
    },
    visitMobileSiteOrFeedback: (): string => {
        return interpolate(gettext("Visit the <a href=\"%(link)s\" style=\"color: #0a5a83;\">mobile site</a>  again, or give us some <a style=\"color: #0a5a83;\">feedback</a> on why you left"),
            { "link": normalizeResource("/?mobile_site=1") },
            true)
    },
    ageVerifiedBody2: (): string => {
        return interpolate(gettext("To age verify another person on your account, submit their ID at the <a href=\"%(link)s\" target=\"_blank\" style=\"white-space: nowrap;\">Independent Broadcaster Verification</a> page"),
            { "link": normalizeResource("/identity/") },
            true)
    },
    roomFilteredByHashtag: (hashtag: string): string => {
        return interpolate(gettext("Rooms filtered by #%(hashtag)s "),
            { "hashtag": hashtag },
            true)
    },
    categoryDescriptionPrivate: (siteName: string): string => {
        return interpolate(gettext("Spy on private shows to experience the most exclusive shows on %(siteName)s. You won't get to interact, but it's much cheaper than buying a private show yourself."),
            { "siteName": siteName },
            true)
    },
    seconds: (seconds: number, abbrev = false): string => {
        if (abbrev) {
            return interpolate(gettext("%(seconds)s sec"), { "seconds": seconds }, true)
        } else {
            return interpolate(ngettext("%(seconds)s second", "%(seconds)s seconds", seconds),
                { "seconds": seconds }, true)
        }
    },
    secondsAgo: (seconds: number): string => {
        return interpolate(ngettext("%(seconds)s second ago", "%(seconds)s seconds ago", seconds),
            { "seconds": seconds }, true)
    },
    secondsFromNow: (seconds: number): string => {
        return interpolate(ngettext("%(seconds)s second from now", "%(seconds)s seconds from now", seconds),
            { "seconds": seconds }, true)
    },
    minutes: (minutes: number, abbrev = false): string => {
        if (abbrev) {
            return interpolate(ngettext("%(minutes)s min", "%(minutes)s mins", minutes),
                { "minutes": minutes }, true)
        }
        return interpolate(ngettext("%(minutes)s minute", "%(minutes)s minutes", minutes),
            { "minutes": minutes }, true)
    },
    minutesAgo: (minutes: number): string => {
        return interpolate(ngettext("%(minutes)s minute ago", "%(minutes)s minutes ago", minutes),
            { "minutes": minutes }, true)
    },
    minutesFromNow: (minutes: number): string => {
        return interpolate(ngettext("%(minutes)s minute from now", "%(minutes)s minutes from now", minutes),
            { "minutes": minutes }, true)
    },
    hours: (hours: number, abbrev = false): string => {
        if (abbrev) {
            const hr = hours.toFixed(1)
            return interpolate(ngettext("%(hours)s hr", "%(hours)s hrs", hours), { "hours": hr }, true)
        }
        return interpolate(ngettext("%(hours)s hour ago", "%(hours)s hours ago", hours),
            { "hours": hours }, true)
    },
    hoursAgo: (hours: number): string => {
        return interpolate(ngettext("%(hours)s hour ago", "%(hours)s hours ago", hours),
            { "hours": hours }, true)
    },
    hoursFromNow: (hours: number): string => {
        return interpolate(ngettext("%(hours)s hour from now", "%(hours)s hours from now", hours),
            { "hours": hours }, true)
    },
    days: (days: number, abbrev = false): string => {
        if (abbrev) {
            return interpolate(ngettext("%(days)s d", "%(days)s d", days), { "days": days }, true)
        }
        return interpolate(ngettext("%(days)s day", "%(days)s days", days), { "days": days }, true)
    },
    daysAgo: (days: number): string => {
        return interpolate(ngettext("%(days)s day ago", "%(days)s days ago", days),
            { "days": days }, true)
    },
    daysFromNow: (days: number): string => {
        return interpolate(ngettext("%(days)s day from now", "%(days)s days from now", days),
            { "days": days }, true)
    },
    weeks: (weeks: number, abbrev = false): string => {
        if (abbrev) {
            return interpolate(ngettext("%(weeks)s wk", "%(weeks)s wks", weeks), { "weeks": weeks }, true)
        }
        return interpolate(ngettext("%(weeks)s week", "%(weeks)s weeks", weeks), { "weeks": weeks }, true)
    },
    weeksAgo: (weeks: number): string => {
        return interpolate(ngettext("%(weeks)s week ago", "%(weeks)s weeks ago", weeks),
            { "weeks": weeks }, true)
    },
    weeksFromNow: (weeks: number): string => {
        return interpolate(ngettext("%(weeks)s week from now", "%(weeks)s weeks from now", weeks),
            { "weeks": weeks }, true)
    },
    months: (months: number, abbrev = false): string => {
        if (abbrev) {
            return interpolate(ngettext("%(months)s mo", "%(months)s mos", months), { "months": months }, true)
        }
        return interpolate(ngettext("%(months)s month", "%(months)s months", months), { "months": months }, true)
    },
    monthsAgo: (months: number): string => {
        return interpolate(ngettext("%(months)s month ago", "%(months)s months ago", months),
            { "months": months }, true)
    },
    monthsFromNow: (months: number): string => {
        return interpolate(ngettext("%(months)s month from now", "%(months)s months from now", months),
            { "months": months }, true)
    },
    years: (years: number, abbrev = false): string => {
        if (abbrev) {
            return interpolate(ngettext("%(years)s yr", "%(years)s yrs", years), { "years": years }, true)
        }
        return interpolate(ngettext("%(years)s year", "%(years)s years", years), { "years": years }, true)
    },
    yearsAgo: (years: number): string => {
        return interpolate(ngettext("%(years)s year ago", "%(years)s years ago", years),
            { "years": years }, true)
    },
    yearsFromNow: (years: number): string => {
        return interpolate(ngettext("%(years)s year from now", "%(years)s years from now", years),
            { "years": years }, true)
    },
    ageRangeTo: (fromAge?: number | string, toAge?: number | string): string => {
        // If ages are omitted, can just render the "to" with the appropriate context of representing an age range
        const fromStr = String(fromAge ?? "")
        const toStr = String(toAge ?? "")
        return interpolate(gettext("%(fromAge)s to %(toAge)s"), { fromAge: fromStr, toAge: toStr }, true)
    },
    tipSentAction: (username: string): string => {
        return interpolate(gettext("Outbound Tip: <a class='hrefColor' href=\"%(link)s\" rel='noopener' target='_blank'>%(username)s</a>"), { username: username, link: normalizeResource(`/${username}`) }, true)
    },
    tipReceivedAction: (username: string): string => {
        return interpolate(gettext("Tip From: <a class='hrefColor' href=\"%(link)s\" rel='noopener' target='_blank'>%(username)s</a>"), { username: username, link: normalizeResource(`/${username}`) }, true)
    },
    promotedRoomAction: (username: string): string => {
        return interpolate(gettext("Promoted Room: <a class='hrefColor' href=\"%(link)s\" rel='noopener' target='_blank'>%(username)s</a>"), { username: username, link: normalizeResource(`/${username}`) }, true)
    },
    groupShowAction: (username: string): string => {
        return interpolate(gettext("Group Show: <a class='hrefColor' href=\"%(link)s\" rel='noopener' target='_blank'>%(username)s</a>"), { username: username, link: normalizeResource(`/${username}`) }, true)
    },
    spyShowAction: (username: string): string => {
        return interpolate(gettext("Spy Show: <a class='hrefColor' href=\"%(link)s\" rel='noopener' target='_blank'>%(username)s</a>"), { username: username, link: normalizeResource(`/${username}`) }, true)
    },
    fanClubShowAction: (username: string): string => {
        return interpolate(gettext("Fan Club Membership: <a class='hrefColor' href=\"%(link)s\" rel='noopener' target='_blank' data-room=\"%(username)s\">%(username)s</a>"), { username: username, link: normalizeResource(`/${username}`) }, true)
    },
    privateShowAction: (username: string): string => {
        return interpolate(gettext("Private Show: <a class='hrefColor' href=\"%(link)s\" rel='noopener' target='_blank'>%(username)s</a>"), { username: username, link: normalizeResource(`/${username}`) }, true)
    },
    premiumPrivateShowAction: (username: string): string => {
        return interpolate(gettext("Premium Private Show: <a class='hrefColor' href=\"%(link)s\" rel='noopener' target='_blank'>%(username)s</a>"), { username: username, link: normalizeResource(`/${username}`) }, true)
    },
    adminAdjustmentAction: (note: string): string => {
        return interpolate(gettext("Admin Adjustment: %(note)s"), { note: note }, true)
    },
    bioAndFreeWebCamText: (username: string): string => {
        return interpolate(gettext("%(username)s's Bio and Free Webcam"), { username: username }, true)
    },
    bitrateMuchLowerThanHigh: (lowBitrate: number): string => {
        return interpolate(gettext("Your bitrate is much lower than the recommended minimum of %(lowBitrate)skbps for this resolution at a high frame rate. You may need to switch to a standard frame rate."),
            { lowBitrate: lowBitrate }, true)
    },
    bitrateMuchLower: (lowBitrate: number): string => {
        return interpolate(gettext("Your bitrate is much lower than the recommended minimum of %(lowBitrate)skbps for this resolution."),
            { lowBitrate: lowBitrate }, true)
    },
    bitrateLowerThanHigh: (lowBitrate: number): string => {
        return interpolate(gettext("Your bitrate is lower than the recommended minimum of %(lowBitrate)skbps for this resolution at a high frame rate. You may need to switch to a standard frame rate."),
            { lowBitrate: lowBitrate }, true)
    },
    bitrateLower: (lowBitrate: number): string => {
        return interpolate(gettext("Your bitrate is lower than the recommended minimum of %(lowBitrate)skbps for this resolution."),
            { lowBitrate: lowBitrate }, true)
    },
    promotionConfirmPriceText: (tokens: number): string => {
        return interpolate(ngettext("Current Price: %(tokens)s token", "Current Price: %(tokens)s tokens", tokens),
            { "tokens": tokens }, true)
    },
    tapToChatWithUser: (username: string): string => {
        return interpolate(gettext("Tap here to chat with %(username)s."),
            { "username": username }, true)
    },
    privateShowEarlyCancelMessage: (username: string, tokens: number): string => {
        return interpolate(gettext(" %(username)s has paid %(tokens)s tokens to end the private show early."),
            { "username": username, "tokens": tokens }, true)
    },
    nNewMessages: (count: number): string => {
        return interpolate(ngettext(
            "%(count)s new message",
            "%(count)s new messages", count),
        { "count": count }, true)
    },
    emoticonAutocompleteNoDelay: (verbose = true): string => {
        if (verbose) {
            return gettext("No Delay - 0s")
        }
        return gettext("0s")
    },
    emoticonAutocompleteShortDelay: (verbose = true): string => {
        if (verbose) {
            return gettext("Short - 0.5s")
        }
        return gettext("0.5s")
    },
    emoticonAutocompleteMediumDelay: (verbose = true): string => {
        if (verbose) {
            return gettext("Medium - 1s")
        }
        return gettext("1s")
    },
    emoticonAutocompleteLongDelay: (verbose = true): string => {
        if (verbose) {
            return gettext("Long - 1.5s")
        }
        return gettext("1.5s")
    },
    highestTokenColorDarkPurple: (verbose = true): string => {
        if (verbose) {
            return gettext("Dark Purple (Tipped 1000 recently)")
        }
        return gettext("Dark Purple")
    },
    highestTokenColorLightPurple: (verbose = true): string => {
        if (verbose) {
            return gettext("Light Purple (Tipped 250 recently)")
        }
        return gettext("Light Purple")
    },
    highestTokenColorDarkBlue: (verbose = true): string => {
        if (verbose) {
            return gettext("Dark Blue (Tipped 50 recently)")
        }
        return gettext("Dark Blue")
    },
    highestTokenColorLightBlue: (verbose = true): string => {
        if (verbose) {
            return gettext("Light Blue (Owns or purchased tokens)")
        }
        return gettext("Light Blue")
    },
    tokensBadge: (tokens: number): string => {
        return interpolate(gettext("%(tokens)s TKS"), { "tokens": tokens }, true)
    },
    userListCountCAPS: (userCount: number): string => {
        return interpolate(gettext("USERS (%(userCount)s)"), { "userCount": userCount }, true)
    },
    improvedMobileSite: (siteName: string): string => {
        return interpolate(gettext("We've improved %(siteName)s's mobile site by adding these feature(s):"), { "siteName": siteName }, true)
    },
    PmCAPS: gettext("PM"),
    sendDmCAPS: gettext("SEND DM"),
    pmMenuTitle: gettext("Send PM"),
    dmMenuTitle: gettext("Send DM"),
    pmSupporterNotice: gettext("You must be a supporter, fan club member, or moderator to send this private message."),
    pmSupporterNoticeAgeVerified: gettext("You must be a supporter, fan club member, moderator, or have received a tip today from this user to send this private message."),
    dmSupporterNotice: gettext("You must be a supporter, fan club member, or moderator to send this direct message."),
    dmSupporterNoticeAgeVerified: gettext("You must be a supporter, fan club member, moderator, or have received a tip in the past week from this user to send this direct message."),
    pmSupporterSignUp: gettext("Sign up for a"),
    pmSupporterMembership: gettext("Supporter Membership"),
    newPrivateMessageClickPrompt: gettext(" (<a class='msg-link underline-link' style='cursor:pointer'>open conversation</a> or press TAB to cycle through)"),
    newConversationPmClickHTML: gettext("<a class='msg-link underline-link' style='cursor:pointer'>open in PMs</a> or press TAB to cycle through"),
    newConversationPmClickMobile: gettext("open in PMs"),
    newConversationDmClick: gettext("open in DMs"),
    openConversationLower: gettext("open conversation"),
    joinChaturbateCommunity: gettext("Join Chaturbate's community."),
    roomSignupPopupText: gettext("Chat live, follow broadcasters, access private shows, or earn money!"),
    roomSignupPopupSignupText: gettext("Sign Up Free"),
    chatColor: gettext("Chat Color"),
    font: gettext("Font"),
    fontSize: gettext("Font Size"),
    showEmoticons: gettext("Show Emoticons"),
    emoticonAutoCompleteDelay: gettext("Autocomplete Delay"),
    sortUsers: gettext("Sort Users"),
    highestTokenColor: gettext("Highest Token Color"),
    chatAllowedBy: gettext("Chat Allowed By"),
    moderatorsExpireAfter: gettext("Moderators Expire After"),
    silenceOtherBroadcasters: gettext("Silence Other Age-Verified Broadcasters"),
    yesAlways: gettext("Yes, Always"),
    preventC2CRedisplay: gettext("Cam to Cam Notification Rate Limit"),
    noLimit: gettext("No Limit"),
    fiveMinutes: gettext("Once per User Every 5 Minutes"),
    forever: gettext("Once per User Until You View Their Cam"),
    tipVolume: gettext("Tip Volume"),
    collapseNotices: gettext("Collapse Notices"),
    viewEditIgnoredUsers: gettext("View/Edit Ignored Users"),
    tagLine: gettext("THE ACT OF MASTURBATING WHILE CHATTING ONLINE"),
    tagsCAPS: gettext("TAGS"),
    myCollectionText: gettext("My Collection"),
    loginVerbText: gettext("Log in"),
    signUpText: gettext("Sign Up"),
    signUpLower: gettext("Sign up"),
    defaultTipMessageLabel: gettext("Include an optional message:"),
    TipOptionalMessageLabel: gettext("Optional message:"),
    sendTipButtonText: gettext("Send Tip"),
    sendTipButtonCAPS: gettext("SEND TIP"),
    sendTipText: gettext("Send a tip"),
    publicTippingText: gettext("Public Tipping"),
    publicTippingDesc: gettext("Username is visible to all users."),
    anonTippingText: gettext("Anonymous Tipping"),
    anonTippingDesc: gettext("Username only visible to broadcaster."),
    anonButtonText: gettext("Send Anonymous Tip"),
    sending: gettext("Sending..."),
    anonTipDisabledModalCont: gettext("Click OK to continue to supporter signup."),
    mobileTipMessageLabelLandscape: gettext("Include optional message"),
    mobileTipMessageLabelPortrait: gettext("Tip note..."),
    mobileChatLabel: gettext("Tap to chat..."),
    broadcasterDoesNotAcceptTips: gettext("This broadcaster doesn't accept tips."),
    purchaseTokensText: gettext("Purchase Tokens"),
    myProfileTextLc: gettext("My Profile"),
    viewProfile: gettext("View Profile"),
    liveChatSupportTextLc: gettext("Contact Support"),
    logOutTextLc: gettext("Log Out"),
    logoutModalText: gettext("Are you sure you want to log out?"),
    switchAccountModalText: gettext("Are you sure you want to switch accounts?"),
    sendFeedbackTextLc: gettext("Send Feedback"),
    statusText: gettext("Status"),
    tokensLabel: gettext("You have:"),
    getMoreTokensLabel: gettext("Get more tokens"),
    chatDisconnectedMessage: gettext("Chat disconnected. The broadcaster has set a new password on this room."),
    passwordSetMessage: gettext("This room is now password protected."),
    passwordRemovedMessage: gettext("This room is no longer password protected."),
    privateText: gettext("Private"),
    premiumShowDesc: gettext("Premium private shows cannot be spied on."),
    premiumShowDisabled: gettext("Premium private shows are disabled because spying is disabled."),
    privateShowStartLabel: gettext("Start Private Show"),
    privateShowSpyLabel: gettext("Spy on Private Show"),
    disabledShowSpyWarning: gettext("Spying is unavailable."),
    premiumInProgressMessage: gettext("Premium private show in progress."),
    privateShowStartedMessage: gettext("Private show has started."),
    premiumPrivateShowStartedMessage: gettext("Premium private show has started. Spying is not allowed."),
    privateShowFinishedMessage: gettext("Private show has finished."),
    premiumPrivateShowFinishedMessage: gettext("Premium private show has finished."),
    privateShowRequestLabel: gettext("Request Private Show"),
    privateShowCancelRequestLabel: gettext("Cancel Private Show Request"),
    privateShowRequestCancelMessage: gettext("Cancel Private Request"),
    privateShowRequestCancelErrorMessage: gettext("Cannot cancel private show. Please try again in a few seconds."),
    privateShowLeaveLabel: gettext("Leave Private Show"),
    privateShowLeaveWarning: gettext("Are you sure you want to leave the private show?"),
    privateRequestCancelConfirmation: gettext("Are you sure you want to cancel your private show request?"),
    privateShowRequestMessage: gettext("Private show request has been sent. Waiting on broadcaster to approve."),
    premiumPrivateShowRequestMessage: gettext("Premium private show request has been sent. Waiting on broadcaster to approve."),
    privateShowRecordingSavedMessage: gettext("A recording of this private show has been saved in the buyer's collection!"),
    requestPrivate: gettext("Request Private"),
    cancelRequest: gettext("Cancel Request"),
    leavePrivate: gettext("Leave Private"),
    spyOnPrivate: gettext("Spy on Private"),
    returnFromAwayModeMessage: gettext("Broadcaster has returned from away mode."),
    rejoinedRoomKickedMessage: gettext("You were disconnected because you have joined this room again."),
    kickedFromRoomMessage: gettext("The broadcaster has kicked you from the room."),
    kickedMessage: gettext("You were kicked from the room."),
    signUpToChatText: gettext("signup to chat"),
    aboutMeText: gettext("About Me"),
    wishListText: gettext("Wish List"),
    purchasedCAPS: gettext("PURCHASED"),
    orCAPS: gettext("OR"),
    photosAndVideosText: gettext("Photos and Videos"),
    realNameText: gettext("Real Name"),
    followersText: gettext("Followers"),
    sexText: gettext("I Am"),
    sexTextCouple: gettext("We are"),
    birthdayText: gettext("Birthday"),
    birthdateText: gettext("Birth Date"),
    ageText: gettext("Age"),
    dateText: gettext("Date"),
    actionText: gettext("Action"),
    bodyTypeText: gettext("Body Type"),
    bodyDecorationsText: gettext("Body Decorations"),
    interstedInText: gettext("Interested In"),
    locationText: gettext("Location"),
    languagesText: gettext("Language(s)"),
    lastBroadcastText: gettext("Last Broadcast"),
    langaugesSpokenText: gettext("Languages"),
    smokeDrinkText: gettext("Smoke Drink"),
    smokeOrDrinkText: gettext("Smoke / Drink"),
    bioUnavailableText: gettext("User has no available BIO"),
    errorSubmitting: gettext("Unable to submit"),
    submittingText: gettext("Submitting..."),
    loadingText: gettext("Loading"),
    loadingTextLower: gettext("loading"),
    chatSettings: gettext("Chat Settings"),
    chatSettingsTabHandleText: gettext("SETTINGS"),
    chatSettingsSavedText: gettext("Chat settings saved"),
    chatCAPS: gettext("CHAT"),
    sendCAPS: gettext("SEND"),
    tipCAPS: gettext("TIP"),
    usersText: gettext("Users"),
    aUser: gettext("a user"),
    reportAbuseText: gettext("Report Room"),
    reportAbuseTextTerms: gettext("Your room report will be reviewed as soon as practicable."),
    moreRoomsText: gettext("More Rooms"),
    spyPrivateShowLower: gettext("spy private show"),
    unfollowText: gettext("Unfollow"),
    unfollowLower: gettext("unfollow"),
    followText: gettext("Follow"),
    notifyMe: gettext("Notify me"),
    notifyOptionAlways: gettext("Always"),
    notifyOptionAuto: gettext("Auto"),
    never: gettext("Never"),
    refreshRoomsText: gettext("Refresh Rooms"),
    womenText: gettext("Women"),
    womenLower: gettext("women"),
    femaleText: gettext("Female"),
    menText: gettext("Men"),
    menLower: gettext("men"),
    maleText: gettext("Male"),
    transText: gettext("Trans"),
    couplesText: gettext("Couples"),
    couplesLower: gettext("couples"),
    coupleText: gettext("Couple"),
    saveCAPS: gettext("SAVE"),
    recentlyUsedEmojis: gettext("Recently used"),
    smileysPeopleEmojis: gettext("Smileys & People"),
    animalsNatureEmojis: gettext("Animals & Nature"),
    foodDrinkEmojis: gettext("Food & Drink"),
    activitiesEmojis: gettext("Activities"),
    travelPlacesEmojis: gettext("Travel & Places"),
    objectsEmojis: gettext("Objects"),
    symbolsEmojis: gettext("Symbols"),
    flagsEmojis: gettext("Flags"),
    uploadEmoticonsText: gettext("Upload"),
    tone: gettext("Tone"),
    currentBalanceText: gettext("Your current balance:"),
    balanceText: gettext("Balance:"),
    tokenBalanceText: gettext("Token Balance"),
    currentHaveText: gettext("You currently have: "),
    satisfactionWarningText: gettext("WARNING: This room has a low satisfaction rating."),
    tipWarningText: gettext("TIP AT YOUR OWN RISK!"),
    tipAmountText: gettext("Enter tip amount:"),
    tipAmountInvalid: gettext("Invalid tip amount!"),
    amountText: gettext("Amount:"),
    toggleWindowMessage: gettext("Toggle this window with Ctrl+S"),
    leaveOpenCheckBoxLabel: gettext("Leave open after tipping"),
    selectOneLabel: gettext("Select One"),
    selectReason: gettext("Please select a reason"),
    loadingUserListText: gettext("Loading user list"),
    refreshUserListText: gettext("refresh userlist"),
    refreshUserListSentenceCase: gettext("Refresh userlist"),
    errorRefreshingUserList: gettext("Error refreshing user list. Please try again later."),
    sendAMessage: gettext("Send a message"),
    sendAMessageDesktop: gettext("Send a message..."),
    sendPrivateMessageText: gettext("Send private message"),
    sendDirectMessageText: gettext("Send direct message"),
    loggedInToSendAMessage: gettext("You must be logged in to send a message. Click \"OK\" to login."),
    loggedInToPm: gettext("You must be logged in to send a private message. Click \"OK\" to login."),
    loggedInToDm: gettext("You must be logged in to send a direct message. Click \"OK\" to login."),
    mentionUserText: gettext("Mention this user"),
    mention: gettext("Mention"),
    showMoreText: gettext("Show More"),
    picsAndVideosText: gettext("Pics & Videos"),
    picsText: gettext("Pictures"),
    vidsText: gettext("Videos"),
    socialMediaText: gettext("Social Media"),
    appsAndBots: gettext("Apps & Bots"),
    appsTabDescription: gettext("Add features like <strong>Tip Menus</strong>, <strong>Ticket Shows</strong>, and <strong>Top Tippers</strong> to your room with apps!"),
    v2AppsDescription: gettext("v2 Apps can keep track of your top visitors and tippers across multiple broadcasts."),
    newCapitalized: gettext("New"),
    apps: gettext("Apps"),
    games: gettext("Games"),
    dismissMessage: gettext("dismiss this message"),
    hashtagWarning: gettext("Only 5 #hashtags will be applied"),
    dismissLower: gettext("dismiss"),
    registerLaterText: gettext("Register later"),
    alreadyRegisteredText: gettext("Already have an account?"),
    loginHereText: gettext("Login here"),
    usernameText: gettext("Username"),
    passwordText: gettext("Password"),
    keepLoggedInText: gettext("Keep me logged in"),
    forgotPassword: gettext("Forgot password?"),
    incorrectPassword: gettext("Incorrect password"),
    satisfactionVoting: gettext("Satisfaction Voting"),
    orText: gettext("Or "),
    orLower: gettext("or"),
    followerBrowserNotifications: gettext("Follower browser notifications"),
    allowBrowserNotificationsMessage: gettext("Would you like to receive browser notifications when a broadcaster you follow comes online?"),
    followSubscribeFailedMessage: gettext("Subscription failure. Please try again from the Settings & Privacy page on your profile."),
    yesText: gettext("Yes"),
    notNowText: gettext("Not now"),
    roomRequiresPasswordText: gettext("This room requires a password."),
    loginToRoom: gettext("Login to room"),
    moderators: gettext("Moderators"),
    removeModerators: gettext("Remove Moderators"),
    nonModerators: gettext("Non-Moderators"),
    removeIgnoredUsers: gettext("Remove Ignored Users"),
    unignoreThisUserText: gettext("Unignore this user"),
    unignoredUsers: gettext("Unignored Users"),
    unignore: gettext("Unignore"),
    ignore: gettext("Ignore"),
    ignoreThisUserText: gettext("Ignore this user"),
    reportThisMessageText: gettext("Report this message"),
    ignoredUsers: gettext("Ignored Users"),
    clickToUnignoreText: gettext("Click on a user to unignore them."),
    clickToUnignoreMobileText: gettext("Select a user from the list to unignore them."),
    clickToUnmodText: gettext("Click on a user to revoke their moderator status."),
    loginForIgnore: gettext("You must be logged in to ignore a user"),
    loginForIgnoreClickOkToLogin: gettext("You must be logged in to ignore a user. Click \"OK\" to login."),
    reachedMaxIgnore: gettext("You have reached the maximum number of ignored users. Ignoring a new user when at the limit will remove your oldest ignore. Click OK to continue"),
    roomOfflineMessage: gettext("Room is currently offline"),
    instantNotificationMessage: gettext("Follow broadcasters to receive instant notifications when they come online."),
    unfollow: gettext("UNFOLLOW"),
    follow: gettext("FOLLOW"),
    shareText: gettext("Share"),
    bioText: gettext("Bio"),
    contestStatsText: gettext("Contest Stats"),
    settingsAndPrivacy: gettext("Settings & Privacy"),
    settingsSaved: gettext("Settings saved"),
    settingsNotSaved: gettext("Please fix the errors to save the unsaved settings."),
    tokenStats: gettext("Token Stats"),
    broadcasterStats: gettext("Broadcaster Stats"),
    memberships: gettext("Memberships"),
    submitYourFeedbackText: gettext("Submit feedback to broadcaster"),
    optionalCommentNote: gettext("Optional comment for broadcaster:"),
    commentSubmittedMessage: gettext("Submitted"),
    satisfiedText: gettext("Satisfied?"),
    scanCamsText: gettext("SCAN CAMS"),
    nextCamText: gettext("NEXT CAM"),
    scanNextText: gettext("SCAN / NEXT"),
    scanText: gettext("SCAN"),
    skipCamText: gettext("SKIP CAM"),
    exitScanningText: gettext("EXIT SCANNING"),
    tokenLinkCodesText: gettext("Token Link codes"),
    earnTokensForRegisteredUsers: gettext("Earn up to 10 tokens for every registered user and 500 tokens for users who broadcast (broadcasters must earn $20.00 before they qualify)."),
    linkCodeMessage: gettext("Please send to chaturbate using one of the link codes below."),
    embedCBTopCamText: gettext("Embed Chaturbate's Top Cam on Your Webpage"),
    affiliateProgramStatsText: gettext("the affiliate program stats"),
    affiliateStatsDetails: gettext("See details about tokens earned in "),
    silenceText: gettext("Silence"),
    silenceDurationMessage: gettext("Silence for 6 hours"),
    removeSilenceMessage: gettext("Remove Silence"),
    videoQualityLabel: gettext("Video Quality"),
    volumeLabel: gettext("Volume"),
    volumeSliderLabel: gettext("Volume Slider"),
    theaterModeLabel: gettext("Theater Mode"),
    fullScreenLabel: gettext("Fullscreen"),
    interactiveFullscreenLabel: gettext("Interactive Fullscreen"),
    nativeFullscreenLabel: gettext("Native Fullscreen"),
    switchFullscreenModeLabel: gettext("Switch Fullscreen Mode"),
    exitFullScreenLabel: gettext("Exit Fullscreen"),
    defaultViewLabel: gettext("Default View"),
    unknownText: gettext("unknown"),
    showAllText: gettext("Show All"),
    anonFollowMore: gettext(" to follow your favorite broadcasters and see when they are live."),
    userFollowMore: gettext("Follow your favorite broadcasters to see when they are live."),
    cannotPromoteRoom: gettext("You cannot promote this room."),
    userPromotionToolbarOpenBodyButton: gettext("Promote your room"),
    userPromotionNotEnoughTokensText: gettext("Not enough tokens."),
    userPromotionPurchaseTokensText: gettext("Purchase more tokens"),
    userPromotionIneligibleText: gettext("User promotion currently unavailable."),
    userPromotionPurchaserIneligibleText: gettext("You are ineligible to promote the room."),
    userPromotionBadSettingsText: gettext("User promotion currently unavailable - please check your settings."),
    userPromotionMustBeLoggedInAlert: gettext("You must be logged in to promote the room."),
    userPromotionMustBeOnlineAlert: gettext("User promotion currently unavailable - user must be broadcasting."),
    userPromotionBodyHeadline: gettext("Get More Viewers"),
    userPromotionBodyDescription: gettext("Add your room to a rotating promoted spot on the homepage for 5 minutes."),
    userPromotionDetails: gettext("details"),
    userPromotionSubmit: gettext("Promote now"),
    userPromotionCurrentPriceLabel: gettext("Current price"),
    userPromotionSettingUp: gettext("Setting up user promotion..."),
    userPromotionEnding: gettext("Promotion ending..."),
    userPromotionPriceUnknownException: gettext("Unknown error occurred while retrieving promotion purchase price. Please try again."),
    userPromotionSubmitUnknownException: gettext("Unknown error occurred while purchasing user promotion. Please try again."),
    userPromotionLoadingNewPriceText: gettext("Loading new pricing..."),
    broadcastNotificationTopBarOpenBodyButton: gettext("Announce you're online"),
    broadcastNotificationTopBarCooldownText: gettext("Announce again in "),
    broadcastNotificationTopBarFollowerTextSingularOld: gettext("You have 1 follower"),
    broadcastNotificationImageContainerHeadline: gettext("Send email and browser notifications to your followers."),
    broadcastNotificationUploadLink: gettext("Add a photo"),
    broadcastNotificationUploadLinkReplace: gettext("Update photo"),
    broadcastNotificationImageHint1: gettext("Must be non-nude"),
    broadcastNotificationImageHint2: gettext("Recommended size: 1280 x 720 px"),
    broadcastNotificationImageHint3: gettext("Shown in notification and featured emails"),
    broadcastNotificationButtonSendNotification: gettext("Send notifications"),
    broadcastNotificationButtonSendNotificationInProgress: gettext("In Progress"),
    broadcastNotificationTooltip: gettext("New photos must be approved. If not approved in 15 minutes, email notifications will be sent without a photo."),
    broadcastNotificationConfirmSendWithoutImage: gettext("Are you sure you want to notify your followers without an image?"),
    broadcastNotificationUseApprovedFormat: gettext("Please use a JPEG, PNG, HEIC, or HEIF image."),
    broadcastNotificationImageTooLarge: gettext("Image must not be larger than 10 megabytes."),
    broadcastNotificationAlreadySent: gettext("A notification has already been sent."),
    broadcastNotificationErrorProcessing: gettext("Something went wrong processing your image. Please try again."),
    broadcastNotificationErrorAfterSubmit: gettext("Something went wrong after submit. Please try again."),
    broadcastNotificationErrorDuringSubmit: gettext("Something went wrong during submit. Please try again."),
    broadcastNotificationTooSmallDimensions: gettext("Image must be at least 200px wide and 200px tall."),
    broadcastNotificationTooWide: gettext("Image is too wide."),
    broadcastNotificationTooTall: gettext("Image is too tall."),
    broadcastNotificationRemoveWhileInProgress: gettext("Please wait until all pending notifications have been sent."),
    broadcastNotificationRemoveApprovedPhoto: gettext("Remove approved photo?"),
    broadcastNotificationBadBrowserNotice: gettext("Sorry, this feature is not supported in your browser. Please upgrade to a modern browser like Chrome or Firefox."),
    close: gettext("Close"),
    closeWindow: gettext("Close Window"),
    cancelText: gettext("Cancel"),
    cancelLower: gettext("cancel"),
    cancelCAPS: gettext("CANCEL"),
    submitCAPS: gettext("SUBMIT"),
    submitText: gettext("Submit"),
    leave: gettext("Leave"),
    request: gettext("Request"),
    ending: gettext("Ending"),
    started: gettext("Started"),
    warnOnClosingDirty: gettext("You have unsaved changes, are you sure you want to leave this page?"),
    chooseCategoryText: gettext("Choose a category"),
    reportAbuse: gettext("Report Room"),
    reportAbuseCatUnderage: gettext("Broadcaster is underage"),
    reportAbuseCatAdvertising: gettext("Broadcaster is advertising"),
    reportAbuseCatAbusive: gettext("Broadcaster is abusive"),
    reportAbuseCatIntoxicated: gettext("Broadcaster is intoxicated"),
    reportAbuseCatLarge: gettext("Using a toy that is too large"),
    reportAbuseCatOffline: gettext("Asking for offline payments"),
    reportAbuseCatPublic: gettext("Broadcasting in public"),
    reportAbuseCatUniform: gettext("Broadcasting in service uniform"),
    reportAbuseCatSleeping: gettext("Broadcaster is sleeping"),
    reportAbuseCatGender: gettext("Broadcaster is wrong gender"),
    reportAbuseCatOther: gettext("Other"),
    reportAbuseCategoryRequired: gettext("Please choose a category"),
    reportAbuseAdditionalComments: gettext("Additional comments:"),
    reportAbuseDescriptionRequired: gettext("Please add a description"),
    reportMessageInappropriate: gettext("Message is inappropriate"),
    reportMessageRudeToBcaster: gettext("Message is rude towards the broadcaster"),
    reportMessageSpam: gettext("Message is spam"),
    reportMessageOffensiveMedia: gettext("Media is offensive"),
    reportMessageOther: gettext("Other (please describe):"),
    report: gettext("Report"),
    reportCAPS: gettext("REPORT"),
    unableToReport: gettext("Unable to send report."),
    tooManyReports: gettext("Too many reports sent. Try slowing down."),
    errorRequestingPrivateShow: gettext("There was an error requesting your private show. Please try again."),
    errorRequestingSpyShow: gettext("There was an error requesting your spy show. Please try again."),
    unableToCancelSpyShow: gettext("Unable to cancel spy show"),
    unableToCancelPrivateRequest: gettext("Unable to cancel private request"),
    privateShows: gettext("Private Shows"),
    areYouSure: gettext("Are you sure?"),
    broadcasterAllowsRecordingsText: gettext("This broadcaster allows private show recordings, so you will receive a recorded video of this show in your collection."),
    performerIsAwayText: gettext("Performer Is Away"),
    privateShowLeaveLabelCAPS: gettext("LEAVE PRIVATE SHOW"),
    privateShowRequestCancelMessageCAPS: gettext("CANCEL PRIVATE REQUEST"),
    privateShowStartLabelCAPS: gettext("START PRIVATE SHOW"),
    privateShowSpyLeaveLabelCaps: gettext("LEAVE SPY SHOW"),
    privateShowText: gettext("Private Show"),
    privateShowInProgressText: gettext("Private Show in Progress"),
    featureNotEnabled: gettext("This feature is not currently enabled on your device."),
    loginForPrivateShow: gettext("You must be logged in to enter a private show. Click \"OK\" to login."),
    loginForPrivateShowSpy: gettext("You must be logged in to spy on a private show. Click \"OK\" to login."),
    privateShowSpyDisabled: gettext("Spy has been disabled by the broadcaster."),
    youWillReceiveRecording: gettext("You will receive a recorded video of this show in your collection."),
    broadcasterDisabledRecordings: gettext("This broadcaster has disabled private show recordings."),
    privatesNotAvailable: gettext("Private shows are not available for this broadcaster."),
    privateShowChatActive: gettext("Private Show chat is active."),
    tapToChatWithBroadcaster: gettext("Tap here to chat with the broadcaster."),
    goToPrivateTabToChat: gettext("Go to the Private Tab to chat with the broadcaster."),
    moreRoomsLikeThisText: gettext("More Rooms Like This"),
    trendingRoomsInstead: gettext("Here are some trending rooms instead:"),
    promotedCAPS: gettext("PROMOTED"),
    recommendedCAPS: gettext("RECOMMENDED"),
    offlineCAPS: gettext("OFFLINE"),
    exhibitionistCAPS: gettext("EXHIBITIONIST"),
    newCAPS: gettext("NEW"),
    new: gettext("New"),
    inPrivateCAPS: gettext("IN PRIVATE"),
    gamingCAPS: gettext("GAMING"),
    hiddenStaffOnlyTextCAPS: gettext("HIDDEN - STAFF ONLY"),
    viewOnTwitterText: gettext("View on X"),
    showTipsAndMessages: gettext("Show tips and messages"),
    seeMore: gettext("See more"),
    howCanWeImproveText: gettext("How Can We Improve?"),
    updatesText: gettext("Updates"),
    noPicsVideos: gettext("This user has not added any photos or videos yet."),
    morePics: gettext("More Pics"),
    moreVideos: gettext("More Videos"),
    fanTextCAPS: gettext("FAN"),
    clubTextCAPS: gettext("CLUB"),
    tokensCC: gettext("Tokens"),
    uploadedText: gettext("Uploaded"),
    purchasedPhotoSet: gettext("If you already purchased this photo set, try reloading the page to view it."),
    purchasedVideo: gettext("If you already purchased this video, try reloading the page to view it."),
    orSimple: gettext("or"),
    hiResImage: gettext("View Hi-Res Image"),
    loggedInToViewHiRes: gettext("You must be logged in to view hi-res images.  Click \"OK\" to login."),
    pressPurchaseTokens: gettext("Press \"Purchase Tokens\" to get more."),
    deactivateText: gettext("Deactivate"),
    audioRemovedText: gettext("Audio muted to remove music"),
    failedCAPS: gettext("FAILED"),
    pendingCAPS: gettext("PENDING"),
    approvalCAPS: gettext("APPROVAL"),
    videoCAPS: gettext("VIDEO"),
    processingCAPS: gettext("PROCESSING"),
    errorCAPS: gettext("ERROR"),
    failedApprovalText: gettext("Failed Approval"),
    removeOffendingItemsText: gettext("Please delete or edit offending items."),
    openInNewWindowText: gettext("Open in new window"),
    ipadFailedToSetup: gettext("iPad has failed to setup the broadcast. Please try again."),
    poorBroadcast: gettext("Please check your internet connection or close other applications"),
    noCameraFound: gettext("No camera found."),
    camAndMicPermissionPrompt: gettext("We need permission to use your camera and microphone in order to proceed. When prompted by the browser, please click \"Allow.\""),
    camAndMicPermissionPromptFF: gettext("We need permission to use your camera and microphone in order to proceed. When prompted by the browser, please check \"Remember this decision\" and click \"Allow.\""),
    camAndMicPermissionDenied: gettext("Permissions to use your camera and microphone are currently denied."),
    needCamAndMicPermission: gettext("We need permission to use your camera and microphone in order to proceed."),
    permanentPermsMessage: gettext("We need permanent permission to use your camera and microphone. Remember to check \"Remember this decision\" when granting permission."),
    requestDevicePermissions: gettext("Request Device Permissions"),
    resolutionNotSupported: gettext("The resolution is not supported by your device."),
    minResolutionNotSupported: gettext("Your device does not support the minimum resolution."),
    unknownDeviceError: gettext("Your device does not support the minimum resolution."),
    noOtherTabsOpen: gettext("Make sure there are no other broadcast tabs open."),
    lowFPS: gettext("Low FPS"),
    wantToLeaveConfirmation: gettext("Are you sure you want to leave?"),
    wentToAway: gettext("This private show has ended. To give you\ntime to prepare, you are now shown as \"Away\" to\nthose in your room. To return and be visible on\ncam, click \"Exit Away Mode\" below."),
    exitAwayMode: gettext("Exit Away Mode"),
    couldNotGetDevices: gettext("Could not get devices"),
    turnDeviceLandscape: gettext("Turn your device into landscape mode."),
    cameraLabel: gettext("Camera"),
    resolutionLabel: gettext("Resolution"),
    microphoneLabel: gettext("Microphone"),
    inputLevelLabel: gettext("Input Level"),
    muteLabel: gettext("Mute"),
    noMicrophone: gettext("None (Silent)"),
    startBroadcasting: gettext("Start Broadcasting"),
    stopBroadcasting: gettext("Stop Broadcasting"),
    youAreAway: gettext("You Are Away"),
    highQualityStream: gettext("For high quality streams we strongly recommend"),
    useOBS: gettext("Use external software (OBS)"),
    pleaseWaitConnecting: gettext("Please wait. Connecting in"),
    couldNotStartBroadcast: gettext("Could not start broadcast"),
    welcomeBack: gettext("Welcome back"),
    couldNotSetupPreview: gettext("Could not setup preview. Please make any possible adjustments and reload the page"),
    couldNotSetupBroadcast: gettext("Could not setup broadcast"),
    showMyCamPreviewTitle: gettext("My Cam Preview"),
    showMyCamShow: gettext("Cam To Cam"),
    showMyCamStop: gettext("Stop Cam To Cam"),
    showMyCamLoginRequired: gettext("You must be logged in to share your cam"),
    showMyCamAlreadyBroadcasting: gettext("Unable to share cam: You are already broadcasting from another window or tab"),
    showMyCamPreviewInfo2: gettext("The cam icon will turn orange when the broadcaster is viewing you."),
    showMyCamBroadcastError: gettext("Error sharing cam"),
    showMyCamBroadcastTimeoutError: gettext("Error sharing cam: Timed out trying to connect"),
    showMyCamCouldNotView: gettext("Error viewing cam"),
    showMyCamMustBeConnected: gettext("Unable to share cam: Make sure you are connected to chat and the broadcast is playing"),
    showMyCamMayNotShareIsPrivate: gettext("Unable to share cam: Broadcaster is in a private show"),
    showMyCamMayNotShareUpsell: gettext("Sharing your cam is only available to users who have purchased tokens. Click OK to purchase tokens now."),
    showMyCamSharingPrefix: gettext("Viewer "),
    showMyCamStartedSharing: gettext(" started sharing their cam"),
    showMyCamStartedViewing: gettext(" started viewing your cam"),
    showMyCamStoppedViewing: gettext(" stopped viewing your cam"),
    showMyCamIsViewing: gettext("is currently viewing your cam"),
    showMyCamNotViewing: gettext("is not currently viewing your cam"),
    showMyCamMuted: gettext("Muted"),
    showMyCamCloseCam: gettext("Close Cam"),
    showMyCamPopoutCam: gettext("Pop Out Cam"),
    showMyCamReportCam: gettext("Report Cam"),
    showMyCamReportPublic: gettext("User is broadcasting in public"),
    showMyCamReportRude: gettext("User is rude/abusive"),
    showMyCamReportIntoxicated: gettext("User is intoxicated"),
    showMyCamReportSleeping: gettext("User is sleeping"),
    showMyCamReportSpamming: gettext("User is spamming"),
    showMyCamReportUnderage: gettext("User is underage"),
    showMyCamMyCam: gettext("My Cam"),
    showMyCamView: gettext("View Cam"),
    showMyCamStopView: gettext("Stop Viewing Cam"),
    showMyCamTooManyCams: gettext("Unable to view cam: A maximum of five cams can be viewed at once"),
    showMyCamCamOffline: gettext("Unable to view cam: This viewer is no longer sharing their cam"),
    showMyCamConnectionClosed: gettext("(connection closed)"),
    broadcaster: gettext("Broadcaster"),
    moderator: gettext("Moderator"),
    fanclubMember: gettext("Fan club member"),
    user: gettext("User"),
    roomJoinedMessage: gettext("has joined the room"),
    roomLeftMessage: gettext("has left the room."),
    roomFollowToGetNotified: gettext("Follow to be notified when online."),
    feedbackCommentsLabel: gettext("Describe your issue or share your ideas:"),
    feedbackNotice: gettext("Some account and system information may be sent to %SITE_NAME%. We will use the information you give us to help address technical issues to improve our services, subject to our Privacy Policy and Terms of Service."),
    feedbackSubmit: gettext("Send feedback"),
    feedbackAddScreenshot: gettext("Add screenshot"),
    feedbackAddScreenshotInfoScrollDown: gettext("Scroll down to capture lower on the page"),
    feedbackAddScreenshotInfoScrollUp: gettext("Scroll up to capture higher on the page"),
    feedbackCanvasToggle: gettext("Click to highlight or hide info"),
    feedbackHighlightInfo: gettext("Highlight issues with orange or hide sensitive info with black. Hit the ESC key to finish."),
    feedbackHighlightControl: gettext("Highlight issue"),
    feedbackHideControl: gettext("Hide sensitive info"),
    feedbackPermissionDenied: gettext("Permission to capture screen not granted"),
    feedbackUnknownCaptureError: gettext("Could not capture screen"),
    feedbackResize: gettext("Please take screenshot again after resizing"),
    feedbackSubmitted: gettext("Your feedback has been sent! Thank you for your feedback."),
    feedbackUnknownError: gettext("Something went wrong. Please try again later."),
    feedbackRateLimitError: gettext("Please wait before submitting more feedback."),
    feedbackBannedUser: gettext("Unable to submit feedback. Please contact support."),
    doneText: gettext("Done"),
    denyPrivate: gettext("Deny Private"),
    acceptPrivate: gettext("Accept Private"),
    returnToPublicChat: gettext("Return to Public Chat"),
    privateShowEnded: gettext("Your private show has ended."),
    cameraRemainHidden: gettext("Your camera will remain hidden until you re-enter public chat."),
    aboutToStopPrivateShow: gettext("You're about to stop the private show. Do you want to continue?"),
    errorBroadcasting: gettext("Error broadcasting"),
    fontSettings: gettext("Font Settings"),
    emoticonsSettings: gettext("Emoticons Settings"),
    emoticonAutocompleteDelayOff: gettext("Turn Off Autocomplete"),
    notifyEntryLeaveAllUsers: gettext("All Registered Users"),
    notifyEntryLeaveModsFansUsersTokens: gettext("Mods, Fans, and Users With Tokens"),
    notifyEntryLeaveModsFans: gettext("Mods and Fans"),
    notifyEntryLeaveNoUsers: gettext("No Users"),
    usersSettings: gettext("Users Settings"),
    notificationsSettings: gettext("Notifications Settings"),
    otherSettings: gettext("Other Settings"),
    noOneIgnored: gettext("You have no users ignored."),
    backToChatSettings: gettext("Back to Chat Settings"),
    notifyEntryFor: gettext("Notify on Entry For"),
    notifyLeaveFor: gettext("Notify on Leave For"),
    noNewUpdates: gettext("No new updates"),
    videoBeingProcessed: gettext("The video is currently being processed.  It may take several minutes depending on the size of the video."),
    videoProcessingError: gettext("The video is damaged or its format is not supported."),
    videoReupload: gettext("Please delete this video and reupload again."),
    mustBeSupporterFeature: gettext("You must be a supporter to use this feature."),
    loginToFollow: gettext("You must be logged in to Follow. Click \"OK\" to login."),
    loginToUseFeature: gettext("Log in or sign up to use this feature"),
    loginToTip: gettext("You must be logged in to send a tip. Click \"OK\" to login."),
    loginToContinue: gettext("Log in to continue"),
    dontHaveAccount: gettext("Don't have an account?"),
    dontHaveAccountYet: gettext("Don't have an account yet?"),
    tippedTonsRecently: gettext("Tipped Tons"),
    tippedTonsRecentlyDef: gettext("1000+ tokens in the past 2 weeks"),
    tippedALotRecently: gettext("Tipped Lots"),
    tippedALotRecentlyDef: gettext("250+ tokens in the past 2 weeks"),
    tippedRecently: gettext("Tipped Recently"),
    tippedRecentlyDef: gettext("50+ tokens in the past 2 weeks"),
    hasTokens: gettext("Purchased Tokens"),
    hasTokensDef: gettext("Has bought tokens"),
    inFanClub: gettext("Fan Club Member"),
    isMod: gettext("Moderator"),
    isBroadcaster: gettext("Broadcaster"),
    upgradeToSupporterLabel: gettext("Upgrade to Supporter"),
    promotedRoom: gettext("Promoted room"),
    transferTokensToCash: gettext("Transfer tokens to cash"),
    transferTokensToAdvertising: gettext("Transfer tokens to advertising credit"),
    referredMember: gettext("Referred Member"),
    email: gettext("email"),
    emailLabel: gettext("Email"),
    spyNow: gettext("Spy Now"),
    spyShowCost: gettext("Spy Show cost"),
    spyingNow: gettext("Spying now"),
    broadcasterWelcomeWarning: gettext("Broadcaster Rules: Tokens are the only form of payment allowed. You may promote your personal website as long as it does not offer video chat. Do not ask users to chat with you elsewhere."),
    viewerWelcomeWarning: gettext("Rules: No spamming. Do not insist the cam hosts to do as you please. Do not announce other rooms or websites that would conflict with this room. Avoid any argumentative and/or rude posts related to the cam viewing. Do not attempt to post your e-mail address in the public chat."),
    useExternalEncoder: gettext("Use External Encoder to Broadcast"),
    broadcastWithOBS: gettext("Broadcast yourself using Open Broadcaster Software (OBS)"),
    accept: gettext("Accept"),
    decline: gettext("Decline"),
    userTokenRate: gettext("User(s) Token Rate"),
    privateShowStats: gettext("Private Show Stats"),
    exitPrivateShow: gettext("Exit Private Show"),
    useAppsAndBots: gettext("Enhance your room with Apps & Bots in the tab below."),
    disabled: gettext("Disabled"),
    allowed: gettext("Allowed"),
    privates: gettext("Privates"),
    free: gettext("Free"),
    broadcastGuideTitle: gettext("Broadcaster's Guide"),
    myBroadcast: gettext("My Broadcast"),
    passwordProtectedWarning: gettext("Your room is password protected. Users may not enter your room unless they know your password. To remove your password, edit your settings & privacy."),
    kickBan: gettext("Kick/Ban"),
    removeModeratorStatus: gettext("Remove Moderator Status"),
    promoteToModerator: gettext("Promote to Moderator"),
    removeModeratorLoginError: gettext("You must be logged in to remove a moderator. Click \"OK\" to login."),
    promoteModeratorLoginError: gettext("You must be logged in to promote a user to moderator. Click \"OK\" to login."),
    obsShareWarning: gettext("WARNING: Never show or share your broadcast token with anyone!"),
    yourBroadcastToken: gettext("Your broadcast token"),
    broadcastingInformation: gettext("RTMP/OBS Broadcasting Information"),
    clickToViewOBSSetupGuide: gettext("Click here to view our OBS Setup Guide"),
    importantInformation: gettext("Important Information"),
    doNotUpscale: gettext("Do not upscale your source input (e.g. using a 1080p camera to a 1440p stream)."),
    ensureMinimumBitrate: gettext("Ensure you use the minimum video bitrate specified in this table for a given resolution"),
    copyrightContentWarning: gettext("You may only have copyrighted content -- including music, movies, and video games -- in your stream if you are the rights holder or have the permission of all rights holders."),
    recommendedSettingsTable: gettext("Recommended Settings Table"),
    generateNewOBSTokenConfirm: gettext("Generate a new broadcast token? \n(This will invalidate your prior RTMP / OBS token and disconnect your current broadcast)"),
    thisIsSatisfactionScore: gettext("This is your satisfaction score. You can hide this by setting \"Show my satisfaction score\" to \"No\" in the Settings & Privacy tab."),
    dataUpdatedOncePerDay: gettext("This data is updated once per day and is based on votes in the past 90 days."),
    clickScoreToSeeHistory: gettext("Click on your score (%) to view your rating history."),
    fetchingHistory: gettext("Fetching rating history"),
    noHistoryToShow: gettext("No history to show"),
    activeApp: gettext("Active App"),
    activeGame: gettext("Active Game"),
    bot1: gettext("Bot #1"),
    bot2: gettext("Bot #2"),
    bot3: gettext("Bot #3"),
    bot4: gettext("Bot #4"),
    bot5: gettext("Bot #5"),
    noneSelected: gettext("None Selected"),
    chooseAnApp: gettext("Choose an App"),
    chooseABot: gettext("Choose a Bot"),
    chooseAGame: gettext("Choose a Game"),
    endGame: gettext("End Game"),
    stopStreamingGame: gettext("Stop streaming game"),
    areYouAPublisher: gettext("Are you a publisher?"),
    addYourGameLink: gettext("Add your game."),
    restart: gettext("Restart"),
    addNewSocialMedia: gettext("Add new social media"),
    uploadNewPics: gettext("Upload new pics"),
    uploadNewVideos: gettext("Upload new videos"),
    editYourBio: gettext("Edit Your Bio"),
    currentContestStatistics: gettext("Current Contest Statistics for"),
    payoutInformation: gettext("Payout Information"),
    refreshStats: gettext("Refresh Stats"),
    seeContestDetails: gettext("See contest details"),
    managePhotoSet: gettext("Manage photo sets"),
    updateBio: gettext("Update Bio"),
    january: gettext("January"),
    february: gettext("February"),
    march: gettext("March"),
    april: gettext("April"),
    may: gettext("May"),
    june: gettext("June"),
    july: gettext("July"),
    august: gettext("August"),
    september: gettext("September"),
    october: gettext("October"),
    november: gettext("November"),
    december: gettext("December"),
    expiredFanClubMemberships: gettext("Expired Fan Club Memberships"),
    expiredSocialMediaSubscriptions: gettext("Expired Social Media Subscriptions"),
    noActiveMemberships: gettext("You Have No Active Memberships"),
    expires: gettext("Expires"),
    expired: gettext("Expired"),
    extendThreeMonths: gettext("Extend by 3 months"),
    renews: gettext("Renews"),
    renew: gettext("renew"),
    cantRenew: gettext("can't renew"),
    cantExtend: gettext("can't extend"),
    noEmailOnFile: gettext("No email address on file"),
    blockedCountries: gettext("Blocked countries"),
    ableToView: gettext("Able to view"),
    blockedRegions: gettext("Blocked regions"),
    security: gettext("Security"),
    statistics: gettext("Statistics"),
    updateSettings: gettext("Update Settings"),
    updateYourPassword: gettext("Update your password"),
    viewSecurityCenter: gettext("View the security center and adjust Two-Step Verification settings"),
    authorizeThirdPartyStats: gettext("Authorize your 3rd party stats"),
    addEmailAddress: gettext("Add an email address"),
    changeEmailAddress: gettext("Change email address"),
    emailVerified: gettext("E-mail Verified"),
    resendVerificationEmail: gettext("Resend verification email"),
    verificationEmailSent: gettext("Verification Email Sent"),
    emailIsUnverifiedForNotificationsAndNewsletter: gettext("Email is Unverified. Verify to receive follower notifications and newsletters."),
    detailedIncomeStats: gettext("Detailed Income Stats"),
    paymentInformationForm: gettext("Payment Information Form"),
    requiredForPayments: gettext("required for payments"),
    broadcasterVerificationForm: gettext("Broadcaster Verification Form"),
    downloadTransactionHistory: gettext("Download Transaction History"),
    pleaseWaitForFileToGenerate: gettext("Please wait for the file to be generated"),
    download30Days: gettext("Download your last 30 days of transactions?\nProcessing may take up to 20 seconds."),
    yourAccountsActivity: gettext("Your Account's Activity"),
    periodEarnings: gettext("Period Earnings"),
    latestCashOuts: gettext("Latest cashouts"),
    tokensAutoCashedAtMidnight: gettext("tokens are automatically cashed out at midnight"),
    verifyToEnableTokens: gettext("Please verify your identity to enable tokens on your account"),
    transferTokens: gettext("Transfer Tokens"),
    transferTokensDateInfo: gettext("Tokens transferred on the 1st-15th of the month will be sent by the 22nd. Tokens transferred on the 16th-31st will be sent by the 7th."),
    silencedTableTitle: gettext("Silenced Users"),
    silencedUsersInfo: gettext("Listed are users currently silenced from your room. Silences expire after 6 hours by default. You can convert a silence to a ban."),
    bannedTableTitle: gettext("Banned Users"),
    bannedUsersInfo: gettext("Listed are users currently banned from your room. Bans expire after 1 month by default. You can convert a ban to a permanent ban."),
    usernameToBan: gettext("Please enter a username to ban"),
    errorOccurred: gettext("An error occurred, please try again"),
    pageLoadError: gettext("Error loading page. Please try again."),
    roomLoadError: gettext("Error loading room. Please try again."),
    banned: gettext("Banned"),
    silenced: gettext("Silenced"),
    actions: gettext("Actions"),
    remove: gettext("Remove"),
    makePermanent: gettext("Make Permanent"),
    convertToBan: gettext("Convert to Ban"),
    clickToClearHiddenBansAndSilences: gettext("Click here to clear hidden silences and bans"),
    addBan: gettext("Add ban"),
    enterUsernameToBan: gettext("Enter user to ban"),
    viewEditBansAndSilences: gettext("View/Edit Bans and Silences"),
    viewEditModerators: gettext("View/Edit Moderators"),
    viewFanClubMembers: gettext("View Fan Club Members"),
    viewEditRegionBlockExemptions: gettext("View/Edit Region Block Exemptions"),
    viewEditBannedTerms: gettext("View/Edit Banned Terms"),
    alphabetically: gettext("Alphabetically"),
    allUsers: gettext("All Users"),
    usersWhoTippedToday: gettext("Users who tipped me today"),
    usersWhoHaveTipped: gettext("Users who have tipped me"),
    usersWhoHaveTokens: gettext("Users who have tokens"),
    oneDay: gettext("1 Day"),
    twoDays: gettext("2 Days"),
    oneWeek: gettext("1 Week"),
    twoWeeks: gettext("2 Weeks"),
    oneMonth: gettext("1 Month"),
    twoMonths: gettext("2 Months"),
    sixMonths: gettext("6 Months"),
    no: gettext("No"),
    yes: gettext("Yes"),
    yesIfBroadcasting: gettext("Yes, only if they're broadcasting now"),
    ageVerificationRequired: gettext("Age Verification Required"),
    onlyAgeVerifiedCanChangeSetting: gettext("Only age verified broadcasters can change this setting"),
    currentFanClubMembersInfo: gettext("Listed are your current fan club members, the date they last joined, and the total number of months they've been your fan (Expiring memberships have a short grace period to renew before removed from your fanclub.)"),
    allFanClubMembersInfo: gettext("Listed are all of your fan club members past and present and how long they were a member for. Current members are <strong>highlighted</strong>."),
    addUsername: gettext("Add user"),
    add: gettext("Add"),
    regionBlockExemptions: gettext("Region Block Exemptions"),
    regionBlockExemptionsInfo: gettext("Users on this list will be exempt from region blocks"),
    enterUsernameToExemptRegionBlock: gettext("Enter user to exempt"),
    uniqueRegisteredViewers: gettext("Unique Registered Viewers"),
    yourPoints: gettext("Your Points"),
    yourRank: gettext("Your Rank"),
    joinFanClub: gettext("Join Fan Club"),
    member: gettext("Member"),
    fanClubMember: gettext("Fan Club Member"),
    lastJoined: gettext("Last Joined"),
    totalMonthsAMember: gettext("Total Months a Member"),
    darkMode: gettext("Dark Mode"),
    searchBarPlaceholder: gettext("Search #tags or broadcasters"),
    searchBarPlaceholderSbjSrch: gettext("Search subjects, #tags, or broadcasters"),
    broadcastingNow: gettext("Broadcasting right now"),
    recentlyOnline: gettext("Recently online"),
    previouslySearched: gettext("Previously searched"),
    tapToUnmute: gettext("TAP TO UNMUTE"),
    confirmOK: gettext("OK"),
    homepageInBioViolation: gettext("Please note that the 'Room subject' of your broadcast and the 'Location' field of your bio may not be used for promotion of social media or other websites. Thank you for your understanding."),
    noteText: gettext("Note"),
    loadingPlaceholderText: gettext("Loading..."),
    unsavedText: gettext("(unsaved)"),
    back: gettext("Back"),
    loadingMoreMessages: gettext("Loading More Messages"),
    sendPrivateMessage: gettext("Send a private message"),
    sendDirectMessage: gettext("Send a direct message"),
    sendDirectMessageNew: gettext("Send a direct message"),
    searchUsers: gettext("Search users"),
    unopenedCAPS: gettext("UNOPENED"),
    now: gettext("now"),
    leavePrivateShowWarningText: gettext("To avoid ending the private show, a new tab will open. Click OK to continue."),
    acceptRules: gettext("Accept Rules"),
    chatCapitalized: gettext("Chat"),
    unmuteCapitalized: gettext("Unmute"),
    directMessageLabel: gettext("Direct Messages"),
    fanclubCancelErrorMessage: gettext("There was an error cancelling your Fan Club."),
    supporterCancelConfirmMessage: gettext("Are you sure you wish to remove your supporter status? You will remain a supporter until your currently paid month expires."),
    supporterCancelErrorMessage: gettext("There was an error cancelling your supporter membership."),
    eventsApiSettingsHeader: gettext("Events API"),
    banRemovedMessage: gettext("Ban removed"),
    silenceRemovedMessage: gettext("Silence removed"),
    banRemoveErrorMessage: gettext("Error removing ban, please try again"),
    banConvertErrorMessage: gettext("Error converting to ban, please try again"),
    silenceRemoveErrorMessage: gettext("Error removing silence –– it may have already been removed"),
    inlineUndoText: gettext("Undo"),
    inlineBanText: gettext("Ban"),
    transViewers: gettext("trans viewers"),
    and: gettext("and"),
    howIsSmcDoingToday: gettext("How is Cam To Cam doing today?"),
    yourFeedbackHasBeenSent: gettext("Your feedback has been sent!"),
    describeYourIssues: gettext("Describe your issues or share your ideas."),
    unfollowInfo: gettext("You will no longer receive notifications or see them in your following tab."),
    allApps: gettext("All Apps"),
    aspDescription: gettext("Customize your chat room experience with Apps"),
    orderBy: gettext("Order By:"),
    searchForApps: gettext("Search for Apps"),
    appsLoadingError: gettext("Failed to load Apps"),
    appsLoadingErrorV2: gettext("Failed to load v2 Apps"),
    topEarning: gettext("Top Earning"),
    featuredApps: gettext("Featured Apps"),
    recentlyUpdated: gettext("Recently Updated"),
    recentlyUsed: gettext("Recently Used"),
    version: gettext("version"),
    installed: gettext("Installed"),
    videoPanelLabel: gettext("Broadcast panel"),
    uninstall: gettext("Uninstall"),
    transformMessagesLabel: gettext("Transform messages"),
    tipOptionsLabel: gettext("Tip options"),
    exclusiveAppPermissionText: gettext("Only one app can have this permission."),
    addApp: gettext("Add app"),
    upgradeAndApply: gettext("Upgrade \u0026 Apply"),
    upgradeAndRestart: gettext("Upgrade \u0026 Restart"),
    apply: gettext("Apply"),
    applyLower: gettext("apply"),
    running: gettext("Running"),
    stopped: gettext("Stopped"),
    crashed: gettext("Crashed"),
    stop: gettext("Stop"),
    upgrade: gettext("Upgrade"),
    settings: gettext("Settings"),
    sourceCode: gettext("Source code"),
    noDescription: gettext("No Description"),
    noAppSettingsText: gettext("This app does not have any settings."),
    fieldRequiredText: gettext("This field is required"),
    stringMinError: gettext("This field is too short"),
    stringMaxError: gettext("This field is too long"),
    invalidInputText: gettext("Invalid input"),
    selectAnOption: gettext("Select an option"),
    permissions: gettext("Permissions"),
    allPermissionsText: gettext("All permissions granted"),
    noPermissionsText: gettext("No permissions granted"),
    somePermissionsText: gettext("Some permissions granted"),
    permissionModalText: gettext("The app you are adding is requesting the following permissions."),
    activeAppOverrideText: gettext("⚠️ App running in \"Active App\" is overriding this permission."),
    aspPermissionOverrideText: gettext("⚠️ Apps running in \"Active App\" slot can override this permission."),
    appLimitError: gettext("You have reached the maximum number of apps."),
    appNotAgeVerified: gettext("Only age verified broadcasters may install apps."),
    becomeAgeVerified: gettext("Verify Age"),
    appNotAvailable: gettext("App is not available."),
    refresh: gettext("Refresh"),
    userAddedToExemptList: gettext("User has been added to your exemption list."),
    anErrorOccurred: gettext("An error occurred."),
    confirmPhotoDelete: gettext("Do you really want to delete this image? This cannot be undone."),
    errorDeletingMedia: gettext("There was an error deleting your media."),
    errorUploadingMedia: gettext("There was an error uploading your media."),
    invalidFileErrorUploadingMedia: gettext("This file type is not supported or the file is corrupted."),
    fileSizeErrorUploadingMedia: gettext("This file is too large. Images must be at most 50MB"),
    legalBlockErrorUploadingMedia: gettext("Unfortunately, local law restricts this function."),
    errorLoadingMedia: gettext("Could not load media. It may have been deleted."),
    selectGameHeaderText: gettext("Select your game from the list of approved games below."),
    installGameHeaderText: gettext("Install and start the game"),
    installGameSectionText: gettext("Download and and run the game."),
    downloadOBSHeaderText: gettext("Download OBS and set it up"),
    authorizeGameHeaderText: gettext("Authorize this game"),
    privateShowC2cTip: gettext("Pro tip: Viewers who use Cam To Cam tip 50% more on average than viewers who don't. Encouraging your viewers to share their cam can build a more intimate connection and potentially earn you more tokens!"),
    notifyWhenBroadcastersOnlineMessage: gettext("Notify me when broadcasters I follow come online with"),
    popularCAPS: gettext("POPULAR"),
    browserNotificationLabel: gettext("Browser notification"),
    browserNotificationEnabled: gettext("Browser notification enabled"),
    saveNotificationSettings: gettext("Save notification settings"),
    emailAddressText: gettext("Email address"),
    preferencesSavedText: gettext("Your preferences have been saved."),
    checkYourEmailToVerifyText: gettext("Check your email inbox to verify your email!"),
    emailSavedAndVerifyText: gettext("Your email address has been updated on your account. Check your email inbox to <span style=\"font-family: UbuntuBold, Arial, Helvetica, sans-serif;\">verify your email</span>!"),
    emailSendErrorMessage: gettext("Error sending verification email, please try again."),
    emailSubmitErrorMessage: gettext("Error submitting email, please try again."),
    browserSubmitErrorMessage: gettext("Error saving browser notification."),
    browserSubmitErrorMessagePt2: gettext("Please try again from the Settings & Privacy page on your profile."),
    errorSendingMessage: gettext("Unable to send message"),
    buyMoreTokens: gettext("Buy more tokens"),
    removedForViolation: gettext("Image removed for violating site rules"),
    imageAttached: gettext("Image attached"),
    subgenderSelectionConfirm: gettext("Submit and Start Broadcasting"),
    subgenderSelectionHeader: gettext("We launched new gender categories in order to allow the Chaturbate community to better identify their gender identity."),
    subgenderSelectionPrompt: gettext("Please choose an option below, selecting which one expresses you best:"),
    subgenderFemme: gettext("Trans Femme"),
    subgenderMasc: gettext("Trans Masc"),
    subgenderNonbinary: gettext("Non-Binary"),
    haveYouNoticedAnyBugs: gettext("Did you notice any bugs today? Please describe them in as much detail as possible."),
    away: gettext("Away"),
    offline: gettext("Offline"),
    live: gettext("Live"),
    yourStreamIsCurrently: gettext("Your stream is currently "),
    obsAwayMessage: gettext("This private show has ended. To give you time to prepare, you are now shown as \"Away\" to those in your room. To return and be visible on cam, click \"Exit Away Mode\" below."),
    streamRejected: gettext("Stream Rejected"),
    waitingforStreamStats: gettext("Waiting for stream stats"),
    streamStatusUnknown: gettext("Stream Status Unknown"),
    streamTime: gettext("Stream time"),
    codec: gettext("Codec"),
    frameRate: gettext("Frame Rate: "),
    bitrate: gettext("Bitrate: "),
    alsoSupportOtherEncoders: gettext("We also support other encoders such as XSplit, Wirecast, etc."),
    obsDescription: gettext(" (OBS) is an open source streaming software which provides broadcasters control to configure stream settings and customizable plugins so they can enhance their stream."),
    tryAddingHashtags: gettext("Try adding #hashtags to your room subject to attract more viewers."),
    viewRTMPInfo: gettext("View RTMP/OBS broadcast information and stream key"),
    waitingForStream: gettext("Waiting for stream to start"),
    startInBrowserBroadcaster: gettext("Start the in-Browser Broadcaster"),
    outdatedBrowser: gettext("Your browser is outdated and cannot use in browser broadcast. Please update to use the latest Chaturbate features. We recommend Chrome, Firefox, or Edge."),
    ctrlLToClose: gettext("(Ctrl+L to close)"),
    welcomeMessage: gettext("To go to next room, press Ctrl+/. To send a tip, press Ctrl+S or type \"/tip 25\". To disable emoticons or adjust autocomplete settings, click the 'Gear' tab above."),
    mobileWelcomeMessage: gettext("To send a tip, click SEND TIP or type \"/tip 25\"."),
    backToRoomMenu: gettext("Back to Room Menu"),
    bannedTerms: gettext("Banned Terms"),
    bannedTermsInfo: gettext("Viewers will not be able to send messages containing these terms. All characters are allowed, including spaces."),
    addTerm: gettext("Add term"),
    term: gettext("Term"),
    banAsSubstring: gettext("Ban as Substring"),
    banAsSubstringExplanation: gettext("Ban the term if it appears anywhere in a message, even as part of other words. For example, if the term is \"hello\" then this will also ban \"xhellox\". Otherwise, if disabled, only messages like \"x hello x\" will be banned."),
    enterTermToBan: gettext("Enter term to ban"),
    pleaseEnterTermToBan: gettext("Please enter a term to ban"),
    errorBanningTerm: gettext("There was an error banning this term. Please try again later"),
    errorUpdatingTerm: gettext("There was an error updating this term. Please try again later"),
    errorRemovingTerm: gettext("There was an error removing this term. Please try again later"),
    followsYou: gettext("Follows you"),
    sharingCam: gettext("Sharing their cam"),
    notes: gettext("Notes"),
    broadcastDate: gettext("Broadcast Date"),
    broadcastWeekday: gettext("Broadcast Weekday"),
    broadcastLength: gettext("Broadcast Length"),
    maxViewers: gettext("Max Viewers"),
    tokensTotal: gettext("Tokens Total"),
    avgTokensPerMin: gettext("Average Tokens Per Minute"),
    avgTokensPerUser: gettext("Average Tokens Per User"),
    chatMessagesTotal: gettext("Chat Messages Total"),
    avgMessagesPerUser: gettext("Average Messages Per User"),
    featuredCAPS: gettext("FEATURED"),
    allGendersCAPS: gettext("ALL GENDERS"),
    notShowingFromRegions: gettext("Not showing broadcasters from"),
    noRoomsAvailableFiltered: gettext("No rooms match your preferences, please update your search/filter options."),
    dragToResize: gettext("Drag to resize"),
    userInRoom: gettext("User is in the room"),
    userNotInRoom: gettext("User has left the room"),
    userNotInPrivate: gettext("User is not in the private show"),
    dmCloseTab: gettext("Close tab"),
    dmCloseTabShortcut: gettext("Ctrl+L to close window"),
    reportThisUserText: gettext("Report this user"),
    inappropriateMessage: gettext("Inappropriate message"),
    rudeMessage: gettext("Rude message"),
    spamMessage: gettext("Spam message"),
    getMoreLabel: gettext("Get More"),
    invalidAmount: gettext("Invalid amount"),
    hashtag: gettext("Hashtag"),
    viewers: gettext("Viewers"),
    rooms: gettext("Rooms"),
    incorrectOrientation: gettext("Your stream may appear upside down or sideways to viewers. Turn your device."),
    incorrectPortrait: gettext("Your broadcast may appear upside down or sideways to viewers. Turn your device into landscape mode."),
    missingFullscreen: gettext("Fullscreen is required for broadcasting. "),
    fullscreenStop: gettext("Screen is no longer fullscreen so stream has been stopped."),
    androidBroadcast: gettext("Starting a broadcast will enter fullscreen."),
    mobileBroadcastError: gettext("Must be in proper landscape to broadcast, rotate 90 or 180 degrees and try again"),
    allTagsCAPS: gettext("ALL TAGS"),
    dismissCapitalized: gettext("Dismiss"),
    becomeSupporter: gettext("Become a supporter"),
    joinCB: gettext("Join Chaturbate"),
    popoutInNewWindow: gettext("Popout in new window"),
    connecting: gettext("Connecting"),
    waitingToConnect: gettext("Waiting to connect"),
    connectingToBroadcastMessage: gettext("Please wait while we connect you to this webcam broadcast."),
    roomAwayMessage: gettext("You may continue chatting while you wait for the broadcaster to return."),
    roomPrivateNotWatchingMessage: gettext("You may continue chatting while you wait for the broadcaster to return from the private show."),
    spyOnThisPrivateShowCAPS: gettext("SPY ON THIS PRIVATE SHOW"),
    camIsHidden: gettext("Cam is Hidden"),
    roomOfflineSubheader: gettext("The member you are trying to view is currently offline. Please wait or choose another member to view."),
    passwordRequired: gettext("Password Required"),
    roomPasswordProtectedMessage: gettext("The broadcaster has set a password which is required for viewing. If you know the password, refresh this page to enter it."),
    errorLoadingConversationHistory: gettext("Error loading conversation history. Close and reopen this DM to reload"),
    ageVerifiedHeader: gettext("Age Verified Broadcasters"),
    ageVerifiedBodyHeader: gettext("Your account is age verified!"),
    ageVerifiedGuests: gettext("Additionally the following guests are approved to appear on your account:"),
    ageVerifiedBody: gettext("Only the age verified persons below can appear through your account. If you would like to broadcast with someone that is not displayed below, they must upload their government-issued identification prior to appearing on your account."),
    camCategoryNorthAmerica: gettext("North American Cams"),
    camCategorySouthAmerica: gettext("South American Cams"),
    camCategoryAsia: gettext("Asian Cams"),
    camCategoryEuroRussia: gettext("Euro Russian Cams"),
    camCategoryOtherRegion: gettext("Other Region Cams"),
    camCategoryTeen: gettext("Teen Cams (18+)"),
    camCategory18to20: gettext("18 to 20 Cams"),
    camCategory18to21: gettext("18 to 21 Cams"),
    camCategory20to30: gettext("20 to 30 Cams"),
    camCategory30to50: gettext("30 to 50 Cams"),
    camCategory21to35: gettext("21 to 35 Cams"),
    camCategoryMature: gettext("Mature Cams (50+)"),
    camCategoryExhib: gettext("Exhibitionist Cams"),
    camCategoryPrivate: gettext("Private Shows"),
    camCategoryHidden: gettext("Community Controlled Shows"),
    camCategoryNewCams: gettext("New Cams"),
    camCategoryGaming: gettext("Gaming Cams"),
    camCategory6TkPerMin: gettext("6 Tokens per Minute"),
    camCategory12TkPerMin: gettext("12 Tokens per Minute"),
    camCategory18TkPerMin: gettext("18 Tokens per Minute"),
    camCategory30TkPerMin: gettext("30+ Tokens per Minute"),
    camCategory60TkPerMin: gettext("60+ Tokens per Minute"),
    camCategory12to18TkPerMin: gettext("12 to 18 Tokens per Minute"),
    camCategory30to42TkPerMin: gettext("30 to 42 Tokens per Minute"),
    camCategory60to72TkPerMin: gettext("60 to 72 Tokens per Minute"),
    camCategory90TkPerMin: gettext("90+ Tokens per Minute"),
    categoryDescriptionHidden: gettext("Community controlled cams are using an app which has the ability to hide and show the cam based on viewers feedback."),
    firefoxWarning: gettext("Firefox is not recommended for browser broadcasting. For a better experience, please use Chrome or an external encoder."),
    noFanClub: gettext("Broadcaster does not currently have a fan club."),
    inValidTipAmount: gettext("Please input a tip amount between 1 and 9999"),
    shortcodeNotSupportedInDMs: gettext("Shortcodes are not supported in DMs."),
    shortcodeNotSupportedInPMs: gettext("Shortcodes are not supported in PMs."),
    shortcodeNotSupportedInPrivates: gettext("Shortcodes are not supported in private shows."),
    shortcodeGeneralError: gettext("Error sending shortcode. Please try again."),
    tipNotBroadcaster: gettext("Only broadcasters & apps can publish tip shortcodes"),
    tooManyShortcodes: gettext("Max shortcodes allowed per message is 5"),
    shortcode: gettext("Shortcode"),
    shortcodeHelp: gettext("Shortcodes are specific codes that can be typed into a chat room to automatically create clickable links with predefined actions. These shortcodes can act as a shortcut for users to perform various functions like joining a specific page or becoming a supporter. Broadcasters can also use shortcodes to suggest a tip."),
    shortcodeHelpHelp: gettext("Generates information about the shortcode system and each of the shortcodes."),
    shortcodeURLNotAllowedInTip: gettext("URLs are not allowed in tip shortcodes"),
    shortcodeHelpTipExample: gettext("Tip if you enjoyed!"),
    loggedInForFeature: gettext("You must be logged in to use this feature"),
    loggedInForFeatureClickOkToLogin: gettext("You must be logged in to use this feature. Click \"OK\" to login."),
    errorSendingTip: gettext("Error sending tip"),
    visibleRegionsTag: gettext("Visible Regions"),
    northAmericaTitle: gettext("North America"),
    southAmericaTitle: gettext("South America"),
    asiaTitle: gettext("Asia"),
    europeRussiaTitle: gettext("Europe/Russia"),
    otherTitle: gettext("Other"),
    refresh30Seconds: gettext("30 Seconds"),
    refresh45Seconds: gettext("45 Seconds"),
    refresh60Seconds: gettext("60 Seconds"),
    refresh90Seconds: gettext("90 Seconds"),
    refreshDisable: gettext("Disable"),
    refreshThumbnailsEvery: gettext("Refresh thumbnails every"),
    showLocations: gettext("Show locations"),
    previewRooms: gettext("Preview rooms"),
    moreTags: gettext("(more tags)"),
    removeFilter: gettext("(remove filter)"),
    filters: gettext("Filters"),
    options: gettext("Options"),
    privateBrowsingMessage: gettext("Broadcasting while private browsing is not supported for Firefox. Please disable private browsing and try again."),
    unableToReloadErrorMessage: gettext("Something went wrong while loading the rooms. Please <a class='link'>refresh the page</a> to try again."),
    onlineRooms: gettext("Online Rooms"),
    offlineRooms: gettext("Offline Rooms"),
    metaContentCardDescriptionPremium: gettext("Join a private or spy show for greater intimacy and voyeurism."),
    metaContentCardDescriptionFemale: gettext("Enjoy uncensored adult chat & live webcams with amateur girls. No registration required!"),
    metaContentCardDescriptionMale: gettext("Enjoy uncensored adult chat & live webcams with amateur guys. No registration required!"),
    metaContentCardDescriptionCouples: gettext("Enjoy uncensored adult chat & live webcams with amateur couples. No registration required!"),
    metaContentCardDescriptionTrans: gettext("Enjoy uncensored adult chat & live trans webcams with amateurs. No registration required!"),
    metaContentCardDescriptionDefault: gettext("Enjoy free chat and live webcam broadcasts from amateurs around the world. No registration required!"),
    metaDescriptionDefault: gettext("Watch Live Cams Now! No Registration Required - 100% Free Uncensored Adult Chat. Start chatting with amateurs, exhibitionists, pornstars w/ HD Video & Audio."),
    metaDescriptionPremium: gettext("You can join a private show and for only a few tokens per minute. Chat in private shows and direct all the action!"),
    metaDescriptionSiteDefault: gettext("Enjoy free webcams broadcasted live from amateurs around the world! - Join 100% Free"),
    metaKeywordsFemale: gettext("free chat with girls, live cam girls, free webcam girls"),
    metaKeywordsMale: gettext("free chat with men, live gay cams, free gay webcams"),
    metaKeywordsCouple: gettext("free chat with couple, live cam couple, free webcam couple"),
    metaKeywordsTrans: gettext("free chat with trans, live cam trans, free webcam trans"),
    metaKeywordsDefault: gettext("free cams, free adult video chat, free sex webcams, live adult cams, adult webcams, free live porn"),
    metaKeywordsPremium: gettext("private cams and spy cams"),
    metaKeywordsSiteDefault: gettext("adult chat, adult cams, free chat, free cams, live cams"),
    premiumShowsCaps: gettext("PREMIUM SHOWS"),
    featuredText: gettext("Featured"),
    highestTokenColorTooltip: gettext("If your \"tipped recently\" amount is higher than the selected value, your username will be displayed in the selected color."),
    tipShortcodeMessageInDoubleQuotes: gettext("The message content for tip shortcode must be enclosed in double quotes"),
    tipShortcodeArgsMissing: gettext("Tip shortcode requires an amount and message"),
    loadingPreview: gettext("Loading Preview"),
    obsGuideCodec: gettext("Codec:"),
    obsGuideCodecBody: gettext("H.264 Video, AAC Audio."),
    obsGuideAspect: gettext("Aspect ratio:"),
    obsGuideAspectBody: gettext("4:3 or 16:9 only."),
    obsGuideResolution: gettext("Resolution:"),
    obsGuideResolutionBody: gettext("From a height of 240 pixels up to 4k (2160 pixels)."),
    obsGuideFPS: gettext("Frame Rate:"),
    obsGuideFPSBody: gettext("24-30fps, or 48-60fps for high frame rate."),
    obsGuideBitrate: gettext("Bitrate:"),
    obsGuideBitrateBody: gettext("Up to 50 Mbps (50 000 Kbps) Video, 192 Kbps Audio - CBR Preferred."),
    obsGuideKeyFrame: gettext("Key Frame Interval:"),
    obsGuideKeyFrameBody: gettext("2 seconds."),
    obsGuideKeyProfile: gettext("H.264 Profile:"),
    obsGuideKeyProfileBody: gettext("Main or High preferred; baseline is acceptable."),
    obsGuideGenerate: gettext("Generate New Token"),
    obsGuideGenerateFail: gettext("Failed to reset token. Please try again."),
    internalStaffPrivate: gettext("Cannot start or spy on private shows as staff while on internal site."),
    internalStaffMessage: gettext("Cannot chat as staff while on internal site."),
    internalStaffFanClub: gettext("Cannot join fanclubs as staff while on internal site."),
    internalStaffPurchase: gettext("Cannot purchase as staff while on internal site."),
    internalStaffTip: gettext("Cannot tip as staff while on internal site."),
    internalStaffC2C: gettext("Cannot Cam to Cam as staff while on internal site."),
    internalStaffPromotion: gettext("Cannot start promotion as staff while on internal site."),
    reset: gettext("reset"),
    notFound: gettext("Not found"),
    agesCAPS: gettext("AGES"),
    hmpgFilterOpt6TksAbbrev: gettext("6 tks"),
    hmpgFilterOpt12To18TksAbbrev: gettext("12 - 18 tks"),
    hmpgFilterOpt30TksAbbrev: gettext("30 - 42 tks"),
    hmpgFilterOpt60TksAbbrev: gettext("60 - 72 tks"),
    hmpgFilterOpt90TksAbbrev: gettext("90+ tks"),
    regionsCAPS: gettext("REGIONS"),
    privateTksPerMinCAPS: gettext("PRIVATE SHOW TOKENS PER MINUTE"),
    otherEncoderSupport: gettext("See our support page for <a class='hrefColor' href='https://support.chaturbate.com/hc/en-us/articles/360037971972' target='_blank'>technical info</a>."),
    tagSearchPlaceholder: gettext("Search #tags"),
    privateTimeWithBroadcaster: gettext("Private time with broadcaster"),
    recordedVideo: gettext("A recorded video of this show in your collection."),
    lowStreamResolution: gettext("Your stream resolution is lower than the recommended minimum of 480 pixels, we suggest you increase your stream resolution."),
    streamNotHD: gettext("Note: Your stream is <strong>not</strong> HD, a minimum height of 720 pixels is required."),
    streamNotWidescreen: gettext("Your stream is not widescreen. We recommend all streams to be widescreen (16:9 ratio) with external encoders"),
    errorHandlingStream: gettext("Error handling your stream, please restart it and contact support if this persists."),
    unstableBitrate: gettext("Your bitrate is unstable which can indicate a poor connection."),
    veryGoodBitrate: gettext("Your bitrate is very good!"),
    frameRateMuchLower: gettext("Your frame rate is much lower than the recommended minimum of 24 fps"),
    frameRateLower: gettext("Your frame rate is lower than the recommended minimum of 24 fps"),
    frameRateHigh: gettext("You are broadcasting with a high frame rate. Please only use this if your camera is set to a high frame rate."),
    frameRateTooHigh: gettext("Your frame rate is higher than the maximum of 60 fps. This will result in a poor stream output."),
    fpsVeryUnstable: gettext("Your fps is very unstable. Your encoder may be overloaded."),
    fpsUnstable: gettext("Your fps is unstable. Your encoder may be overloaded."),
    noQualityInformation: gettext("Unable to get quality information, please contact support if this persists. Your stream is not affected."),
    alert: gettext("Alert"),
    warning: gettext("Warning"),
    yourStreamIsGood: gettext("Your stream is good"),
    noIssues: gettext("No issues to report"),
    enableLLHLS: gettext("Enable LL-HLS"),
    promoteThisRoom: gettext("Promote This Room"),
    notEnoughTokensMessage: gettext("You do not have enough tokens."),
    promotionUnavailableOffline: gettext("Broadcaster must be online and public in order to promote room."),
    promotionUnavailableTitle: gettext("The promotion feature is currently unavailable"),
    promotionUnavailableDescription: gettext("This room may already be enjoying high visibility or undergoing promotion. Check back later for a chance to add this room to the homepage."),
    promotionConfirmTitle: gettext("Promote Room"),
    promotionConfirmDescription: gettext("Boost this room's visibility by adding it to a rotating promoted spot on the homepage for 5 minutes."),
    promotionConfirmPromoteText: gettext("Yes, promote room"),
    promotionIneligibleUserMessage: gettext("Your account is unable to promote room. Please contact support."),
    roomSizeCAPS: gettext("ROOM SIZE"),
    intimateRoomSizeCategory: gettext("Intimate"),
    intimateRoomsLabel: gettext("Intimate Rooms"),
    midSizeRoomSizeCategory: gettext("Mid-Sized"),
    midSizeRoomsLabel: gettext("Mid-Sized Rooms"),
    hiTrafficRoomSizeCategory: gettext("High-Traffic"),
    hiTrafficRoomsLabel: gettext("High-Traffic Rooms"),
    broadcasterWarningMessage: gettext("A site administrator has sent you the following message:"),
    broadcasterWarningAknowledge: gettext("I understand"),
    broadcasterWarningQuestion: gettext("I have a question"),
    broadcastTermsHeader: gettext("WARNING:  THIS SITE HAS A ZERO TOLERANCE POLICY ON UNDERAGE BROADCASTING."),
    broadcastTerms1: gettext("The Chaturbate service is for use by adults only. By clicking the link below, you are affirming your representation and warranty to us that:"),
    broadcastTerms2: gettext("You are at least 18 years old or the age of majority in your jurisdiction, whichever is older (the \"Age of Majority\");"),
    broadcastTerms3: gettext("Anyone appearing on camera with you has also reached the Age of Majority;"),
    broadcastTerms4: gettext("At no time will anyone who has not reached the Age of Majority be present while you are using the Chaturbate service;"),
    broadcastTerms5: gettext("You will not permit anyone who has not reached the Age of Majority to use the Chaturbate service; and"),
    broadcastTerms6: gettext("You will immediately report by email to <a href=\"mailto:support@chaturbate.com\">support@chaturbate.com</a> any and all instances you become aware of wherein an individual has used the Chaturbate service without first reaching the Age of Majority."),
    broadcastTerms7: gettext("Obviously, you consent to and request your image being broadcast on the Chaturbate platform."),
    broadcastTerms8: gettext("You agree that you will be governed by the Chaturbate Terms and by the Chaturbate Broadcaster Agreement."),
    broadcastTerms9: gettext("You agree that only age verified broadcasters associated with this room may broadcast in this room."),
    broadcastTerms10: gettext("If we suspect, in our sole discretion, that you are using the Chaturbate service without having complied in every respect with the foregoing statements, your account <span style=\"text-decoration: underline; font-weight: bold;\">will</span> be terminated immediately, and we <span style=\"text-decoration: underline; font-weight: bold;\">will</span> report your conduct to law enforcement."),
    termsAgree: gettext("I AGREE"),
    preferenceUpdateError: gettext("An error occurred while updating your preferences. Please try again"),
    mustBeLoggedInForChatSettings: gettext("You must be logged in to change chat settings. Click \"OK\" to login."),
    mustBeSupporterForChatColor: gettext("You must be a supporter to change chat color"),
    mustBeLoggedInForChatColor: gettext("You must be logged in to change chat color. Click \"OK\" to login."),
    mustBeSupporterForFontFamily: gettext("You must be a supporter to change font family"),
    mustBeLoggedInForFontFamily: gettext("You must be logged in to change font family. Click \"OK\" to login."),
    mustBeLoggedInForIgnoredUsers: gettext("You must be logged in to edit ignored users. Click \"OK\" to login."),
    dropDownWhatsApp: gettext("WhatsApp"),
    dropDownDiscord: gettext("Discord"),
    whatsAppLabelText: gettext("Your WhatsApp number with country code but without the + sign (e.g. 112345678900):"),
    whatsAppInvalidInput: gettext("Please enter a valid WhatsApp number."),
    discordLabelText: gettext("Your Discord Invite Link:"),
    discordInvalidInput: gettext("Please enter a valid Discord link."),
    defaultInvalidInput: gettext("Please enter valid social media username/channel."),
    defaultLabelText: gettext("Your Social Media Username/Channel/Invite Link:"),
    scrollToBottom: gettext("Scroll to bottom"),
    tenPlusNewMessages: gettext("10+ new messages"),
    tryingToReconnect: gettext("Trying to reconnect"),
    chatDisconnected: gettext("Chat disconnected"),
    tooltipPrivateMinute: gettext("Private price per min."),
    tooltipSpyMinute: gettext("Spy price per min."),
    rotateForPMs: gettext("Rotate device to view PMs"),
    rotateForDMs: gettext("Rotate device to view DMs"),
    hiddenBansRequestProcessing: gettext("Your request is processing. You can close and reopen this form to check on progress."),
    hiddenBansRequestError: gettext("An error occurred removing hidden silences and bans"),
    recommendedForYou: gettext("Recommended For You"),
    searchBar: gettext("Search Bar"),
    loadMore: gettext("Load more"),
}
