import { iOSVersion } from "@multimediallc/web-utils/modernizr"
import { SubSystemType } from "../../common/debug"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { getChatMode } from "../chatSettings"
import { EventRouter } from "../events"
import { requestFullscreen } from "../fullscreen"
import { addPageAction, NRGetPlayerVolume } from "../newrelic"
import { i18n } from "../translation"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import { isDocumentVisible } from "../windowUtils"
import { HlsPlayer, pictureInPictureChange } from "./hlsPlayer"
import type { IRoomContext } from "../context"
import type { RoomStatusNotifier } from "../roomStatusNotifier"

export const airPlayChange = new EventRouter<boolean>("HlsNativePlayer:AirPlayChange")

export class HlsNativePlayer extends HlsPlayer {
    private lastTimeUpdate = 0
    private userUnmuted = false
    private prevIsMuted = true
    private chatMode: string
    private unmuteContainer: HTMLSpanElement | undefined
    private showingNativeControls = false
    private refreshTimeout: number | undefined

    constructor(roomStatusNotifier: RoomStatusNotifier, isEmbed: boolean) {
        super(roomStatusNotifier, "HlsNativePlayer", isEmbed)
        this.playerType = "Native"

        this.playableHlsPlayer = this.videoElement
        this.element.appendChild(this.videoElement)

        this.videoMetrics.bindAllHTMLMedia(this.videoElement, this.element)

        airPlayChange.listen((isActive) => {
            this.isCasting = isActive
        })

        getChatMode.listen((mode: string) => {
            this.chatMode = mode
        })

        this.videoElement.onclick = ev => {
            this.play()
            debug(["click", ev])
        }
        this.videoElement.onpause = ev => {
            debug(["pause", ev])
        }
        this.videoElement.onplay = ev => {
            addPageAction("VideoOnloadMuteStatus", { "muted": this.videoElement.muted, "chatMode": this.chatMode })
            if (!this.videoElement.muted) {
                this.userUnmuted = true
            } else {
                this.prevIsMuted = true
            }
            debug(["play", ev])
        }
        this.videoElement.oncanplay = ev => {
            debug(["canplay", ev])
            if (this.isStreamReconnecting) {
                this.removeReconnecting()
            }
        }
        this.videoElement.oncanplaythrough = ev => {
            debug(["canplaythrough", ev])
            const version = iOSVersion()
            if (version === undefined || version < 15) {
                this.play()
            }
        }
        this.videoElement.onseeking = ev => {
            debug(["seeking", ev])
        }
        this.videoElement.onerror = ev => {
            if (this.videoElement.error !== null && (this.videoElement.error.code === 2 || this.videoElement.error.code === 4)) {
                clearTimeout(this.refreshTimeout)
                this.refreshTimeout = window.setTimeout(() => {
                    this.refreshStreamOnSameEdge(this.context)
                }, 2000)
            }
            error(ev, {}, SubSystemType.Video)
        }
        this.videoElement.onvolumechange = ev => {
            const volume = this.videoElement.volume
            let isMuted = this.videoElement.muted
            if (volume === 0) {
                isMuted = true
            }
            if (!isMuted && !this.userUnmuted && NRGetPlayerVolume() === 0) {
                this.userUnmuted = true
                addPageAction("UserUnmuted", { "chatMode": this.chatMode })
            }
            if (this.prevIsMuted !== isMuted && this.showingNativeControls) {
                addPageAction("ToggleMute", { "newState": isMuted })
            }
            this.setControlIsMuted.fire({ isMuted, save: true })
            this.setControlVolume.fire({ volume: volume * 100, save: true })
            if (this.unmuteContainer !== undefined && !isMuted) {
                this.element.removeChild(this.unmuteContainer)
                this.unmuteContainer = undefined
            }
            this.prevIsMuted = isMuted
        }
        this.videoElement.onstalled = ev => {
            if (!isDocumentVisible() || this.isInPictureInPicture) {
                return
            }
            this.videoElement.pause()
            window.setTimeout(() => {
                this.play()
            }, 10)
        }
        // Currently no browsers will use NativeHLS and also support W3C, but have this here in case this changes.
        addEventListenerPoly("enterpictureinpicture", this.videoElement, () => {
            debug("enterpictureinpicture")
            pictureInPictureChange.fire({ active: true })
        })
        // Currently no browsers will use NativeHLS and also support W3C, but have this here in case this changes.
        addEventListenerPoly("leavepictureinpicture", this.videoElement, () => {
            debug("leavepictureinpicture")
            // May need to timeout this if future browsers support NativeHLS, but pause after event (like videojs)
            pictureInPictureChange.fire({
                active: false,
                videoPaused: this.videoElement.paused,
            })
        })
        let prevPresentationMode = this.videoElement["webkitPresentationMode"]
        addEventListenerPoly("webkitpresentationmodechanged", this.videoElement, () => {
            const newPresentationMode = this.videoElement["webkitPresentationMode"]
            if (["inline", "fullscreen"].indexOf(prevPresentationMode) !== -1 && newPresentationMode === "picture-in-picture") {
                debug("enterpictureinpicture")
                pictureInPictureChange.fire({ active: true })
            } else if (prevPresentationMode === "picture-in-picture" && ["inline", "fullscreen"].indexOf(newPresentationMode) !== -1) {
                debug("leavepictureinpicture")
                pictureInPictureChange.fire({
                    active: false,
                    videoPaused: this.videoElement.paused,
                })
            }
            if (newPresentationMode === "fullscreen") {
                videoModeHandler.setFireVideoMode(VideoMode.Fullscreen)
            }
            if (prevPresentationMode === "fullscreen") {
                videoModeHandler.setFireVideoMode(videoModeHandler.getPreviousNonFullscreenVideoMode())
            }
            prevPresentationMode = newPresentationMode
        })

        if (iOSVersion() === 10) {
            this.createiOSUnmute()
        }
    }

    protected loadHlsStream(context: IRoomContext, source: string): void {
        this.setTooMuchWaitingTimeout(context)

        if (this.videoElement.onplaying === null) {
            this.videoElement.onplaying = ev => {
                this.setTooMuchWaitingTimeout(context)
            }
        }

        if (this.videoElement.ontimeupdate === null) {
            // We have to use ontimeupdate because onprogress does not work properly in all browsers
            this.videoElement.ontimeupdate = ev => {
                const currentTime = this.videoElement.currentTime
                if (currentTime - this.lastTimeUpdate > 5) {
                    this.lastTimeUpdate = currentTime
                    this.setTooMuchWaitingTimeout(context, () => {
                        this.lastTimeUpdate = 0
                    })
                    this.tryingTimeoutRefresh = false
                }
            }
        }

        if (source !== "") {
            this.videoElement.src = source
            this.roomStatusNotifier.hide()
        } else {
            this.videoElement.removeAttribute("src")
        }
        this.videoElement.load()
        this.videoElement.style.display = "inline"

        // Avoid race conditions caused by firing play() multiple times when the browser
        // supports the oncanplaythrough event.
        const version = iOSVersion()
        if (!("oncanplaythrough" in window) || version !== undefined && version >= 15) {
            this.play()
        }

        if (source === "") {
            this.stop()
            this.stopTooMuchWaitingTimeout()
        }
    }

    setVolume(volume: number): void {
        this.videoElement.volume = volume / 100
    }

    getVolume(): number {
        if (this.videoElement.muted || !this.isStreamReconnecting && this.videoElement.paused) {
            return 0
        }
        return this.videoElement.volume * 100
    }

    setMuted(muted: boolean): void {
        this.videoElement.muted = muted
        // This keeps the muted attribute in sync with the muted property. Most browsers respect the property but some,
        // namely the Samsung default mobile browser, respect the attribute.
        this.setControlIsMuted.fire({ isMuted: muted, save: true })
        if (muted) {
            this.videoElement.setAttribute("muted", "") // eslint-disable-line @multimediallc/no-set-attribute
        } else {
            this.videoElement.removeAttribute("muted")
        }
    }

    stop(): void {
        this.lastTimeUpdate = 0
        this.videoElement.style.display = "none"
        this.videoElement.pause()
        this.videoElement.removeAttribute("src")
        this.videoElement.load()
        super.stop()
    }

    protected showNativeControls(): void {
        super.showNativeControls()

        if (this.showingNativeControls) {
            return
        }
        this.showingNativeControls = true

        this.videoElement.setAttribute("controls", "") // eslint-disable-line @multimediallc/no-set-attribute
        if (this.unmuteContainer !== undefined) {
            this.unmuteContainer.style.display = "block"
        }
    }

    protected hideNativeControls(): void {
        if (!this.showingNativeControls) {
            return
        }
        this.showingNativeControls = false

        this.videoElement.removeAttribute("controls") // hide safari native video controls
        if (this.unmuteContainer !== undefined) {
            this.unmuteContainer.style.display = "none"
        }
    }

    protected showCustomControls(): void {
        super.showCustomControls()
        this.hideNativeControls()
    }

    // Needed for interface
    setQualityLevel(level: number): void {
    }

    public setVolumeMuted(volume: number, muted: boolean): void {
        this.videoElement.volume = volume / 100
        if (volume === 0 || muted) {
            this.videoElement.muted = true
            this.videoElement.setAttribute("muted", "") // eslint-disable-line @multimediallc/no-set-attribute
        } else {
            this.videoElement.muted = false
            this.videoElement.removeAttribute("muted")
        }
    }

    public getControlBarHeight(): number {return 0}

    public enterFullScreenMode(): void {
        requestFullscreen(this.videoElement)
        this.showNativeControls()
    }

    public toggleFullscreen(): void {
        this.enterFullScreenMode()
    }

    public getVideoElement(): HTMLVideoElement {
        return this.videoElement
    }

    private createiOSUnmute(): void {
        // The styling here is taken from #ios_unmute_* in all.css
        this.unmuteContainer = document.createElement("span")
        this.unmuteContainer.style.position = "absolute"
        this.unmuteContainer.style.top = "6px"
        this.unmuteContainer.style.right = "6px"
        this.unmuteContainer.style.opacity = "0.8"
        this.unmuteContainer.style.float = "right"
        this.unmuteContainer.style.backgroundColor = "rgba(255, 255, 255, 0.8)"
        this.unmuteContainer.style.borderRadius = "6px"
        this.unmuteContainer.style.height = "36px"
        this.unmuteContainer.style.padding = "3px"
        this.unmuteContainer.style.cursor = "pointer"

        const unmuteText = document.createElement("span")
        unmuteText.textContent = i18n.tapToUnmute
        unmuteText.style.color = "#0c6a93"
        unmuteText.style.fontSize = "14px"
        unmuteText.style.padding = "6px"
        unmuteText.style.position = "relative"
        unmuteText.style.top = "2px"
        unmuteText.style.textShadow = "0 0 1px #0c6a93"

        const unmuteImg = document.createElement("img")
        unmuteImg.src = `${STATIC_URL_ROOT}images/volume-mute.svg`
        unmuteImg.style.width = "36px"
        unmuteImg.style.height = "36px"
        unmuteImg.style.display = "inline"
        unmuteImg.style.verticalAlign = "middle"

        this.unmuteContainer.appendChild(unmuteText)
        this.unmuteContainer.appendChild(unmuteImg)
        this.element.appendChild(this.unmuteContainer)

        this.unmuteContainer.onclick = () => {
            this.setMuted(false)
            if (this.unmuteContainer !== undefined) {
                this.element.removeChild(this.unmuteContainer)
                this.unmuteContainer = undefined
            }
        }
        window.setTimeout(() => {
            unmuteText.style.display = "none"
        }, 5000)
    }
}
