import { error } from "@multimediallc/logging";
import { ShortcodeParser } from "./shortcodeParsing";
import { MessagePartType } from "./types/chatInterfaces";
const EMOTICON_START_TOKEN = "%%%[";
const EMOTICON_END_TOKEN = "]%%%";
const EMPTY_EMOTICON = {
    partType: MessagePartType.emoticon,
    height: 0,
    name: "",
    imgUrl: "",
    width: 0,
    abuseUrl: "",
};
// eslint-disable-next-line complexity
export function parsedResult(msg, shortcodes = []) {
    let buffer = [];
    const parts = [];
    const hasNoEmoticons = msg.indexOf(EMOTICON_START_TOKEN) < 0;
    if (hasNoEmoticons && !ShortcodeParser.hasShortcodes(msg)) {
        return [makeStringPart(msg)];
    }
    for (let char = 0; char < msg.length; char += 1) {
        if (char + ShortcodeParser.shortcodePrefix.length < msg.length &&
            ShortcodeParser.isShortcodePrefix(msg.slice(char, char + ShortcodeParser.shortcodePrefix.length))) {
            const shortcodeEndIndex = char +
                msg
                    .slice(char, msg.length)
                    .indexOf(ShortcodeParser.shortcodeSuffix);
            if (shortcodeEndIndex === -1) {
                // prefix false positive
                buffer.push(msg[char]);
                continue;
            }
            const shortcodeString = msg.slice(char, shortcodeEndIndex + ShortcodeParser.shortcodeSuffix.length);
            const shortcodeForPart = ShortcodeParser.getShortcodeForPart(shortcodeString, shortcodes);
            if (shortcodeForPart === undefined) {
                // invalid shortcode
                buffer.push(msg[char]);
                continue;
            }
            if (buffer.length !== 0) {
                parts.push(makeStringPart(buffer.join("")));
            }
            buffer = [];
            parts.push(makeShortcodePart(shortcodeString, shortcodeForPart));
            char += shortcodeString.length - 1;
        }
        else if (msg[char] === "%") {
            if (msg.slice(char, char + EMOTICON_START_TOKEN.length) ===
                EMOTICON_START_TOKEN) {
                const emoticonEndIndex = char +
                    msg.slice(char, msg.length).indexOf(EMOTICON_END_TOKEN);
                if (msg.slice(emoticonEndIndex, emoticonEndIndex + EMOTICON_END_TOKEN.length) === EMOTICON_END_TOKEN) {
                    if (buffer.length !== 0) {
                        parts.push(makeStringPart(buffer.join("")));
                    }
                    buffer = [];
                    const part = parsePart(msg.slice(char, emoticonEndIndex + EMOTICON_END_TOKEN.length));
                    parts.push(part);
                    char = emoticonEndIndex + EMOTICON_END_TOKEN.length - 1;
                }
            }
            else {
                buffer.push(msg[char]);
            }
        }
        else {
            buffer.push(msg[char]);
        }
    }
    if (buffer.length !== 0) {
        parts.push(makeStringPart(buffer.join("")));
    }
    return parts;
}
function parsePart(part) {
    const start = part.slice(EMOTICON_START_TOKEN.length);
    if (start.startsWith("emoticon")) {
        const emoticon = parseEmoticon(part);
        if (emoticon !== undefined) {
            return emoticon;
        }
        else {
            error(`Cannot parse emoticon: ${emoticon}`);
            return EMPTY_EMOTICON;
        }
    }
    // string matches %%%[ but no actual tokens, just render as string
    return makeStringPart(part);
}
function makeStringPart(msg) {
    return {
        partType: MessagePartType.string,
        s: msg,
    };
}
function makeShortcodePart(sc, shortcode) {
    return {
        partType: MessagePartType.shortcode,
        s: sc,
        code: shortcode.code,
        amt: shortcode.amt,
        msg: shortcode.msg,
        signupLink: shortcode.signupLink,
    };
}
function parseEmoticon(emoticon) {
    emoticon = emoticon.replace("%%%[emoticon ", "").replace("]%%%", "");
    const emoticonParts = emoticon.split(/\|/);
    if (emoticonParts.length === 6) {
        const emoticon = {
            partType: MessagePartType.emoticon,
            name: emoticonParts[0],
            thumbUrl: emoticonParts[1],
            width: validDimension(emoticonParts[2])
                ? Number(emoticonParts[2])
                : 80,
            height: validDimension(emoticonParts[3])
                ? Number(emoticonParts[3])
                : 80,
            imgUrl: emoticonParts[4],
            abuseUrl: emoticonParts[5],
        };
        if (emoticon.thumbUrl !== undefined && validUrl(emoticon.thumbUrl)) {
            return emoticon;
        }
        else {
            return undefined;
        }
    }
    else if (emoticonParts.length === 5) {
        const emoticon = {
            partType: MessagePartType.emoticon,
            name: emoticonParts[0],
            imgUrl: emoticonParts[1],
            width: validDimension(emoticonParts[2])
                ? Number(emoticonParts[2])
                : 80,
            height: validDimension(emoticonParts[3])
                ? Number(emoticonParts[3])
                : 80,
            abuseUrl: emoticonParts[4],
        };
        if (validUrl(emoticon.imgUrl)) {
            return emoticon;
        }
        else {
            return undefined;
        }
    }
    else {
        return undefined;
    }
}
function validUrl(str) {
    const res = str.match(/(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-/]))?/g);
    if (res === null) {
        error("Invalid emoticon URL");
        return false;
    }
    else {
        return true;
    }
}
function validDimension(str) {
    if (isNaN(parseInt(str, 10))) {
        error("Invalid dimension argument");
        return false;
    }
    else {
        return true;
    }
}
