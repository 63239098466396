import { isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"

const LOCAL_STORAGE_KEY = "dmMessagingConversation"

export interface IDmWindowState {
    currentConversation: string
    isMinimized: boolean
}

export class DmMessagingStorage {
    private static getStorageObject(): Record<string, IDmWindowState> {
        if (!isLocalStorageSupported()) {
            return {}
        }
        
        const storage = window.localStorage.getItem(LOCAL_STORAGE_KEY)
        if (storage === null) {
            return {}
        }
        try {
            return JSON.parse(storage)
        } catch {
            return {}
        }
    }

    private static saveStorageObject(storageObject: Record<string, IDmWindowState>): void {
        if (!isLocalStorageSupported()) {
            return
        }
        
        window.localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storageObject))
    }

    public static saveState(myUsername: string, state: IDmWindowState): void {
        if (!isLocalStorageSupported()) {
            return
        }
        const storageObject = { [myUsername]: state }
        this.saveStorageObject(storageObject)
    }

    public static removeState(myUsername: string): void {
        if (!isLocalStorageSupported()) {
            return
        }
        window.localStorage.removeItem(LOCAL_STORAGE_KEY)
    }

    public static getState(myUsername: string): IDmWindowState | undefined {
        if (!isLocalStorageSupported()) {
            return undefined
        }
        const storageObject = this.getStorageObject()
        return storageObject[myUsername]
    }
} 