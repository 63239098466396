import { isPortrait } from "@multimediallc/web-utils"
import { Toggle } from "../../cb/components/toggle"
import { Component } from "../defui/component"
import { numberFromStyle } from "../DOMutils"
import { EventRouter } from "../events"
import { sdRatio } from "../player/playerSettings"
import { LLHLSSupported, mobileLLHLS } from "../player/utils"
import { dom } from "../tsxrender/dom"
import { createAdvancedOptions } from "./advancedOptions"
import { Header } from "./header"
import { RoomTabs } from "./roomTabs"
import { TabList, TabName } from "./tabList"
import { sendMessageInputFocus, userPanelRequest, userSwitchedTab } from "./userActionEvents"
import { UserPanel } from "./userPanel"
import { getPortraitDimensions, getViewportWidth } from "./viewportDimension"
import type { AdvancedOptions } from "./advancedOptions"
import type { ChatContents } from "./chatContents"
import type { MobileDismissibleMessages } from "./mobileDismissibleMessages"
import type { MobilePlayer } from "./mobilePlayer"
import type { IUserPanelRequest } from "./userPanel"

interface IPortraitContentsProps {
    player: MobilePlayer
    mobileDismissibleMessages: MobileDismissibleMessages
}

export const closeAdvancedOptions = new EventRouter<void>("closeAdvancedOptions")

export class PortraitContents extends Component<HTMLDivElement, IPortraitContentsProps> {
    private player: MobilePlayer
    private mobileDismissibleMessages: MobileDismissibleMessages
    private tabList: TabList
    private header: Header
    private roomTabs: RoomTabs
    private userPanel: UserPanel
    private optionsDiv: HTMLDivElement
    private LLHLSEnabled = false
    private optionOverlay: HTMLDivElement
    private advancedOptions: AdvancedOptions

    constructor(props: IPortraitContentsProps) {
        super("div", props)
    }

    protected initData(props: IPortraitContentsProps): void {
        this.player = props.player
        this.mobileDismissibleMessages = props.mobileDismissibleMessages
        this.tabList = new TabList(this.player)
        this.userPanel = new UserPanel()
    }

    protected initUI(): void {
        const containerStyle: CSSX.Properties = {
            width: "100%",
            height: "100%",
            top: `${getViewportWidth() * sdRatio}px`,
            position: "absolute",
            boxSizing: "border-box",
        }

        this.element = <div style={containerStyle} />

        if (LLHLSSupported() && mobileLLHLS()) {
            this.element.appendChild(this.createAdvancedOptions())
        }

        this.header = new Header({ tabList: this.tabList })
        this.addChild(this.header)

        this.roomTabs = new RoomTabs({ tabList: this.tabList })
        this.addChild(this.roomTabs)

        this.player.didRepositionEvent.listen(() => {
            this.repositionChildrenRecursive()
        })

        this.initListenersForUserPanel()
    }

    private initListenersForUserPanel(): void {
        userPanelRequest.listen((panelRequest: IUserPanelRequest) => {
            this.closeUserPanel()
            this.openUserPanel(panelRequest)
        })

        sendMessageInputFocus.listen(() => {
            this.closeUserPanel()
        })

        userSwitchedTab.listen(() => {
            this.closeUserPanel()
        })
    }

    // Close UCM if user clicks outside the UCM (replaces UCM's overlay element, and allows chat to scroll freely)
    private closeUcmClickHandler = (e: MouseEvent): void => {
        if (e.target instanceof Node && this.userPanel.element !== undefined && !this.userPanel.element.contains(e.target)) {
            this.closeUserPanel()
        }
    }

    private openUserPanel(panelRequest: IUserPanelRequest): void {
        this.userPanel.updateContents(panelRequest.username, panelRequest.message)
        const clickedMessageLine = panelRequest.attachTo

        if (clickedMessageLine.parentElement !== null) {
            clickedMessageLine.parentElement.insertBefore(this.userPanel.element, clickedMessageLine.nextSibling)
        } else {
            error("Error inserting userPanel.div: panelRequest.attachTo.parentElement is null")
        }

        this.scrollMessageList(panelRequest)
        this.scrollUserList(panelRequest)

        document.addEventListener("click", this.closeUcmClickHandler)
    }

    private closeUserPanel(): void {
        this.userPanel.hide()
        document.removeEventListener("click", this.closeUcmClickHandler)
    }

    private scrollMessageList(panelRequest: IUserPanelRequest): void {
        if (![TabName.Chat, TabName.Private].includes(RoomTabs.currentTab)) {
            return
        }

        const chatMessageListWrapper = this.tabList.getChatTab().getChatWindow().chatContents.messageListWrapper
        const privateTabMessageListWrapper = this.tabList.getPrivateTab().getChatContents().messageListWrapper
        const messageListWrapper = RoomTabs.currentTab === TabName.Chat ? chatMessageListWrapper : privateTabMessageListWrapper

        const clickedMessageDiv = panelRequest.attachTo.parentElement

        if (clickedMessageDiv !== null && messageListWrapper !== undefined) {
            const privateContainerHeight = RoomTabs.currentTab === TabName.Private
                ? this.tabList.getPrivateTab().getPrivateContainerHeight()
                : this.tabList.getChatTab().getChatWindow().getPrivateContainerHeight()
            const distanceFromBottom = clickedMessageDiv.offsetTop
                + clickedMessageDiv.offsetHeight
                - (messageListWrapper.offsetHeight + messageListWrapper.scrollTop)
                - (privateContainerHeight + numberFromStyle(this.userPanel.element.style.marginBottom))

            if (distanceFromBottom > 0) {
                messageListWrapper.scrollTop += distanceFromBottom
            }
        }
    }

    private scrollUserList(panelRequest: IUserPanelRequest): void {
        // scroll room menu user list when opening the last user's
        // userPanel to ensure that it's fully visible
        const scrollableWrapper = this.userPanel.element.offsetParent
        const usernameLabel = panelRequest.attachTo

        if (this.userPanel === null || !(scrollableWrapper instanceof HTMLElement) || RoomTabs.currentTab !== TabName.RoomMenu) {
            return
        }

        const labelHeight = usernameLabel.offsetHeight + numberFromStyle(usernameLabel.style.marginBottom)
        const userPanelHeight = this.userPanel.element.offsetHeight + numberFromStyle(this.userPanel.element.style.marginBottom)
        const newScrollTop =
            usernameLabel.offsetTop
            - (scrollableWrapper.offsetHeight + scrollableWrapper.scrollTop)
            + labelHeight
            + userPanelHeight

        if (newScrollTop > 0) {
            scrollableWrapper.scrollTop += newScrollTop
        }
    }

    public getChatTabChatContents(): ChatContents {
        return this.tabList.getChatTab().getChatContents()
    }

    public getPrivateTabChatContents(): ChatContents {
        return this.tabList.getPrivateTab().getChatContents()
    }

    public getPrivateShowChatContents(): ChatContents | undefined {
        return this.tabList.getPrivateTab().getPrivateShowChatContents()
    }

    public getAllChatContents(): ChatContents[] {
        const privateShowChatContents = this.getPrivateShowChatContents()

        return [
            this.getChatTabChatContents(),
            this.getPrivateTabChatContents(),
            ...privateShowChatContents !== undefined ? [privateShowChatContents] : [],
        ]
    }

    protected repositionChildren(): void {
        if (!isPortrait() || this.player.isFullscreen) {
            this.hideElement()
        } else {
            if (!this.player.isFullscreen) {
                this.showElement()
                this.player.videoControls.mobilePureChat?.setVisible(false)
            }

            this.repositionContainer()
            this.repositionRoomTabs()
        }
    }

    protected repositionContainer(): void {
        const roomHeaderHeight = document.getElementById("static-header")?.offsetHeight ?? 40
        const playerHeight = this.player.element.offsetHeight
        const dismissibleMessagesHeight = this.mobileDismissibleMessages.element.offsetHeight
        const contentHeight =
            getPortraitDimensions().height
            - roomHeaderHeight
            - playerHeight
            - dismissibleMessagesHeight

        this.element.style.height = `${contentHeight}px`
        this.element.style.top = `${playerHeight}px`
    }

    protected repositionRoomTabs(): void {
        const portraitHeaderHeight = this.header.element.offsetHeight
        this.roomTabs.element.style.height = `calc(100% - ${portraitHeaderHeight}px)`
    }

    protected createAdvancedOptions(): HTMLDivElement {
        this.optionOverlay = document.createElement("div")
        this.optionOverlay.style.backgroundColor = "rgba(0, 0, 0, 0.50)"
        this.optionOverlay.style.position = "absolute"
        this.optionOverlay.style.display = "none"
        this.optionOverlay.style.opacity = "0"
        this.optionOverlay.style.width = "100%"
        this.optionOverlay.style.height = "100%"
        this.optionOverlay.style.justifyContent = "center"
        this.optionOverlay.style.zIndex = "2"

        this.advancedOptions = createAdvancedOptions()

        this.optionsDiv = this.advancedOptions.optionsDiv
        this.optionOverlay.appendChild(this.optionsDiv)

        const toggle = new Toggle(false, () => {
            this.player.playerComponent.forceStream(toggle.isChecked())
        }, { height: 24, width: 48 })
        toggle.element.style.marginRight = "16px"
        this.optionsDiv.appendChild(toggle.element)

        this.optionsDiv.onclick = (e) => {
            e.stopPropagation()
            toggle.setChecked(!toggle.isChecked())
            this.LLHLSEnabled = toggle.isChecked()
        }

        this.optionOverlay.onclick = () => {
            closeAdvancedOptions.fire()
        }

        this.advancedOptions.HLSToggleTooltip.style.top = "-90px"
        this.advancedOptions.HLSToggleTooltip.style.left = "24px"

        this.optionOverlay.appendChild(this.advancedOptions.HLSToggleTooltip)

        this.player.playerComponent.updateLLHLSButton.listen(({ allowed, enabled }) => {
            if (this.LLHLSEnabled !== enabled) {
                this.LLHLSEnabled = enabled
                toggle.setCheckedDirectly(enabled)
            }
        })

        return this.optionOverlay
    }

    public toggleAdvancedOptions(open: boolean): void {
        if (open) {
            this.optionOverlay.style.display = "flex"
            this.optionOverlay.style.opacity = "1"
            this.optionsDiv.style.display = "flex"
            this.optionsDiv.style.opacity = "1"
        } else {
            this.optionOverlay.style.display = "none"
            this.optionOverlay.style.opacity = "0"
            this.optionsDiv.style.display = "none"
            this.optionsDiv.style.opacity = "0"
            this.advancedOptions.HLSToggleTooltip.style.display = "none"
            this.advancedOptions.HLSToggleTooltip.style.opacity = "0"
        }
    }
}
