import { isLocalStorageSupported, isUsingPwa } from "@multimediallc/web-utils/modernizr"
import { getCookieOrEmptyString } from "@multimediallc/web-utils/storage"
import { addOrientationChangeListener, RoomStatus } from "@multimediallc/web-utils"
import twemoji from "@twemoji/api"
import { dmsEnabled } from "../cb/api/pm"
import { tokenBalanceUpdate } from "../cb/api/tipping"
import { Footer } from "../cb/components/footer"
import { Header } from "../cb/components/header/header"
import { setupDmWindow } from "../cb/components/pm/dmWindowsManager"
import { setupMessagingConversationWindow } from "../cb/components/messaging/dmMessagingConversation"
import { openFeedbackForm } from "../cb/components/userMenus/ui/userFeedbackFormEvents"
import { UserFeedbackModal } from "../cb/components/userMenus/ui/userFeedbackModal"
import {
    bindDmListDropDown,
    bindHeaderOpenedEvents,
    bindMessagingDropdown,
    bindUserMenuDropdown,
} from "../cb/components/userMenus/ui/userMenuDropDown"
import { FollowedTab } from "../cb/components/followingTab/followedTab"
import { ReactComponentRegistry } from "../cb/components/ReactRegistry"
import { inactiveTabCheck } from "../cb/inactiveTabCheck"
import { pageContext } from "../cb/interfaces/context"
import { parseSocialAuths } from "../cb/interfaces/socialAuth"
import { parseLoggedInUser } from "../cb/interfaces/user"
import { PushService } from "../cb/pushservicelib/pushService"
import { UserAutoRefillAttemptTopic, UserLowBalanceTopic, UserTokenUpdateTopic } from "../cb/pushservicelib/topics/user"
import { RoomReload, setRoomAnimation } from "../cb/roomList"
import { showAVSignupOverlayIfEnabled } from "../cb/ui/ageVerificationSignupOverlay"
import { showLoginOverlay } from "../cb/ui/loginOverlay"
import { bindGetMoreTokensLink, isOneClickEligible, popUpPurchasePage, setupOneClickListener, updateTokencountElements } from "../cb/ui/tipping"
import { addEventListenerPoly } from "../common/addEventListenerPolyfill"
import { EmoticonPreviewModal, MobileEmoticonPreviewModal, openEmoticonPreview } from "../common/emoticonPreviewModal"
import { addPageAction, setCustomAttribute } from "../common/newrelic"
import { UserNotesBadgeManager } from "../common/notes"
import {
    commandeerJoinOverlayAnchors,
    convertBaseHeaderFooterLinksToJoinOverlayAnchors,
    JoinOverlay,
    showJoinOverlay,
} from "../common/theatermodelib/joinOverlay"
import type { DiscoverJoinOverlay } from "../common/theatermodelib/discoverJoinOverlay"
import { parseQueryString } from "../common/urlUtil"
import { LOGIN_BASE_PATH, SIGN_UP_BASE_PATH, watchForAccountRedirectNavigation } from "../common/redirectParamUtils"
import { bindAgeGateSignup } from "../cb/ui/agegate/ageGateSignup"
import { initCssVhVarHandling } from "../cb/util/cssVhVarUtil"
import { initMessageRenderOptions } from "../common/renderMessage"
import { isDesktopDmActive, isMobileDmActive, isMobilePushMenuRefactorActive, isReactUserListActive } from "../common/featureFlagUtil"
import { initFullscreenChangeEvent, isFullscreen } from "../common/fullscreen"
import { normalizeResource } from "../common/api"
import { modalConfirm } from "../common/alerts"
import { i18n } from "../common/translation"

import type { NewrelicAttributes } from "@multimediallc/web-utils"
import type { DmListDropdown } from "../cb/components/pm/dmListDropdown"
import type { UserMenuDropDown } from "../cb/components/userMenus/ui/userMenuDropDown"
import type { UserUpdatesPanel } from "../cb/components/userMenus/ui/userUpdatesPanel"
import type { IPageContext } from "../cb/interfaces/context"
import type { IEmoticon } from "../common/emoticonAutocompleteModal"
import type { ILoginOverlayProps } from "../cb/ui/loginOverlay"
import { bindGlobalDismissibleNotices } from "../cb/dismissibleNotice"
import { activateI18n } from "../i18n"
import { MobilePushMenu } from "../cb/components/userMenus/ui/mobilePushMenu"
import { MobileMessagingIcon } from "../cb/components/messaging/mobileMessagingIcon"
import { performToggleIsActive } from "../common/featureFlag"
import { ReactWrapper } from "../cb/components/ReactWrapper"
import { isAnonymous } from "../common/auth"
import { screenOrientationChanged } from "../common/mobilelib/windowOrientation"
import { siteHeaderMenuOpened } from "../common/mobilelib/userActionEvents"
import { dismissToast, showToast } from "../common/showToast"
import { t } from "@lingui/macro"
import { DesktopMessagingIcon } from "../cb/components/messaging/desktopMessagingIcon"
import { VideoMode, videoModeHandler } from "../common/videoModeHandler"
import { roomLoaded } from "../common/context"
import { bindMobileDmsMenu, bindMobileLogoutLink, bindUserUpdatesPanel } from "../cb/components/userMenus/mobilePushMenuBindUtils"

declare const REVISION: string
declare const GIT_TAG: string


type IEntryPoint = new(context: IPageContext) => BaseEntryPoint

export function exportEntryPoint(entryPoint: IEntryPoint): void {
    window["TS"] = entryPoint
}

export class BaseEntryPoint {
    protected context: IPageContext
    public roomReload: object
    public addPageAction: (name: string, attributes: NewrelicAttributes, modernTiming?: boolean) => void
    public showLoginOverlay: (options: ILoginOverlayProps) => void // for template use
    public openFeedbackForm: (source: string) => void | undefined // undefined for mobile

    constructor(contextObj: IPageContext) {
        this.parseContext(contextObj)
        pageContext.current = this.context
        this.initI18n()
        this.setupTwemojiBase()
        this.init()
        this.listenForOrientationChanges()
        this.addErrorAttributes()
    }

    // eslint-disable-next-line complexity, @typescript-eslint/no-explicit-any
    protected parseContext(contextObj: Record<string, any>): void {
        this.context = {
            current_logo: contextObj["current_logo"],
            isMobile: contextObj["is_mobile"],
            sample_metrics_off: contextObj["sample_metrics_off"],
            qualityMessage: contextObj["quality_message"],
            loggedInUser: parseLoggedInUser(contextObj["logged_in_user"]),
            isAgeGated: contextObj["user_age_gated"],
            animateThumbnails: contextObj["animate_thumbnails"],
            socialAuths: parseSocialAuths(contextObj["social_auths"]),
            forceSocialAuth: contextObj["force_social_auth"],
            isBroadcast: false,  // Overridden in broadcast entrypoints
            darkModeEnabled: contextObj["dark_mode_enabled"],
            themeName: contextObj["theme_name"],
            dmsEnabled: contextObj["sitewide_pms_enabled"] ?? false,
            contextID: contextObj["entrypoint_context_id"],
            csrftoken: contextObj["csrftoken"],
            isLoadedFromCache: undefined,
            showPaxumNotice: contextObj["show_paxum_notice"] ?? false,
            realtimeUserlistEnabled: contextObj["realtime_userlist_enabled"] === true,
            isInternal: contextObj["INTERNAL"],
            isDevstage: contextObj["DEVSTAGE"],
            pageLocation: contextObj["pageLocation"] ?? "homepage", // Overridden in entrypoints if needed
            PurchaseEventSources: contextObj["PurchaseEventSources"],
            languageCode: contextObj["language_code"],
            showLocation: Boolean(contextObj["showLocation"]) ?? true,
            showNpsSentimentSurveyGroup: contextObj["show_nps_sentiment_survey_group"] ?? false,
            showBroadcasterAppSurvey: contextObj["show_app_usage_survey"] ?? false,
            regions: contextObj["regions"] ?? "",
            isTestbed: contextObj["isTestbed"] ?? false,
            noImage: contextObj["noImage"] ?? false,
            throttleTopicPublish: contextObj["push_throttle_topic_publish"] === true,
            isNoninteractiveUser: false,
            mergePmDm: contextObj["merge_pm_dm"],
            noAnalytics: contextObj["no_analytics"],
            showMobileSiteBannerLink: contextObj["show_mobile_site_banner_link"] ?? false,
            twemojiBase: contextObj["twemoji_base"] ?? "",
            noindexOnWhitelabels: contextObj["enable_whitelabels_noindex"] ?? false,
        }
        PushService.initialize(contextObj)
        setRoomAnimation(this.context.animateThumbnails)
        this.setUserInteractive()

        if (isLocalStorageSupported()) {
            const prevContextID = window.localStorage.getItem("contextID")
            window.localStorage.setItem("contextID", this.context.contextID)

            this.context.isLoadedFromCache = prevContextID === this.context.contextID
        }
    }

    /*
    Use this method to bind global site-wide actions. This file should be kept very short and
    concise. Actions must be defined and performed in other files/objects.
    NOTE: several entrypoints override init(), e.g. embed and photovideos, and may need updating too if adding code here
     */
    protected init(): void {
        inactiveTabCheck()
        this.roomReload = RoomReload.exportToJS()
        this.addPageAction = addPageAction
        this.showLoginOverlay = showLoginOverlay
        if (this.context.isMobile) {
            this.initMobile()
        } else {
            this.initDesktop()
        }
        this.setCustomAttributes()
        setupOneClickListener()
        this.setupNotificationListeners()
        initMessageRenderOptions()
        initCssVhVarHandling()
        initFullscreenChangeEvent()
        showAVSignupOverlayIfEnabled()
        bindGlobalDismissibleNotices()
        if (this.context.isAgeGated) {
            bindAgeGateSignup()
        }
    }

    protected initMobile(): void {
        this.bindMobilePushMenu()
        this.bindPurchaseTokenLinks()
        this.bindTokenCountUpdater()
        this.setupEmoticonPreviewModal(true)
        watchForAccountRedirectNavigation()
        this.updateFlashMessageDisplay()
        this.bindAutoRefillToggle()

        if (this.context.loggedInUser !== undefined) {
            this.setupLogoutModal()
        }
        this.setupMobileMessagingIcon()
    }

    protected initDesktop(): void {
        this.bindUserInfoPanelDropdown()
        this.bindUserUpdatesPanel(false)
        this.bindPurchaseTokenLinks()
        if (!this.context.isAgeGated) {
            isDesktopDmActive() ? this.bindMessagingDropdown() : this.bindDmListDropdown(false)
            this.bindFollowedTab()
            this.handlePmChatWindows()
        }
        this.setupPurchaseNotificationsContainer()
        this.setupEmoticonPreviewModal(false)
        this.bindHeader()
        this.bindFooter()

        this.bindTokenCountUpdater()
        watchForAccountRedirectNavigation()

        if (this.context.loggedInUser === undefined) {
            if (window.location.pathname !== LOGIN_BASE_PATH) {
                this.setupLoginOverlay()
            }
            if (parseQueryString(window.location.search)["join_overlay"] !== undefined) {
                if (window.location.pathname !== SIGN_UP_BASE_PATH) {
                    this.setupJoinOverlay()
                }
            }
        }
        if (this.context.loggedInUser !== undefined) {
            this.setupLogoutModal()
        }
        this.setupUserFeedbackForm()
        this.watchForAdIntervention()
        this.openFeedbackForm = (source: string) => {
            openFeedbackForm.fire({ source: source })
        }

        this.setupDesktopMessagingIcon()
    }

    protected initI18n(): void {
        activateI18n(this.context.languageCode)
    }

    // Called only in room entrypoints (broadcast.ts, theatermode.ts, etc), but defined here so they can share the code
    protected initRoom(): void {
        // Definitely other parts of init could go in here too, so feel free to move them here if you notice them

        // Ensure UserNotesChatBadges singleton instance is created and prefetches notes
        UserNotesBadgeManager.getOrCreateInstance()
        this.setupReactGenderIcons()
    }

    // Call in entrypoints that have instances of the React GenderIcon
    protected setupReactGenderIcons(): void {
        if (isReactUserListActive()) {
            const defsDiv = document.createElement("div")
            defsDiv.style.height = "0"
            defsDiv.style.width = "0"
            document.body.appendChild(defsDiv)
            const GenderIconSvgDefs = ReactComponentRegistry.get("GenderIconDefs")
            new GenderIconSvgDefs({}, defsDiv)
        }
    }

    private setCustomAttributes(): void {
        setCustomAttribute("history_length", history.length.toString())
        setCustomAttribute("is_using_pwa", isUsingPwa() ? "true" : "false")
    }

    protected addErrorAttributes(): void {
        // adds base attributes to JS errors when reporting to NewRelic
        if (this.context.loggedInUser !== undefined) {
            setCustomAttribute("username", this.context.loggedInUser.username)
            setCustomAttribute("enduser.id", this.context.loggedInUser.userUid)
        } else {
            setCustomAttribute("username", "__anon__")
            setCustomAttribute("enduser.id", "0")
        }
        setCustomAttribute("enduser.session", this.context.contextID)
        setCustomAttribute("revision", REVISION)
        setCustomAttribute("git_tag", GIT_TAG)
    }

    protected setupJoinOverlay(): void {
        convertBaseHeaderFooterLinksToJoinOverlayAnchors()
        commandeerJoinOverlayAnchors.fire(undefined)

        this.addJoinOverlayListener(new JoinOverlay({}))
    }

    protected addJoinOverlayListener(overlay: JoinOverlay): void {
        let joinOverlay: JoinOverlay | DiscoverJoinOverlay | undefined
        showJoinOverlay.listen(joinOverlayAnchor => {
            if (joinOverlay === undefined) {
                joinOverlay = overlay
            }
            window.scrollTo(0, 0)
            joinOverlay.initAndShow(joinOverlayAnchor)
        })
    }

    protected setupUserFeedbackForm(): void {
        openFeedbackForm.listen(data => {
            UserFeedbackModal.show(data)
        })
    }

    protected setupLoginOverlay(): void {
        for (const el of document.querySelectorAll<HTMLAnchorElement>("a.login-link")) {
            el.onclick = (e) => {
                showLoginOverlay({})
                e.preventDefault()
            }
        }
    }

    private setupNotificationListeners(): void {
        const userUid = pageContext.current?.loggedInUser?.userUid
        const isAutoRefillActive = performToggleIsActive("TknRflTopUp")

        if (userUid === undefined || !isAutoRefillActive) {
            return
        }

        function isRealFullscreen(): boolean {
            return isFullscreen() || videoModeHandler.getVideoMode() === VideoMode.Theater || window.location.pathname.includes("fullvideo")
        }

        new UserAutoRefillAttemptTopic(userUid).onMessage.listen((message) => {
            const refillAmount = message.refillAmount
            const messageText = t`${refillAmount} tokens have been auto refilled.`
            showToast({
                message: messageText,
                type: "success-autorefill",
                className: isRealFullscreen() ? "fullscreen" : "",
            })
        })

        new UserLowBalanceTopic(userUid).onMessage.listen((message) => {
            const type = message.context === "private" ? "low-balance-private" : "low-balance-common"
            const timeRemaining = message?.timeRemaining ?? 0
            showToast({
                message: "",
                type: type,
                timeRemaining: timeRemaining,
                onClick: popUpPurchasePage,
                className: isRealFullscreen() ? "fullscreen" : "",
            })
        })
        roomLoaded.listen((room) => {
            room.chatConnection.event.statusChange.listen(roomStatusChangeNotification => {
                const leftPrivate = [RoomStatus.PrivateWatching, RoomStatus.PrivateSpying].includes(roomStatusChangeNotification.previousStatus)
                if (leftPrivate) {
                    dismissToast("low-balance-private")
                }
            })
        })
    }

    private setupPurchaseNotificationsContainer(): void {
        const ToastContainer = ReactComponentRegistry.get("SharedToastContainer")
        const userInfoPanel = document.querySelector("#header .section")

        if (ToastContainer !== undefined && userInfoPanel !== null) {
            const wrapper = document.createElement("div")
            wrapper.id = "purchase-toast-container"
            new ToastContainer({ containerId: "purchase-toast-container", style: { position: "absolute" }  }, wrapper)
            userInfoPanel.appendChild(wrapper)
        }
    }

    private bindAutoRefillToggle(): void {
        const userId = pageContext.current.loggedInUser?.userUid

        if (userId === undefined || !performToggleIsActive("TknRflTopUp")) {
            return
        }

        const tokenAutoRefillWrapper = new ReactWrapper({
            component: "MobileAutoRefillToggle",
            componentProps: { hidden: true },
        })
        const headerLinks = document.querySelector("#header-links")
        if (headerLinks !== null) {
            headerLinks.appendChild(tokenAutoRefillWrapper.element)
        }

        siteHeaderMenuOpened.listen((isOpen) => {
            if (!isOneClickEligible) {
                return
            }
            tokenAutoRefillWrapper.update({ hidden: !isOpen })
        })
    }

    protected setupLogoutModal(): void {
        const logoutLinks = document.querySelectorAll<HTMLAnchorElement>("a.logout-link")
        if (logoutLinks.length > 0) {
            const logoutForm = document.createElement("form")
            logoutForm.method = "post"
            logoutForm.action = normalizeResource("/auth/logout/")
            logoutForm.target = "_top"
            logoutForm.style.display = "none"

            const csrfField = document.createElement("input")
            csrfField.type = "hidden"
            csrfField.name = "csrfmiddlewaretoken"
            csrfField.value = getCookieOrEmptyString("csrftoken")
            logoutForm.appendChild(csrfField)
            document.body.appendChild(logoutForm)

            for (const el of document.querySelectorAll<HTMLAnchorElement>("a.logout-link")) {
                el.onclick = (e) => {
                    e.preventDefault()
                    const text = this.context.isAgeGated ? i18n.switchAccountModalText : i18n.logoutModalText
                    modalConfirm(text, () => {
                        csrfField.value = getCookieOrEmptyString("csrftoken")
                        logoutForm.submit()
                    })
                }
            }
        }
    }

    private listenForOrientationChanges(): void {
        addOrientationChangeListener(() => screenOrientationChanged.fire())
    }

    protected bindUserInfoPanelDropdown(): UserMenuDropDown | undefined {
        const userInfoPanelMenuDropdownRoot = document.querySelector<HTMLDivElement>("#userInfoPanelMenuDropdownRoot")
        const userInfoToggleId = "user_information_profile_container"
        const userInformationProfileDropDownToggle = document.getElementById(userInfoToggleId)

        return bindUserMenuDropdown(
            userInfoPanelMenuDropdownRoot,
            userInformationProfileDropDownToggle,
            this.context.loggedInUser !== undefined ? this.context.loggedInUser.username : "",
            this.context.darkModeEnabled,
        )
    }

    protected bindMobilePushMenu(): void {
        let desktopLink: HTMLAnchorElement | undefined

        if (isMobilePushMenuRefactorActive()) {
            const pushmenuContainer = document.getElementById("pushmenu-container")
            const menuButton = document.getElementById("mmnav")
            if (pushmenuContainer !== null && menuButton !== null) {
                const mobilePushMenu = new MobilePushMenu(pushmenuContainer, { menuButton: menuButton as HTMLDivElement })
                desktopLink = mobilePushMenu.desktopLink
            }
        } else {
            this.bindUserUpdatesPanel(true)
            if (!this.context.isAgeGated) {
                this.bindDmListDropdown(true)
            }
            bindHeaderOpenedEvents()
            bindMobileLogoutLink()
            desktopLink = document.querySelector<HTMLAnchorElement>("#mobile_desktop_link") ?? undefined
        }

        if (desktopLink !== undefined) {
            this.bindDesktopRedirectLinkUpdates(desktopLink)
        }
    }

    protected bindDesktopRedirectLinkUpdates(anchor: HTMLAnchorElement): void { }

    protected bindUserUpdatesPanel(mobile=false): UserUpdatesPanel | undefined {
        const userUpdatesBellRoot = document.querySelector<HTMLDivElement>("#userUpdatesBellRoot")
        const userUpdatesDropDownRoot = document.querySelector<HTMLDivElement>("#userUpdatesMenuDropdownRoot")
        const mmnav = document.querySelector<HTMLDivElement>("#mmnav")

        return bindUserUpdatesPanel(
            userUpdatesBellRoot,
            userUpdatesDropDownRoot,
            mobile,
            mmnav,
        )
    }

    protected bindDmListDropdown(mobile=false): DmListDropdown | undefined{
        const userUpdatesBellRoot = document.querySelector<HTMLDivElement>("#userUpdatesBellRoot")
        const dmListDropdownRoot = document.querySelector<HTMLDivElement>("#dmListDropdownRoot")
        const dmListIconRoot = document.querySelector<HTMLDivElement>("#dmListIconRoot")
        const messagingIconRoot = document.querySelector<HTMLDivElement>("#messagingIconRoot")
        const mmnav = document.querySelector<HTMLDivElement>("#mmnav")

        if (mobile) {
            bindMobileDmsMenu(
                isMobileDmActive() && isAnonymous() && messagingIconRoot ? messagingIconRoot : dmListIconRoot,
                userUpdatesBellRoot,
                mmnav,
                dmListDropdownRoot,
            )
        } else {
            return bindDmListDropDown(
                dmListIconRoot,
                dmListDropdownRoot,
            )
        }

        return undefined
    }

    protected bindMessagingDropdown(): void {
        if(isAnonymous()) {
            return
        }
        const messagingIconRoot = document.querySelector<HTMLDivElement>("#messagingIconRoot")
        const dmMessagingDropdownRoot = document.querySelector<HTMLDivElement>("#dmListDropdownRoot")
        bindMessagingDropdown(messagingIconRoot, dmMessagingDropdownRoot)
    }

    protected bindPurchaseTokenLinks(): void {
        for (const el of document.querySelectorAll<HTMLAnchorElement>(".purchase_tokens a, a.purchase_tokens, a#purchase_tokens")) {
            if (this.context.loggedInUser !== undefined) {
                bindGetMoreTokensLink(el)
            } else {
                el.onclick = (e) => {
                    showLoginOverlay({})
                    e.preventDefault()
                }
            }
        }
    }

    protected watchForAdIntervention(): void {
        if (!window.hasOwnProperty("ReportingObserver")) {
            return
        }

        const sendReports = (reports: Report[]) => {
            for (const report of reports) {
                if (report["type"] === "intervention") {
                    addPageAction("AdIntervention", { "report": JSON.stringify(report["body"]) })
                }
            }
        }
        const observer = new window["ReportingObserver"](
            (reports: Report[], observer: ReportingObserver) => {
                sendReports(reports)
            },
            { buffered: true },
        )
        observer.observe()

        // Needed in addition to observer.observe()
        // Experimentation shows beforeunload but not pagehide works to get sendReports in before newrelic flushes its queue
        addEventListenerPoly("beforeunload", window, (event) => {
            const reports = observer.takeRecords()
            sendReports(reports)
        })
    }

    protected bindFollowedTab(): void {
        const followedAnchor = document.getElementById("followed_anchor")
        if (followedAnchor !== null && followedAnchor instanceof HTMLAnchorElement) {
            followedAnchor.onclick = (ev) => {
                if (!ev.ctrlKey && !ev.metaKey) {
                    ev.preventDefault()
                }
            }
            new FollowedTab(followedAnchor, this.context.loggedInUser !== undefined ? this.context.loggedInUser.username : "")
        }
    }

    protected bindHeader(): Header | undefined  {
        const headerEl = document.querySelector("#header")
        if (headerEl !== null && headerEl instanceof HTMLElement) {
            return new Header(headerEl)
        }
        return undefined
    }

    protected bindFooter(): Footer | undefined {
        const footerEl = document.querySelector(".footer-holder")
        if (footerEl !== null && footerEl instanceof HTMLElement) {
            return new Footer(footerEl)
        }
        return undefined
    }


    protected handlePmChatWindows(): void {
        const isStaffTools = window.location.pathname.startsWith("/staff_tools")
        const headerDiv = document.getElementById("header")
        if (headerDiv !== null && headerDiv.offsetParent !== null && !isStaffTools) {
            if (this.context.loggedInUser?.isStaff === true || this.context.loggedInUser !== undefined && dmsEnabled()) {
                isDesktopDmActive() ? setupMessagingConversationWindow(this.context.loggedInUser.username) : setupDmWindow(this.context.loggedInUser.username)
            }
        }
    }

    protected setupEmoticonPreviewModal(isMobile = false): void {
        let emoticonPreviewModal: EmoticonPreviewModal | MobileEmoticonPreviewModal | undefined
        openEmoticonPreview.listen((emoticon: IEmoticon) => {
            if (emoticonPreviewModal === undefined) {
                emoticonPreviewModal = isMobile ? new MobileEmoticonPreviewModal() : new EmoticonPreviewModal()
            }
            emoticonPreviewModal.initAndShow(emoticon)
        })
    }

    protected setupTwemojiBase(): void {
        twemoji.base = this.context.twemojiBase
    }

    protected bindTokenCountUpdater(): void {
        const userUid = pageContext.current.loggedInUser?.userUid

        if (userUid !== undefined) {
            new UserTokenUpdateTopic(userUid).onMessage.listen(update => {
                tokenBalanceUpdate.fire(update)
            })
        }

        tokenBalanceUpdate.listen((update) => {
            updateTokencountElements(update.tokens)
        })
    }

    private updateFlashMessageDisplay(): void {
        /**
         * Currently only used on mobile to prevent showing the flash message container in the case that
         * messages with specific tags have been filtered out within the template.
         */
        const flashMessageContainer = document.querySelector<HTMLDivElement>(".flash-message-container")
        if (flashMessageContainer !== null) {
            const messages = flashMessageContainer.querySelectorAll<HTMLParagraphElement>(".message p")

            if (messages.length === 0) {
                flashMessageContainer.style.display = "none"
            }
        }
    }

    private setUserInteractive(): void {
        /*
         * Staff users who are on internal.chaturbate.com are deemed to be non-interactive and will not show up in userlist.
         * They should not be able to participate in a room in any way.
         */
        if (this.context.isInternal && this.context.loggedInUser?.isStaff === true && PRODUCTION && !this.context.isDevstage) {
            this.context.isNoninteractiveUser = true
        }
    }

    private setupMobileMessagingIcon(): void {
        if (!isMobileDmActive()) {
            return
        }

        const menuIconContainer = document.getElementById("messagingIconRoot")
        if (menuIconContainer) {
            const mobileMessagingIconMenu = new MobileMessagingIcon()
            menuIconContainer.appendChild(mobileMessagingIconMenu.element)
        }
    }

    private setupDesktopMessagingIcon(): void {
        if(!performToggleIsActive("DesktopDm")) {
            return
        }

        const menuIconContainer = document.getElementById("messagingIconRoot")
        if (menuIconContainer) {
            const desktopMessagingIconMenu = new DesktopMessagingIcon()
            menuIconContainer.appendChild(desktopMessagingIconMenu.element)
        }
    }
}

exportEntryPoint(BaseEntryPoint)
