import { Component } from "../../../common/defui/component"
import { dom } from "../../../common/tsxrender/dom"
import { createDmWindowRequest } from "../pm/dmWindowsManager"
import { ReactComponentRegistry } from "../ReactRegistry"
import { DmMessagingStorage } from "./dmMessagingStorage"
import { messagingProps } from "./messagingUtil"
import type { ReactComponent } from "../ReactRegistry"

const MINIMIZED_HEIGHT = "48px"
const EXPANDED_HEIGHT = "424px"
const MINIMIZED_WIDTH = "292px"
const EXPANDED_WIDTH = "328px"

interface IDmMessagingConversationProps {
    username: string;
    myUsername: string;
}

class DmMessagingConversation extends Component {
    username: string
    myUsername: string
    messaging?: ReactComponent
    private static instance: DmMessagingConversation
    isMinimized: boolean

    constructor(props: IDmMessagingConversationProps) {
        super("div", props)
    }

    protected initData(props?: IDmMessagingConversationProps): void {
        if (props) {
            this.username = props.username
            this.myUsername = props.myUsername

            const savedState = DmMessagingStorage.getState(props.myUsername)
            this.isMinimized = savedState?.currentConversation === props.username ? savedState.isMinimized : false
        }
    }

    public saveWindowState(): void {
        DmMessagingStorage.saveState(this.myUsername, {
            currentConversation: this.username,
            isMinimized: this.isMinimized,
        })
    }

    initUI(props: IDmMessagingConversationProps): void {
        super.initUI(props)

        const containerStyle: CSSX.Properties = {
            position: "fixed",
            bottom: "0",
            right: "35px",
            width: this.isMinimized ? MINIMIZED_WIDTH : EXPANDED_WIDTH,
            height: this.isMinimized ? MINIMIZED_HEIGHT : EXPANDED_HEIGHT,
            backgroundColor: "#fff",
            boxShadow: "0px 0px 20px 0px #00000033",
            borderRadius: "8px 8px 0 0",
        }
        this.element = <div style={containerStyle} />
        this.updateHeight()
        this.initMessaging(props.username)
    }

    protected initMessaging(username: string): void {
        const Messaging = ReactComponentRegistry.get("Messaging")
        const onClose = () => {
            this.hideElement()
        }

        const handleMinimize = (minimized: boolean) => {
            this.isMinimized = minimized
            this.updateHeight()
            this.saveWindowState()

            if (this.messaging) {
                this.messaging.update({ isMinimized: this.isMinimized })
            }
        }

        this.messaging = new Messaging(
            {
                ...messagingProps,
                view: "desktop_conversation",
                initialEntry: `/messages/${username}`,
                onClose,
                onMinimize: handleMinimize,
                isMinimized: this.isMinimized,
            },
            this.element,
        )
    }

    private updateHeight(): void {
        if (this.element !== null) {
            this.element.style.height = this.isMinimized ? MINIMIZED_HEIGHT : EXPANDED_HEIGHT
            this.element.style.width = this.isMinimized ? MINIMIZED_WIDTH : EXPANDED_WIDTH
        }
    }

    public hideElement(): void {
        super.hideElement()
        DmMessagingStorage.removeState(this.myUsername)
    }

    public showElement(): void {
        super.showElement()
    }

    public updateConversation(username: string): void {
        if (this.messaging) {
            this.messaging.dispose()
            this.username = username
            this.isMinimized = false
            this.updateHeight()
            this.initMessaging(username)
            this.showElement()
            this.saveWindowState()
        }
    }

    public static getOrCreateInstance(props: IDmMessagingConversationProps): DmMessagingConversation {
        if (DmMessagingConversation.instance === undefined) {
            DmMessagingConversation.instance = new DmMessagingConversation(props)
        }
        return DmMessagingConversation.instance
    }
}

export function setupMessagingConversationWindow(myUsername: string): void {
    let dmMessagingConversation: DmMessagingConversation | undefined

    const createMessagingConversationWindow = (username: string): void => {
        if (!username) { return }

        if (dmMessagingConversation === undefined) {
            dmMessagingConversation = DmMessagingConversation.getOrCreateInstance({ username, myUsername })
            document.body.appendChild(dmMessagingConversation.element)
            dmMessagingConversation.saveWindowState()
        } else if (dmMessagingConversation.username !== username) {
            dmMessagingConversation.updateConversation(username)
        }
    }

    const savedState = DmMessagingStorage.getState(myUsername)
    if (savedState) {
        createMessagingConversationWindow(savedState.currentConversation)
    }

    createDmWindowRequest.listen((username: string) => {
        createMessagingConversationWindow(username)
    })
}
