// Module include functions and data structure to track from which place broadcaster url has been opened.
import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { setCookieWithExpiration } from "@multimediallc/web-utils/storage"
import { RoomListSource } from "@multimediallc/web-utils/types"
import { getCb, normalizeResource } from "../common/api"
import { isLanguageSubdomainActive } from "../common/featureFlagUtil"
import { Gender, GendersSymbolToNameMap, getCurrentGender } from "../common/genders"
import { addPageAction } from "../common/newrelic"
import { ignoreCatch } from "../common/promiseUtils"
import { RoomImageStreamer } from "../common/roomImage"
import { isRoomRoomlistSpaEligiblePage, shouldBlur } from "./components/roomlist/spaHelpers"
import { pageContext } from "./interfaces/context"
import { currentSiteSettings } from "./siteSettings"

const COOKIE_TTL = 30  // Tracker cookie time to live in seconds.
const COOKIE_PREFIX = "tbu_"
export const MILLISECOND_MULTIPLIER = 1000
export const ROOM_RELOAD_SECONDS = 900  // 15 minutes

type VoidFunction = () => void

// eslint-disable-next-line complexity
export function parseRoomListSource(source?: string): RoomListSource {
    switch (source) {
        case RoomListSource.Default:
        case RoomListSource.Recommended:
        case RoomListSource.RecommendedSearch:
        case RoomListSource.FallbackRoomlist:
        case RoomListSource.FollowedTab:
        case RoomListSource.RecommendedFollow:
        case RoomListSource.FollowedNotification:
        case RoomListSource.FollowedPageOnline:
        case RoomListSource.FollowedPageOffline:
        case RoomListSource.MoreLikeThis:
        case RoomListSource.MoreRooms:
        case RoomListSource.TagPage:
        case RoomListSource.Promoted:
        case RoomListSource.Boosted:
        case RoomListSource.NextCam:
        case RoomListSource.ScanCam:
        case RoomListSource.Discover:
        case RoomListSource.Trending:
        case RoomListSource.Small:
        case RoomListSource.PrivateShows:
        case RoomListSource.CommunityControlledShows:
        case RoomListSource.AffiliatesCamListing:
            return source as RoomListSource
        default:
            return RoomListSource.Unknown
    }
}

function genCookieName(broadcasterUsername: string): string {
    return `${COOKIE_PREFIX}${broadcasterUsername}`
}

export interface RoomSourceOptionalFields {
    sourceIndex?: number,   // Extra index if the source is a subset of another source [1-indexed].
    hashtag?: string,       // Room hashtag.
    sourceInfo?: string,    // Source info string holding extra information about source.
    rowNum?: number,        // Number of the row where this room appeared (1-indexed).
    colNum?: number,        // Number of the column where this room appeared (1-indexed).
    roomListId?: string,    // Unique ID associated with the current/given roomlist.
    blsPayload?: string,    // JSON-encoded extra data for BLS events on the current/given roomlist.
}

function roomListSourceCheckOversizedCookie(data: string) {
    const cookie = document.cookie // eslint-disable-line no-restricted-syntax
    if (cookie.length + data.length < 4096) {
        return
    }

    const cookieLenAttrs = Object.fromEntries(cookie.split(";").slice(0, 50).map(rawValue => {
        const [name, value] = rawValue.trim().split("=")
        return [`cookielen-${name}`, value.length]
    }))
    addPageAction("BroadcasterSourceOversizedCookie", {
        "cookie_length": cookie.length,
        "data_length": data.length,
        ...cookieLenAttrs,
    })
}

function getRoomlistFilterSource(): string {
    const state = UrlState.current.state
    return JSON.stringify({
        "gender": state.genders?.[0],
        "tag": state.tags?.[0],
        "regions": state.regions?.join(","),
        "private_prices": state.privatePrices?.join(","),
        "spy_prices": state.spyShowPrices?.join(","),
        "room_size": state.roomSize,
        "ages": state.ageMin === undefined && state.ageMax === undefined
            ? undefined
            : `${state.ageMin ?? ""}-${state.ageMax ?? ""}`,
        "languages": state.spokenLanguages?.join(","),
        "keywords": state.keywords,
    })
}

/**
 * Set cookie with info about room list source of room user navigated to
 * @param broadcasterUsername Username of room to which user initiated navigation.
 * @param source Room list source.
 * @param index Room index (1-indexed).
 * @param optionalFields additional optional supported fields. KEYS MUST BE STRINGS.
 */
export function saveRoomListSourceCookie(
    broadcasterUsername: string, source: RoomListSource, index: number,
    optionalFields?: RoomSourceOptionalFields,
): void {
    const value = JSON.stringify({
        "source": source,
        "index": index,
        "source_filter": getRoomlistFilterSource(),
        // Explicitly name the optional field keys as strings to avoid minification.
        "source_index": optionalFields?.sourceIndex,
        "hashtag": optionalFields?.hashtag,
        "source_info": optionalFields?.sourceInfo,
        "row_num": optionalFields?.rowNum,
        "col_num": optionalFields?.colNum,
        "room_list_id": optionalFields?.roomListId,
        "bls_payload": optionalFields?.blsPayload,
    }, (k, v) => v === "" ? undefined : v ?? undefined)
    roomListSourceCheckOversizedCookie(value)
    setCookieWithExpiration(`${genCookieName(broadcasterUsername)}`, value, { seconds: COOKIE_TTL }, "/", isLanguageSubdomainActive())
}

export function getRoomImageURL(roomName: string, useWideImage = true): string {
    const noImage = pageContext.current.isTestbed || pageContext.current.noImage
    if (noImage) {
        return `${STATIC_URL_ROOT}images/no_thumbnail_1${useWideImage ? "_wide" : ""}.jpg`
    } else {
        return `${currentSiteSettings.jpegRoomImgUrl}${useWideImage ? "riw" : "ri"}/${roomName}.jpg?${Math.floor(new Date().getTime() / 30000)}`
    }
}

/**
 * Singleton class to load/reload rooms.
 */
export class RoomReload {
    private static instance?: RoomReload
    private static refreshTimer?: number
    private static refreshImageTimer?: number
    protected onLoadhandlers: VoidFunction[] = []
    protected streamers: Map<string, RoomImageStreamer>

    private constructor() {
        this.streamers = new Map()
    }

    /**
     * Returns an array of HTMLElement with rooms.
     */
    private getContainers(): HTMLElement[] {
        const containers: HTMLElement[] = []

        let querySelectors = ".endless_page_template, .MoreRooms, .followedDropdown"
        if (isRoomRoomlistSpaEligiblePage()) {
            const querySelectorList = [".followedDropdown"]
            if (UrlState.current.state.room !== undefined) {
                querySelectorList.push(".MoreRooms")
            } else {
                querySelectorList.push(".endless_page_template")
            }
            querySelectors = querySelectorList.join(", ")
        }

        const elements = document.querySelectorAll<HTMLElement>(querySelectors)
        for (const element of elements) {
            containers.push(element)
        }
        return containers
    }

    /**
     * Adds a handler to run after loading rooms.
     * @param handler A function without arguments to run after room load is completed.
     */
    public addOnLoadHandler(handler: VoidFunction): void {
        this.onLoadhandlers.push(handler)
    }

    /**
     * Runs handlers once rooms are loaded successfully.
     */
    protected onSuccess(): void {
        for (const handler of this.onLoadhandlers) {
            handler()
        }
    }

    /**
     * Returns an instance of the class.
     */
    static getInstance(): RoomReload {
        if (RoomReload.instance === undefined) {
            RoomReload.instance = new RoomReload()
        }
        return RoomReload.instance
    }

    /**
     * Returns a url which could be used to load rooms.
     * @param roomListContainer A wrapper of room list.
     */
    protected getUrl(roomListContainer: HTMLElement): string | undefined {
        let href = ""
        const dataHref = roomListContainer.getAttribute("data-href")
        if (dataHref === "skip") {
            return undefined
        }
        if (typeof dataHref !== "string" || dataHref.length === 0) {
            href = window.location.href.replace(location.hash === "" ? "#" : location.hash, "")
        } else {
            href = dataHref[0] === "/" ? dataHref.substr(1) : dataHref

            let addchar = "?"

            if (href.indexOf("?") !== -1) {
                addchar = "&"
            }

            const keywordSearchParams =  new URLSearchParams(window.location.search).get("keywords")
            const formParams = keywordSearchParams !== null ? `keywords=${keywordSearchParams}` : ""
            return href + addchar + formParams
        }

        return href
    }

    /**
     * Schedule room reload.
     * @param delaySeconds Refresh rate in seconds.
     */
    static scheduleRefresh(delaySeconds: number): void {
        RoomReload.clearRefresh()
        if (delaySeconds > 0) {
            RoomReload.refreshTimer = window.setTimeout(() => {
                const loader = RoomReload.getInstance()
                loader.loadRooms()
                RoomReload.scheduleRefresh(delaySeconds)
            }, delaySeconds * MILLISECOND_MULTIPLIER)
        }
    }

    /**
     * Schedule Thumbnail Refresh.
     * @param delaySeconds Refresh rate in seconds.
     */
    static scheduleImageRefresh(delaySeconds: number): void {
        RoomReload.clearImageRefresh()
        let delayMilliseconds = delaySeconds * MILLISECOND_MULTIPLIER
        if (shouldBlur()) {
            delayMilliseconds = 300
        }

        if (delaySeconds > 0) {
            RoomReload.refreshImageTimer = window.setTimeout(() => {
                const loader = RoomReload.getInstance()
                loader.loadImages()
                // On mobile we don't auto-refresh thumbnails for bandwidth reasons, so we
                // only poll until the thumbnails are unblurred and then clear the interval
                if (pageContext.current.isMobile && !shouldBlur()) {
                    return
                }
                RoomReload.scheduleImageRefresh(delaySeconds)
            }, delayMilliseconds)
        }
    }

    static clearRefresh(): void {
        clearTimeout(RoomReload.refreshTimer)
    }

    static clearImageRefresh(): void {
        clearTimeout(RoomReload.refreshImageTimer)
    }

    /**
     * Loads rooms.
     * @param onSuccess A function without arguments to execute once rooms are loaded.
     */
    public loadRooms(onSuccess?: VoidFunction): void {
        for (const streamer of this.streamers.values()) {
            streamer.stopStreaming()
        }
        this.streamers.clear()

        const containers = this.getContainers()
        const loadRoomPromises: Promise<void>[] = []
        for (const container of containers) {
            const requestUrl = this.getUrl(container)
            if (requestUrl !== undefined) {
                loadRoomPromises.push(getCb(requestUrl).then(resp => {
                    // eslint-disable-next-line @multimediallc/no-inner-html
                    container.innerHTML = resp.responseText
                }))
            }
        }
        // Once all containers with URLs finish successfully reloading via AJAX (or immediately, if there are none)
        // we call the onSuccess handlers EXACTLY ONCE.
        Promise.all(loadRoomPromises).then(() => {
            this.onSuccess()
            if (onSuccess !== undefined) {
                onSuccess()
            }
        }).catch(ignoreCatch)
    }

    public loadImages(): void {
        if (shouldBlur()) {
            return
        }
        for (const container of this.getContainers()) {
            if (container.style.display === "none") {
                continue
            }
            container.querySelectorAll("img.room_thumbnail").forEach((img: HTMLImageElement) => {
                if (img.parentElement !== null) {
                    const roomName = img.parentElement.dataset["room"]
                    const useWideImage = img.dataset["wideImage"] === "true"

                    if (roomName !== undefined) {
                        const newImage = new Image(img.width, img.height)
                        newImage.onload = () => {
                            img.src = newImage.src
                        }
                        newImage.src = getRoomImageURL(roomName, useWideImage)
                    }
                }
            })
        }
    }

    public getImageStreamer(room: string): RoomImageStreamer|undefined {
        return this.streamers.get(room)
    }

    public removeImageStreamer(room: string): void {
        this.streamers.delete(room)
    }

    public getOrCreateImageStreamer(room: string, imgElement: HTMLImageElement, startStreaming = true, useWideImages?: boolean): RoomImageStreamer {
        let streamer = this.getImageStreamer(room)
        if (streamer === undefined) {
            streamer = new RoomImageStreamer(room, imgElement, {
                startStreaming: startStreaming,
                useWideImages: useWideImages ?? !pageContext.current.isMobile,
                streamingFadeoutTimeoutMs: 0,
            })
            this.streamers.set(room, streamer)
        }
        return streamer
    }

    public safariReloadAllImages(): void {
        for (const streamer of this.streamers.values()) {
            streamer.safariReloadImage()
        }
    }

    static exportToJS(): object {
        return {
            "addOnLoadHandler": addOnLoadHandler,
            "loadRooms": loadRooms,
            "scheduleRefresh": scheduleRefresh,
            "chatRoomListOnClick": chatRoomListOnClick,
            "startStreaming": startStreaming,
            "stopStreaming": stopStreaming,
            "clearRefresh": clearRefresh,
            "setRoomAnimation": setRoomAnimation,
        }
    }
}

/**
 * Adds a handler to run after loading rooms.
 * @param handler A function without arguments to run after room load is completed.
 */
export function addOnLoadHandler(handler: VoidFunction): void {
    RoomReload.getInstance().addOnLoadHandler(handler)
}

/**
 * Loads rooms.
 * @param onSuccess A function without arguments to execute once rooms are loaded.
 */
export function loadRooms(onSuccess?: VoidFunction): void {
    RoomReload.getInstance().loadRooms(onSuccess)
}

/**
 * Schedule room reload.
 * @param delaySeconds Refresh rate in seconds.
 */
export function scheduleRefresh(delaySeconds: number): void {
    RoomReload.scheduleRefresh(delaySeconds)
}

export function clearRefresh(): void {
    RoomReload.clearRefresh()
}

export function chatRoomListOnClick(
    broadcasterUsername: string, sourceName: string, position: number,
    sourcePosition?: number, hashTag?: string, roomListId?: string, blsPayload?: string,
): void {
    saveRoomListSourceCookie(broadcasterUsername, parseRoomListSource(sourceName), position, {
        sourceIndex: sourcePosition,
        hashtag: hashTag,
        roomListId: roomListId,
        blsPayload: blsPayload,
    })
}

let ROOM_ANIMATION_ENABLED = false
export function setRoomAnimation(showRoomAnimations: boolean): void {
    ROOM_ANIMATION_ENABLED = showRoomAnimations
}

export function isRoomAnimationEnabled(): boolean {
    return ROOM_ANIMATION_ENABLED
}

export function startStreaming(room: string, imgElement: HTMLImageElement|null, useWideImages?: boolean, forceAnimations?: boolean): void {
    const enableAnimations = forceAnimations === undefined ? isRoomAnimationEnabled() : forceAnimations
    if (enableAnimations && imgElement !== null) {
        RoomReload.getInstance().getOrCreateImageStreamer(room, imgElement, true, useWideImages).startStreaming()
    }
}

export function stopStreaming(room: string, forceAnimations?: boolean): void {
    const enableAnimations = forceAnimations === undefined ? isRoomAnimationEnabled() : forceAnimations
    if (enableAnimations) {
        const streamer = RoomReload.getInstance().getImageStreamer(room)
        if (streamer !== undefined) {
            streamer.stopStreaming()
            RoomReload.getInstance().removeImageStreamer(room)
        }
    }
}

export function addGenderToSubjectLinks(container: HTMLElement): void {
    const currentGender = getCurrentGender()
    if (currentGender !== Gender.All) {
        container.querySelectorAll<HTMLAnchorElement>(".subject > li > a").forEach(anchor => {
            anchor.href = normalizeResource(anchor.href.concat(`${GendersSymbolToNameMap.get(currentGender)}/`))
        })
    }
}
