import { MessagingIcon } from "./messagingIcon"

export class DesktopMessagingIcon extends MessagingIcon {
    protected getStyles(): Record<string, CSSX.Properties> {
        const baseStyles = this.getBaseStyles()
        return {
            ...baseStyles,
            img: {
                width: "13px",
                height: "13px",
            },
        }
    }

    protected handleClick(): void {
        return
    }

    protected getBadgeProps(unread: number): Record<string, unknown> {
        return {
            ...super.getBadgeProps(unread),
            variant: "compact",
        }
    }
} 