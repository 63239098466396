import type { IUserColors } from "./roomDossier"
import type { GameSelectionData } from "../cb/components/games/gameSelection"
import type { IShortcode } from "../cb/interfaces/shortcode"
import type { ITopicMessage } from "../cb/pushservicelib/topics/base"
import type { PrivateRequestStatus, RoomStatus } from "@multimediallc/web-utils"
import type { IPrivateMessage, IRoomMessage, IRoomNotice, IRoomNoticePart, IUserInfo } from "@multimediallc/web-utils/types"

// Reexports for allowing types to be moved to web-utils/types without updating a million imports.
// For new types that don't have existing imports to update, recommend importing directly from web-utils where used instead of reexporting here
// TODO(CBD-1028) Clean some of this up
export type {
    IChatursafeFlagged,
    IPrivateMessage,
    IRoomMessage,
    IRoomNotice,
    IRoomNoticePart,
    IUserInfo,
    IChatMedia,
    IRemoveMessagesNotification,
    IShortcodeMessage,
} from "@multimediallc/web-utils/types"

export interface ITipAlert {
    fromUser: IUserInfo
    message: string
    amount: number
    isAnonymousTip: boolean
    ts?: number
    tid?: string
    toUsername?: string
}


export function isPrivateMessage(message: IRoomMessage): message is IPrivateMessage {
    return (message as IPrivateMessage).isPrivate === true
}

export interface IAppLog {
    type: string,
    errorMessages?: IRoomNoticePart[][]
    debugMessage?: string,
    tid?: string,
}

export interface IPrivateShowSettings {
    allowPrivateShow: boolean
    privatePrice: number
    spyPrice: number
    privateMinMinutes: number
    allowShowRecordings: boolean
    fanClubSpyPrice?: number
    premiumPrivatePrice: number
    premiumPrivateMinMinutes: number
}

export interface IPrivateShowParameters {
    allowPrivateShow: boolean
    privatePrice: number
    spyPrice: number
    privateMinMinutes: number
    allowShowRecordings: boolean
}

export interface ISettingsUpdateNotification extends IPrivateShowSettings {
    hasFanClub: boolean
    activePassword: boolean
    tid?: string
}

export interface IPrivateShowRequestNotification {
    userRequesting: string
    tokensPerMinute: number
    isPremium?: boolean
    delayFiringEvent: boolean
}

export interface ITokenBalanceUpdateNotification {tokens: number}

export interface ITipInPast24HoursNotification {
    tokens: number
    roomName: string
}

export interface IRoomStatusChangeNotification {
    previousStatus: RoomStatus
    currentStatus: RoomStatus
}

export interface IUserUpdateItem {
    text: string
    notificationType?: string
    url: string
    timestamp: number
    timeSince: string
    seen: boolean
}

export interface IUserAlert {
    message: string,
    tid?: string,
}

export interface IBanSilenceInfo {
    silenced: string,
    silencer: string,
    isBan: boolean,
}

export interface IPurchase {
    fromUser: IUserInfo,
    message: string,
    ts?: number,
    tid?: string,
}

export interface IRoomAction {
    username: string,
    fromUser: string,
}

export const enum EnterLeaveAction {
    Enter= "enter",
    Leave= "leave",
}

export interface IEnterLeave {
    user: IUserInfo,
    action: EnterLeaveAction,
    connections?: number,
}

// Push Service related interfaces

export interface IPushNewsUpdateItem extends IUserUpdateItem, ITopicMessage {}
export interface IPushUserColors extends IUserColors, ITopicMessage {}
export interface IPushTokenBalanceUpdateNotification extends ITokenBalanceUpdateNotification, ITopicMessage {}
export interface IPushRoomMessage extends IRoomMessage, ITopicMessage {}
export interface IPushAppLog extends IAppLog, ITopicMessage {}
export interface IPushPurchase extends IPurchase, ITopicMessage {}
export interface IPushUserAlert extends IUserAlert, ITopicMessage {}
export interface IPushRoomAction extends IRoomAction, ITopicMessage {}
export interface IPushSettingsUpdateNotification extends ISettingsUpdateNotification, ITopicMessage {}
export interface IPushNotice extends IRoomNotice, ITopicMessage { }

export interface IPushBroadcastWarning extends ITopicMessage {
    text: string
    warningId: number
    response: boolean
}

export interface IUpdateOfflineTipItem extends IPushNewsUpdateItem {fromUsername: string}

export interface IPushEnterLeave extends IEnterLeave, ITopicMessage {viewers: number}

export interface IPushPrivateMessage extends IPrivateMessage, ITopicMessage {room: string}

export interface IPushTipAlert extends ITipAlert, ITopicMessage {roomType: string}

export interface IOneClickPush extends ITopicMessage {is_one_click_eligible: boolean}

export interface INewsSeenUpdate extends ITopicMessage {timestamp: number}


export const enum UserUpdateType {
    appTabRefresh = "app_tab_refresh",
}

export interface IUserUpdate extends ITopicMessage {target: UserUpdateType,}

export interface IChatMediaOpened extends ITopicMessage {
    messageId: string
    mediaId: number
}

export interface IChatMediaRemoved extends ITopicMessage {
    mediaId: number
    isCompliance: boolean
}

export interface IRoomTitleChange extends ITopicMessage {title: string,}

export interface IRoomStatus extends ITopicMessage {
    status: RoomStatus,
    message: string,
    substatus?: string,
}

export const enum HiddenShowStatuses {
    APPROVED = "approved",
    DENIED = "denied",
}

export interface IHiddenShowStatus extends ITopicMessage {status: HiddenShowStatuses}

export const enum RoomUpdateType {
    refreshPanel = "refresh_panel",
}

export interface IRoomUpdate extends ITopicMessage {
    target: RoomUpdateType,
    targetUser?: string,
    appId?: string,
    appSystem?: string,
}

export interface IPrivateShowStatus extends ITopicMessage {
    status: PrivateRequestStatus
    privateShowId: string
    isPremium: boolean
    reason?: string
}

export interface IBroadcasterPrivateStatus extends IPrivateShowStatus {
    requester?: string
    earlyCancelTokens?: number
    delayFiringEvent: boolean
}

export interface IFollowerTopic extends ITopicMessage {
    followerUsername: string
    followedUsername: string
    isFollowing: boolean
}

export interface IIgnoreTopic extends ITopicMessage {
    username: string
    isIgnored: boolean
}

export interface IPmReadTopic extends ITopicMessage {
    otherUsername: string
    room?: string
}

export interface IPushBackendChange extends ITopicMessage {backends: string[]}

export interface IShortcodeNotice {
    shortcodes: IShortcode[],
    message: string,
}

export interface IGameUpdate extends ITopicMessage {game: GameSelectionData}

export interface IViewerPromotion extends ITopicMessage {
    purchaser: IUserInfo
    durationMins: number
}

export interface IRoomPasswordProtected extends ITopicMessage {passwordHash: string}

export interface IAutoRefillAttempt extends ITopicMessage {refillAmount: number}

export const enum LowBalanceContext {
    Common = "common",
    Private = "private",
}

export interface ILowBalance extends ITopicMessage {
    context: LowBalanceContext
    timeRemaining: number
}

export interface IPushUserTyping extends ITopicMessage {fromUsername: string}
