import { CbColor, ColorSpace } from "./cbColor/cbColor";
import { LinearGradient } from "./linearGradient";
CbColor.setColorSpace(ColorSpace.YPbPr);
const bgLightness = CbColor.darkModeBg.lightness;
const fgLightness = CbColor.darkModeFg.lightness;
function stroke(elm, noTextShadow) {
    if (!noTextShadow) {
        elm.style.textShadow =
            "1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000";
    }
}
function hasNoticeInnerText(msgDiv) {
    if (!msgDiv.innerText) {
        return false;
    }
    return msgDiv.innerText.startsWith("Notice:");
}
export function setColors(outerDiv, initFg, initBg, noTextShadow = false) {
    initFg = (initFg !== null && initFg !== void 0 ? initFg : "").trim();
    initBg = (initBg !== null && initBg !== void 0 ? initBg : "").trim();
    const innerDiv = outerDiv.firstElementChild;
    let msgDiv = innerDiv;
    let bgDiv = outerDiv;
    if (innerDiv.classList.contains("roomNotice")) {
        bgDiv = innerDiv;
        if (!hasNoticeInnerText(msgDiv)) {
            return;
        }
    }
    else {
        msgDiv = outerDiv.querySelector(".msg-text");
    }
    const fg = CbColor.get(initFg);
    if (fg.rgbRange < 10) {
        // if the color has barely any saturation, just use the default
        msgDiv.style.color = "#D3D3D3";
    }
    else {
        msgDiv.style.color = fg
            .setLightness(fgLightness)
            .setAlpha(1)
            .toRgbString();
    }
    if (initBg.length > 0) {
        const gradient = LinearGradient.parse(initBg);
        if (gradient !== undefined) {
            gradient.stops.forEach((stop) => {
                const alpha = stop.color.getAlpha();
                if (stop.color.rgbRange < 10) {
                    stop.color = CbColor.transparent;
                }
                else {
                    stop.color = stop.color
                        .setLightness(bgLightness)
                        .setAlpha(alpha);
                }
            });
            bgDiv.style.background = gradient.toString();
        }
        else {
            let bg = CbColor.get(initBg);
            if (bg.rgbRange < 10) {
                bgDiv.style.removeProperty("background");
                stroke(msgDiv, noTextShadow);
            }
            else {
                const alpha = bg.getAlpha();
                bg = bg.setLightness(bgLightness).setAlpha(alpha);
                bgDiv.style.background = bg.toRgbString();
                if (alpha < 1) {
                    stroke(msgDiv, noTextShadow);
                }
            }
        }
    }
    else {
        stroke(msgDiv, noTextShadow);
    }
}
