import { t } from "@lingui/macro"
import { applyStyles } from "../../../common/DOMutils"
import { ModalComponent } from "../../../common/modalComponent"
import { dom } from "../../../common/tsxrender/dom"

export class DmMessagingLandscapeModal extends ModalComponent {
    protected modalContent: HTMLDivElement

    constructor() {
        super()
        applyStyles(this.element, {
            position: "fixed",
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: 1006,
            touchAction: "none",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        })
        applyStyles(this.overlay, {
            zIndex: 1005,
            background: "rgba(0, 0, 0, 0.5)",
            touchAction: "none",
        })

        this.modalContent = <ModalContent onConfirm={() => this.hide()} />
        this.element.appendChild(this.modalContent)

        this.overlayClick.listen(() => {
            this.hide()
        })
    }
}

type ModalProps = { onConfirm: () => void }

const ModalContent = ({ onConfirm }: ModalProps) => {
    const headline = t`Rotate to View Messages`
    const text = t`Please rotate your device to portrait mode to view messages.`
    const buttonText = t`Got it`

    const containerStyles: CSSX.Properties = {
        display: "flex",
        verticalAlign: "middle",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "24px",
        backgroundColor: "#fff",
        borderRadius: "8px",
        margin: "0 16px",
    }

    const imageStyles: CSSX.Properties = { marginBottom: "18px" }

    const headlineStyles: CSSX.Properties = {
        fontFamily: "'UbuntuBold', Arial, Helvetica, sans-serif",
        fontWeight: 700,
        fontSize: "16px",
        marginBottom: "8px",
        textAlign: "center",
        width: "100%",
    }

    const textStyles: CSSX.Properties = {
        fontFamily: "'UbuntuRegular', Arial, Helvetica, sans-serif",
        fontWeight: 400,
        fontSize: "12px",
        marginBottom: "16px",
        textAlign: "center",
        width: "100%",
    }

    const buttonStyles: CSSX.Properties = {
        all: "unset",
        fontFamily: "'UbuntuRegular', Arial, Helvetica, sans-serif",
        fontWeight: 400,
        fontSize: "12px",
        backgroundColor: "#0C6A93",
        color: "#fff",
        padding: "12px 90px",
        borderRadius: "4px",
        whiteSpace: "nowrap",
    }

    return (
        <div style={containerStyles}>
            <img style={imageStyles} src={`${STATIC_URL_ROOT}images/rotate-to-portrait.svg`}/>
            <div style={headlineStyles}>{headline}</div>
            <div style={textStyles}>{text}</div>
            <button style={buttonStyles} onClick={onConfirm}>{buttonText}</button>
        </div>
    )
}
