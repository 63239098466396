import { addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { modalConfirm } from "../../../common/alerts"
import { isAnonymous } from "../../../common/auth"
import { MobileDmsMenu } from "../../../common/mobilelib/mobileDmsMenu"
import { i18n } from "../../../common/translation"
import { currentSiteSettings } from "../../siteSettings"
import { MobileUserUpdatesPanel, UserUpdatesPanel } from "./ui/userUpdatesPanel"

export function bindMobileDmsMenu(
    dmListIconRoot: HTMLDivElement | null,
    userUpdatesBellRoot: HTMLDivElement | null,
    hamburgerIcon: HTMLDivElement | null | undefined,
    dmListDropDownRoot: HTMLDivElement | null,
): void {
    if (dmListIconRoot !== null && dmListDropDownRoot !== null) {
        dmListDropDownRoot.appendChild(new MobileDmsMenu({ dmListIconRoot: dmListIconRoot, userUpdatesBellRoot, hamburgerIcon }).element)
    }
}

export function bindMobileLogoutLink(): void {
    const logoutLink = document.querySelector<HTMLAnchorElement>("#logout-conf")
    const logoutForm = document.querySelector<HTMLFormElement>("#user_logout_form")

    if (logoutLink === null || logoutForm === null || isAnonymous()) {
        return
    }

    addEventListenerPoly("click", logoutLink, (event) => {
        event.preventDefault()
        modalConfirm(i18n.logoutModalText, () => {logoutForm.submit()})
    })
}

export function bindUserUpdatesPanel(userUpdatesBellRoot: HTMLDivElement | null,
    userUpdatesDropDownRoot: HTMLDivElement | null,
    mobile = false,
    hamburgerIcon?: HTMLDivElement | null): UserUpdatesPanel | undefined {
    // for whitelabels we don't show the bell
    let userUpdatesPanel: UserUpdatesPanel | undefined
    if (!currentSiteSettings.isWhiteLabel && userUpdatesBellRoot !== null && userUpdatesDropDownRoot !== null) {
        if (mobile) {
            userUpdatesPanel = new MobileUserUpdatesPanel(userUpdatesBellRoot)
            if (hamburgerIcon !== undefined && hamburgerIcon !== null) {
                userUpdatesPanel.initAdditionalToggleElement(hamburgerIcon)
            }
        } else {
            userUpdatesPanel = new UserUpdatesPanel(userUpdatesBellRoot)
        }

        userUpdatesDropDownRoot.appendChild(userUpdatesPanel.dropDownComponent.element)
        userUpdatesPanel.element.dataset.testid = "user-updates-panel"
    }
    return userUpdatesPanel
}
