import { isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { pageContext } from "../../cb/interfaces/context"
import { addEventListenerPoly } from "../../common/addEventListenerPolyfill"
import { modalConfirm } from "../../common/alerts"
import { normalizeResource } from "../../common/api"
import { roomLoaded } from "../../common/context"
import { scanCoords } from "../../common/coords"
import { Component } from "../../common/defui/component"
import { DivotPosition } from "../../common/divot"
import { EventRouter } from "../../common/events"
import { performToggleIsActive } from "../../common/featureFlag"
import { siteHeaderMenuOpened } from "../../common/mobilelib/userActionEvents"
import { addPageAction } from "../../common/newrelic"
import { OverlayComponent } from "../../common/overlayComponent"
import { dismissToast } from "../../common/showToast"
import { i18n } from "../../common/translation"
import { safeWindowOpen } from "../../common/windowUtils"
import { TipType } from "../api/tipping"
import { addColorClass, removeColorClass } from "../colorClasses"
import {
    MobilePurchasePageWrapper,
    OneClickFlowPurchase,
    OneClickFlowPurchaseDesktop,
    PurchasePageDesktopWrapper,
} from "../oneClickFlowPurchase"
import { UserOneClickTopic } from "../pushservicelib/topics/user"
import { buildTooltip } from "./tooltip"

import Key = JQuery.Key

import type { IOffsets } from "../../common/coords"
import type { RoomType } from "../../common/roomUtil"

export const MAX_TIP_DIGITS = 16

// Internally used interface for focus management
interface IFocusData {
    elements: Element[]
    index: number
}

/**
 * Represents the data that backs a {@link ButtonMenuItem}.
 * @typedef {Object} IButtonMenuData
 * @property label - text shown in the drop down menu
 * @property value - value to associate with the tip button when selected (default: label)
 * @property description - shown below the choice's label in the drop down menu.
 * @property buttonLabel - what the button's text changes to when this item is selected (default: label)
 * @property enabledMouseClick - callback for when an enabled choice is clicked
 * @property disabledMouseClick - callback for when a disabled choice is clicked
 * @property disabledTooltip - tooltip for when a disabled choice is moused over
 * @property disabledModalText - modal text for when a disabled choice is touched on a touch screen
 */
export interface IButtonMenuData {
    label: string
    value: TipType
    description?: string
    buttonLabel?: string
    enabledMouseClick: EventListener
    disabledMouseClick?: EventListener
    disabledTooltip?: HTMLDivElement
    disabledModalText?: string
}

interface IMenuItemChange {
    previous?: ButtonMenuItem
    current: ButtonMenuItem
}

export class ButtonMenuItem extends Component {
    readonly dt: HTMLElement
    readonly input: HTMLInputElement
    readonly dd: HTMLElement | undefined
    readonly enabledMouseClick: EventListener
    readonly disabledMouseClick: EventListener | undefined
    readonly disabledTooltip: HTMLDivElement | undefined
    private disabled = false

    constructor(private data: IButtonMenuData) {
        super()

        // region DOM Creation
        this.element.removeAttribute("id")
        addColorClass(this.element, "menuItem")
        this.element.style.padding = "9px 0"
        this.element.style.cursor = "pointer"
        this.element.style.boxSizing = "border-box"
        this.element.style.position = "relative"
        this.element.style.overflow = "visible"
        this.element.style.borderWidth = "1px"
        this.element.style.borderStyle = "solid"
        if (data.disabledTooltip !== undefined) {
            this.disabledTooltip = data.disabledTooltip
            this.disabledTooltip.style.top = "57px"
            this.element.appendChild(data.disabledTooltip)
        }

        this.dt = document.createElement("dt")
        this.dt.style.margin = "0"
        this.dt.style.padding = "0 8px 5px 28px"
        this.dt.style.backgroundSize = "14px 14px"
        this.dt.innerText = data.label
        if (data.label === i18n.anonTippingText) {
            this.dt.dataset.testid = "anon-tip-option"
        }
        this.element.appendChild(this.dt)

        const description = data.description
        if (description !== undefined && description.length > 0) {
            this.dd = document.createElement("dd")
            this.dd.style.margin = "0"
            this.dd.style.padding = "0 8px 0 28px"
            this.dd.innerText = description
            this.element.appendChild(this.dd)
        }

        // Create a hidden input for the purpose of allowing tab navigation
        // through the menu items
        const inputId = ButtonMenuItem.createItemId("ButtonMenuItem")
        this.input = document.createElement("input")
        this.input.id = inputId
        this.input.name = inputId
        this.input.type = "radio"
        this.input.style.cursor = "pointer"
        this.input.style.position = "absolute"
        this.input.style.opacity = "0"
        this.input.style.width = "0"
        this.input.style.height = "0"
        this.element.appendChild(this.input)

        // endregion

        if (data.disabledMouseClick !== undefined) {
            this.disabledMouseClick = data.disabledMouseClick
        }

        // Attach mouseover event
        addEventListenerPoly("mouseenter", this.element, () => {
            if (this.disabled && this.disabledTooltip !== undefined) {
                this.showTooltip()
            }
        })

        // Attach mouse off event
        addEventListenerPoly("mouseleave", this.element, () => {
            if (this.disabled && this.disabledTooltip !== undefined) {
                this.hideTooltip()
            }
        })

        // Attach mouse click event. Start off enabled.
        this.enabledMouseClick = data.enabledMouseClick
        addEventListenerPoly("click", this.element, this.onClick)

        addEventListenerPoly("touchstart", this.element, this.onTouch)

        // delegateFocusStyling(this.input, this.element
        addEventListenerPoly("focus", this.input, () => {
            this.element.style.outline = "2px solid Highlight"
        })
        addEventListenerPoly("blur", this.input, () => {
            this.element.style.removeProperty("outline")
        })
    }

    select(): void {
        addColorClass(this.dt, "selected")
    }

    deselect(): void {
        removeColorClass(this.dt, "selected")
    }

    /** Resolve attached value - falling back on the specified label if none */
    getValue(): TipType {
        return this.data.value
    }

    /** Determines attached button label - falling back on the specified label if none */
    getButtonLabel(): string {
        const buttonLabel = this.data.buttonLabel
        return buttonLabel === undefined ? this.data.label : buttonLabel
    }

    disable(): void {
        if (this.disabled) {
            return
        }
        this.disabled = true
        addColorClass(this.element, "disabled")
    }

    enable(): void {
        if (!this.disabled) {
            return
        }
        this.disabled = false
        removeColorClass(this.element, "disabled")
    }

    onClick = (event: Event): void => {
        if (!this.disabled) {
            this.enabledMouseClick(event)
        } else if (this.disabledMouseClick !== undefined) {
            this.disabledMouseClick(event)
        }
    }

    private onTouch = (event: TouchEvent) => {
        if (this.disabled) {
            event.preventDefault()
            if (this.data.disabledModalText !== undefined) {
                modalConfirm(this.data.disabledModalText,
                    () => {
                        if (this.disabledMouseClick !== undefined) {
                            this.disabledMouseClick(event)
                        }
                    })
            } else if (this.disabledMouseClick !== undefined) {
                this.disabledMouseClick(event)
            }
        }
    }

    private showTooltip = () => {
        if (this.disabledTooltip !== undefined) {
            this.disabledTooltip.style.display = "block"
        }
    }

    private hideTooltip = () => {
        if (this.disabledTooltip !== undefined) {
            this.disabledTooltip.style.display = "none"
        }
    }

    // region Static ID Generation

    private static lastId = 0

    private static createItemId(prefix: string): string {
        ButtonMenuItem.lastId += 1
        return prefix + ButtonMenuItem.lastId.toString()
    }

    // endregion

}

export class ButtonMenu extends OverlayComponent {
    readonly width = 280
    protected visible = false
    protected selectedItem: ButtonMenuItem
    readonly focusElements: Element[]

    // Events
    readonly visibilityChange = new EventRouter<boolean>("visibilityChange")
    readonly selectionChange = new EventRouter<IMenuItemChange>("selectionChange")

    constructor(itemsData: IButtonMenuData[], initial?: TipType) {
        super()

        // Verify option count
        const focusElements: Element[] = []
        const itemCount = itemsData.length
        if (itemCount === 0) {
            error("ButtonMenu requires at least one option!")
        }

        // region DOM Creation
        addColorClass(this.element, "menu")
        this.element.style.padding = "0"
        this.element.style.display = "none"
        this.element.style.width = `${this.width}px`
        this.element.style.textAlign = "left"
        this.element.style.fontSize = "14px"
        this.element.style.lineHeight = "14px"
        this.element.style.borderRadius = "4px"

        // Override some of the Component styling
        this.overlay.style.zIndex = "1002"
        this.element.style.zIndex = "1003"
        this.element.style.overflow = "visible"
        this.element.style.removeProperty("height")

        // Build menu items from options
        let selectedIndex = -1
        const lastItemIndex = itemCount - 1
        for (let index = 0; index < itemCount; index += 1) {
            // Determine if this option is currently selected
            const itemData = itemsData[index]
            if (itemData.value === initial) {
                selectedIndex = index
            }

            // Wire up the click handler to send the event to this menu
            itemData.enabledMouseClick = (event) => {
                event.stopPropagation()
                this.onItemClick(menuItem)
            }

            // Build the new option element
            const menuItem = this.createMenuItem(itemData)

            // Also wire up a key handlers so that pressing space or enter while
            // focused on a menu item results in that item being selected
            const keyHandler = (event: KeyboardEvent) => {
                event.preventDefault()
                event.stopPropagation()
                menuItem.onClick(event)
                return false
            }
            addEventListenerPoly("keyup", menuItem.input, (event: KeyboardEvent) => {
                if (event.keyCode === Key.Space) {
                    return keyHandler(event)
                }
                return true
            })
            addEventListenerPoly("keydown", menuItem.input, (event: KeyboardEvent) => {
                if (event.keyCode === Key.Enter) {
                    return keyHandler(event)
                }
                return true
            })

            // Apply additional styling for first and last options
            if (index === 0) {
                menuItem.element.style.borderTopWidth = "1px"
                menuItem.element.style.borderTopLeftRadius = "2px"
                menuItem.element.style.borderTopRightRadius = "2px"
            }

            // Could be only one item
            if (index === lastItemIndex) {
                menuItem.element.style.borderBottomLeftRadius = "2px"
                menuItem.element.style.borderBottomRightRadius = "2px"
            }

            // Bind each menu item to the {@link DropDownMenu.prototype.valueChanged} event
            this.addChild(menuItem)
            focusElements.push(menuItem.input)
        }
        // endregion

        // Default to the first available item if no selection was found
        const menuOptions = this.children() as ButtonMenuItem[]
        selectedIndex = selectedIndex === -1 ? 0 : selectedIndex
        this.selectedItem = menuOptions[selectedIndex]
        this.focusElements = focusElements

        // Attach event listener to selection event
        this.selectionChange.listen(params => {
            this.onSelectionChange(params)
        })

        this.overlayClick.listen(() => {
            this.hide()
        })
    }

    getValue(): TipType {
        return this.selectedItem.getValue()
    }

    getButtonLabel(): string {
        return this.selectedItem.getButtonLabel()
    }

    isVisible(): boolean {
        return this.visible
    }

    show(offsets: IOffsets): void {
        if (this.isVisible()) {
            return
        }
        this.visibilityChange.fire(true)
        this.element.style.display = "inline-block"
        this.element.style.top = `${offsets.top}px`
        this.element.style.left = `${offsets.left}px`
        this.showOverlay()
        this.visible = true
        this.repositionChildrenRecursive()
    }

    hide(suppressEvent = false): void {
        if (!this.isVisible()) {
            return
        }
        super.hide()
        if (!suppressEvent) {
            this.visibilityChange.fire(false)
        }
        this.visible = false
    }

    toggle(offsets: IOffsets): void {
        if (this.isVisible()) {
            this.hide()
        } else {
            this.show(offsets)
        }
    }

    disableItem(index: number): void {
        const menuOptions = this.children() as ButtonMenuItem[]
        if (index < menuOptions.length) {
            menuOptions[index].disable()
        }
    }

    enableItem(index: number): void {
        const menuOptions = this.children() as ButtonMenuItem[]
        if (index < menuOptions.length) {
            menuOptions[index].enable()
        }
    }

    afterDOMConstructedIncludingChildren(): void {
        super.afterDOMConstructedIncludingChildren()
        // Call the handler directly to avoid firing off SendTipButton handlers
        this.onSelectionChange({ current: this.selectedItem })
    }

    // Attempts to intelligently "find" a good boundary. The alternative is to set this manually where required.
    findBoundary(): HTMLElement | undefined {
        let bound
        for (let ancestor = this.parent; ancestor !== undefined; ancestor = ancestor.parent) {
            const style = window.getComputedStyle(ancestor.element)
            if (style.overflowY === "hidden") {
                bound = ancestor.element
                break
            }
        }
        return bound
    }

    protected repositionChildren(): void {
        if (!this.isVisible()) {
            return
        }

        const expectedBottom = 12 + 20

        const bounds = this.findBoundary()
        const clientHeight = bounds !== undefined
            ? bounds.getBoundingClientRect().bottom
            : document.documentElement.clientHeight

        const clientBottom = clientHeight - this.element.getBoundingClientRect().bottom
        if (clientBottom < expectedBottom) {
            this.element.style.top = `${this.element.offsetTop - (expectedBottom - clientBottom)}px`
        }
    }

    // Overridable menu item creation
    protected createMenuItem(itemData: IButtonMenuData): ButtonMenuItem {
        return new ButtonMenuItem(itemData)
    }

    protected onSelectionChange(event: IMenuItemChange): void {
        event.current.select()
        if (event.previous !== undefined) {
            event.previous.deselect()
        }
    }

    protected onItemClick(menuItem: ButtonMenuItem): void {
        const previousItem = this.selectedItem
        this.hide()
        if (menuItem !== previousItem) {
            this.selectedItem = menuItem
            this.selectionChange.fire({
                previous: previousItem,
                current: menuItem,
            })
        }
    }
}

// I don't know if this component will fit anywhere else on the site, but it shouldn't be
// too difficult to refactor it into a reusable piece.
export class BaseSendTipButton extends Component {
    private menuEnabled = true
    private anonymousEnabled = true
    private tipType: TipType

    protected enabled = true
    protected readonly dropDownMenu: ButtonMenu
    protected readonly submitButton: HTMLButtonElement
    protected readonly dropDownButton: HTMLButtonElement
    focusElements: Element[]

    // Events
    readonly tipTypeChange = new EventRouter<TipType>("tipTypeChange")  // tipType change

    private static readonly menuItemsData: IButtonMenuData[] = [
        {
            label: i18n.publicTippingText,
            value: TipType.public,
            description: i18n.publicTippingDesc,
            buttonLabel: i18n.sendTipButtonText,
            enabledMouseClick: () => {},
        },
        {
            label: i18n.anonTippingText,
            value: TipType.anonymous,
            description: i18n.anonTippingDesc,
            buttonLabel: i18n.anonButtonText,
            enabledMouseClick: () => {},
            disabledMouseClick: (event) => {
                addPageAction("SupporterPageOpened", { "source": "anon_tip" })
                if (window.top !== null) {
                    window.top.location.href = normalizeResource(`/supporter/upgrade/?source=${pageContext.current.PurchaseEventSources["SUPPORTER_SOURCE_ANON_TIP_UPSELL"]}`)
                }
            },
            disabledTooltip: buildTooltip({
                content: i18n.mustBeSupporterFeature,
                hasHTML: false,
                width: 240,
                divotPosition: DivotPosition.Top,
                divotLeftOrTop: "40px",
            }),
            disabledModalText: `${i18n.mustBeSupporterFeature} ${i18n.anonTipDisabledModalCont}`,
        },
    ]

    /**
     * @param initial - Initial tip type value
     */
    constructor(initial?: TipType) {
        super()

        // region DOM Creation
        addColorClass(this.element, "sendTip")
        this.element.style.margin = "6px"
        this.element.style.marginLeft = "auto"
        this.element.style.display = "inline-block"
        this.element.style.verticalAlign = "top"
        this.element.style.cssFloat = "right"
        this.element.style.overflow = "visible"
        this.element.style.position = "static"
        this.element.style.removeProperty("width")
        this.element.style.removeProperty("height")

        // submitButton
        this.submitButton = this.createSubmitButton()
        this.submitButton.style.marginLeft = "6px"
        this.submitButton.dataset.testid = "send-tip-button"

        // dropdownButton
        this.dropDownButton = this.createDropDownMenu()
        // eslint-disable-next-line @multimediallc/no-inner-html
        this.dropDownButton.innerHTML = "&#9660;"
        this.dropDownButton.id = "id_tip_type_select_tm"
        this.dropDownButton.dataset.testid = "tip-type-dropdown-button"

        // dropdownMenu
        this.dropDownMenu = new ButtonMenu(BaseSendTipButton.menuItemsData, initial)
        this.submitButton.innerText = this.dropDownMenu.getButtonLabel()

        // Apply additional desktop/mobile styling to child elements
        for (const menuItem of this.dropDownMenu.children() as ButtonMenuItem[]) {
            this.styleButtonMenuItem(menuItem)
        }

        this.element.appendChild(this.submitButton)
        this.element.appendChild(this.dropDownButton)

        // Hidden input element that will store the selected option's value
        this.tipType = this.dropDownMenu.getValue()

        // Finally, add dropDownMenu to child components
        this.addChild(this.dropDownMenu)

        // DOM/Component event handlers
        this.dropDownMenu.selectionChange.listen(event => {
            if (this.isAnonymousEnabled()) {
                const itemValue = event.current.getValue()
                this.changeTipType(itemValue)
                this.submitButton.focus()
            }
        })

        const handleMouseEnterSubmit = (event: MouseEvent) => {
            this.submitButton.style.textDecoration = "underline"
        }

        const handleMouseLeaveSubmit = (event: MouseEvent) => {
            this.submitButton.style.textDecoration = "none"
        }

        addEventListenerPoly("mouseenter", this.submitButton, handleMouseEnterSubmit)
        addEventListenerPoly("mouseleave", this.submitButton, handleMouseLeaveSubmit)
        addEventListenerPoly("click", this.dropDownButton, (event) => {
            event.preventDefault()
            event.stopPropagation()
            this.dropDownMenu.toggle(this.getMenuOffsets())
            return false
        })

        // When the menu is hidden while one of the menu items are actively
        // focused, have the focus revert to the drop down button.
        this.dropDownMenu.visibilityChange.listen((isVisible: boolean) => {
            if (isVisible) {
                return
            }
            this.submitButton.focus()
        })

        this.focusElements = [ this.submitButton, this.dropDownButton ]
        // endregion

        // Global event handlers
        roomLoaded.listen(context => {
            if (!context.dossier.allowAnonymousTipping) {
                this.disableAnonymousTips()
            } else {
                this.enableAnonymousTips()
            }
        })
    }

    getOffsetWidth(): number {
        return this.element.offsetWidth
    }

    hideMenu(): void {
        this.dropDownMenu.hide(true)
    }

    promptUser(buttonText: string): void {
        // eslint-disable-next-line @multimediallc/no-inner-html
        this.submitButton.innerHTML = `${buttonText} &#9656;`
        this.submitButton.title = buttonText
        this.disableMenu()
    }

    cancelPrompt(): void {
        this.resetText()
        this.enableMenu()
    }

    resetText(): void {
        this.submitButton.innerText = this.dropDownMenu.getButtonLabel()
        this.submitButton.title = this.dropDownMenu.getButtonLabel()
    }

    isAnonymousEnabled(): boolean {
        return this.anonymousEnabled
    }

    isEnabled(): boolean {
        return this.enabled
    }

    disable(): void {
        if (this.isEnabled()) {
            this.enabled = false
            this.dropDownMenu.hide()

            this.submitButton.disabled = true
            addColorClass(this.submitButton, "disabled")

            this.dropDownButton.disabled = true
            addColorClass(this.dropDownButton, "disabled")
        }
    }

    enable(): void {
        if (!this.isEnabled()) {
            this.enabled = true

            this.submitButton.disabled = false
            removeColorClass(this.submitButton, "disabled")

            this.dropDownButton.disabled = false
            removeColorClass(this.dropDownButton, "disabled")
        }
    }

    getTipType(): TipType {
        return this.tipType
    }

    hasFocus(): boolean {
        return this.getCurrentFocusData().index > -1
    }

    focusPrev(): boolean {
        const focusData = this.getCurrentFocusData()
        const elementsLength = focusData.elements.length
        focusData.index -= 1
        if (focusData.index === -2) {
            focusData.index = elementsLength - 1
        } else if (focusData.index === -1) {
            return false
        }
        this.focusElement(focusData)
        return true
    }

    focusPrevMenuItem(): boolean {
        // Allow user to navigate only dropdown menu using arrow keys
        // User needs to press 'Tab' to go back to the Tip button
        if (!this.menuEnabled || !this.dropDownMenu.isVisible()) {
            return false
        }

        const focusData = this.getCurrentFocusData()
        const elementsLength = focusData.elements.length
        focusData.index -= 1
        // Circle back to the last menu item list if all items are navigated
        if (focusData.index <= elementsLength - this.dropDownMenu.focusElements.length - 1) {
            focusData.index = elementsLength - 1
        }
        this.focusElement(focusData)
        return true
    }

    focusNext(): boolean {
        const focusData = this.getCurrentFocusData()
        const elementsLength = focusData.elements.length
        focusData.index += 1
        if (focusData.index >= elementsLength) {
            return false
        }
        this.focusElement(focusData)
        return true
    }

    focusNextMenuItem(): boolean {
        // Allow user to navigate only dropdown menu using arrow keys
        // User needs to press 'Tab' to go back to the Tip button
        if (!this.menuEnabled || !this.dropDownMenu.isVisible()) {
            return false
        }

        const focusData = this.getCurrentFocusData()
        const elementsLength = focusData.elements.length
        focusData.index += 1
        // Circle back to the first menu item list if all items are navigated
        if (focusData.index >= elementsLength || focusData.index <= elementsLength - this.dropDownMenu.focusElements.length - 1) {
            focusData.index = elementsLength - this.dropDownMenu.focusElements.length
        }
        this.focusElement(focusData)
        return true
    }

    protected focusElement(focusData: IFocusData): void {
        const index = focusData.index
        const focusElement = focusData.elements[index] as HTMLElement
        focusElement.focus()
    }

    protected getCurrentFocusData(): IFocusData {
        let focusElements = this.focusElements
        if (this.menuEnabled && this.dropDownMenu.isVisible()) {
            focusElements = this.focusElements.concat(
                this.dropDownMenu.focusElements,
            )
        }
        return {
            elements: focusElements,
            index: focusElements.indexOf((document.activeElement as HTMLElement)),
        }
    }

    // Align menu with the right edge of our div
    protected getMenuOffsets(): IOffsets {
        const div = this.element
        const coords = scanCoords(this.submitButton)
        const divRight = div.offsetLeft + div.offsetWidth + 1 // +1 border
        const menuLeft = divRight - this.dropDownMenu.width
        return {
            top: coords.height + 2 * coords.top,
            left: menuLeft,
        }
    }

    // When anonymous tipping is enabled, the drop down button becomes active
    // and the component reverts to drawing its "tipType" value from the selected
    // drop down menu item. If "Anonymous Tipping" was selected previously, this
    // results in the `tipTypeChange` event being fired.
    protected enableAnonymousTips(): void {
        const menuValue = this.dropDownMenu.getValue()
        this.anonymousEnabled = true
        this.dropDownMenu.enableItem(1)
        this.changeTipType(menuValue)
    }

    // When anonymous tipping is disabled, the drop down button is grayed out
    // and links to the supporter signup page. The component uses the default
    // public tipping for its "tipType" value.
    protected disableAnonymousTips(): void {
        this.anonymousEnabled = false
        this.dropDownMenu.disableItem(1)
        this.changeTipType(TipType.public)
    }

    protected enableMenu(): void {
        if (this.menuEnabled) {
            return
        }
        this.menuEnabled = true
        this.submitButton.style.borderRadius = "4px 0 0 4px"
        this.dropDownButton.style.display = "inline-block"
        this.focusElements = [ this.submitButton, this.dropDownButton ]
        this.repositionChildrenRecursive()
    }

    protected disableMenu(): void {
        // The only focusable element after disabling the dropdown is submitButton
        if (!this.menuEnabled) {
            return
        } else if (this.hasFocus()) {
            this.submitButton.focus()
        }
        this.menuEnabled = false
        this.dropDownMenu.hide()
        this.submitButton.style.borderRadius = "4px"
        this.dropDownButton.style.display = "none"
        this.focusElements = [ this.submitButton ]
    }

    // Used internally for any potential change to the `tipType` value.
    protected changeTipType(newValue: TipType): void {
        this.tipType = newValue
        this.tipTypeChange.fire(newValue)
        this.resetText()
    }

    protected styleButtonMenuItem(menuItem: ButtonMenuItem): void {
        menuItem.dt.style.fontFamily = "'UbuntuMedium', Helvetica, Arial, sans-serif"
        if (menuItem.dd !== undefined) {
            menuItem.dd.style.fontFamily = "'UbuntuRegular', Helvetica, Arial, sans-serif"
        }
    }

    protected createCommonButton(): HTMLButtonElement {
        const button = document.createElement("button")

        // Common styling
        addColorClass(button, "buttons")
        button.style.position = "relative"
        button.style.display = "inline-block"
        button.style.cursor = "pointer"
        button.style.fontSize = "14px"
        button.style.borderWidth = "1px"
        button.style.borderStyle = "solid"
        button.style.height = "auto"
        button.style.verticalAlign = "top"
        button.style.margin = "0"

        // Event listeners
        addEventListenerPoly("focus", button, () => {
            button.style.zIndex = "100"
        })
        addEventListenerPoly("blur", button, () => {
            button.style.zIndex = "auto"
        })

        return button
    }

    protected createSubmitButton(): HTMLButtonElement {
        // submitButton
        const submitButton = this.createCommonButton()
        submitButton.type = "submit"
        submitButton.title = i18n.sendTipButtonText

        submitButton.style.padding = "6px 18px"
        submitButton.style.borderRadius = "4px 0 0 4px"
        submitButton.style.borderRight = "0 none transparent"
        submitButton.style.overflow = "hidden"
        submitButton.style.textOverflow = "ellipsis"
        return submitButton
    }

    protected createDropDownMenu(): HTMLButtonElement {
        // dropdownButton
        const dropDownButton = this.createCommonButton()
        dropDownButton.style.padding = "6px 0"
        dropDownButton.style.borderRadius = "0 4px 4px 0"
        dropDownButton.style.width = "24px"
        return dropDownButton
    }
}

// If the broadcaster has anonymous tipping enabled, record a NewRelic page action whenever
// a TipCallout becomes visible.
export function recordTipTypeViewed(source: string, sendButton: BaseSendTipButton): void {
    window.setTimeout(() => {
        if (sendButton.isAnonymousEnabled()) {
            const tipType = sendButton.getTipType()
            addPageAction("SendTipViewed", {
                "source": source,
                "tipType": tipType,
                "localStorage": isLocalStorageSupported(),
            })
        }
    }, 0)
}

export const purchaseTokensUrl = "/tipping/purchase_tokens/"

function generatePurchaseTokensUrlWithSource({ source }: { source?: string }): string { // eslint-disable-line complexity
    let url
    // Only apply refresh opener if the user is not logged in
    const isAnon = pageContext.current.loggedInUser === undefined
    if (window.location.search === "") {
        url = purchaseTokensUrl
        if (!(source === null || source === undefined || source === "")) {
            url += `?source=${source}`
            if (isAnon) {
                url += "&refresh_opener=1"
            }
        } else {
            if (isAnon) {
                url += "?refresh_opener=1"
            }
        }
    } else {
        url = `${purchaseTokensUrl}${window.location.search}`
        if (!(source === null || source === undefined || source === "")) {
            url += `&source=${source}`
        }
        if (isAnon) {
            url += "&refresh_opener=1"
        }
    }
    return url
}

export function cleanTipAmountInput(input: HTMLInputElement): void {
    const cleanValue = input.value.replace(/[^\d]+/g, "").substring(0, MAX_TIP_DIGITS)

    // Early return if values match, since the code below will move the cursor to the end of the input
    if (input.value === cleanValue) {
        return
    }

    // MUST clear input first, or else browser will keep trailing decimal point
    input.value = ""
    input.value = cleanValue
}

export function isValidTipInput(value: string): boolean {
    const isOnlyDigits = value.match(/[^\d]/) === null
    return isOnlyDigits && parseInt(value) > 0
}

export function createTipAmountInput(colorClass = "tipAmountInput"): HTMLInputElement {
    const input = document.createElement("input")
    addColorClass(input, colorClass)
    input.dataset.testid = "tip-amount-input"
    input.type = "tel"
    input.autocomplete = "off"
    input.value = "25"

    // Only allow positive integer values
    addEventListenerPoly("beforeinput", input, (e: InputEvent) => {
        if (e.data === null) {
            return
        }
        if (!isNaN(parseInt(e.data)) && input.value.length < MAX_TIP_DIGITS ) {
            return
        }
        e.preventDefault()
    })
    addEventListenerPoly("input", input, () => cleanTipAmountInput(input))

    return input
}

let oneClickFlow: OneClickFlowPurchase | OneClickFlowPurchaseDesktop | undefined
export let isOneClickEligible = false
let purchasePage: PurchasePageDesktopWrapper | MobilePurchasePageWrapper | undefined

export const setupOneClickListener = (): void => {
    if (!pageContext?.current?.loggedInUser || !pageContext.current?.loggedInUser?.userUid) {
        return
    }
    isOneClickEligible = pageContext.current.loggedInUser.canWegOneClick
    const userUid = pageContext.current?.loggedInUser?.userUid
    new UserOneClickTopic(userUid).onMessage.listen((message) => {
        isOneClickEligible = message.is_one_click_eligible
    })
}

export const openPurchasePage = (source: string): void => {
    const url = generatePurchaseTokensUrlWithSource({ source })

    if (performToggleIsActive("TknParalNew3") || performToggleIsActive("TknParalRet3")) {
        if (!purchasePage) {
            purchasePage = pageContext.current.isMobile ? new MobilePurchasePageWrapper(source ?? "") : new PurchasePageDesktopWrapper(source ?? "")
            return
        }
        purchasePage.show(source ?? "")
        return
    }
    safeWindowOpen(url, "_blank", "height=615, width=850, scrollbars=1")
}

interface IPopUpPurchasePageProps {
    source?: string,
    roomType?: RoomType,
    target?: HTMLElement
}

// eslint-disable-next-line complexity
export function popUpPurchasePage({ source = undefined, roomType = undefined, target = undefined }: IPopUpPurchasePageProps = {}): void {
    if (isOneClickEligible ?? false) {
        if (pageContext.current.isMobile) {
            if (!oneClickFlow) {
                oneClickFlow = new OneClickFlowPurchase(source ?? "", roomType)
            } else {
                oneClickFlow.show(source ?? "", roomType)
            }
        } else {
            if (!oneClickFlow) {
                oneClickFlow = new OneClickFlowPurchaseDesktop(source ?? "", roomType, target)
            } else {
                oneClickFlow.show(source ?? "", roomType, target)
            }
        }
        return
    }
    openPurchasePage(source ?? "")
}

export function popUpTokenPurchaseModal(message: string, source?: string, roomType?: RoomType ): void {
    modalConfirm(message, () => {
        popUpPurchasePage({ source, roomType })
    }, undefined, { acceptText: i18n.purchaseTokensText })
}

// for CB template tokencount elements
export function updateTokencountElements(tokens: number): void {
    const tokenCountEls = document.querySelectorAll(".tokencount[updatable-count]")
    if (!tokenCountEls.length) {
        return
    }

    if (performToggleIsActive("TknRflTopUp") && performToggleIsActive("LowBNotif")) {
        const currentBalance = parseInt(tokenCountEls[0].textContent ?? "0")
        // user purchased tokens
        if (!isNaN(currentBalance) && tokens > currentBalance) {
            dismissToast("low-balance-common")
            dismissToast("low-balance-private")
        }
    }

    for (const el of tokenCountEls) {
        el.textContent = `${tokens}`
    }
}

export function extractSourceFromDataAttribute(element: HTMLElement): string | undefined {
    const purchaseEventSourceAttribute = element.dataset["purchaseEventSource"]
    // Make the following cases explicit to satisfy typescript linter
    if (purchaseEventSourceAttribute === null || purchaseEventSourceAttribute === undefined || purchaseEventSourceAttribute === "") {
        return undefined
    }
    if (Object.values(pageContext.current.PurchaseEventSources).includes(purchaseEventSourceAttribute)) {
        return Object.values(pageContext.current.PurchaseEventSources).find(purchaseEventSource => purchaseEventSource === purchaseEventSourceAttribute)
    }
    return undefined
}

export function bindGetMoreTokensLink(linkElem: HTMLAnchorElement): void {
    linkElem.onclick = (event: MouseEvent) => {
        if (linkElem.classList.contains("welcome-page-purchase-tokens")) {
            addPageAction("WelcomePagePurchaseTokensClicked")
        }
        if (!event.ctrlKey && !event.metaKey) {
            const source = extractSourceFromDataAttribute(linkElem)
            event.preventDefault()

            // Get more tokens link in mobile push menu
            if (linkElem.href.includes(pageContext.current.PurchaseEventSources["TOKEN_SOURCE_USER_INFO_PANEL"])) {
                // TODO (CBD-2042): Replace with `MobilePushMenu.closeMenu` and fix messed up tests
                hidePushMenu()
            }
            popUpPurchasePage({ source })
        }
    }
    if (linkElem.href === "") {
        linkElem.href = normalizeResource(purchaseTokensUrl)
    }
}

function hidePushMenu(): void {
    const pushMenuOverlay = document.querySelector(".push-overlay") as HTMLElement
    const pushMenu = document.querySelector(".pushmenu") as HTMLElement
    const pushMenuContainer = document.querySelector("#pushmenu-container") as HTMLDivElement

    if (pushMenuOverlay === null || pushMenu === null || pushMenuContainer === null) {
        return
    }

    pushMenu.classList.remove("pushmenu-animate")

    document.querySelectorAll(".content, header").forEach((el) => {
        el.classList.remove("push-page-content")
    })

    window.setTimeout(() => {
        pushMenuOverlay.style.display = "none"
        pushMenuContainer.style.display = "none"
    }, 150)

    siteHeaderMenuOpened.fire(false)
}
