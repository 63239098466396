import { EventRouter, eventsPmSessionsCount } from "./events"
import type { AudioHolderSound } from "./audioHolder"
import type { WowzaHandler } from "./chatconnection/wowzaHandler"
import type {
    IBanSilenceInfo, IPrivateShowRequestNotification, IRemoveMessagesNotification,
    IRoomMessage, IRoomNotice, IRoomStatusChangeNotification, ISettingsUpdateNotification, IShortcodeMessage, ITipAlert,
    ITokenBalanceUpdateNotification,
} from "./messageInterfaces"
import type { EnterLeaveSettings, IAppInfo, IRoomDossier } from "./roomDossier"
import type { ITipSent } from "./theatermodelib/tipCallout"
import type { IRefreshPanel } from "../cb/interfaces/asp"
import type { IShortcodeForm } from "../cb/interfaces/shortcode"
import type { IProfileContext } from "../entrypoints/profile"
import type { IDeniedProfileContext } from "../entrypoints/profile_denied"
import type { RoomStatus } from "@multimediallc/web-utils"

export interface IRoomContext {
    dossier: IRoomDossier
    chatConnection: IChatConnection
}

export interface IChatConnectionEvent {
    messageSent: EventRouter<undefined>
    roomMessage: EventRouter<IRoomMessage>
    roomNotice: EventRouter<IRoomNotice>
    roomShortcode: EventRouter<IShortcodeMessage>

    statusChange: EventRouter<IRoomStatusChangeNotification>
    hiddenMessageChange: EventRouter<string>
    refreshPanel: EventRouter<IRefreshPanel | undefined>
    titleChange: EventRouter<string>
    clearApp: EventRouter<undefined>
    removeMessages: EventRouter<IRemoveMessagesNotification>
    settingsUpdate: EventRouter<ISettingsUpdateNotification>
    playSound: EventRouter<AudioHolderSound>

    tipAlert: EventRouter<ITipAlert>

    roomCountUpdate: EventRouter<number>
    modStatusChange: EventRouter<boolean>
    // privateShowRequest: userRequesting and tokensPerMinute used for updating the DOM.
    privateShowRequest: EventRouter<IPrivateShowRequestNotification>
    // Note: Our current chat sever does not send a token balance update event when sending tokens via normal tips.
    tokenBalanceUpdate: EventRouter<ITokenBalanceUpdateNotification>
    appTabRefresh: EventRouter<undefined>
    appDebugLog: EventRouter<string>

    connectionLost: EventRouter<undefined>
    onBanSilence: EventRouter<IBanSilenceInfo>
}


export interface IChatConnection {
    previousStatus: RoomStatus
    status: RoomStatus

    room(): string

    username(): string

    age(): number | undefined

    ignore: (username: string) => Promise<boolean>

    unignore: (username: string) => Promise<boolean>

    isIgnored(username: string): boolean

    disconnect(): void

    sendShortcode(form: IShortcodeForm): void

    sendRoomMessage(message: string): void

    // requestPrivateShow requires you to pass the price, minimumMinutes, and recordingsAllowed so that it CB can
    // verify the performer didn't change settings before the user could be notified (race condition)
    requestPrivateShow(price: number, minimumMinutes: string, recordingsAllowed: boolean, premiumChosen: boolean, delayFiringEvent: boolean): Promise<void>
    leavePrivateOrSpyShow(allowLeaveEarly: boolean): Promise<void>
    requestSpyShow(price: number, fanclubPrice?: number): Promise<void>

    isBroadcasting: boolean
    isModerator: boolean
    isNewConnection: boolean
    kickUser: (username: string) => void
    updateRoomCount: (ignoreThrottle?: boolean) => void
    inPrivateRoom: () => boolean
    inPrivateOrSpy: () => boolean
    getPrivateShowUser: () => string
    isAppDebuggingEnabled: () => boolean
    toggleAppDebugging: () => void
    updateEnterLeaveSettings: (entrySetting: EnterLeaveSettings, leaveSetting: EnterLeaveSettings) => void
    appsRunning: () => IAppInfo[]
    changeStatus: (status: RoomStatus) => void
    event: IChatConnectionEvent
    wowzaHandler: WowzaHandler
    privatePrice: number
    privateMinEnd: number
    premiumShowActive: boolean
    premiumRequested: boolean
}

export const roomLoaded = new EventRouter<IRoomContext>("roomLoaded", {
    // Lots of things use this. By experiment, as of May 2022, loading a room page loads 84 listeners and starting a new
    // pm adds 3 more per session (3 for the split mode session and 3 for the theatermode session)
    listenersWarningThreshold: () => 100 + 3 * eventsPmSessionsCount,
    maxHistorySize: 1, // don't leak room contexts and kill all event listeners
})

export const roomCleanup = new EventRouter<undefined>("roomCleanup")

export const userViewedPm = new EventRouter<string>("userViewedPm")

export const onTipSent = new EventRouter<ITipSent>("tipSent")

export const profileLoaded = new EventRouter<IProfileContext>("context")

export const deniedLoaded = new EventRouter<IDeniedProfileContext>("context")
