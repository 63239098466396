import { RoomStatus } from "@multimediallc/web-utils"
import { PartType, RoomNoticeType } from "@multimediallc/web-utils/types"
import { isAnonymous } from "../../../common/auth"
import { roomCleanup, roomLoaded } from "../../../common/context"
import { ListenerGroup } from "../../../common/events"
import { ignoreCatch } from "../../../common/promiseUtils"
import { roomDossierContext } from "../../interfaces/context"
import { RoomUsers } from "../../roomUsers"
import { privateMessage } from "../pm/userActionEvents"
import { updateUserMention } from "./userMentionAutocompleteModal"
import type { IUserSearch } from "./userMentionAutocompleteModal"
import type { IPrivateMessage, IRoomNotice, IRoomStatusChangeNotification, IUserInfo } from "../../../common/messageInterfaces"

export class MentionUserList {
    private static instance: MentionUserList | undefined
    private userList: IUserSearch[]
    private invalidMentions = new Set<string>()
    private chatListeners = new ListenerGroup()
    private userListNeedsRefresh = true

    private constructor() {
        this.userList = []
        roomLoaded.listen(context => {
            context.chatConnection.event.statusChange.listen((status: IRoomStatusChangeNotification) => {
                this.statusChangeShouldRefresh(status.currentStatus, status.previousStatus)
            }).addTo(this.chatListeners)

            // only update list when user is logged in
            if (!isAnonymous()) {
                context.chatConnection.event.roomNotice.listen((m: IRoomNotice) => {
                    if (m.noticeType === RoomNoticeType.RoomLeave) {
                        return
                    }
                    for (const msg of m.messages) {
                        for (const part of msg) {
                            if (part.partType === PartType.user && part.user !== undefined) {
                                this.addRecentUser(part.user)
                            }
                        }
                    }
                }).addTo(this.chatListeners)

                context.chatConnection.event.roomMessage.listen((m) => {
                    this.addRecentUser(m.fromUser)
                }).addTo(this.chatListeners)

                privateMessage.listen((m: IPrivateMessage) => {
                    this.addRecentUser(m.fromUser)
                }, false).addTo(this.chatListeners)
            }
        })

        roomCleanup.listen(() => {
            this.chatListeners.removeAll()
            this.userList = []
            this.userListNeedsRefresh = true
        })
    }

    public static getInstance(): MentionUserList {
        if (MentionUserList.instance === undefined) {
            MentionUserList.instance = new MentionUserList()
        }
        return MentionUserList.instance
    }

    public refreshUserListIfNeeded(): Promise<void> {
        if (this.userListNeedsRefresh) {
            return this.refreshUserList()
        } else {
            return Promise.resolve()
        }
    }

    private setUserListRefreshCooldown(): void {
        this.userListNeedsRefresh = false
        window.setTimeout(() => { this.userListNeedsRefresh = true }, 60000)
    }

    public getSortedList(): IUserSearch[] {
        this.userList.sort((a, b) => {
            if (a.user.username.toLowerCase() < b.user.username.toLowerCase()) {
                return -1
            } else if (a.user.username.toLowerCase() > b.user.username.toLowerCase()) {
                return 1
            } else {
                return 0
            }
        })
        return this.userList
    }

    public users(): IUserInfo[] {
        return this.userList.map(u => u.user)
    }

    private refreshUserList(keepRecents = true): Promise<void> {
        const currentRoom = roomDossierContext.getState().room
        return RoomUsers.getInstance().fetchRoomUsers().then((roomUsersInfo) => {
            if (roomDossierContext.getState().room !== currentRoom) {
                this.userList = []
                return
            }
            const emptyDiv = document.createElement("div")
            if (keepRecents) {
                this.removeNonRecentUsers()
            } else {
                this.userList = []
            }
            for (const u of roomUsersInfo.roomUsers) {
                if (!this.userInList(u.username)) {
                    this.userList.push({
                        user: u,
                        slug: u.username,
                        element: emptyDiv,
                        recent: false,
                    })
                }
                if (this.invalidMentions.has(u.username)) {
                    updateUserMention.fire(undefined)
                    this.invalidMentions.delete(u.username)
                }
            }
            this.setUserListRefreshCooldown()
        }).catch(err => {
            error("Error loading username autocomplete", err)
        })
    }

    private addRecentUser(user: IUserInfo): void {
        const emptyDiv = document.createElement("div")
        // add user to recent users list if they are not in main list or already in recent list
        const userSearch = this.getUser(user.username)
        if (userSearch === undefined) {
            this.userList.push({
                user: user,
                slug: user.username,
                element: emptyDiv,
                recent: true,
            })
        } else {
            // update user in case they have changed to tip/fan club/etc
            userSearch.user = user
            userSearch.recent = true
        }
        if (this.invalidMentions.has(user.username)) {
            updateUserMention.fire(undefined)
            this.invalidMentions.delete(user.username)
        }
    }

    public addInvalidUsers(names: string[]): void {
        for (const n of names) {
            this.invalidMentions.add(n)
        }
    }

    private shouldNotRefresh(status: RoomStatus): boolean {
        return [RoomStatus.Offline, RoomStatus.NotConnected, RoomStatus.Hidden, RoomStatus.PasswordProtected].indexOf(status) > -1
    }

    private statusChangeShouldRefresh(currentStatus: RoomStatus, prevStatus: RoomStatus): void {
        if (isAnonymous() || this.shouldNotRefresh(currentStatus)) {
            return
        }

        const privateStatuses = new Set([
            RoomStatus.PrivateSpying, RoomStatus.PrivateWatching, RoomStatus.PrivateNotWatching,
        ])

        const wasInPublic = !privateStatuses.has(prevStatus)
        const isInPublic = !privateStatuses.has(currentStatus)

        if (wasInPublic !== isInPublic) {
            this.refreshUserList(isInPublic).catch(ignoreCatch)
        } else {
            this.refreshUserListIfNeeded().catch(ignoreCatch)
        }
    }

    private removeNonRecentUsers(): void {
        this.userList = this.userList.filter(user => user.recent)
    }

    private getUser(username: string): IUserSearch | undefined {
        return this.userList.find(u => u.user.username === username)
    }

    public userInList(username: string): boolean {
        return this.getUser(username) !== undefined
    }
}
