export var RoomStatus;
(function (RoomStatus) {
    RoomStatus["Unknown"] = "unknown";
    RoomStatus["Offline"] = "offline";
    RoomStatus["NotConnected"] = "notconnected";
    RoomStatus["Away"] = "away";
    RoomStatus["PrivateRequesting"] = "privaterequesting";
    RoomStatus["PrivateNotWatching"] = "privatenotwatching";
    RoomStatus["PrivateWatching"] = "privatewatching";
    RoomStatus["PrivateSpying"] = "privatespying";
    RoomStatus["Public"] = "public";
    RoomStatus["Hidden"] = "hidden";
    RoomStatus["HiddenWatching"] = "hiddenwatching";
    RoomStatus["PasswordProtected"] = "passwordprotected";
})(RoomStatus || (RoomStatus = {}));
export var PrivateSubstatus;
(function (PrivateSubstatus) {
    PrivateSubstatus["Standard"] = "standard";
    PrivateSubstatus["Premium"] = "premium";
})(PrivateSubstatus || (PrivateSubstatus = {}));
