import {
    getRoomlistSortCookieValue,
    SortOption,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { setCookieWithDomain } from "@multimediallc/web-utils/storage"
import { isAnonymous } from "../../../../common/auth"
import { Component } from "../../../../common/defui/component"
import { ignoreMetaClick } from "../../../../common/DOMutils"
import { EventRouter } from "../../../../common/events"
import { isMobileDmActive } from "../../../../common/featureFlagUtil"
import { followingEvent } from "../../../../common/follow"
import { siteHeaderMenuOpened, toggleDms } from "../../../../common/mobilelib/userActionEvents"
import { addPageAction } from "../../../../common/newrelic"
import { addNoScroll, removeNoScroll } from "../../../../common/noScroll"
import { pageContext } from "../../../interfaces/context"
import { bindMobileDmsMenu, bindMobileLogoutLink, bindUserUpdatesPanel } from "../mobilePushMenuBindUtils"

interface MobilePushMenuProps { menuButton: HTMLDivElement }

const TRANSITION_MS = 150  // Match transition in `mobile_menu.scss`
const ANIMATE_CLASS = "pushmenu-animate"
const MENU_EXPANDED_CONTENT_CLASS = "push-page-content"

export class MobilePushMenu extends Component<HTMLElement, MobilePushMenuProps> {
    private menuButton: HTMLDivElement
    private menuContent: Component
    private pushOverlay: Component
    private _desktopLink: HTMLAnchorElement
    private socialIconsContainer: HTMLElement | null
    private followingLink: HTMLElement | null
    private lastScrollTop = 0
    private static _isOpen = false
    public static closeMenu = new EventRouter<void>("closeMobilePushMenu")

    protected initUI(props: MobilePushMenuProps): void {
        this.menuButton = props.menuButton
        this.menuContent = new Component(this.element.querySelector<HTMLElement>(".main-menu")!)
        this.pushOverlay = new Component(this.element.querySelector<HTMLElement>(".push-overlay")!)
        this._desktopLink = this.element.querySelector<HTMLAnchorElement>("#mobile_desktop_link")!
        this.socialIconsContainer = this.element.querySelector("#mobile-social-media-icons")
        this.followingLink = this.element.querySelector("#follow_page_link")

        this.menuButton.addEventListener("click", this.onMenuOpen.bind(this))
        this.pushOverlay.element.addEventListener("click", this.onMenuClosed.bind(this))
        this.bindSubMenus()
        this.bindHeaderOpenedEvents()
        bindMobileLogoutLink()
        this.bindDesktopLink()
        this.bindSocialMediaIcons()
        this.bindFollowCountUpdates()

        this.bindRoomlistLinks()
        this.listenForCloseEvents()
    }

    private listenForCloseEvents(): void {
        MobilePushMenu.closeMenu.listen(() => {
            this.onMenuClosed()
        })
    }

    private onMenuOpen(): void {
        this.showElement()
        this.pushOverlay.showElement()
        document.querySelectorAll(".content, header").forEach((el) => {
            el.classList.add(MENU_EXPANDED_CONTENT_CLASS)
        })
        this.setScroll(true)

        // wait for pushmenu display before adding transition
        window.setTimeout(() => {
            this.menuContent.element.classList.add(ANIMATE_CLASS)
        })

        addPageAction("MobileMenuClick", { "is_orange": this.menuButton.classList.contains("has_unseen") })
        siteHeaderMenuOpened.fire(true)
    }

    private onMenuClosed(): void {
        this.menuContent.element.classList.remove(ANIMATE_CLASS)
        this.pushOverlay.element.style.opacity = "0"  // Opacity transition handled in scss
        document.querySelectorAll(".content, header").forEach((el) => {
            el.classList.remove(MENU_EXPANDED_CONTENT_CLASS)
        })
        this.setScroll(false)
        window.setTimeout(() => {
            this.hideElement()
            this.pushOverlay.element.style.opacity = ""  // Transition finished
        }, TRANSITION_MS)
        siteHeaderMenuOpened.fire(false)
    }

    public showElement(): void {
        super.showElement()
        MobilePushMenu._isOpen = true
    }

    public hideElement(): void {
        super.hideElement()
        MobilePushMenu._isOpen = false
    }

    private setScroll(isMenuOpen: boolean): void {
        if (isMenuOpen) {
            this.lastScrollTop = window.scrollY
            addNoScroll()
            document.body.style.top = `-${this.lastScrollTop}px`
        } else {
            removeNoScroll()
            document.body.style.top = ""
            window.scrollTo(0, this.lastScrollTop)
        }
    }

    private bindSubMenus(): void {
        const updatesBellRoot = this.element.querySelector<HTMLDivElement>("#userUpdatesBellRoot")
        bindUserUpdatesPanel(
            updatesBellRoot,
            this.element.querySelector<HTMLDivElement>("#userUpdatesMenuDropdownRoot"),
            true,  // mobile
            this.menuButton,
        )
        if (!pageContext.current.isAgeGated) {
            const dmListIconRoot = this.element.querySelector<HTMLDivElement>("#dmListIconRoot")
            const messagingIconRoot = document.querySelector<HTMLDivElement>("#messagingIconRoot")
            bindMobileDmsMenu(
                isMobileDmActive() && isAnonymous() && messagingIconRoot ? messagingIconRoot : dmListIconRoot,
                updatesBellRoot,
                this.menuButton,
                this.element.querySelector<HTMLDivElement>("#dmListDropdownRoot"),
            )
        }
    }

    public static isOpen(): boolean {
        return MobilePushMenu._isOpen
    }

    private bindHeaderOpenedEvents(): void {
        toggleDms.listen((shouldOpen: boolean) => {
            if (shouldOpen && !MobilePushMenu._isOpen) {
                this.onMenuOpen()
            } else if (!shouldOpen && MobilePushMenu._isOpen) {
                this.onMenuClosed()
            }
        })
    }

    private bindDesktopLink(): void {
        const domain = this.desktopLink.dataset.domain ?? window.location.hostname
        this.desktopLink.addEventListener("click", () => {
            setCookieWithDomain("mobile_redirect", "never", { hours: 18 }, "/", domain)
            setCookieWithDomain("mobile_redirect_source", "user", { hours: 18 }, "/", domain)
            addPageAction("Mobile_DesktopLinkClicked")
        })
    }

    private bindSocialMediaIcons(): void {
        if (this.socialIconsContainer !== null) {  // Not rendered on whitelabels
            this.socialIconsContainer.querySelectorAll<HTMLAnchorElement>("a").forEach((el) => {
                el.addEventListener("click", () => {
                    addPageAction("ClickSocialMediaLink", {
                        "attributes.source": "mobile",
                        "attributes.clicked_socialmedia": el.dataset["social-type"],
                    })
                })
            })
        }
    }

    // Replaces the inline JS follow count logic in roomlist.html
    private bindFollowCountUpdates(): void {
        if (this.followingLink === null) {
            return  // Not rendered for logged-out users
        }
        const totalEl = this.followingLink.querySelector("#follow_count_total")!
        const onlineEl = this.followingLink.querySelector("#follow_count_online")!
        followingEvent.listen((event) => {
            const totalCount = event.totalCount ?? 0
            const onlineCount = event.onlineCount ?? 0
            if (totalCount > 0) {
                this.followingLink!.style.display = "block"
                onlineEl.textContent = `${onlineCount}`
                totalEl.textContent = `${totalCount}`
            } else {
                this.followingLink!.style.display = "none"
            }
        })
    }

    private bindRoomlistLinks(): void {
        this.element.querySelectorAll(".pushmenu-link.ts-roomlist-link a:not([data-spa-ignore])").forEach((el) => {
            el.addEventListener("click", (event) => {
                ignoreMetaClick(event as MouseEvent, () => {
                    event.preventDefault()
                    this.onMenuClosed()
                    const url = new URL((event.target as HTMLAnchorElement).href)
                    const sortValue = getRoomlistSortCookieValue()
                    if (sortValue !== SortOption.Popular) {
                        url.searchParams.set("sort", sortValue)
                    } else {
                        url.searchParams.delete("sort")
                    }
                    UrlState.current.navigateTo(url.toString())
                })
            })
        })
    }

    get desktopLink(): HTMLAnchorElement {
        return this._desktopLink
    }
}
