import { t } from "@lingui/macro"
import { EventRouter } from "../../../common/events"
import { isSubjectSearchToggleActive } from "../../../common/featureFlagUtil"
import { addPageAction } from "../../../common/newrelic"
import { BaseSuggestedItem } from "./baseSuggestedItem"
import type { ISuggestionProps } from "./baseSuggestedItem"

export const keywordsSearchClicked = new EventRouter<void>(
    "keywordsSearchClicked",
)

export class KeywordsSuggestedItem extends BaseSuggestedItem {
    protected getIconClassName(): string {
        return "icon-keywords"
    }

    protected getStatusText(): string {
        return t`in room subjects and tags`
    }

    protected getRowClassName(): string {
        return "keywords_suggestion"
    }

    protected onClickHandler(props: ISuggestionProps, evt?: MouseEvent): void {
        evt?.preventDefault()
        keywordsSearchClicked.fire()
        if (isSubjectSearchToggleActive()) {
            addPageAction("KeywordsSearch", {
                active_test: "SbjSrchSrt",
                keywords: props.input,
            })
        }
    }
}
