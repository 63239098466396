import { RoomListSource } from "@multimediallc/web-utils/types"
import { pageContext, roomDossierContext } from "../../cb/interfaces/context"
import { featureFlagIsActive } from "../featureFlag"
import type { IRoomUsersInfo } from "../../cb/roomUsers"
import type { IUserInfo } from "../messageInterfaces"

export const enum UserColorGroup {
    Broadcaster = "o",
    Moderator = "m",
    Fanclub = "f",
    TippedTonsRecently = "l",
    TippedALotRecently = "p",
    TippedRecently = "tr",
    HasTokens = "t",
    Grey = "g",
}

const orderedColorGroups = new Map<UserColorGroup, number>([
    [UserColorGroup.Broadcaster, 1],
    [UserColorGroup.Moderator, 2],
    [UserColorGroup.Fanclub, 3 ],
    [UserColorGroup.TippedTonsRecently, 4],
    [UserColorGroup.TippedALotRecently, 5],
    [UserColorGroup.TippedRecently, 6],
    [UserColorGroup.HasTokens, 7],
    [UserColorGroup.Grey, 8],
])

// eslint-disable-next-line complexity
export function parseRoomUsersInfo(rawData: string): IRoomUsersInfo {
    let usersListArray: string[] = rawData.split(",")
    const anonUsers: number = ~~usersListArray[0]
    usersListArray = usersListArray.slice(1)
    const parsedUsersArray: IUserInfo[] = []
    // Don't add the broadcaster to the list, may cause issues depending on sort type
    let foundCurrentUser = pageContext.current.isBroadcast ? true : false
    const dossier = roomDossierContext.getState()
    const currentUserColor = dossier.userlistColor as UserColorGroup
    const currentFollowing = dossier.following ? "t" : "f"
    const shouldTryToAddCurrentUser = featureFlagIsActive("PushEnblRecEnter") && !pageContext.current.isNoninteractiveUser

    for (const user of usersListArray) {
        const [name, rawColorGroup, gender, isFollowing] = user.split("|")
        const colorGroup = rawColorGroup as UserColorGroup

        if (shouldTryToAddCurrentUser && !foundCurrentUser && pageContext.current.loggedInUser !== undefined) {
            const sort = pageContext.current.loggedInUser.username === name ? 0 :
                pageContext.current.loggedInUser.username > name ? 1: -1
            if (sort === 0) {
                foundCurrentUser = true
            } else if (colorGroup === currentUserColor){
                // The list of users is sorted by color group, so if the color group is the same as the current user's color group, and the name comes first alphabetically, we need to insert the current user into the list
                if (sort < 0) {
                    const currentUser = createIUserInfo(pageContext.current.loggedInUser.username, currentUserColor, pageContext.current.loggedInUser.gender ?? "", currentFollowing)
                    parsedUsersArray.push(currentUser)
                    foundCurrentUser = true
                }
            } else if ((orderedColorGroups.get(colorGroup) ?? 0) > (orderedColorGroups.get(currentUserColor) ?? 0)) {
                // If we reach a "lower" color group, and we haven't found the current user, we need to insert the current user into the list
                const currentUser = createIUserInfo(pageContext.current.loggedInUser.username, currentUserColor, pageContext.current.loggedInUser.gender ?? "", currentFollowing)
                parsedUsersArray.push(currentUser)
                foundCurrentUser = true
            }
        }
        const parsedUser: IUserInfo = createIUserInfo(name, colorGroup, gender, isFollowing)
        parsedUsersArray.push(parsedUser)
    }
    // if the user is still not found, add them to the end of the list
    if (featureFlagIsActive("PushEnblRecEnter") && !foundCurrentUser && pageContext.current.loggedInUser !== undefined && !pageContext.current.isNoninteractiveUser) {
        const currentUser = createIUserInfo(pageContext.current.loggedInUser.username, currentUserColor, pageContext.current.loggedInUser.gender ?? "", currentFollowing)
        parsedUsersArray.push(currentUser)
        foundCurrentUser = true
    }
    return {
        totalCount: anonUsers + parsedUsersArray.length,
        anonCount: anonUsers,
        roomUsers: parsedUsersArray,
    }
}

function createIUserInfo(username: string, colorGroup: UserColorGroup, gender: string, isFollowing: string): IUserInfo {
    const newUser: IUserInfo = {
        username: username,
        isBroadcaster: false,
        inFanclub: false,
        isFollowing: isFollowing === "t",
        hasTokens: false,
        isMod: false,
        tippedRecently: false,
        tippedALotRecently: false,
        tippedTonsRecently: false,
        exploringHashTag: "",
        gender: gender,
        sourceName: RoomListSource.Unknown,
    }

    if (colorGroup === UserColorGroup.Broadcaster) {
        newUser.isBroadcaster = true
    } else if (colorGroup === UserColorGroup.Moderator) {
        newUser.isMod = true
    } else if (colorGroup === UserColorGroup.Fanclub) {
        newUser.inFanclub = true
    } else if (colorGroup === UserColorGroup.TippedTonsRecently) {
        newUser.tippedTonsRecently = true
    } else if (colorGroup === UserColorGroup.TippedALotRecently) {
        newUser.tippedALotRecently = true
    } else if (colorGroup === UserColorGroup.TippedRecently) {
        newUser.tippedRecently = true
    } else if (colorGroup === UserColorGroup.HasTokens) {
        newUser.hasTokens = true
    } else if (colorGroup !== UserColorGroup.Grey) {
        error("Unexpected IUserInfo colorGroup", { "colorGroup": colorGroup })
    }

    return newUser
}

// NOTE: currently only works for broadcaster
export function currentIUserInfo(): IUserInfo | undefined {
    if (pageContext.current.loggedInUser !== undefined) {
        if (pageContext.current.isBroadcast) {
            const username = pageContext.current.loggedInUser.username
            const gender = pageContext.current.loggedInUser.gender ?? ""
            return createIUserInfo(username, UserColorGroup.Broadcaster, gender, "f")
        }
        // TODO implement for viewers in followup PR
    }
    return undefined
}

export function getUserColorGroup(user: IUserInfo): UserColorGroup {
    if (user.isBroadcaster === true) {
        return UserColorGroup.Broadcaster
    } else if (user.isMod) {
        return UserColorGroup.Moderator
    } else if (user.inFanclub) {
        return UserColorGroup.Fanclub
    } else if (user.tippedTonsRecently) {
        return UserColorGroup.TippedTonsRecently
    } else if (user.tippedALotRecently) {
        return UserColorGroup.TippedALotRecently
    } else if (user.tippedRecently) {
        return UserColorGroup.TippedRecently
    } else if (user.hasTokens) {
        return UserColorGroup.HasTokens
    } else {
        return UserColorGroup.Grey
    }
}
