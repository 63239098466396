export var RoomListSource;
(function (RoomListSource) {
    RoomListSource["Default"] = "df";
    RoomListSource["Recommended"] = "rc";
    RoomListSource["RecommendedSearch"] = "rs";
    RoomListSource["FallbackRoomlist"] = "fr";
    RoomListSource["FollowedTab"] = "ft";
    RoomListSource["RecommendedFollow"] = "rf";
    RoomListSource["FollowedNotification"] = "fn";
    RoomListSource["FollowedPageOnline"] = "fo";
    RoomListSource["FollowedPageOffline"] = "ff";
    RoomListSource["MoreLikeThis"] = "ml";
    RoomListSource["MoreRooms"] = "mr";
    RoomListSource["TagPage"] = "tp";
    RoomListSource["Promoted"] = "pr";
    RoomListSource["Boosted"] = "bs";
    RoomListSource["NextCam"] = "nc";
    RoomListSource["ScanCam"] = "sc";
    RoomListSource["Discover"] = "dc";
    RoomListSource["Trending"] = "tr";
    RoomListSource["Small"] = "sm";
    RoomListSource["PrivateShows"] = "ps";
    RoomListSource["CommunityControlledShows"] = "cc";
    RoomListSource["AffiliatesCamListing"] = "ac";
    RoomListSource["Unknown"] = "un";
})(RoomListSource || (RoomListSource = {}));
