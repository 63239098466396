import { hasWellSupportedEmojis, isiOS } from "@multimediallc/web-utils/modernizr"
import { OutgoingMessageType } from "@multimediallc/web-utils/types"
import twemoji from "@twemoji/api"
import { addColorClass } from "../../cb/colorClasses"
import { PMControlBar } from "../../cb/components/pm/pmControlBar"
import { ReactComponentRegistry } from "../../cb/components/ReactRegistry"
import { MentionUserList } from "../../cb/components/userMentions/MentionUserList"
import { updateUserMention, UserMentionAutocompleteModal } from "../../cb/components/userMentions/userMentionAutocompleteModal"
import { hideRulesModal, maybeShowRulesModal } from "../../cb/components/userMenus/ui/rulesModal"
import { roomDossierContext } from "../../cb/interfaces/context"
import { ChatButtonHolder, ChatButtonType } from "../../cb/ui/chatButtonHolder"
import { NewMessageLine } from "../../cb/ui/newMessageLine"
import { windowResizeInProgress } from "../../cb/ui/responsiveUtil"
import { ScrollDownButton } from "../../cb/ui/scrollDownButton"
import { addEventListenerMultiPoly, addEventListenerPoly } from "../addEventListenerPolyfill"
import { isNotLoggedIn } from "../auth"
import {
    handleBlurredChatInput,
    handleFocusedChatInput,
} from "../chatPageAction"
import { roomCleanup, roomLoaded } from "../context"
import { CustomInput } from "../customInput"
import { Debouncer, DebounceTypes } from "../debouncer"
import { Component } from "../defui/component"
import { applyStyles, stopScrollMomentum } from "../DOMutils"
import { EmoticonAutocompleteModal } from "../emoticonAutocompleteModal"
import { EventRouter, ListenerGroup } from "../events"
import { addChatHistoryPageActionsScollListener, isChatScrollingPageactionsActive, isReduceChatHistoryActive, isScrollDownNoticeActive } from "../featureFlagUtil"
import { styleUsernameMention } from "../fullvideolib/messageToDOM"
import { insertByTimestamp } from "../messageToDOM"
import { addPageAction } from "../newrelic"
import { styleUserSelect } from "../safeStyle"
import { ShortcodeAutocompleteModal } from "../shortcodeAutocompleteModal"
import { errorBehindShortcode, parseOutgoingMessage } from "../specialoutgoingmessages"
import { i18n } from "../translation"
import { getMoreHistoryMessages, standardEmojiRequest } from "../userActionEvents"
import { videoModeHandler } from "../videoModeHandler"
import { createLogMessage } from "./messageToDOM"
import { userChatSettingsUpdate } from "./userActionEvents"
import type { LibraryMediaDock, SelectedMediaDock } from "../../cb/components/pm/mediaDock"
import type { RulesModal } from "../../cb/components/userMenus/ui/rulesModal"
import type { IChatContents } from "../chatRoot"
import type { IRoomContext } from "../context"
import type {
    IOutgoingMessageHandlers,
    IOutgoingShortcodeMessage,
    ITipRequestMessage,
} from "../specialoutgoingmessages"
import type { IHtmlCreateEvent } from "../ui/interfaces"

// region DOM Creation
export const inputDivHeight = 28
export const inputFieldMargin = 6
export const maxInputChat = 1024
export const maxInputPm = 4096

export const maxMessageHistory = (isPMs = false): number => {
    const dossier = roomDossierContext.getState()
    const isBroadcaster = dossier.userName === dossier.room
    if (!isReduceChatHistoryActive() || dossier.isModerator || isBroadcaster || isPMs) {
        return 1000
    }
    return 200
}

export const enum SendButtonVariant {
    SplitMode = "SplitMode",
    DraggableCanvas = "DraggableCanvas",
    MobileSplitMode = "MobileSplitMode",
}

function createMessageListWrapper(): HTMLDivElement {
    // the wrapper handles the scrolling and padding so that other parts of the project can
    // call `getBoundingClientRect` on the window
    const messageListWrapper = document.createElement("div")
    messageListWrapper.className = "msg-list-wrapper-split"
    messageListWrapper.style.boxSizing = "border-box"
    if (isScrollDownNoticeActive()) {
        messageListWrapper.style.overflowY = "auto"
        messageListWrapper.style.overflowX = "hidden"
    } else {
        messageListWrapper.style.overflow = "auto"
    }
    messageListWrapper.style.margin = "0 auto 0"
    messageListWrapper.style.width = "100%"
    messageListWrapper.style.padding = "5px"
    messageListWrapper.style.flex = "1"
    return messageListWrapper
}

function createNoticeList(): HTMLDivElement {
    const m = document.createElement("div")
    m.style.width = "100%"
    m.className = "notice-list-fvm"
    styleUserSelect(m, "text")
    m.style.cursor = "text"
    return m
}

function createMessageList(): HTMLDivElement {
    const m = document.createElement("div")
    m.className = "msg-list-fvm"
    addColorClass(m, "message-list")
    m.style.width = "100%"
    styleUserSelect(m, "text")
    m.style.cursor = "text"
    m.style.paddingBottom = "4px"
    return m
}

export function createInputForm(): HTMLFormElement {
    const form = document.createElement("form")
    addColorClass(form, "chat-input-form")
    form.style.display = "inline-block"
    form.style.boxSizing = "border-box"
    form.style.display = "flex"
    form.style.alignItems = "center"
    return form
}

export function createInputDiv(): HTMLDivElement {
    const inputDiv = document.createElement("div")
    addColorClass(inputDiv, "inputDiv")
    inputDiv.style.height = `${inputDivHeight}px`
    inputDiv.style.boxSizing = "border-box"
    inputDiv.style.margin = "0 5px 5px 5px"
    inputDiv.style.position = "relative"
    inputDiv.style.borderWidth = "1px"
    inputDiv.style.borderStyle = "solid"
    inputDiv.style.fontSize = "12px"
    inputDiv.style.display = "grid" // can move to flex when all our supported browsers support `gap` with flex layouts
    inputDiv.style.gridTemplateColumns = "minmax(25px, 1fr) auto" // input form and button holder
    inputDiv.style.columnGap = `${inputFieldMargin}px`
    inputDiv.style.padding = `0 ${inputFieldMargin}px`
    inputDiv.style.borderRadius = "4px 4px 0 0"
    return inputDiv
}

export function createInputField(submitInput: () => boolean, maxLength: number, className?: string): CustomInput {
    const customInput = new CustomInput(submitInput, maxLength)
    addColorClass(customInput.element, "chat-input-field")
    applyStyles(customInput.element, {
        height: `${inputDivHeight - 2 * inputFieldMargin}px`,
        fontFamily: "Helvetica, Arial, sans-serif",
        lineHeight: "15px", // firefox text is 1px too high without this
    })
    customInput.element.classList.add("inputFieldChatPlaceholder")
    customInput.element.dataset["testid"] = "chat-input"

    if (className !== undefined) {
        customInput.element.classList.add(className)
    }

    // we have to set an attribute here to properly internationalize the content in the pseudo element because we cannot _set_ a dataset unless
    // the property already exists, which it will not as we're creating the element dynamically.
    customInput.element.setAttribute("data-placeholder", i18n.sendAMessageDesktop) // eslint-disable-line @multimediallc/no-set-attribute

    // Added touchstart to work around an android tablet issue where focus isn't being fired properly
    // until text is typed.
    addEventListenerMultiPoly(["focus", "touchstart"], customInput.element, () => handleFocusedChatInput())
    addEventListenerPoly("blur", customInput.element, handleBlurredChatInput)

    return customInput
}

export function createEmojiButton(isPmChat: boolean): HTMLSpanElement {
    const span = document.createElement("span")
    span.textContent = "😁"
    span.style.cursor = "pointer"
    span.style.fontSize = "1em"
    span.style.lineHeight = "1em"
    span.classList.add(isPmChat ? "theatermodeEmojiButtonPm" : "theatermodeEmojiButtonChat")

    if (!hasWellSupportedEmojis()) {
        twemoji.parse(span, { className: "emojiButton" })
        span.style.fontSize = "15px"
        span.style.lineHeight = "15px"
    }
    span.dataset["paction"] = "Chat"
    span.dataset["pactionName"] = "EmojiClick"
    span.dataset["testid"] = "emoji-button"
    return span
}

function createMediaDockButton(onClick: () => void): HTMLSpanElement {
    const span = document.createElement("span")
    span.style.cursor = "pointer"
    span.style.height = "1.2em"
    span.style.minWidth = "1.2em"
    span.dataset["paction"] = "Chat"
    span.dataset["pactionName"] = "UploadPhoto"
    span.dataset["testid"] = "send-image-button"
    span.onclick = onClick

    const img = document.createElement("img")
    img.src = `${STATIC_URL}mediaDock/uploadBackground.svg`
    img.style.width = "100%"
    img.style.height = "100%"
    span.appendChild(img)
    return span
}

// endregion

export class ChatTabContents extends Component implements IChatContents {
    private addMessageHTMLEvent = new EventRouter<IHtmlCreateEvent>("addMessageHtml", { reportIfNoListeners: false })
    private removeMessagesForUserEvent = new EventRouter<string>("removeMessageHtml", { reportIfNoListeners: false })
    public scrolledToBottom = new EventRouter<void>("scrolledToBottom")
    // tracks if chat is scrolled up, ignoring scroll changes caused by window resizing
    private wasScrolledUp: boolean
    public messageList = createMessageList()
    private noticeList = createNoticeList()
    public messageListWrapper = createMessageListWrapper()
    public customInputField: CustomInput
    public inputDiv: HTMLDivElement
    private inputForm: HTMLFormElement
    public rulesModal: RulesModal | undefined
    private buttonHolder: ChatButtonHolder
    private emojiButton: HTMLSpanElement
    private emoticonAutocompleteModal: EmoticonAutocompleteModal
    private shortcodeAutocompleteModal: ShortcodeAutocompleteModal | undefined
    private userMentionAutocompleteModal: UserMentionAutocompleteModal | undefined
    private listenerGroup = new ListenerGroup()
    private earliestMessageId: string | undefined
    protected isPmChatContents = this.pmOtherUser !== undefined
    protected currentRoomContext: IRoomContext
    private newMessageNotice?: NewMessageLine
    private scrollDownButton?: ScrollDownButton
    private scrollToBottomDebouncer = new Debouncer(() => this.scrollToBottom(), { bounceLimitMS: 0, debounceType: DebounceTypes.throttle })

    // only for PM chat
    public libraryMediaDock?: LibraryMediaDock
    public selectedMediaDock?: SelectedMediaDock
    private mediaDockButton?: HTMLSpanElement
    private pmControlBar?: PMControlBar

    constructor(protected outgoingHandlers: IOutgoingMessageHandlers, private inPrivateRoom: () => boolean, private isConversationShowing: () => boolean, protected pmOtherUser?: string) {
        super()

        this.element.classList.add("ChatTabContents")
        this.element.style.position = "static"
        this.element.style.boxSizing = "border-box"
        this.element.style.fontSize = "12px"
        this.element.style.overflow = ""
        this.element.style.display = "flex"
        this.element.style.flexDirection = "column"
        this.element.classList.add(this.isPmChatContents ? "TheatermodeChatDivPm" : "TheatermodeChatDivChat")
        this.messageListWrapper.appendChild(this.noticeList)
        this.messageListWrapper.appendChild(this.messageList)
        if (this.isPmChatContents) {
            this.pmControlBar = new PMControlBar(false, pmOtherUser!) // eslint-disable-line @typescript-eslint/no-non-null-assertion
            this.element.appendChild(this.pmControlBar.element)
        }
        this.element.appendChild(this.messageListWrapper)

        if (isScrollDownNoticeActive()) {
            this.scrollDownButton = new ScrollDownButton({
                scrollToBottom: () => this.scrollToBottom(),
                bottomStyle: () => `${this.totalInputHeight() + 9}px`,
            })
            this.addChild(this.scrollDownButton)

            this.newMessageNotice = new NewMessageLine({
                getUnreadCount: () => this.scrollDownButton?.getUnreadCount(),
                isConversationShowing: () => this.isConversationShowing(),
                isScrolledUp: () => this.isScrolledUp(),
                setParentScrollTop: (oldScrollTop: number) => this.setScrollTop(oldScrollTop),
                scrollParentDiv: this.messageListWrapper,
            })

            // Clear new notice line(only on active chat window) and show latest chat to avoid having different scroll & new line positions
            // when switching between theatre/fullscreen purechat and spit mode chat.
            videoModeHandler.changeVideoMode.listen(() => {
                this.newMessageNotice?.remove()
                this.scrollToBottom()
            }).addTo(this.listenerGroup)
        }

        this.buildChatInput()

        this.wasScrolledUp = false
        // TODO organize scroll code in each mode (split, theater, mobile, etc) in followup PR https://multimediallc.leankit.com/card/30502080789707
        const onChatScroll = () => {
            // should still load more messages whenever top is shown
            const scrollAmount = this.messageListWrapper.scrollTop
            if (this.isPmChatContents && scrollAmount === 0 && this.isScrolledUp()) {
                getMoreHistoryMessages.fire()
            }
            // don't track scrolling triggered by resize events
            if (windowResizeInProgress) {
                return
            }
            if (!this.isScrolledUp() && this.wasScrolledUp) {
                this.scrolledToBottom.fire()
            }
            this.wasScrolledUp = this.isScrolledUp()
        }

        const chatScrollDebouncer = new Debouncer(
            onChatScroll,
            { bounceLimitMS: 50, debounceType: DebounceTypes.debounce },
        )

        addEventListenerPoly("scroll", this.messageListWrapper, () => {
            chatScrollDebouncer.callFunc()
            this.undebouncedOnScrollChange()
        })
        if (isChatScrollingPageactionsActive() && !this.isPmChatContents) {
            addChatHistoryPageActionsScollListener(this.messageListWrapper, this.messageList)
        }

        this.listenerGroup.add(roomLoaded.listen((context) => {
            this.currentRoomContext = context
            this.element.style.fontSize = context.dossier.userChatSettings.fontSize
            this.setLineHeight()
        }))

        roomCleanup.listen(() => {
            this.customInputField.clearText()
            this.customInputField.blur()
            this.scrollDownButton?.hideElement()
            hideRulesModal()
        }).addTo(this.listenerGroup)

        userChatSettingsUpdate.listen(userChatSettings => {
            this.element.style.fontSize = userChatSettings.fontSize
            this.setLineHeight()
        }).addTo(this.listenerGroup)

        this.emoticonAutocompleteModal = new EmoticonAutocompleteModal({
            inputElement: this.customInputField,
            leftOffset: 8,
            rightOffset: 207,
        })
        this.inputDiv.appendChild(this.emoticonAutocompleteModal.element)
        this.emoticonAutocompleteModal.afterDOMConstructedIncludingChildren()

        if (!this.isPmChatContents) {
            this.userMentionAutocompleteModal = new UserMentionAutocompleteModal({
                inputElement: this.customInputField,
                leftOffset: 8,
                rightOffset: 207,
            })
            this.inputDiv.appendChild(this.userMentionAutocompleteModal.element)
            this.userMentionAutocompleteModal.afterDOMConstructedIncludingChildren()
            updateUserMention.listen(() => {
                styleUsernameMention(this.messageList)
            }).addTo(this.listenerGroup)

            this.shortcodeAutocompleteModal = new ShortcodeAutocompleteModal({
                inputElement: this.customInputField,
                leftOffset: 8,
                rightOffset: 207,
            }, this.isPmChatContents)
            this.inputDiv.appendChild(this.shortcodeAutocompleteModal.element)
            this.shortcodeAutocompleteModal.afterDOMConstructedIncludingChildren()
        }

        this.emoticonAutocompleteModal.element.classList.add(this.isPmChatContents ? "theatermodeEmoticonAutocompleteModalPm" : "theatermodeEmoticonAutocompleteModalChat")
        this.emoticonAutocompleteModal.element.dataset["testid"] = "emoticon-autocomplete-modal"
    }

    private buildChatInput(): void {
        const maxLength = this.isPmChatContents ? maxInputPm : maxInputChat
        this.inputDiv = createInputDiv()
        this.inputForm = createInputForm()
        this.customInputField = createInputField(
            () => this.sendMessageFromInput(),
            maxLength,
            this.isPmChatContents ? "theatermodeInputFieldPm" : "theatermodeInputFieldChat",
        )
        this.buildButtonHolder()

        this.inputForm.appendChild(this.customInputField.element)
        this.inputDiv.appendChild(this.inputForm)
        this.inputDiv.appendChild(this.buttonHolder.element)
        this.element.appendChild(this.inputDiv)

        this.addInputListeners()
    }

    private buildButtonHolder(): void {
        this.buttonHolder = new ChatButtonHolder({ columnGap: `${inputFieldMargin}px` })

        if (this.isPmChatContents) {
            this.mediaDockButton = createMediaDockButton(() => {this.onMediaDockButtonClick()})
            this.buttonHolder.addButton(this.mediaDockButton, ChatButtonType.Icon)
        }

        this.emojiButton = createEmojiButton(this.isPmChatContents)
        this.buttonHolder.addButton(this.emojiButton, ChatButtonType.Icon)

        const sendButtonRoot = document.createElement("span")
        const SendButton = ReactComponentRegistry.get("SendButton")
        new SendButton({
            "onClick": () => { this.customInputField.submit() },
            "isPm": this.isPmChatContents,
            "variant": SendButtonVariant.SplitMode,
        }, sendButtonRoot)
        this.buttonHolder.addButton(sendButtonRoot, ChatButtonType.Text)
    }

    private onMediaDockButtonClick(): void {
        addPageAction("PMPhotoButtonClicked")

        if (!isNotLoggedIn()) {
            if (maybeShowRulesModal(this)) {
                return
            }
            this.libraryMediaDock?.toggle()
            this.scrollToBottom()
        }
    }

    private addInputListeners(): void {
        addEventListenerPoly("mousedown", this.inputDiv, (ev) => {
            if (maybeShowRulesModal(this)) {
                return
            }
            if (ev.target === this.inputDiv || ev.target === this.inputForm) {
                this.customInputField.focus()
                ev.preventDefault()
            }
        })

        addEventListenerPoly("focus", this.customInputField.element, (ev) => {
            if (maybeShowRulesModal(this)) {
                ev.preventDefault()
                return
            }
        })

        addEventListenerPoly("click", this.emojiButton, (ev) => {
            if (maybeShowRulesModal(this)) {
                return
            }
            // Send Pageaction for anon focusing chat on emoji click
            handleFocusedChatInput()
            standardEmojiRequest.fire(this.emojiButton)
        })

        addEventListenerPoly("submit", this.inputForm, (ev) => {
            ev.preventDefault()
            this.customInputField.submit()
        })
    }

    private setLineHeight(): void {
        this.messageListWrapper.style.lineHeight = `${Number(this.element.style.fontSize.slice(0, -2)) + 7}pt`
    }

    private totalInputHeight(): number {
        return inputDivHeight + (this.libraryMediaDock?.element.offsetHeight ?? 0) + (this.selectedMediaDock?.element.offsetHeight ?? 0)
    }

    public dispose(): void {
        this.listenerGroup.removeAll()
        this.emoticonAutocompleteModal.dispose()
        this.userMentionAutocompleteModal?.dispose()
        this.libraryMediaDock?.dispose()
        this.customInputField.dispose()
        this.pmControlBar?.dispose()
        this.shortcodeAutocompleteModal?.dispose()
    }

    protected shouldSendMessageFromInput(): boolean {
        if (isNotLoggedIn(i18n.loggedInToSendAMessage)) {
            return false
        }
        if (this.emoticonAutocompleteModal.isVisible() || this.userMentionAutocompleteModal?.isVisible() === true) {
            return false
        }
        if (this.shortcodeAutocompleteModal?.isVisible() === true) {
            return false
        }
        if (maybeShowRulesModal(this)) {
            return false
        }
        return true
    }

    // Don't call this directly, go through this.customInputField.submit() instead
    protected sendMessageFromInput(): boolean {
        if (!this.shouldSendMessageFromInput()) {
            return false
        }
        const val = this.customInputField.getText()
        const hasSelectedMedia = this.selectedMediaDock !== undefined && !this.selectedMediaDock.isEmpty()
        this.scrollToBottom()

        if (val.trim() !== "" || hasSelectedMedia) {
            this.processMessage(val)
        }

        this.recordUserAction(val)
        return true
    }

    protected shortcodeErrorMsg(shortcodeMessage: IOutgoingShortcodeMessage, raw: string): string | undefined {
        if (this.isPmChatContents) {
            return i18n.shortcodeNotSupportedInPMs
        } else if (this.inPrivateRoom()) {
            return i18n.shortcodeNotSupportedInPrivates
        } else if (shortcodeMessage.shortcodes.length === 0) {
            // Recognized as shortcode syntax, but does not contain valid shortcodes
            return errorBehindShortcode(raw)
        }
    }

    protected processMessage(val: string): void {
        const outgoingMessage = parseOutgoingMessage(val)
        switch (outgoingMessage.messageType) {
            case OutgoingMessageType.Shortcode:
                const shortcode = outgoingMessage as IOutgoingShortcodeMessage
                const errorMsg = this.shortcodeErrorMsg(shortcode, val)
                if (errorMsg !== undefined) {
                    this.appendMessageDiv(createLogMessage(errorMsg))
                } else if (this.outgoingHandlers.onShortcode) {
                    this.outgoingHandlers.onShortcode(shortcode)
                }
                break
            case OutgoingMessageType.ToggleDebugMode:
                this.outgoingHandlers.onToggleDebugMode()
                break
            case OutgoingMessageType.TipRequest:
                // `clearText` gets called in CustomInput.submit() but clear it early
                // here so the one in CustomInput doesn't mess up tip callout input focus
                this.customInputField.clearText()
                const tipMessage = outgoingMessage as ITipRequestMessage
                this.outgoingHandlers.onTipRequest(tipMessage.messageData)
                break
            default:
                this.outgoingHandlers.onChatMessage(val)
                break
        }
    }

    protected recordUserAction(val: string): void {
        const matches = val.match(/@[a-zA-Z0-9_]+/gm)
        const userList = MentionUserList.getInstance()
        if (matches !== null) {
            for (const m of matches) {
                if (userList.userInList(m.replace("@", ""))) {
                    addPageAction("UserMentionMessage")
                }
            }
        }
        this.customInputField.blur()
    }

    protected repositionChildren(): void {
        if (this.selectedMediaDock?.isShown() ?? false) {
            this.inputDiv.style.borderRadius = "0"
        } else {
            this.inputDiv.style.borderRadius = "4px 4px 0 0"
        }

        this.emoticonAutocompleteModal.element.style.bottom = `${this.inputDiv.offsetHeight - 2}px`
        if (this.shortcodeAutocompleteModal !== undefined) {
            this.shortcodeAutocompleteModal.element.style.bottom = `${this.inputDiv.offsetHeight - 2}px`
        }
        if (this.userMentionAutocompleteModal !== undefined) {
            this.userMentionAutocompleteModal.element.style.bottom = `${this.inputDiv.offsetHeight - 2}px`
        }
        if (!this.wasScrolledUp) {
            this.scrollToBottom()
        }
    }

    public initMediaDocks(libraryMediaDock: LibraryMediaDock): void {
        this.libraryMediaDock = libraryMediaDock
        this.selectedMediaDock = libraryMediaDock.sibling
        this.addMediaDocksToDOM()
    }

    private addMediaDocksToDOM(): void {
        if (this.libraryMediaDock !== undefined) {
            this.addChild(this.libraryMediaDock)
        }

        if (this.selectedMediaDock !== undefined) {
            applyStyles(this.selectedMediaDock, { margin: "0 5px" })
            this.element.insertBefore(this.selectedMediaDock.element, this.inputDiv)
        }
    }

    public isScrolledUp(): boolean {
        return this.messageListWrapper.scrollTop <= this.messageListWrapper.scrollHeight - (this.messageListWrapper.offsetHeight + 20)
    }

    public scrollToBottom(): void {
        const wasScrolledUp = this.isScrolledUp()

        if (isiOS()) {
            stopScrollMomentum(this.messageListWrapper)
        }
        this.messageListWrapper.scrollTop = this.messageListWrapper.scrollHeight
        this.scrollDownButton?.hideElement()

        if (wasScrolledUp) {
            this.scrolledToBottom.fire()
        }
    }

    public debouncedScrollToBottom(): void {
        this.scrollToBottomDebouncer.callFunc()
    }

    public getScrollTop(): number {
        return this.messageListWrapper.scrollTop
    }

    public setScrollTop(top: number): void {
        this.messageListWrapper.scrollTo({ top: top })
    }

    private undebouncedOnScrollChange(): void {
        if (this.isScrolledUp()) {
            this.scrollDownButton?.showElement()
        } else {
            this.scrollDownButton?.hideElement()
            this.clearScrollButtonUnread()
        }
    }

    public appendNoticeDiv(c: HTMLDivElement): HTMLDivElement {
        this.noticeList.appendChild(c)
        return c
    }

    private toBottom: EventHandler = () => { this.scrollToBottom() }

    // eslint-disable-next-line complexity
    public appendMessageDiv(c: HTMLDivElement, countsForUnread = true, isFirstHistoryUnread = false): HTMLDivElement {
        const oldScrollTop = this.messageListWrapper.scrollTop
        const wasScrolledUp = this.isScrolledUp()
        if (!wasScrolledUp) {
            c.querySelectorAll("img").forEach((img) => {
                const src = img.src
                img.src = ""
                img.onload = this.toBottom
                img.src = src
            })
        }
        if (countsForUnread && (wasScrolledUp || !this.isConversationShowing())) {
            this.scrollDownButton?.incUnread()
        }

        if (this.newMessageNotice?.shouldAppendNewMessageNotice(countsForUnread, isFirstHistoryUnread) === true) {
            insertByTimestamp(this.newMessageNotice.element, this.messageList, this.isPmChatContents)
        }

        insertByTimestamp(c, this.messageList, this.isPmChatContents)

        // When cleaning out flag, also remove eslint-disable-next-line complexity from func
        if (isChatScrollingPageactionsActive() && this.messageList.childElementCount % 100 === 0 && !this.isPmChatContents) {
            const dossier = roomDossierContext.getState()
            addPageAction("ChatHistoryReached", {
                n: this.messageList.childElementCount,
                is_mod: dossier.isModerator,
                is_broadcaster: dossier.userName === dossier.room,
            })
        }

        let overflow = this.messageList.childElementCount - maxMessageHistory(this.isPmChatContents)
        for (; overflow > 0 ; overflow -= 1) {
            const firstNode = this.messageList.firstElementChild
            if (firstNode !== null) {
                this.messageList.removeChild(firstNode)
            }
        }
        if (!wasScrolledUp) {
            this.scrollToBottom()
        } else {
            this.newMessageNotice?.maybeScrollJump(oldScrollTop)
        }
        this.addMessageHTMLEvent.fire({
            makeByCloning: () => {
                // we know it's a DIV because we just created it
                return c.cloneNode(true) as HTMLDivElement
            },
        })
        return c
    }

    public clearScrollButtonUnread(): void {
        this.scrollDownButton?.clearUnread()
    }

    public showElement(): void {
        super.showElement("flex")
    }

    public getEarliestMessageId(): string | undefined {
        return this.earliestMessageId
    }

    public setEarliestMessageId(newMessageId: string): void {
        this.earliestMessageId = newMessageId
    }

    public clear(): void {
        while (this.messageList.firstChild !== null) {
            this.messageList.removeChild(this.messageList.firstChild)
        }
    }

    public focusCurrentChatInput(): void {
        this.customInputField.focus()
    }

    public blurCurrentChatInput(): void {
        this.customInputField.blur()
    }

    public removeMessagesForUser(c: string): void {
        this.removeMessagesForUserEvent.fire(c)
    }

    public isInputFocused(): boolean {
        return document.activeElement === this.customInputField.element
    }

    public getInputText(): string {
        return this.customInputField.getText()
    }

    public setInputText(text: string): void {
        this.customInputField.setText(text)
    }

    public appendInputText(text: string): void {
        this.customInputField.appendText(text)
    }
}
