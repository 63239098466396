// IRoomNotice contains messageParts and usernameParts.
// To construct a message from this, use this formula:
//     messageParts[0] + format(usernameParts[0]) + messageParts[1] ...
//
// If a notice starts with a username, format like this:
//      messageParts = ["", " has entered the room"]
//      usernameParts = [{username: "foo", ...},]
export var PartType;
(function (PartType) {
    PartType[PartType["user"] = 0] = "user";
    PartType[PartType["message"] = 1] = "message";
    PartType[PartType["hashtag"] = 2] = "hashtag";
    PartType[PartType["spyPrivateShow"] = 3] = "spyPrivateShow";
    PartType[PartType["acceptPrivateShow"] = 4] = "acceptPrivateShow";
})(PartType || (PartType = {}));
export var RoomNoticeType;
(function (RoomNoticeType) {
    RoomNoticeType["App"] = "App";
    RoomNoticeType["RoomLeave"] = "RoomLeave";
})(RoomNoticeType || (RoomNoticeType = {}));
export var OutgoingMessageType;
(function (OutgoingMessageType) {
    OutgoingMessageType[OutgoingMessageType["ChatMessage"] = 0] = "ChatMessage";
    OutgoingMessageType[OutgoingMessageType["TipRequest"] = 1] = "TipRequest";
    OutgoingMessageType[OutgoingMessageType["ToggleDebugMode"] = 2] = "ToggleDebugMode";
    OutgoingMessageType[OutgoingMessageType["Shortcode"] = 3] = "Shortcode";
    OutgoingMessageType[OutgoingMessageType["Invalid"] = 0] = "Invalid";
})(OutgoingMessageType || (OutgoingMessageType = {}));
export var MessagePartType;
(function (MessagePartType) {
    MessagePartType[MessagePartType["string"] = 0] = "string";
    MessagePartType[MessagePartType["emoticon"] = 1] = "emoticon";
    MessagePartType[MessagePartType["shortcode"] = 2] = "shortcode";
})(MessagePartType || (MessagePartType = {}));
export var PrivateMessageSource;
(function (PrivateMessageSource) {
    PrivateMessageSource["RoomViewPM"] = "roomviewPM";
    PrivateMessageSource["MobilePM"] = "mobilePM";
    PrivateMessageSource["Mobile"] = "mobile";
    PrivateMessageSource["DM"] = "DM";
})(PrivateMessageSource || (PrivateMessageSource = {}));
