import { CbColor, ColorSpace, LinearGradient } from "@multimediallc/web-utils/colors"
import { colorModeChangeRequest } from "../fullvideolib/userActionEvents"
import type { IRoomMessage, IRoomNotice } from "../messageInterfaces"

colorModeChangeRequest.listen((mode) => {
    const toAdjust = document.querySelectorAll(".dm-adjust")
    const isToDarkMode = mode === "darkmode"
    for (const outerDiv of toAdjust) {
        swapColors(outerDiv, isToDarkMode)
    }
})

const darkmodeChatBackground = "#202C39"

// eslint-disable-next-line complexity
export function swapColors(outerDiv: Element, isToDarkMode: boolean): void {
    if (outerDiv.getAttribute("type") === "photo") {
        return
    }
    const msg = outerDiv.firstElementChild as HTMLDivElement | null
    if (msg !== null) {
        const isRoomNotice = msg.classList.contains("roomNotice")
        if (isRoomNotice && !(msg.textContent ?? "").startsWith("Notice:")) {
            return
        }
        const isChatursafeFlaggedMsg = outerDiv.classList.contains("chtrsf-flagged-chat")
        if (!isChatursafeFlaggedMsg) {
            // Do not add darkmode background for chatursafe flagged messages
            const bg = isToDarkMode ? msg.getAttribute("dm-adjust-bg") ?? darkmodeChatBackground : msg.getAttribute("dm-init-bg") ?? "white"
            if (bg.startsWith("linear-gradent")) {
                msg.style.backgroundImage = bg
            } else {
                msg.style.background = bg
            }
        }

        const fg = isToDarkMode ? msg.getAttribute("dm-adjust-fg") : msg.getAttribute("dm-init-fg")
        const fgElement = isRoomNotice ? msg : msg.querySelector("span.msg-text")
        if (fg !== null && fgElement !== null) {
            (fgElement as HTMLElement).style.color = fg
        }
    }
}

CbColor.setColorSpace(ColorSpace.YPbPr)
const bgLightness = CbColor.darkModeBg.lightness
const fgLightness = CbColor.darkModeFg.lightness

export class DarkModeHandler {
    public background?: string
    public foreground?: string
    public bgDark?: string
    public fgDark?: string

    private constructor(msg: { fg?: string, bg?: string }) {
        this.background = msg.bg
        this.foreground = msg.fg
        this.calc()
    }

    private calc(): void {
        const gradient = LinearGradient.parse(this.background)
        const fg = CbColor.get(this.foreground ?? CbColor.darkModeFg).setAlpha(1)
        this.fgDark = fg.setLightness(fgLightness).setAlpha(1).toRgbString()

        if (gradient !== undefined) {
            gradient.stops.forEach(stop => {
                stop.color = stop.color.onBackground(CbColor.darkModeBg).setLightness(bgLightness)
            })
            this.bgDark = gradient.toString()
        } else {
            const bg = CbColor.get(this.background)
            if (!bg.isValid || bg.isWhite || bg.isTransparent || bg.isBlack) {
                this.bgDark = darkmodeChatBackground
                return
            }
            this.bgDark = bg.setLightness(bgLightness).toRgbString()
        }
    }

    public static parseNotice(msg: IRoomNotice): DarkModeHandler {
        return new DarkModeHandler({ fg: msg.foreground, bg: msg.background })
    }

    public static parseMessage(msg: IRoomMessage): DarkModeHandler {
        return new DarkModeHandler({ fg: msg.textColor, bg: msg.backgroundColor })
    }
}
