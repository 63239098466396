import { isMobileDevice } from "@multimediallc/web-utils/modernizr"
import { setCookieWithExpiration } from "@multimediallc/web-utils/storage"
import { addColorClass } from "../cb/colorClasses"
import { Toggle } from "../cb/components/toggle"
import { currentSiteSettings } from "../cb/siteSettings"
import { buildTooltip } from "../cb/ui/tooltip"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { modalAlert } from "./alerts"
import { postCb } from "./api"
import { isAnonymous, isNotLoggedIn } from "./auth"
import { ChatSettingsStyles } from "./chatSettingsStyles"
import { roomLoaded } from "./context"
import { Component } from "./defui/component"
import { DivotPosition } from "./divot"
import { getFixedOffset, hoverEvent } from "./DOMutils"
import { EventRouter } from "./events"
import { isLanguageSubdomainActive } from "./featureFlagUtil"
import { scrollFix } from "./mobilelib/scrollFix"
import { addPageAction, NRSetTipVolume } from "./newrelic"
import { LLHLSSupported, mobileLLHLS } from "./player/utils"
import { convertEnterLeaveSettings, EnterLeaveSettings } from "./roomDossier"
import { Slider } from "./slider"
import { ColorPickerModal } from "./theatermodelib/colorPickerModal"
import {
    DesktopRemoveIgnoredUsersModal,
    TabletRemoveIgnoredUsersModal,
} from "./theatermodelib/removeIgnoredUsersModal"
import { userChatSettingsUpdate } from "./theatermodelib/userActionEvents"
import { i18n } from "./translation"
import type { XhrError } from "./api"
import type { IRoomContext } from "./context"
import type { IUserChatSettings } from "./roomDossier"
import type { RemoveIgnoredUsersModal } from "./theatermodelib/removeIgnoredUsersModal"
import type { IChoices } from "../cb/ui/fields"

export const getChatMode = new EventRouter<string>("getChatMode")

export class ChatSettings extends Component<HTMLDivElement> {
    protected roomName: string
    private isModerator: boolean
    private isSupporter: boolean
    protected userChatSettings: IUserChatSettings
    protected notificationsSettings: HTMLDivElement
    protected otherSettings: HTMLDivElement
    protected form: HTMLFormElement
    protected usersSettings: HTMLDivElement
    private chatColorInput: HTMLInputElement
    protected chatColorSwatch: HTMLSpanElement
    protected fontSettingsHeader: HTMLDivElement
    private fontFamilySelect: HTMLSelectElement
    private fontSizeSelect: HTMLSelectElement
    private emoticonAutocompleteDelaySelect: HTMLSelectElement
    private showEmoticonsCheckbox: Toggle
    private highestTokenColorSelect: HTMLSelectElement
    private notifyEntryForSelect: HTMLSelectElement
    private notifyLeaveForSelect: HTMLSelectElement
    private tipVolumeValueLabel: HTMLLabelElement
    private tipVolumeSlider: Slider
    private collapseNoticesCheckbox: Toggle
    public manageIgnoredUsersLink: HTMLLabelElement
    private colorPickerModal: ColorPickerModal | undefined
    public removeIgnoredUserUI: RemoveIgnoredUsersModal | undefined

    public chatSettingsSavedRequest = new EventRouter<undefined>("chatSettingsSavedRequest")

    protected initData(): void {
        let toggleHeight = 30
        let toggleWidth = 50
        if (isMobileDevice() && LLHLSSupported() && mobileLLHLS()) {
            toggleHeight = 24
            toggleWidth = 48
        }
        this.isModerator = false
        this.isSupporter = false
        this.notificationsSettings = document.createElement("div")
        this.notificationsSettings.dataset.testid = "notifications-settings"
        this.otherSettings = document.createElement("div")
        this.otherSettings.dataset.testid = "other-settings"
        this.form = document.createElement("form")
        this.usersSettings = document.createElement("div")
        this.usersSettings.dataset.testid = "users-settings"
        this.chatColorInput = document.createElement("input")
        this.chatColorInput.dataset.testid = "chat-color-input"
        this.chatColorSwatch = document.createElement("span")
        this.chatColorSwatch.dataset.testid = "chat-color-swatch"
        this.fontFamilySelect = document.createElement("select")
        this.fontFamilySelect.dataset.testid = "font-family-select"
        this.fontSizeSelect = document.createElement("select")
        this.fontSizeSelect.dataset.testid = "font-size-select"
        this.emoticonAutocompleteDelaySelect = document.createElement("select")
        this.emoticonAutocompleteDelaySelect.dataset.testid = "emoticon-autocomplete-delay-select"
        this.showEmoticonsCheckbox = new Toggle(false, () => this.saveSettings(), { width: toggleWidth, height: toggleHeight })
        this.showEmoticonsCheckbox.element.dataset.testid = "show-emoticons-checkbox"
        this.highestTokenColorSelect = document.createElement("select")
        this.highestTokenColorSelect.dataset.testid = "highest-token-color-select"
        this.notifyEntryForSelect = document.createElement("select")
        this.notifyEntryForSelect.dataset.testid = "notify-entry-for-select"
        this.notifyLeaveForSelect = document.createElement("select")
        this.notifyLeaveForSelect.dataset.testid = "notify-leave-for-select"
        this.tipVolumeValueLabel = document.createElement("label")
        this.tipVolumeValueLabel.dataset.testid = "tip-volume-value-label"
        this.tipVolumeSlider = new Slider({
            handleDiameter: 16,
            handleColor: "rgb(246, 115, 0)",
            emptyBarColor: "#cccccc",
            filledBarColor: "rgb(246, 115, 0)",
        })
        this.tipVolumeSlider.element.dataset.testid = "tip-volume-slider"
        this.collapseNoticesCheckbox = new Toggle(false, () => this.saveSettings(), { width: toggleWidth, height: toggleHeight })
        this.collapseNoticesCheckbox.element.dataset.testid = "collapse-notices-checkbox"
        this.manageIgnoredUsersLink = document.createElement("label")
        this.manageIgnoredUsersLink.dataset.testid = "manage-ignored-users-link"
    }

    protected initUI(): void {
        this.element.style.width = "100%"
        this.element.style.overflow = "auto"
        this.element.style.boxSizing = "border-box"
        this.element.style.padding = "11px"
        this.element.style.fontSize = "12px"
        this.element.style.position = "static"

        this.form.style.marginBottom = "40px"
        this.form.style.maxWidth = "400px"
        this.element.appendChild(this.form)

        const fontSettings = document.createElement("div")
        fontSettings.dataset.testid = "font-settings"
        const emoticonsSettings = document.createElement("div")
        emoticonsSettings.dataset.testid = "emoticons-settings"

        this.form.appendChild(fontSettings)
        this.form.appendChild(emoticonsSettings)
        this.form.appendChild(this.usersSettings)
        this.form.appendChild(this.notificationsSettings)
        this.form.appendChild(this.otherSettings)

        this.getStyles().styleContainer(fontSettings)
        this.getStyles().styleContainer(emoticonsSettings)
        this.getStyles().styleContainer(this.usersSettings)
        this.getStyles().styleContainer(this.notificationsSettings)
        this.getStyles().styleContainer(this.otherSettings)

        this.fontSettingsHeader = this.createHeader(i18n.fontSettings)
        this.fontSettingsHeader.style.marginTop = "0"
        fontSettings.appendChild(this.fontSettingsHeader)
        fontSettings.appendChild(this.createColorPicker())
        fontSettings.appendChild(this.createFontFamilyPicker())
        fontSettings.appendChild(this.createFontSizePicker())

        emoticonsSettings.appendChild(this.createHeader(i18n.emoticonsSettings))
        emoticonsSettings.appendChild(this.createShowEmoticonsPicker())
        emoticonsSettings.appendChild(this.createEmoticonAutocompleteDelayPicker())

        this.usersSettings.appendChild(this.createHeader(i18n.usersSettings))
        this.createUserSettings()

        this.createNotificationSettings()

        this.otherSettings.appendChild(this.createHeader(i18n.otherSettings))
        this.createOtherSettings()

        scrollFix(this.element)

        userChatSettingsUpdate.listen(chatSettings => {
            this.updateChatSettings(chatSettings)
        })

        roomLoaded.listen((context) => {
            this.handleRoomLoaded(context)
            context.chatConnection.event.modStatusChange.listen((isMod: boolean) => {
                this.isModerator = isMod
                this.updateNotificationsSettingsVisibility()
            })
        })
    }

    public hideModals(): void {
        if (this.colorPickerModal !== undefined) {
            this.colorPickerModal.hide()
        }
        if (this.removeIgnoredUserUI !== undefined) {
            this.removeIgnoredUserUI.hide()
        }
    }

    private updateChatColor(color: string): void {
        this.chatColorInput.value = color
        const swatchColor = color !== "" ? color: "#000000"
        this.chatColorSwatch.style.color = swatchColor
        this.chatColorSwatch.style.backgroundColor = swatchColor
    }

    private openColorSwatch(event: Event): void {
        if (!this.isBroadcaster()) {
            if (isNotLoggedIn(i18n.mustBeLoggedInForChatColor)) {
                event.preventDefault()
                return
            }
            if (!this.isSupporter) {
                modalAlert(i18n.mustBeSupporterForChatColor)
                event.preventDefault()
                return
            }
        }
        if (this.colorPickerModal === undefined) {
            this.colorPickerModal = this.addChild(this.createColorPickerModal())
        }
        this.colorPickerModal.show(this.chatColorInput.value !== "" ? this.chatColorInput.value : "#000000")
    }

    protected onColorPicked(color: string): void {
        this.updateChatColor(color)
        this.saveSettings()
    }

    protected onColorHovered(color: string): void {
        this.updateChatColor(color)
    }

    protected createColorPickerModal(): ColorPickerModal {
        return new ColorPickerModal(this.chatColorSwatch, (color: string) => this.onColorPicked(color), (color: string) => this.onColorHovered(color))
    }

    protected createLabelWithTooltip(label: HTMLLabelElement, i18nTooltipText: string): HTMLDivElement {
        const infoIconSpan = document.createElement("span")
        addColorClass(infoIconSpan, "tooltip-info-icon")
        const tooltip = buildTooltip({
            content: i18nTooltipText,
            hasHTML: false,
            divotPosition: DivotPosition.Bottom,
        })
        tooltip.style.position = "fixed"
        tooltip.style.display = "block"

        const labelDiv = document.createElement("div")
        labelDiv.style.display = "inline-block"
        labelDiv.appendChild(label)
        labelDiv.appendChild(infoIconSpan)
        hoverEvent(infoIconSpan, { handleTouch: true }).listen(isHover => {
            if (isHover) {
                // Attach tooltip to labelDiv instead of document.body since we need the tooltip in fullscreen mode as well.
                labelDiv.appendChild(tooltip)
            } else {
                tooltip.parentElement?.removeChild(tooltip)
            }
            const badgeBoundingRect = infoIconSpan.getBoundingClientRect()
            const fixedOffset = getFixedOffset()
            // TODO: tooltip.offsetHeight gives wrong value for the first load when screen is resized and tooltip icon moves vertically.
            // This gets fixed on subsequent hovers. Need to fix the issue for first load on offsetHeight change.
            tooltip.style.top = `${-fixedOffset.top + badgeBoundingRect.top - tooltip.offsetHeight - badgeBoundingRect.height / 5 - 5}px`
            tooltip.style.left = `${-fixedOffset.left + badgeBoundingRect.left + (badgeBoundingRect.width - 22)}px`
        })

        return labelDiv
    }

    protected createRow(label: HTMLLabelElement, labelText: string, input: HTMLElement, options?: IChoices[], tooltipText?: string): HTMLDivElement {
        const wrapper = document.createElement("div")
        this.getStyles().styleRowWrapper(wrapper)
        if (tooltipText !== undefined) {
            wrapper.appendChild(this.createLabelWithTooltip(label, tooltipText))
        } else {
            wrapper.appendChild(label)
        }
        wrapper.appendChild(input)
        if (options !== undefined && input instanceof HTMLSelectElement) {
            for (const optionData of options) {
                const option = document.createElement("option")
                option.innerText = optionData.label.toString()
                option.value = optionData.value.toString()
                input.appendChild(option)
            }
        }

        this.getStyles().styleLabel(label, labelText)
        this.getStyles().styleInput(input)

        wrapper.onclick = (event: Event) => {
            if (isNotLoggedIn(i18n.mustBeLoggedInForChatSettings)) {
                event.preventDefault()
                return
            }
            if (event.target !== input) {
                input.focus()
            }
        }
        input.onmousedown = (event: Event) => {
            if (isNotLoggedIn(i18n.mustBeLoggedInForChatSettings)) {
                event.preventDefault()
                return
            }
        }

        addEventListenerPoly("change", input, (event: Event) => {
            this.saveSettings()
        })

        return wrapper
    }

    private createColorPicker(): HTMLElement {
        const label = document.createElement("label")
        const colorPicker = this.createRow(label, i18n.chatColor, this.chatColorSwatch)
        colorPicker.dataset.testid = "chat-settings-color-picker"
        colorPicker.appendChild(this.chatColorInput)

        this.styleWrapperCustom(colorPicker)
        this.styleLabelCustom(label)
        this.styleColorSwatch(this.chatColorSwatch)

        this.chatColorSwatch.onmousedown = (ev: MouseEvent) => this.openColorSwatch(ev)
        colorPicker.onclick = (ev: MouseEvent) => this.openColorSwatch(ev)

        this.chatColorInput.type = "hidden"

        return colorPicker
    }

    private createFontFamilyPicker(): HTMLElement {
        const fontFamilyLabel = document.createElement("label")
        const fontFamilyPicker = this.createRow(fontFamilyLabel, i18n.font, this.fontFamilySelect, this.getFontOptions())
        fontFamilyPicker.dataset.testid = "chat-settings-font-family-picker"
        const mustBeSupporter = (event: Event) => {
            if (!this.isBroadcaster()) {
                if (isNotLoggedIn(i18n.mustBeLoggedInForFontFamily)) {
                    event.preventDefault()
                    return
                }
                if (!this.isSupporter) {
                    event.preventDefault()
                    this.fontFamilySelect.blur()
                    modalAlert(i18n.mustBeSupporterForFontFamily)
                    return
                }
            }
        }
        this.fontFamilySelect.onmousedown = (ev: MouseEvent) => mustBeSupporter(ev)
        // Add supporter checks to existing click event handler of createRow
        addEventListenerPoly("click", fontFamilyPicker, (ev: MouseEvent) => {
            mustBeSupporter(ev)
        })

        return fontFamilyPicker
    }

    private createFontSizePicker(): HTMLElement {
        const fontSizeOptions: IChoices[] = []
        for (let i = 9; i <= 20; i += 1) {
            fontSizeOptions.push({
                label: `${i}pt`,
                value: `${i}pt`,
            })
        }
        const fontSizeLabel = document.createElement("label")
        return this.createRow(fontSizeLabel, i18n.fontSize, this.fontSizeSelect, fontSizeOptions)
    }

    private createShowEmoticonsPicker(): HTMLElement {
        const showEmoticonsLabel = document.createElement("label")
        const showEmoticonsPicker = this.createRow(showEmoticonsLabel, i18n.showEmoticons, this.showEmoticonsCheckbox.element)

        this.styleWrapperCustom(showEmoticonsPicker)
        this.styleLabelCustom(showEmoticonsLabel)
        this.styleCheckbox(this.showEmoticonsCheckbox.element)

        // Toggle checkbox on click of the entire div
        addEventListenerPoly("click", showEmoticonsPicker, (ev: MouseEvent) => {
            ev.preventDefault()
            ev.stopPropagation()
            // Need to manually set focus and toggle value manually to avoid calling the onclick-onchange event chain of Toggle
            this.showEmoticonsCheckbox.focus()
            this.showEmoticonsCheckbox.setChecked(!this.showEmoticonsCheckbox.isChecked())
        }, true)

        return showEmoticonsPicker
    }

    private createEmoticonAutocompleteDelayPicker(): HTMLElement {
        const emoticonAutocompleteDelayLabel = document.createElement("label")
        return this.createRow(
            emoticonAutocompleteDelayLabel,
            i18n.emoticonAutoCompleteDelay,
            this.emoticonAutocompleteDelaySelect,
            this.getEmoticonAutocompleteDelayOptions(),
        )
    }

    protected createHighestTokenColorPicker(): HTMLElement {
        const highestTokenColorLabel = document.createElement("label")
        return this.createRow(
            highestTokenColorLabel,
            i18n.highestTokenColor,
            this.highestTokenColorSelect,
            this.getHighestTokenColorOptions(),
            i18n.highestTokenColorTooltip,
        )
    }

    private createNotifyEntryPicker(): HTMLElement {
        const notifyEntryForLabel = document.createElement("label")
        return this.createRow(
            notifyEntryForLabel,
            i18n.notifyEntryFor,
            this.notifyEntryForSelect,
            this.getNotifyEntryLeaveOptions(),
        )
    }

    private createNotifyLeavePicker(): HTMLElement {
        const notifyLeaveForLabel = document.createElement("label")
        return this.createRow(
            notifyLeaveForLabel,
            i18n.notifyLeaveFor,
            this.notifyLeaveForSelect,
            this.getNotifyEntryLeaveOptions(),
        )
    }

    protected createTipVolumePicker(): HTMLElement {
        let chatMode = ""
        getChatMode.listen((mode: string) => {
            chatMode = mode
        })

        const tipVolumePicker = this.createRow(this.tipVolumeValueLabel, i18n.tipVolume, this.tipVolumeSlider.element)
        this.styleSlider(this.tipVolumeSlider)
        this.handleSliderMove(this.tipVolumeSlider.getValue())

        const handleSliderBegin = (sliderValue: number) => {
            this.handleSliderMove(sliderValue)
        }
        const handleSliderEnd = (sliderValue: number) => {
            this.handleSliderMove(sliderValue)
            addPageAction("TipVolumeChanged", { "tipvol": sliderValue, "chatmode": chatMode })
            this.saveSettingsOrCookie()
        }
        this.tipVolumeSlider.valueChangeStart.listen(handleSliderBegin)
        this.tipVolumeSlider.valueChanged.listen((sliderValue: number) => this.handleSliderMove(sliderValue))
        this.tipVolumeSlider.valueChangeEnd.listen(handleSliderEnd)

        // Overwrite click handlers to do nothing. The slider class handles its own events
        tipVolumePicker.onclick = () => {}
        this.tipVolumeSlider.element.onmousedown = () => {}

        return tipVolumePicker
    }

    private handleSliderMove(value: number): void {
        this.tipVolumeValueLabel.innerText = `${i18n.tipVolume} (${Math.round(value)}%)`
    }

    protected createCollapseNoticesPicker(): HTMLElement {
        const collapseNoticesLabel = document.createElement("label")
        const collapseNoticesPicker = this.createRow(collapseNoticesLabel, i18n.collapseNotices, this.collapseNoticesCheckbox.element)

        this.styleWrapperCustom(collapseNoticesPicker)
        this.styleLabelCustom(collapseNoticesLabel)
        this.styleCheckbox(this.collapseNoticesCheckbox.element)

        // Toggle checkbox on click of the entire div
        addEventListenerPoly("click", collapseNoticesPicker, (ev: MouseEvent) => {
            ev.preventDefault()
            ev.stopPropagation()
            // Need to manually set focus and toggle value manually to avoid calling the onclick-onchange event chain of Toggle
            this.collapseNoticesCheckbox.focus()
            this.collapseNoticesCheckbox.setChecked(!this.collapseNoticesCheckbox.isChecked())
        }, true)

        this.collapseNoticesCheckbox.setOnChange(() => {
            addPageAction("CollapseNoticesToggled", { "state": this.collapseNoticesCheckbox.isChecked() ? "on" : "off" })
            this.saveSettings()
        })

        return collapseNoticesPicker
    }

    protected createManageIgnoredUsersPicker(): HTMLElement {
        const link = this.createSettingsLink(this.manageIgnoredUsersLink, i18n.viewEditIgnoredUsers)
        link.appendChild(this.manageIgnoredUsersLink)
        this.manageIgnoredUsersLink.onclick = () => {
            if (isNotLoggedIn(i18n.mustBeLoggedInForIgnoredUsers)) {
                return
            }
            if (this.removeIgnoredUserUI === undefined) {
                this.createManageIgnoredUsersUI()
            }
            if (this.removeIgnoredUserUI !== undefined) {
                this.removeIgnoredUserUI.initAndShow()
            }
        }
        return link
    }

    protected createSettingsLink(link: HTMLElement, text: string): HTMLDivElement {
        const wrapper = document.createElement("div")
        this.getStyles().styleRowWrapper(wrapper)

        addColorClass(link, "link")
        link.innerText = text
        link.style.marginTop = "16px"
        link.style.cursor = "pointer"
        link.onmouseenter = () => {
            link.style.textDecoration = "underline"
        }
        link.onmouseleave = () => {
            link.style.textDecoration = "none"
        }

        return wrapper
    }

    protected createManageIgnoredUsersUI(): void {
        if (this.removeIgnoredUserUI === undefined) {
            this.removeIgnoredUserUI = isMobileDevice() ? new TabletRemoveIgnoredUsersModal() : new DesktopRemoveIgnoredUsersModal()
        }
    }

    private createHeader(title: string): HTMLDivElement {
        const header = document.createElement("div")
        this.getStyles().styleHeader(header, title)

        return header
    }

    private saveSettingsOrCookie(): void {
        const newTipVol = Math.round(this.tipVolumeSlider.getValue())
        if (isAnonymous()) {
            setCookieWithExpiration("tipvol", `${newTipVol}`, { days: 60 }, "/", isLanguageSubdomainActive())
            this.userChatSettings.tipVolume = newTipVol
            userChatSettingsUpdate.fire(this.userChatSettings)
        } else {
            this.saveSettings()
        }
    }

    protected saveSettings(): void {
        if (isNotLoggedIn(i18n.mustBeLoggedInForChatSettings)) {
            userChatSettingsUpdate.fire(this.userChatSettings) // reverts any changes
            return
        }

        if (
            this.chatColorInput.value !== this.userChatSettings.fontColor &&
            (this.isBroadcaster() || this.isSupporter)
        ) {
            postCb(this.getSaveColorEndpoint(), { "color": this.chatColorInput.value }).catch((err: XhrError) => {
                this.chatColorInput.value = this.userChatSettings.fontColor
                error("Error saving user chat color", err)
            })
        }

        postCb(this.getSaveEndpoint(), this.getFormData()).then((xhr: XMLHttpRequest) => {
            this.chatSettingsSavedRequest.fire(undefined)
            this.setUserChatSettings()
            userChatSettingsUpdate.fire(this.userChatSettings)
        }).catch((err: XhrError) => {
            error("Error saving user chat settings", err)
        })
    }

    protected setUserChatSettings(): void {
        if (this.hasModeratorPrivileges()) {
            this.userChatSettings.roomEntryFor = convertEnterLeaveSettings(this.notifyEntryForSelect.value)
            this.userChatSettings.roomLeaveFor = convertEnterLeaveSettings(this.notifyLeaveForSelect.value)
        }
        this.userChatSettings.fontColor = this.chatColorInput.value
        this.userChatSettings.fontFamily = decodeURIComponent(this.fontFamilySelect.value)
        this.userChatSettings.fontSize = this.fontSizeSelect.value
        this.userChatSettings.showEmoticons = this.showEmoticonsCheckbox.isChecked()
        this.userChatSettings.emoticonAutocompleteDelay = parseInt(this.emoticonAutocompleteDelaySelect.value)
        this.userChatSettings.highestTokenColor = this.highestTokenColorSelect.value
        this.userChatSettings.tipVolume = Math.round(this.tipVolumeSlider.getValue())
        this.userChatSettings.collapseNotices = this.collapseNoticesCheckbox.isChecked()
    }

    protected getFormData(): Record<string, string> {
        const formData: Record<string, string> = {
            "color": this.chatColorInput.value,
            "font_family": decodeURIComponent(this.fontFamilySelect.value),
            "font_size": this.fontSizeSelect.value,
            "show_emoticons": `${this.showEmoticonsCheckbox.isChecked()}`,
            "emoticon_autocomplete_delay": this.emoticonAutocompleteDelaySelect.value,
            "highest_token_color": this.highestTokenColorSelect.value,
            "tip_volume": `${Math.round(this.tipVolumeSlider.getValue())}`,
            "collapse_notices": `${this.collapseNoticesCheckbox.isChecked()}`,
        }
        if (this.hasModeratorPrivileges()) {
            formData["enter_notify"] = this.notifyEntryForSelect.value
            formData["leave_notify"] = this.notifyLeaveForSelect.value
        }
        return formData
    }

    protected getSaveColorEndpoint(): string {
        return "choose_viewer_chat_color/"
    }

    protected getSaveEndpoint(): string {
        return `api/viewerchatsettings/${this.roomName}/`
    }

    protected hasModeratorPrivileges(): boolean {
        return this.isModerator
    }

    protected styleWrapperCustom(wrapper: HTMLDivElement): void {}

    protected styleLabelCustom(label: HTMLLabelElement): void {}

    protected styleColorSwatch(swatch: HTMLSpanElement): void {
        addColorClass(this.chatColorSwatch, "colorSwatch")
        this.chatColorSwatch.style.width = "20px"
        this.chatColorSwatch.style.height = "20px"
        this.chatColorSwatch.style.border = `1px solid ${currentSiteSettings.defaultColor}`
        this.chatColorSwatch.style.cursor = "pointer"
        this.chatColorSwatch.style.verticalAlign = "middle"
    }

    protected styleCheckbox(checkbox: HTMLDivElement): void {
        checkbox.style.display = "block"
        checkbox.style.marginBottom = "10px"
    }

    protected styleSlider(slider: Slider): void {
        this.tipVolumeSlider.element.style.display = "inline-block"
        this.tipVolumeSlider.element.style.border = "none"
        this.tipVolumeSlider.element.style.verticalAlign = "top"
        this.tipVolumeSlider.element.style.height = "16px"
        this.tipVolumeSlider.element.style.width = "100%"
    }

    protected repositionChildren(): void {
        this.tipVolumeSlider.handleResize()
    }

    public getTipVolumeSlider(): Slider {
        return this.tipVolumeSlider
    }

    protected getStyles(): typeof ChatSettingsStyles {
        return ChatSettingsStyles
    }

    protected createUserSettings(): void {
        this.usersSettings.appendChild(this.createHighestTokenColorPicker())
    }

    protected createNotificationSettings(): void {
        this.notificationsSettings.appendChild(this.createHeader(i18n.notificationsSettings))
        this.notificationsSettings.appendChild(this.createNotifyEntryPicker())
        this.notificationsSettings.appendChild(this.createNotifyLeavePicker())
    }

    protected createOtherSettings(): void {
        this.otherSettings.appendChild(this.createTipVolumePicker())
        this.otherSettings.appendChild(this.createCollapseNoticesPicker())
        this.otherSettings.appendChild(this.createManageIgnoredUsersPicker())
    }

    protected updateChatSettings(chatSettings: IUserChatSettings): void {
        this.userChatSettings = chatSettings

        this.updateChatColor(this.userChatSettings.fontColor)

        this.fontFamilySelect.value = this.userChatSettings.fontFamily
        this.fontSizeSelect.value = this.userChatSettings.fontSize
        this.showEmoticonsCheckbox.setCheckedDirectly(this.userChatSettings.showEmoticons)
        this.emoticonAutocompleteDelaySelect.value = this.userChatSettings.emoticonAutocompleteDelay.toString()
        this.highestTokenColorSelect.value = this.userChatSettings.highestTokenColor
        this.notifyEntryForSelect.value = convertEnterLeaveSettingsToString(this.userChatSettings.roomEntryFor)
        this.notifyLeaveForSelect.value = convertEnterLeaveSettingsToString(this.userChatSettings.roomLeaveFor)

        this.tipVolumeSlider.setValue(this.userChatSettings.tipVolume)
        this.handleSliderMove(this.userChatSettings.tipVolume)
        NRSetTipVolume(this.userChatSettings.tipVolume)
        this.collapseNoticesCheckbox.setCheckedDirectly(this.userChatSettings.collapseNotices)
    }

    protected isBroadcaster(): boolean {
        return false
    }

    protected handleRoomLoaded(context: IRoomContext): void {
        this.updateChatSettings(context.dossier.userChatSettings)

        this.roomName = context.dossier.room
        this.isModerator = context.dossier.isModerator
        this.isSupporter = context.dossier.isSupporter

        this.updateNotificationsSettingsVisibility()
    }

    protected updateNotificationsSettingsVisibility(): void {
        if (this.hasModeratorPrivileges()) {
            this.notificationsSettings.style.display = "block"
        } else {
            this.notificationsSettings.style.display = "none"
        }
    }

    protected getFontOptions(): IChoices[] {
        return [
            { label: "Default", value: "default" },
            { label: "Arial", value: "Arial, Helvetica" },
            { label: "Bookman Old Style", value: "Bookman Old Style" },
            { label: "Comic Sans", value: "\"Comic Sans MS\", cursive" },
            { label: "Courier", value: "\"Courier New\"" },
            { label: "Lucida", value: "Lucida" },
            { label: "Palantino", value: "Palantino" },
            { label: "Tahoma", value: "Tahoma, Geneva" },
            { label: "Times New Roman", value: "\"Times New Roman\"" },
        ]
    }
    protected getEmoticonAutocompleteDelayOptions(): IChoices[] {
        return [
            { label: i18n.emoticonAutocompleteDelayOff, value: "-1" },
            { label: i18n.emoticonAutocompleteNoDelay(), value: "0" },
            { label: i18n.emoticonAutocompleteShortDelay(), value: "500" },
            { label: i18n.emoticonAutocompleteMediumDelay(), value: "1000" },
            { label: i18n.emoticonAutocompleteLongDelay(), value: "1500" },
        ]
    }
    protected getHighestTokenColorOptions(): IChoices[] {
        return [
            { label: i18n.highestTokenColorDarkPurple(), value: "darkpurple" },
            { label: i18n.highestTokenColorLightPurple(), value: "lightpurple" },
            { label: i18n.highestTokenColorDarkBlue(), value: "darkblue" },
            { label: i18n.highestTokenColorLightBlue(), value: "lightblue" },
        ]
    }
    protected getNotifyEntryLeaveOptions(): IChoices[] {
        return [
            { label: i18n.notifyEntryLeaveAllUsers, value: "orga" },
            { label: i18n.notifyEntryLeaveModsFansUsersTokens, value: "orgb" },
            { label: i18n.notifyEntryLeaveModsFans, value: "org" },
            { label: i18n.notifyEntryLeaveNoUsers, value: "none" },
        ]
    }
}

function convertEnterLeaveSettingsToString(setting: EnterLeaveSettings): string {
    switch (setting) {
        case EnterLeaveSettings.AllUsers:
            return "orga"
        case EnterLeaveSettings.ModsFansAndTokens:
            return "orgb"
        case EnterLeaveSettings.ModsAndFans:
            return "org"
        case EnterLeaveSettings.None:
            return "none"
        default:
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
            error(`Invalid enter/leave setting: ${setting}`)
            return "orgb"
    }
}
