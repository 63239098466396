import { ArgJSONMap } from "@multimediallc/web-utils"
import { addColorClass, colorClass } from "../cb/colorClasses"
import { AutocompleteModal } from "./autocompleteModal"
import { AutocompleteModalMobileMixin } from "./autocompleteModalMobileMixin"
import { roomLoaded } from "./context"
import { closeEmoticonPreview, emoticonPreviewClosed, openEmoticonPreview } from "./emoticonPreviewModal"
import { EventRouter, eventsPmSessionsCount } from "./events"
import { mobilePureChatChange } from "./mobilelib/mobilePureChat"
import { scrollFix } from "./mobilelib/scrollFix"
import { videoModeHandler } from "./videoModeHandler"
import type { IRoomContext } from "./context"
import type { ICustomInput } from "./customInput"

let ignoredEmoticons: string[]

export interface IEmoticon {
    slug: string
    url: string
    score?: number
}

interface IAutocompleteEmoticon extends IEmoticon {element: HTMLDivElement}

interface IEmoticonAutocompleteResponse {
    slug: string
    emoticons: IAutocompleteEmoticon[]
}

// Base limit + 1 per PM session
const hideEmoticons = new EventRouter<undefined>("hideEmoticons", { listenersWarningThreshold: () => 10 + eventsPmSessionsCount })

videoModeHandler.changeVideoMode.listen(() => {
    hideEmoticons.fire(undefined)
})

export class EmoticonAutocompleteModal extends AutocompleteModal<IAutocompleteEmoticon> {
    protected maxOptionLength = 36
    protected previewWrapper: HTMLDivElement
    protected previewImage: HTMLImageElement
    protected viewReportLink: HTMLDivElement

    protected initData(): void {
        super.initData()
        this.previewWrapper = document.createElement("div")
        this.previewImage = document.createElement("img")
        this.viewReportLink = document.createElement("div")
        this.xhrConfig = { withCredentials: false, noRequestedWith: true } // prevent CORS issues
    }

    protected initUI(): void {
        addColorClass(this.previewWrapper, "previewWrapper")
        this.previewWrapper.style.display = "none"
        this.previewWrapper.style.width = "260px"
        this.previewWrapper.style.height = "110px"
        this.previewWrapper.style.lineHeight = "110px"
        this.previewWrapper.style.borderBottomWidth = "1px"
        this.previewWrapper.style.borderBottomStyle = "solid"
        this.previewWrapper.style.textAlign = "center"
        this.previewWrapper.onclick = () => {
            if (this.selectedIndex !== undefined) {
                this.showPreview(this.items[this.selectedIndex])
            }
        }
        this.previewWrapper.dataset.testid = "emoticon-preview"
        this.element.appendChild(this.previewWrapper)
        super.initUI()

        this.previewImage.src = `${STATIC_URL}loading.png`
        this.previewImage.style.maxWidth = "240px"
        this.previewImage.style.maxHeight = "80px"
        this.previewImage.style.verticalAlign = "middle"
        this.previewImage.style.padding = "10px 10px 16px 10px"
        this.previewWrapper.appendChild(this.previewImage)

        addColorClass(this.viewReportLink, colorClass.hrefColor)
        this.viewReportLink.innerText = "VIEW / REPORT EMOTICON"
        this.viewReportLink.style.fontSize = "10px"
        this.viewReportLink.style.position = "relative"
        this.viewReportLink.style.bottom = "8px"
        this.viewReportLink.style.lineHeight = "0"
        this.viewReportLink.onmouseenter = () => {
            this.viewReportLink.style.textDecoration = "underline"
        }
        this.viewReportLink.onmouseleave = () => {
            this.viewReportLink.style.textDecoration = "none"
        }
        this.viewReportLink.dataset.testid = "view-report-link"
        this.previewWrapper.appendChild(this.viewReportLink)

        roomLoaded.listen((context: IRoomContext) => {
            ignoredEmoticons = context.dossier.ignoredEmoticons
        }).addTo(this.listenerGroup)

        hideEmoticons.listen(() => {
            if (this.overlay !== undefined) {
                this.hide()
            }
        }).addTo(this.listenerGroup)
    }

    protected promptRegex(): string {
        return ":"
    }

    protected searchSlugRegex(): string {
        return "[\\w-]+"
    }

    protected getDataEndpoint(): string {
        return `/api/ts/emoticons/autocomplete/?slug=${this.searchSlug}`
    }

    protected clearListDOM(): void {
        super.clearListDOM()
        this.previewWrapper.style.display = "none"
    }

    protected highlightSelectedIndex(shouldScroll: boolean): void {
        super.highlightSelectedIndex(shouldScroll)
        if (this.selectedIndex !== undefined) {
            this.previewImage.src = `${STATIC_URL}loading.png`
            this.previewImage.src = this.items[this.selectedIndex].url
            this.previewWrapper.style.display = "block"
        }
    }

    protected parseResponse(response: string): IAutocompleteEmoticon[] {
        const responseMap = new ArgJSONMap(response)
        const emoticonAutocompleteResponse:  IEmoticonAutocompleteResponse = {
            slug: responseMap.getString("slug"),
            emoticons: responseMap.getObject("emoticons") as IAutocompleteEmoticon[],
        }
        let emoticons = [] as IAutocompleteEmoticon[]
        for (const emoticon of emoticonAutocompleteResponse.emoticons) {
            emoticons.push({
                slug: emoticon["slug"],
                url: emoticon["url"],
                element: elementPlaceholder,
                score: emoticon["score"],
            })
        }
        emoticons = emoticons.map(val => {
            const valScore = val.score === undefined ? 0 : val.score

            if (val.slug === this.searchSlug) {
                val.score = valScore + 100000
            } else if (val.slug.indexOf(this.searchSlug) === 0) {
                val.score = valScore + 9000
            }
            return val
        })

        emoticons = emoticons.sort((e1, e2) => {
            const e1Score = e1.score === undefined ? 0 : e1.score
            const e2Score = e2.score === undefined ? 0 : e2.score

            return e1Score - e2Score
        })
        responseMap.logUnusedDebugging("parseEmoticonAutocompleteResponse")
        return emoticons
    }

    protected shouldIgnoreItem(item: IAutocompleteEmoticon): boolean {
        return ignoredEmoticons.indexOf(item.slug) >= 0
    }

    protected showPreview(emoticon: IEmoticon): void {
        openEmoticonPreview.fire(emoticon)
    }
}

export class MobileEmoticonAutocompleteModal extends EmoticonAutocompleteModal {
    private mobileMixin: AutocompleteModalMobileMixin

    constructor(inputElement: ICustomInput) {
        super({ inputElement: inputElement, leftOffset: 0, rightOffset: 0 })
        this.mobileMixin= new AutocompleteModalMobileMixin({
            autocompleteConfig: this.config,
            element: this.element,
            list: this.list,
            overlayClick: this.overlayClick,
            listenerGroup: this.listenerGroup,
            isVisible: () => this.visible,
            hide: () => this.hide(),
            pickItem: (index: number, fromArrowPress: boolean) => this.pickItem(index, fromArrowPress),
            hideOnSpace: true,
        })

        mobilePureChatChange.listen(() => {
            closeEmoticonPreview.fire(undefined)
        }).addTo(this.listenerGroup)
    }

    protected initUI(): void {
        super.initUI()
        this.element.style.border = ""
        this.element.style.fontSize = "14px"
        this.element.style.lineHeight = "26px"
        this.element.style.boxShadow = "0px 0px 8px rgba(0, 0, 0, 0.32)"
        this.element.style.marginLeft = "7px"
        this.element.style.boxSizing = "content-box"

        this.previewWrapper.style.width = ""

        this.list.style.width = ""
        scrollFix(this.list)
    }

    protected repositionChildren(): void {
        // pass, override super's implementation
    }

    protected showPreview(emoticon: IEmoticon): void {
        super.showPreview(emoticon)
        this.config.inputElement.blur()
        emoticonPreviewClosed.once(() => {
            if (this.isVisible()) {
                this.config.inputElement.focus()
                // Restart gif bc closing the preview stops it for some reason
                this.previewImage.src = this.previewImage.src
            }
        }, false)
    }

    protected appendItem(emoticon: IAutocompleteEmoticon, index: number): HTMLDivElement {
        const div = super.appendItem(emoticon, index)
        this.mobileMixin.customizeItem(div, index)
        return div
    }

    protected isSpecialFunctionKey(event: KeyboardEvent): boolean {
        const finalWhitespace = this.config.inputElement.getCurrentNodePreCaretText().match(/\s$/)
        return super.isSpecialFunctionKey(event) || this.visible && finalWhitespace !== null
    }

    protected show(): void {
        this.mobileMixin.onShow()
        super.show()
    }
}

const elementPlaceholder = document.createElement("div")
