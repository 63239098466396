import { isAnonymous } from "../../../common/auth"
import { applyStyles } from "../../../common/DOMutils"
import { DropDownComponent } from "../../../common/dropDownComponent"
import { featureFlagIsActive } from "../../../common/featureFlag"
import { ConversationListData } from "../pm/conversationListData"
import { DMUnreadData } from "../pm/dmUnreadData"
import { ReactComponentRegistry } from "../ReactRegistry"
import { messagingProps } from "./messagingUtil"
import type { ReactComponent } from "../ReactRegistry"

interface IDmMessagingDropdownProps {toggleElement: HTMLElement}

export class DmMessagingDropdown extends DropDownComponent {
    toggleElement: HTMLElement
    messaging?: ReactComponent
    unreadDmData: DMUnreadData
    conversationListData?: ConversationListData
    isRefactorDMFlagActive: boolean

    constructor(props: IDmMessagingDropdownProps) {
        super(props.toggleElement, true, props)

        this.unreadDmData = DMUnreadData.getInstance()
        if (this.isRefactorDMFlagActive) {
            DMUnreadData.unreadDataUpdated.listen(({ unread }) => {
                this.updateNumUnread(unread)
            })
        } else {
            ConversationListData.unreadConversationsCountUpdate.listen(({ dmsCount }) => {
                this.updateNumUnread(dmsCount)
            })
        }
    }

    protected createElement(props: object): HTMLDivElement {
        super.createElement(props)
        const Messaging = ReactComponentRegistry.get("Messaging")
        this.element = document.createElement("div")
        this.messaging = new Messaging({
            ...messagingProps,
            view: "desktop_inbox",
            onClose: () => { this.hideElement() },
        }, this.element)
        return this.element
    }

    protected initUI(props: IDmMessagingDropdownProps): void {
        super.initUI(props)

        const containerStyle: CSSX.Properties = {
            borderRadius: "7px",
            boxShadow: "0px 4px 10px 0px #0000001A",
            height: "520px",
            position: "absolute",
            width: "368px",
            display: "none",
            backgroundColor: "#fff",
            left: "-110px",
            top: "24px",
        }
        applyStyles(this.element, containerStyle)
    }

    protected initData(): void {
        this.isRefactorDMFlagActive = featureFlagIsActive("RefactorDm")
        if (this.isRefactorDMFlagActive && !isAnonymous()) {
            this.conversationListData = ConversationListData.getInstance()
        }
    }

    private updateNumUnread(numUnread: number): void {
        const hasUnread = numUnread > 0

        if (hasUnread) {
            this.toggleElement.classList.add("has_unread")
        } else {
            this.toggleElement.classList.remove("has_unread")
        }
    }


}
