import {
    isSpyPriceFiltersActive as isSpyPriceFiltersActivePrefetch,
    PageType,
    UrlState,
} from "@multimediallc/cb-roomlist-prefetch"
import { getCookieOrUndefined } from "@multimediallc/web-utils/storage"
import { roomDossierContext } from "../cb/interfaces/context"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { Debouncer, DebounceTypes } from "./debouncer"
import { featureFlagIsActive, performToggleIsActive } from "./featureFlag"
import { addPageAction } from "./newrelic"

export const isLinguiBasicActive = (): boolean => featureFlagIsActive("LinguiBasic")

export const isLinguiMigrateActive = (): boolean => featureFlagIsActive("LinguiMigrate")

export const isLanguageSubdomainActive = (): boolean => {
    const continuityCookie = getCookieOrUndefined("language_subdomain_continuity")
    return featureFlagIsActive("LanguageSubdomain") || continuityCookie === "1"
}

export const isPSRequestPMTabRedirectActive = (): boolean => featureFlagIsActive("PSRequestPMTabRedirect")

export const isScrollDownNoticeActive = (): boolean => featureFlagIsActive("ScrollDownNotice")

export const isPrivateSpyBadgesActive = (): boolean => performToggleIsActive("PvtSpyBdgs")

export const isRecommendedFollowRoomsActive = (): boolean => featureFlagIsActive("RcmFlwRm")

export const isReactUserListActive = (): boolean => featureFlagIsActive("ReactUserList2")

export const isSpyPriceFiltersActive = (): boolean => isSpyPriceFiltersActivePrefetch()

export const isChatScrollingPageactionsActive = (): boolean => featureFlagIsActive("ChatScrollData")

export const isReduceChatHistoryActive = (): boolean => featureFlagIsActive("ReduceChatHistory")

export const isMobileDmActive = (): boolean => performToggleIsActive("MobileDm")

export const isDesktopDmActive = (): boolean => performToggleIsActive("DesktopDm")

export const isChatursafeActive = (): boolean => performToggleIsActive("Chtrsf")

export const isSubjectSearchToggleActive = (): boolean => performToggleIsActive("SbjSrchSrt")

export const isFallbackRoomlistActive = (): boolean => performToggleIsActive("FlbkRmlst")

export const isMobilePushMenuRefactorActive = (): boolean =>
    UrlState.current.state.pageType === PageType.HOME || performToggleIsActive("MblPshRfctr")

// Util for isChatScrollingPageactionsActive. Remove when flag is removed
const maxHistoryPageactionTriggers = [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000]
export function addChatHistoryPageActionsScollListener(messageListWrapper: HTMLElement, messageList: HTMLElement): void {
    if (!isChatScrollingPageactionsActive()) {
        return
    }

    // The reason for using initial/min scroll on scrollend rather than continually monitoring current scrollTop and firing
    // pagactions as soon as it passes a trigger is that there exist ways for users to scroll super speedy fast (scrubbing
    // scrollbar with mouse) that only fire scroll events for some of the intermediate scroll positions, and thus will miss triggers
    let initialScrollTop: number | undefined
    let minScrollTop: number | undefined
    const scrollEndDebouncer = new Debouncer(() => {
        if (initialScrollTop === undefined || minScrollTop === undefined) {
            return
        }

        const availableTriggers = maxHistoryPageactionTriggers.filter(trigger => trigger <= messageList.children.length)
        const hitTriggers = availableTriggers.filter(trigger => {
            const triggerMessage = messageList.children[messageList.children.length - trigger] as HTMLElement
            return minScrollTop! <= triggerMessage.offsetTop && triggerMessage.offsetTop < initialScrollTop!
        })
        hitTriggers.forEach(trigger => {
            const dossier = roomDossierContext.getState()
            addPageAction("ChatScrolledPast", {
                n: trigger,
                is_mod: dossier.isModerator,
                is_broadcaster: dossier.userName === dossier.room,
            })
        })

        initialScrollTop = undefined
        minScrollTop = undefined
    }, { bounceLimitMS: 100, debounceType: DebounceTypes.debounce })

    let prevScrollTop: number | undefined
    let scrollingUp = false
    addEventListenerPoly("scroll", messageListWrapper, () => {
        const currentScrollTop = messageListWrapper.scrollTop
        if (prevScrollTop === undefined) {
            prevScrollTop = currentScrollTop
        }

        scrollingUp = currentScrollTop < prevScrollTop
        if (scrollingUp) {
            initialScrollTop = initialScrollTop ?? prevScrollTop
            minScrollTop = Math.min(minScrollTop ?? currentScrollTop, currentScrollTop)
            scrollEndDebouncer.callFunc()
        }

        prevScrollTop = currentScrollTop
    })
}

export const isEmptySearchResultsPageActive = (): boolean => featureFlagIsActive("EmptySrchRes")
