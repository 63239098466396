import { ArgJSONMap, PrivateRequestStatus, PrivateSubstatus, RoomStatus } from "@multimediallc/web-utils"
import { GameSelection } from "../../cb/components/games/gameSelection"
import { userPromotionPush } from "../../cb/components/promoteRoom/promoteLinkBase"
import { pageContext, roomDossierContext } from "../../cb/interfaces/context"
import { deepCompare } from "../../cb/pushservicelib/deepCompare"
import { PushService } from "../../cb/pushservicelib/pushService"
import { ConnectionState } from "../../cb/pushservicelib/states"
import { addBaseTopicFields } from "../../cb/pushservicelib/topics/base"
import { GlobalPushServiceBackendChangeTopic } from "../../cb/pushservicelib/topics/global"
import { RoomDarkBlueTopic, RoomDarkPurpleTopic, RoomFanClubTopic, RoomLightBlueTopic, RoomLightPurpleTopic, RoomModeratorNoticeTopic } from "../../cb/pushservicelib/topics/noticeGroups"
import { PrivateRoomEnterLeaveTopic, PrivateRoomMessageTopic, PrivateRoomTipAlertTopic , PrivateRoomUserPresenceTopic } from "../../cb/pushservicelib/topics/privateRoom"
import {
    GameUpdateTopic,
    LatencyUpdateTopic,
    PrivilegedSessionTopic,
    QualityUpdateTopic,
    RoomAnonPresenceTopic,
    RoomAppLogTopic,
    RoomEnterLeaveTopic,
    RoomFanClubJoinedTopic,
    RoomKickTopic,
    RoomMessageTopic,
    RoomModeratorPromotedTopic,
    RoomModeratorRevokedTopic,
    RoomNoticeTopic,
    RoomPasswordProtectedTopic,
    RoomPrivilegedEnterTopic,
    RoomPrivilegedLeaveTopic,
    RoomPurchaseTopic,
    RoomSettingsTopic,
    RoomShortcodeTopic,
    RoomSilenceTopic,
    RoomStatusTopic,
    RoomTipAlertTopic,
    RoomTitleChangeTopic,
    RoomUpdateTopic,
    RoomUserHiddenCamStatusTopic,
    RoomUserNoticeTopic,
    RoomUserPresenceTopic,
    RoomUserPrivateStatusTopic,
    ViewerPromotionTopic,
} from "../../cb/pushservicelib/topics/room"
import { BroadcasterWarningTopic, RoomBroadcasterPrivateStatusTopic, UserAlertTopic, UserColorUpdateTopic, UserTipAlertTopic, UserUpdateTopic } from "../../cb/pushservicelib/topics/user"
import { SubSystemType } from "../../common/debug"
import { postCb } from "../api"
import { isAnonymous } from "../auth"
import { roomLoaded } from "../context"
import { ListenerGroup } from "../events"
import { featureFlagIsActive } from "../featureFlag"
import { isChatursafeActive } from "../featureFlagUtil"
import { warningAlert } from "../logPresence"
import { HiddenShowStatuses, RoomUpdateType, UserUpdateType } from "../messageInterfaces"
import { addPageAction } from "../newrelic"
import { sendVideoMetric } from "../player/videoMetrics"
import { ignoreCatch } from "../promiseUtils"
import { i18n } from "../translation"
import {
    handleAppDebugError, handleAwayModeCancel, handleHiddenApprove, handleHiddenShowStatus, handleKick, handlePasswordChanged, handlePersonallyKicked,
    handlePrivateShowApprove, handlePrivateShowCancel, handlePrivateShowRequest, handlePromotion, handlePurchaseNotification, handleRevoke,
    handleRoomEnterLeave, handleRoomMessage, handleSettingsUpdate, handleSilence, handleTipAlert, handleTitleChange, openComplianceModal,
    sendPrivateShowFinishedNotice,
} from "./messagehandler"
import { stringPart, userPart } from "./roomnoticeparts"
import type { ChatConnection } from "./chatConnection"
import type { IBroadcasterPrivateStatus, IChatursafeFlagged, IPrivateShowStatus, IPushNotice, IPushTipAlert, IRoomAction, IRoomPasswordProtected, IUserInfo } from "../messageInterfaces"
import type { IRoomDossier, IUserColors } from "../roomDossier"

interface IHistoryTopic {
    topicKey: string,
    callback: (data: ArgJSONMap) => void,
    authData: object,
}

class PushServiceHistory {
    private topics = new Map<string, IHistoryTopic>()
    private historyLimit = 10
    private finished = false
    private failed = false
    private initial = true
    private listeners = new ListenerGroup()
    private historyCallback: (() => void)[] = []
    private handledMessages: string[] = []
    constructor(private chatConn: ChatConnection) {
    }

    public setupHistory(): void {
        if (!PushService.isEnabledForVerify()) {
            return
        }
        // sets up all history topics
        // when all topics are connected, checkIfReady will fire and fetch history
        this.tipHistory()
        this.purchaseHistory()
        this.messageHistory()
        this.shortcodeHistory()
        const failureReport = PushService.primaryClientFailure.listen((message) => {
            this.informHistoryFailure(message)
        }, false)
        this.listeners.add(failureReport)
        // in case topics already are subscribed, check in beginning too
        this.fetchHistoryIfReady()
    }

    // complete removal of history handling, to be re-setup on room load
    public dispose(): void {
        this.listeners.removeAll()
        this.finished = false
        this.failed = false
        this.initial = true
        this.handledMessages = []
        PushService.clearMessagesForHistory()
    }

    public push(callback: () => void): void {
        if (this.historyCallback.length > this.historyLimit) {
            this.historyCallback.pop()
        }
        this.historyCallback.push(callback)
    }

    public fireBuffer(): void {
        this.historyCallback.forEach(callback => {
            // check in case switch happens mid private show
            if (PushService.isEnabledForUI()) {
                callback()
            }
        })
        this.historyCallback = []
    }

    private fetchHistory(): Promise<void> {
        const auth: Record<string, object> = {}
        Array.from(this.topics.entries()).forEach(([key, meta]) => {
            auth[key] = meta.authData
        })
        return postCb("push_service/room_history/", { "topics": JSON.stringify(auth) }).then(res => {
            const data: object[] = JSON.parse(res.responseText)
            let count = 0

            data.forEach(item => {
                for (const [key, value] of Object.entries(item)) {
                    const topic = this.topics.get(key)
                    if (topic === undefined || count >= this.historyLimit) {
                        return
                    }
                    // adds base data to avoid parse errors
                    addBaseTopicFields(value)
                    const topicMsg = new ArgJSONMap(value)
                    count += 1
                    if (PushService.addExternalMessage(topic.topicKey, topicMsg.getString("tid"))) {
                        return
                    }
                    topic.callback(topicMsg)
                }
            })
        }).catch(ignoreCatch)
    }

    private tipHistory(): void {
        const { roomUid, viewerUid, privateShowId } = roomDossierContext.getState()

        const tipCallback = (topic: RoomTipAlertTopic | UserTipAlertTopic | PrivateRoomTipAlertTopic) => {
            return (data: ArgJSONMap) => {
                const parsed = topic.parseData(data)
                if(parsed.toUsername !== this.chatConn.room()) {
                    return
                }
                handleTipAlert(this.chatConn, parsed, true)
            }
        }

        const topics = []

        if (viewerUid === roomUid) {
            topics.push(new UserTipAlertTopic(viewerUid))
        } else {
            topics.push(new RoomTipAlertTopic(roomUid))
        }
        if (privateShowId !== "") {
            topics.push(new PrivateRoomTipAlertTopic(roomUid, privateShowId))
        }

        topics.forEach(t => {
            this.topics.set(t.getAuthKey(), {
                callback: tipCallback(t),
                authData: t.getAuthData(),
                topicKey: t.getKey(),
            })

            const listener = t.onSubscribeChange.listen(e => {
                if (e.subscribed) {
                    this.fetchHistoryIfReady()
                } else {
                    this.informHistoryFailure(`${t.getId()} topic failed`)
                }
            })
            this.listeners.add(listener)
        })
    }

    private purchaseHistory(): void {
        const roomUid = roomDossierContext.getState().roomUid
        const topics = [new RoomPurchaseTopic(roomUid), new RoomFanClubJoinedTopic(roomUid)]

        topics.forEach(t => {
            this.topics.set(t.getAuthKey(), {
                callback: data => {
                    const parsed = t.parseData(data)
                    handlePurchaseNotification(this.chatConn, parsed)
                },
                authData: t.getAuthData(),
                topicKey: t.getKey(),
            })

            const listener = t.onSubscribeChange.listen(e => {
                if (e.subscribed) {
                    this.fetchHistoryIfReady()
                } else {
                    this.informHistoryFailure(`${t.getId()} topic failed`)
                }
            })
            this.listeners.add(listener)
        })
    }

    private messageHistory(): void {
        const { roomUid, privateShowId } = roomDossierContext.getState()

        if (roomUid === "") {
            return
        }

        const topic = privateShowId !== "" ? new PrivateRoomMessageTopic(roomUid, privateShowId) : new RoomMessageTopic(roomUid)

        this.topics.set(topic.getAuthKey(), {
            callback: data => {
                const parsed = topic.parseData(data)
                handleRoomMessage(this.chatConn, parsed)
            },
            authData: topic.getAuthData(),
            topicKey: topic.getKey(),
        })
        topic.onSubscribeChange.listen(e => {
            if (e.subscribed) {
                this.fetchHistoryIfReady()
            } else {
                this.informHistoryFailure(`${topic.getId()} topic failed`)
            }
        }).addTo(this.listeners)
    }

    private shortcodeHistory(): void {
        const { roomUid } = roomDossierContext.getState()
        const topics = []

        if (roomUid !== "") {
            topics.push(new RoomShortcodeTopic(roomUid))
        }

        topics.forEach(t => {
            this.topics.set(t.getAuthKey(), {
                callback: data => {
                    const parsed = t.parseData(data)
                    this.chatConn.event.roomShortcode.fire(parsed)
                },
                authData: t.getAuthData(),
                topicKey: t.getKey(),
            })
            const listener = t.onSubscribeChange.listen(e => {
                if (e.subscribed) {
                    this.fetchHistoryIfReady()
                } else {
                    this.informHistoryFailure(`${t.getId()} topic failed`)
                }
            })
            this.listeners.add(listener)
        })
    }

    private fetchHistoryIfReady(): void {
        if (this.finished || !PushService.isEnabledForUI()) {
            return
        }
        for (const topic of this.topics.values()) {
            if (!PushService.isListeningFor(topic.topicKey)) {
                return
            }
        }
        this.finished = true
        this.fetchHistory().then(() => {
            handleTitleChange(this.chatConn, roomDossierContext.getState().roomTitle)
            // only set status on initial room load, otherwise statusAfterConnected may not represent the current status
            if (this.initial) {
                this.chatConn.changeStatus(this.chatConn.statusAfterConnected)
                this.initial = false
            }
        }).catch(() => {}) // fetchHistory cannot throw
    }

    public addHistoryMessage(topicKey: string, tid: string): boolean {
        const key = `${topicKey}:${tid}`
        if (this.handledMessages.includes(key)) {
            return true
        }
        this.handledMessages.push(key)
        return false
    }

    // allows history to be fetched again
    public reset(): void {
        this.finished = false
    }

    private informHistoryFailure(message: string): void {
        if (!this.failed) {
            const unconnectedTopics: string[] = []
            for (const topic of this.topics.values()) {
                if (!PushService.isListeningFor(topic.topicKey)) {
                    unconnectedTopics.push(topic.topicKey)
                }
            }
            if (unconnectedTopics.length > 0) {
                this.failed = true
                addPageAction("PushServiceHistoryFailure", {
                    "reason": message,
                    "unconnectedTopics": unconnectedTopics.toString(),
                    "unconnectedTopicsCount": unconnectedTopics.length,
                })
                this.fetchHistory().then(() => {}).catch(() => {})
            }
        }
    }
}

// A class to handle all Room messages sent from the PushService.
// It is meant to be instantiated in ChatConnection on roomLoad
export class PushServiceHandler {
    private listeners = {
        global: new ListenerGroup(),
        room: new ListenerGroup(),
        user: new ListenerGroup(),
        color: new ListenerGroup(),
        fanclub: new ListenerGroup(),
        privileged: new ListenerGroup(),
        private: new ListenerGroup(),
        enterLeave: new ListenerGroup(),
        roomUser: new ListenerGroup(),
    }
    private roomPresence: RoomUserPresenceTopic | undefined
    private privatePresence: PrivateRoomUserPresenceTopic | undefined
    private hiddenShowStarted: Date
    private isBroadcaster: boolean
    private isInHiddenShow = false
    private history = new PushServiceHistory(this.chatConn)
    private connectionFailedCount = 0
    private lastOnlineStatus = RoomStatus.NotConnected
    private hiddenMessage = ""
    private receivedMessageBuffer: string[] = []
    private messageTimeouts = new Map<string, number>()

    constructor(private dossier: IRoomDossier, private chatConn: ChatConnection) {
        let firstConnect = true
        const startTime = new Date().getTime()
        let connectionCount = 0
        // eslint-disable-next-line complexity
        PushService.connectionChange.listen(event => {
            if ([ConnectionState.connecting, ConnectionState.closing, ConnectionState.initialized].includes(event.current)) {
                return
            }
            if (event.current === ConnectionState.connected) {
                connectionCount += 1
            }
            const nrEvent = {
                "state": event.current,
                "previousState": event.previous,
                "push_connection_type": PushService.getConnectionType(),
                "wowza_state": this.chatConn.wowzaHandler.readyState(),
                "connection_id": PushService.presenceId,
                "first_connection": firstConnect,
                "time_since_start": new Date().getTime() - startTime,
                "client": event.client,
                "connectionCount": connectionCount,
                "connectionFailedCount": this.connectionFailedCount,
                "reason": JSON.stringify(event.reason),
            }
            addPageAction("PushServiceConnection", nrEvent)
            firstConnect = false
            if (event.primary === true && PushService.isEnabledForUI()) {
                switch(event.current) {
                    case ConnectionState.closed:
                    case ConnectionState.failed:
                        this.history.reset()
                        // PushService.clientManager changes service and tries to recover, or fallback to wowza
                        this.chatConn.event.roomNotice.fire({
                            messages: [[stringPart(i18n.chatDisconnected)]],
                            showInPrivateMessage: true,
                        })
                        break
                    case ConnectionState.suspended:
                    case ConnectionState.disconnected:
                        this.history.reset()
                        this.connectionFailedCount += 1
                        this.chatConn.event.roomNotice.fire({
                            messages: [[stringPart(i18n.tryingToReconnect)]],
                            showInPrivateMessage: true,
                            countsForUnread: false,
                        })
                        break
                    case ConnectionState.connected:
                        this.connectionFailedCount = 0
                        break
                    default:
                        this.connectionFailedCount = 0
                        break
                }
            }
        }, false).addTo(this.listeners.global)

        const viewerUid = this.dossier.viewerUid ?? ""
        const roomUid = this.dossier.roomUid ?? ""
        this.isBroadcaster = roomUid !== "" && viewerUid === roomUid
        const { privateShowId, isModerator, isInFanClub, userColors } = roomDossierContext.getState()

        this.setupBackendSwapListener()
        this.joinRoomPresence(roomUid, viewerUid)
        this.setupVideoQualityTopic(roomUid)
        this.setupVideoLatencyTopic(roomUid)
        this.setupUserListeners(viewerUid)
        this.setupRoomListeners(roomUid)
        this.setupRoomEnterLeaveListener(roomUid, isModerator || this.isBroadcaster)
        this.setupRoomUserListeners(roomUid, viewerUid)
        this.setupPrivilegedListeners(roomUid, isModerator || this.isBroadcaster)
        this.setupFanClubListeners(roomUid, isInFanClub)
        this.setupColorGroupListeners(roomUid, userColors)
        this.setupGameListener(roomUid)
        if (privateShowId !== "") {
            this.setupPrivateRoomListeners(roomUid, privateShowId)
        }

        roomLoaded.once(() => {
            this.history.setupHistory()
        }).addTo(this.listeners.global)

        roomDossierContext.onUpdate.listen((oldDossier) => {
            const current = roomDossierContext.getState()

            if (current.privateShowId !== oldDossier.privateShowId) {
                this.setupPrivateRoomListeners(roomUid, current.privateShowId)
            }
            if (current.isModerator !== oldDossier.isModerator) {
                this.setupPrivilegedListeners(roomUid, current.isModerator || this.isBroadcaster)
                this.setupRoomEnterLeaveListener(roomUid, current.isModerator || this.isBroadcaster)
            }
            if (current.isInFanClub !== oldDossier.isInFanClub) {
                this.setupFanClubListeners(roomUid, current.isInFanClub)
            }
            this.setupColorGroupListeners(roomUid, current.userColors, oldDossier.userColors)
        }, false).addTo(this.listeners.global)
    }

    private handleChatMessageError(errorMessage: string, response: ArgJSONMap, roomUid: string): void {
        errorMessage = decodeURIComponent(errorMessage)
        let chatursafeFlagged: IChatursafeFlagged | undefined
        const flaggedCategories = response.getStringList("chatursafe_flagged_categories")
        if (isChatursafeActive() && flaggedCategories.length > 0) {
            const msgObj = response.getObject("message") as Record<string, unknown>
            // adds base data to avoid parse errors
            addBaseTopicFields(msgObj)
            const topic = new RoomMessageTopic(roomUid)
            const roomMsg = topic.parseData(new ArgJSONMap(msgObj))
            chatursafeFlagged = {
                message: roomMsg,
                categories: flaggedCategories,
            }
        }
        if (this.shouldHandleMessage()) {
            this.chatConn.event.roomNotice.fire({ 
                messages: [[stringPart(errorMessage)]],
                showInPrivateMessage: chatursafeFlagged ? false: true,
                chatursafeFlagged: chatursafeFlagged, 
            })
        }
    }

    public sendMessage(room: string, message: string): Promise<object> {
        const { roomUid, privateShowId, userName } = roomDossierContext.getState()

        if (room !== this.chatConn.room() || roomUid === "") {
            return Promise.reject({
                error: "room error",
                sendMessageRoom: room,
                handlerRoom: this.chatConn.room(),
                roomUid: roomUid,
            })
        }

        if (pageContext.current.isNoninteractiveUser) {
            return Promise.reject({
                error: "internal staff",
                sendMessageRoom: room,
                handlerRoom: this.chatConn.room(),
                roomUid: roomUid,
            })
        }

        const data: Record<string,string> = {
            "room": room,
            "message": JSON.stringify({ "m": message }),
        }
        if (this.chatConn.inPrivateRoom() && privateShowId !== "") {
            data["private_show_id"] = privateShowId
        }
        if (userName !== "") {
            data["username"] = userName
        }

        if (PushService.isEnabledForUI()) {
            // only try to reconnect to wowza if push is enabled to avoid reconnect/history
            this.chatConn.wowzaHandler.ensureConnected()
        }
        return postCb("push_service/publish_chat_message_live/", data).then((xhr) => {
            const errorMessage = xhr.getResponseHeader("x-banned") ?? xhr.getResponseHeader("x-denied")
            const args = new ArgJSONMap(xhr.responseText)

            if (errorMessage !== null) {
                this.handleChatMessageError(errorMessage, args, roomUid)
            } else {
                // only using topic to parse, dont need private topic
                const msgObj = args.getObject("message") as Record<string, unknown>
                // adds base data to avoid parse errors
                addBaseTopicFields(msgObj)
                const topic = data["private_show_id"] === undefined ? new RoomMessageTopic(roomUid) : new PrivateRoomMessageTopic(roomUid, data["private_show_id"])
                const roomMsg = topic.parseData(new ArgJSONMap(msgObj))
                roomMsg.isSpam = xhr.getResponseHeader("x-spam") === "True"

                if (PushService.isEnabledForUI() && roomMsg.tid !== undefined && !PushService.addExternalMessage(topic.getKey(), roomMsg.tid)) {
                    // in this case, the publish response beat the push message to display the message
                    this.history.addHistoryMessage(topic.getKey(), roomMsg.tid)
                    handleRoomMessage(this.chatConn, roomMsg)
                    this.messageTimeouts.set(roomMsg.tid, window.setTimeout(() => {
                        if (!this.checkReceivedBuffer(roomMsg.tid ?? "")) {
                            addPageAction("PushServiceMessageTimeout", {
                                "topic_key": topic.getKey(),
                                "topic_id": topic.getId(),
                                "tid": roomMsg.tid,
                                "isSpam": roomMsg.isSpam,
                                "message": roomMsg.message,
                            })
                        }
                    }, 10000))
                } else if (PushService.isEnabledForUI()) {
                    this.checkReceivedBuffer(roomMsg.tid ?? "")
                }
            }

            return {
                "m": message,
                "f": "",
                "c": "",
                "tid": args.getString("tid"),
                "sig": args.getString("sig"),
            }
        })
    }

    private checkReceivedBuffer(tid: string): boolean {
        const index = this.receivedMessageBuffer.indexOf(tid ?? "")
        if (index !== -1) {
            this.receivedMessageBuffer.splice(index, 1)
            return true
        }
        return false
    }

    private setupUserListeners(viewerUid: string): void {
        if (isAnonymous() || viewerUid === "") {
            return
        }

        // PM specific chat UserTopic listeners set up in ConversationListData to be available for sitewide PMs
        new UserColorUpdateTopic(viewerUid).onMessage.listen((msg) => {
            roomDossierContext.setState({ userColors: msg })
        }).addTo(this.listeners.user)

        new UserAlertTopic(viewerUid).onMessage.listen(msg => {
            if (this.shouldHandleMessage()) {
                openComplianceModal(msg.message)
            }
        }).addTo(this.listeners.user)

        if (this.isBroadcaster) {
            this.setupBroadcasterPrivateListener(viewerUid)
        }
    }

    private setupVideoQualityTopic(roomUid: string): void {
        if (roomUid === "") {
            return
        }
        new QualityUpdateTopic(roomUid).onMessage.listen((msg) => {
            const oldState = roomDossierContext.getState()
            const { tid: _, ...newQuality } = msg
            if (!deepCompare(oldState.quality, newQuality)) {
                roomDossierContext.setState({
                    ...oldState,
                    quality: newQuality,
                })
                if(roomDossierContext.getState().quality?.stopped ?? false) {
                    sendVideoMetric({ "eventName": "streamEnd" })
                }
            }
        }).addTo(this.listeners.room)
    }

    private setupVideoLatencyTopic(roomUid: string): void {
        if (roomUid === "") {
            return
        }
        new LatencyUpdateTopic(roomUid).onMessage.listen((msg) => {
            const oldState = roomDossierContext.getState()
            const { tid: _, ...newLatency } = msg
            // If some values are undefined (missing), put the old ones there to simplify deep compare and copying
            newLatency.localTimeTranscoderInput = newLatency.localTimeTranscoderInput ?? oldState.latency?.localTimeTranscoderInput
            newLatency.streamTimeTranscoderInput = newLatency.streamTimeTranscoderInput ?? oldState.latency?.streamTimeTranscoderInput
            newLatency.localTimeSegmentStart = newLatency.localTimeSegmentStart ?? oldState.latency?.localTimeSegmentStart
            newLatency.streamTimeSegmentStart = newLatency.streamTimeSegmentStart ?? oldState.latency?.streamTimeSegmentStart
            if (!deepCompare(oldState.latency, newLatency)) {
                roomDossierContext.setState({
                    ...oldState,
                    latency: newLatency,
                })
            }
        }).addTo(this.listeners.room)
    }

    private setupRoomListeners(roomUid: string): void {
        if (roomUid === "") {
            return
        }
        if (!PushService.isEnabledForVerify()){
            return
        }

        this.setupRoomPublicListeners(roomUid)

        new RoomModeratorPromotedTopic(roomUid).onMessage.listen((msg: IRoomAction) => {
            if (this.chatConn.username() === msg.username) {
                roomDossierContext.setState({ isModerator: true })
            }
            if (this.shouldHandleMessage()) {
                handlePromotion(this.chatConn, msg)
            }
        }).addTo(this.listeners.room)

        new RoomModeratorRevokedTopic(this.dossier.roomUid).onMessage.listen((msg: IRoomAction) => {
            if (this.chatConn.username() === msg.username) {
                roomDossierContext.setState({ isModerator: false })
            }
            if (this.shouldHandleMessage()) {
                handleRevoke(this.chatConn, msg)
            }
        }).addTo(this.listeners.room)

        new RoomStatusTopic(roomUid).onMessage.listen((msg) => { // eslint-disable-line complexity
            const dossierStatus = roomDossierContext.getState().roomStatus
            let statusToSet = msg.status

            if (dossierStatus !== RoomStatus.Offline) {
                this.lastOnlineStatus = dossierStatus
            }
            if (msg.status === RoomStatus.Hidden) {
                if (this.isBroadcaster) {
                    // Broadcaster can go directly from Away to Hidden, uses public status
                    if (dossierStatus === RoomStatus.Away) {
                        handleAwayModeCancel(this.chatConn)
                        roomDossierContext.setState({ roomStatus: RoomStatus.Public })
                    }
                    return
                }
                if (!this.isInHiddenShow){
                    this.hiddenShowStarted = new Date()
                }
                if (this.shouldHandleMessage()) {
                    if (msg.message !== "") {
                        this.hiddenMessage = msg.message
                    }
                    if (this.lastOnlineStatus === RoomStatus.HiddenWatching) {
                        handleHiddenShowStatus(this.chatConn, this.lastOnlineStatus, this.hiddenMessage)
                        statusToSet = this.lastOnlineStatus
                    } else {
                        handleHiddenShowStatus(this.chatConn, msg.status, this.hiddenMessage)
                    }
                }
            } else if (msg.status === RoomStatus.PrivateNotWatching) {
                if (this.isBroadcaster && dossierStatus !== RoomStatus.Offline || [RoomStatus.PrivateRequesting, RoomStatus.PrivateWatching, RoomStatus.PrivateNotWatching].includes(dossierStatus)) {
                    return
                }
                if (this.shouldHandleMessage()) {
                    const premium = featureFlagIsActive("PremPrivShow") && msg.substatus !== undefined && msg.substatus === PrivateSubstatus.Premium
                    if (dossierStatus === RoomStatus.Offline) {
                        if (this.isBroadcaster) {
                            this.chatConn.changeStatus(RoomStatus.PrivateWatching)
                        } else if (this.lastOnlineStatus === RoomStatus.PrivateSpying) {
                            this.chatConn.changeStatus(this.lastOnlineStatus)
                            statusToSet = this.lastOnlineStatus
                        } else {
                            if (roomDossierContext.getState().privateShowId !== ""){
                                this.chatConn.changeStatus(RoomStatus.PrivateRequesting)
                            } else {
                                this.chatConn.changeStatus(RoomStatus.Public)
                            }
                            handlePrivateShowApprove(this.chatConn, premium)
                        }
                    } else {
                        handlePrivateShowApprove(this.chatConn, premium)
                    }
                }
            } else if (msg.status === RoomStatus.Away && this.shouldHandleMessage()) {
                if (dossierStatus === RoomStatus.PrivateWatching) {
                    roomDossierContext.setState({ privateShowId: "", roomStatus: RoomStatus.Away })
                    handlePrivateShowCancel(this.chatConn, msg.substatus === PrivateSubstatus.Premium)
                } else if (dossierStatus === RoomStatus.PrivateNotWatching || dossierStatus === RoomStatus.PrivateSpying) {
                    handlePrivateShowCancel(this.chatConn, msg.substatus === PrivateSubstatus.Premium)
                } else if (!this.isBroadcaster && dossierStatus === RoomStatus.Offline && this.lastOnlineStatus === RoomStatus.PrivateWatching) {
                    // Private show ended while room was offline. Handle for viewer here, handle for broadcaster in `privateStatusHandler()`
                    sendPrivateShowFinishedNotice(this.chatConn, true, msg.substatus === PrivateSubstatus.Premium)
                } else {
                    this.chatConn.changeStatus(msg.status)
                }
                // While in a private show, any new password in the room would have been ignored, so we need to check it now
                if (!this.passwordCheck()) {
                    return
                }
            } else if (msg.status === RoomStatus.Public && this.shouldHandleMessage()) {
                if (dossierStatus === RoomStatus.Away) {
                    handleAwayModeCancel(this.chatConn)
                } else if (dossierStatus === RoomStatus.Hidden || dossierStatus === RoomStatus.HiddenWatching) {
                    handleHiddenShowStatus(this.chatConn, msg.status, msg.message)
                } else {
                    this.chatConn.changeStatus(msg.status)
                }
            } else if (msg.status === RoomStatus.PasswordProtected) {
                // already handled with password checks in beginning
                return
            } else if (msg.status === RoomStatus.Offline && this.shouldHandleMessage()) {
                if (dossierStatus === RoomStatus.PrivateRequesting) {
                    // handlePrivateShowCancel will change status to public
                    handlePrivateShowCancel(this.chatConn, msg.substatus === PrivateSubstatus.Premium)
                    this.chatConn.leavePrivateOrSpyShow().catch(ignoreCatch)
                }
                this.chatConn.changeStatus(msg.status)
            } else {
                if (this.shouldHandleMessage()) {
                    this.chatConn.changeStatus(msg.status)
                }
            }

            roomDossierContext.setState({ roomStatus: statusToSet })
        }).addTo(this.listeners.room)

        new RoomTitleChangeTopic(roomUid).onMessage.listen(msg => {
            if (this.shouldHandleMessage()) {
                handleTitleChange(this.chatConn, msg.title)
            }
        }).addTo(this.listeners.room)

        new RoomSilenceTopic(roomUid).onMessage.listen(msg => {
            if (this.shouldHandleMessage()) {
                handleSilence(this.chatConn, msg)
            }
        }).addTo(this.listeners.room)

        new RoomKickTopic(roomUid).onMessage.listen(msg => {
            if (this.shouldHandleMessage()) {
                // kick fromUser is unknown, use broadcaster name
                msg.fromUser = this.chatConn.room()
                if (this.chatConn.username() === msg.username) {
                    handlePersonallyKicked(this.chatConn, i18n.kickedFromRoomMessage, roomDossierContext.getState().exploringHashTag)
                } else {
                    handleKick(this.chatConn, msg)
                }
            }
        }).addTo(this.listeners.room)

        new RoomUpdateTopic(roomUid).onMessage.listen(msg => {
            if (this.shouldHandleMessage() && msg.target === RoomUpdateType.refreshPanel) {
                const refreshPanel = () => {
                    this.chatConn.event.refreshPanel.fire({ appId: msg.appId, appSystem: msg.appSystem })
                }
                if (pageContext.current.throttleTopicPublish) {
                    // Timeout for when RoomUpdateTopic is throttled so panel updates with most up to date data
                    window.setTimeout(() => {
                        refreshPanel()
                    }, 1000)
                } else {
                    refreshPanel()
                }
            }
        }).addTo(this.listeners.room)

        new RoomSettingsTopic(roomUid).onMessage.listen(msg => {
            const newState = {
                allowPrivateShow: msg.allowPrivateShow,
                privatePrice: msg.privatePrice,
                spyPrice: msg.spyPrice,
                privateMinMinutes: msg.privateMinMinutes,
                allowShowRecordings: msg.allowShowRecordings,
                hasFanClub: msg.hasFanClub,
                activePassword: msg.activePassword,
                fanClubSpyPrice: msg.fanClubSpyPrice,
                premiumPrivatePrice: msg.premiumPrivatePrice,
                premiumPrivateMinMinutes: msg.premiumPrivateMinMinutes,
            } as Partial<IRoomDossier>
            roomDossierContext.setState(newState)
            if (this.chatConn.inPrivateRoom() && !this.isBroadcaster) {
                // update state but don't show message for pvt viewer
                this.chatConn.event.settingsUpdate.fire(msg)
                return
            }
            if (this.shouldHandleMessage()) {
                handleSettingsUpdate(this.chatConn, msg)
            }
        }).addTo(this.listeners.room)

        new ViewerPromotionTopic(roomUid).onMessage.listen((msg) => {
            this.chatConn.event.roomNotice.fire({
                messages: [[
                    userPart(msg.purchaser),
                    stringPart(` promoted the room for ${msg.durationMins} minutes`),
                ]],
                colorClass: "userPromotion",
                foreground: "#272F35",
                background: "#ECF3FD",
                weight: "bold",
                showInPrivateMessage: false,
            })
            userPromotionPush.fire(undefined)
        }).addTo(this.listeners.room)
    }

    private setupRoomPublicListeners(roomUid: string): void {
        // these topics should not show messages if you are in the private show
        // instead build a local buffer to resume when show ends
        new RoomMessageTopic(roomUid).onMessage.listen(msg => {
            if (msg.fromUser.username === this.chatConn.username() && msg.tid !== undefined) {
                this.updateColors(msg.fromUser)
                if (this.messageTimeouts.has(msg.tid)) {
                    window.clearTimeout(this.messageTimeouts.get(msg.tid))
                    this.messageTimeouts.delete(msg.tid)
                    return
                } else {
                    this.receivedMessageBuffer.push(msg.tid)
                }
            }
            if (this.chatConn.inPrivateRoom()) {
                msg.ts = undefined
                this.history.push(() => handleRoomMessage(this.chatConn, msg))
                return
            }
            const topicKey = `RoomMessageTopic:${roomDossierContext.getState().roomUid}`
            if (msg.tid !== undefined){
                this.history.addHistoryMessage(topicKey, msg.tid)
            }
            if (this.shouldHandleMessage()) {
                handleRoomMessage(this.chatConn, msg)
            }
        }).addTo(this.listeners.room)

        new RoomFanClubJoinedTopic(roomUid).onMessage.listen((msg) => {
            const state = roomDossierContext.getState()
            if (msg.fromUser.username === state.userName) {
                roomDossierContext.setState({ isInFanClub: true })
            }
            if (this.chatConn.inPrivateRoom()) {
                msg.ts = undefined
                this.history.push(() => handlePurchaseNotification(this.chatConn, msg))
                return
            }
            if (this.shouldHandleMessage()) {
                handlePurchaseNotification(this.chatConn, msg)
            }
        }).addTo(this.listeners.room)

        new RoomPurchaseTopic(roomUid).onMessage.listen(msg => {
            if (this.chatConn.inPrivateRoom()) {
                msg.ts = undefined
                this.history.push(() => handlePurchaseNotification(this.chatConn, msg))
                return
            }
            if (this.shouldHandleMessage()) {
                handlePurchaseNotification(this.chatConn, msg)
            }
        }).addTo(this.listeners.room)

        new RoomNoticeTopic(roomUid).onMessage.listen(msg => {
            if (this.chatConn.inPrivateRoom()) {
                msg.ts = undefined
                return
            }
            if (this.shouldHandleMessage()) {
                this.chatConn.event.roomNotice.fire(msg)
            }
        }).addTo(this.listeners.room)

        // Broadcasters use UserTipAlertTopic
        new RoomTipAlertTopic(roomUid).onMessage.listen((msg: IPushTipAlert) => {
            if (!this.isBroadcaster) {
                if (msg.fromUser.username === this.chatConn.username()) {
                    this.updateColors(msg.fromUser)
                }
                if (this.chatConn.inPrivateRoom()) {
                    msg.ts = undefined
                    this.history.push(() => handleTipAlert(this.chatConn, msg, true))
                    return
                }
                if (this.shouldHandleMessage()) {
                    handleTipAlert(this.chatConn, msg)
                }
            }
        }).addTo(this.listeners.room)

        new RoomShortcodeTopic(roomUid).onMessage.listen((msg) => {
            if (this.chatConn.inPrivateRoom()) {
                msg.ts = undefined
                this.history.push(() => this.chatConn.event.roomShortcode.fire(msg))
                return
            }
            this.chatConn.event.roomShortcode.fire(msg)
        }).addTo(this.listeners.room)

        // eslint-disable-next-line complexity
        new RoomPasswordProtectedTopic(roomUid).onMessage.listen((msg: IRoomPasswordProtected) => {
            const previousPassword = roomDossierContext.getState().roomPasswordHash
            const lastSubmittedPasswordHash = roomDossierContext.getState().lastSubmittedPasswordHash
            const passwordSet = msg.passwordHash !== ""
            const passwordChanged = previousPassword !== msg.passwordHash
            const previousPasswordAlreadySubmitted = lastSubmittedPasswordHash === previousPassword
            const passwordAlreadySubmitted = lastSubmittedPasswordHash === msg.passwordHash
            // Private show viewers and spies only get a notice added if they have access to the newly set password or if
            // the newly cleared password was one they would have gotten the notice for (ie they had access)
            const hasOrHadAccess = this.isBroadcaster || passwordSet && passwordAlreadySubmitted || !passwordSet && previousPasswordAlreadySubmitted

            if (!passwordChanged) {
                return
            }

            roomDossierContext.setState({ roomPasswordHash: msg.passwordHash })

            if (this.chatConn.inPrivateRoom()) {
                if (hasOrHadAccess) {
                    this.history.push(() => handlePasswordChanged(this.chatConn, passwordSet, passwordAlreadySubmitted))
                }
                return
            }
            if (this.shouldHandleMessage()) {
                if (roomDossierContext.getState().roomStatus === RoomStatus.PrivateSpying && !hasOrHadAccess) {
                    return
                }
                handlePasswordChanged(this.chatConn, passwordSet, passwordAlreadySubmitted)
            }
        }).addTo(this.listeners.room)
    }

    private setupBroadcasterPrivateListener(roomUid: string): void {
        if (roomUid === "" || !this.isBroadcaster) {
            return
        }
        new RoomBroadcasterPrivateStatusTopic(roomUid).onMessage.listen((msg: IBroadcasterPrivateStatus) => {
            this.privateStatusHandler(msg, msg.earlyCancelTokens, msg.requester)
        }).addTo(this.listeners.user)
    }

    // eslint-disable-next-line complexity
    private privateStatusHandler(msg: IPrivateShowStatus, earlyCancelTokens?: number, requester?: string): void {
        let handleFunction
        let premium = false
        if (featureFlagIsActive("PremPrivShow")) {
            premium = msg.isPremium
        }
        switch (msg.status) {
            case PrivateRequestStatus.started:
                if (RoomStatus.PrivateNotWatching.includes(this.chatConn.status)) {
                    break
                }
                if ([RoomStatus.PrivateWatching, RoomStatus.PrivateRequesting].includes(this.chatConn.status)) {
                    roomDossierContext.setState({
                        privateShowId: msg.privateShowId,
                        roomStatus: RoomStatus.PrivateWatching,
                    })
                } else {
                    roomDossierContext.setState({ roomStatus: RoomStatus.PrivateNotWatching })
                }
                if (this.isBroadcaster) {
                    addPageAction("BroadcasterPrivateStarted", { "is_premium": premium })
                }
                handleFunction = handlePrivateShowApprove
                break
            case PrivateRequestStatus.declined:
                roomDossierContext.setState({ roomStatus: RoomStatus.Public })
                handleFunction = handlePrivateShowCancel
                break
            case PrivateRequestStatus.stopped:
                if (this.isBroadcaster && [RoomStatus.Offline, RoomStatus.Away, RoomStatus.PrivateWatching].includes(this.chatConn.status)) {
                    const cancelledEarly = earlyCancelTokens !== undefined && earlyCancelTokens > 0
                    const additionalMessage = cancelledEarly
                        ? i18n.privateShowEarlyCancelMessage(requester ?? this.chatConn.getPrivateShowUser(), earlyCancelTokens)
                        : undefined
                    sendPrivateShowFinishedNotice(
                        this.chatConn,
                        true,
                        premium,
                        requester,
                        additionalMessage,
                    )
                    addPageAction("BroadcasterPrivateEnded", {
                        "reason": msg.reason,
                        "is_premium": premium,
                    })
                }
                roomDossierContext.setState({
                    roomStatus: RoomStatus.Away,
                    privateShowId: "",
                })
                handleFunction = handlePrivateShowCancel
                break
            case PrivateRequestStatus.error:
                roomDossierContext.setState({
                    roomStatus: RoomStatus.Public,
                    privateShowId: "",
                })
                handleFunction = handlePrivateShowCancel
                break
            case PrivateRequestStatus.spy_leave:
                roomDossierContext.setState({
                    roomStatus: RoomStatus.Public,
                    privateShowId: "",
                })
                handleFunction = (conn: ChatConnection) => {
                    conn.changeStatus(RoomStatus.PrivateNotWatching)
                    // While in the spy show, any new password in the room would have been ignored, so we need to check it now
                    this.passwordCheck()
                }
                break
            default:
                if (!this.isBroadcaster) {
                    warn("Invalid private show status on viewer", msg, SubSystemType.PushService)
                }
        }
        if (this.shouldHandleMessage() && handleFunction !== undefined) {
            handleFunction(this.chatConn, premium, msg.reason, requester)
        }
    }

    private passwordCheck(): boolean {
        const currentPasswordHash = roomDossierContext.getState().roomPasswordHash
        const passwordAlreadySubmitted = roomDossierContext.getState().lastSubmittedPasswordHash === currentPasswordHash
        if (currentPasswordHash !== "" && !passwordAlreadySubmitted && !this.isBroadcaster) {
            handlePasswordChanged(this.chatConn, true, false)
            return passwordAlreadySubmitted
        }
        return true
    }

    private setupRoomUserListeners(roomUid: string, viewerUid: string): void {
        if (isAnonymous() || roomUid === "" || viewerUid === "") {
            return
        }

        new RoomUserNoticeTopic(roomUid, viewerUid).onMessage.listen(msg => {
            if (this.chatConn.inPrivateRoom()) {
                return
            }
            if (this.shouldHandleMessage()) {
                this.chatConn.event.roomNotice.fire(msg)
            }
        }).addTo(this.listeners.roomUser)

        // Broadcaster uses RoomBroadcasterPrivateStatusTopic
        new RoomUserPrivateStatusTopic(roomUid, viewerUid).onMessage.listen((msg: IPrivateShowStatus) => {
            if (!this.isBroadcaster) {
                this.privateStatusHandler(msg)
            }
        }).addTo(this.listeners.roomUser)

        new RoomUserHiddenCamStatusTopic(roomUid, viewerUid).onMessage.listen((msg) => {
            if (msg.status === HiddenShowStatuses.APPROVED) {
                this.isInHiddenShow = true
                const isInitial = this.hiddenShowStarted === undefined || new Date().getTime() - this.hiddenShowStarted.getTime() < 2000
                if (this.shouldHandleMessage()) {
                    handleHiddenApprove(this.chatConn, isInitial)
                }
                window.setTimeout(() => {
                    roomDossierContext.setState({ roomStatus: RoomStatus.HiddenWatching })
                }, isInitial ? 2000 : 0)
            } else if (msg.status === HiddenShowStatuses.DENIED) {
                this.isInHiddenShow = false
                roomDossierContext.setState({ roomStatus: RoomStatus.Hidden })
                if (this.shouldHandleMessage()) {
                    this.chatConn.changeStatus(RoomStatus.Hidden)
                }
            }
        }).addTo(this.listeners.roomUser)
    }

    private setupPrivateRoomListeners(roomUid: string, privateShowId: string): void {
        if (roomUid === "") {
            return
        }
        if (privateShowId === "") {
            this.listeners.private.removeAll()
            this.privatePresence?.leavePresence()
            this.joinRoomPresence(roomUid, this.dossier.viewerUid ?? "")
            this.setupRoomUserListeners(roomUid, this.dossier.viewerUid ?? "")
            this.history.fireBuffer()
            return
        }

        this.listeners.roomUser.removeAll()

        this.roomPresence?.leavePresence()

        this.privatePresence = new PrivateRoomUserPresenceTopic(roomUid, privateShowId)
        this.privatePresence.enterPresence()

        new PrivateRoomMessageTopic(roomUid, privateShowId).onMessage.listen(msg => {
            if (msg.fromUser.username === this.chatConn.username() && msg.tid !== undefined) {
                if (this.messageTimeouts.has(msg.tid)) {
                    window.clearTimeout(this.messageTimeouts.get(msg.tid))
                    this.messageTimeouts.delete(msg.tid)
                    return
                } else {
                    this.receivedMessageBuffer.push(msg.tid)
                }
            }
            if (this.shouldHandleMessage()) {
                handleRoomMessage(this.chatConn, { ...msg, isPrivateShowMessage: true })
            }
        }).addTo(this.listeners.private)

        new PrivateRoomEnterLeaveTopic(roomUid, privateShowId).onMessage.listen(msg => {
            if (this.shouldHandleMessage()) {
                handleRoomEnterLeave(this.chatConn, msg)
            }
            if (PushService.isEnabledForUserList()) {
                this.chatConn.event.roomCountUpdate.fire(msg.viewers)
            }
            PushService.setVerifierRoomCount(msg.viewers)
        }).addTo(this.listeners.private)

        new PrivateRoomTipAlertTopic(roomUid, privateShowId).onMessage.listen((msg) => {
            if (!this.isBroadcaster && this.shouldHandleMessage()) {
                handleTipAlert(this.chatConn, msg)
            }
            if (msg.fromUser.username === this.chatConn.username()) {
                this.updateColors(msg.fromUser)
            }
        }).addTo(this.listeners.private)
    }

    private setupPrivilegedListeners(roomUid: string, isPrivileged: boolean): void {
        if (roomUid === "") {
            return
        }
        if (isPrivileged) {
            // empty listener to just subscribe/have push handle reauth
            new PrivilegedSessionTopic(roomUid).onMessage.listen(() => {}).addTo(this.listeners.privileged)

            // privileged enter builds the user object with source/hashtag, so can call the same handler function
            new RoomPrivilegedEnterTopic(roomUid).onMessage.listen(msg => {
                if (this.chatConn.inPrivateRoom()) {
                    return
                }
                if (this.shouldHandleMessage()) {
                    handleRoomEnterLeave(this.chatConn, msg)
                }
                if (PushService.isEnabledForUserList()) {
                    this.chatConn.event.roomCountUpdate.fire(msg.viewers)
                }
                PushService.setVerifierRoomCount(msg.viewers)
            }).addTo(this.listeners.privileged)

            new RoomPrivilegedLeaveTopic(roomUid).onMessage.listen(msg => {
                if (this.chatConn.inPrivateRoom()) {
                    return
                }
                if (this.shouldHandleMessage()) {
                    handleRoomEnterLeave(this.chatConn, msg)
                }
                if (PushService.isEnabledForUserList()) {
                    this.chatConn.event.roomCountUpdate.fire(msg.viewers)
                }
                PushService.setVerifierRoomCount(msg.viewers)
            }).addTo(this.listeners.privileged)

            new RoomModeratorNoticeTopic(roomUid).onMessage.listen(msg => {
                if (!this.isBroadcaster && this.shouldHandleMessage()) {
                    this.chatConn.event.roomNotice.fire(msg)
                }
            }).addTo(this.listeners.privileged)

            if (this.isBroadcaster) {
                new RoomAppLogTopic(roomUid).onMessage.listen(msg => {
                    if (this.shouldHandleMessage()) {
                        handleAppDebugError(this.chatConn, msg)
                    }
                }).addTo(this.listeners.privileged)

                new UserUpdateTopic(roomUid).onMessage.listen(msg => {
                    if (this.shouldHandleMessage()) {
                        if (msg.target === UserUpdateType.appTabRefresh) {
                            this.chatConn.event.appTabRefresh.fire(undefined)
                        }
                    }
                }).addTo(this.listeners.privileged)

                new UserTipAlertTopic(roomUid).onMessage.listen(msg => {
                    if(msg.toUsername !== this.chatConn.username()) {
                        return
                    }
                    if (this.chatConn.inPrivateRoom() && msg.roomType === "public") {
                        msg.ts = undefined
                        this.history.push(() => handleTipAlert(this.chatConn, msg, true))
                        return
                    }
                    if (this.shouldHandleMessage()) {
                        handleTipAlert(this.chatConn, msg)
                    }
                }).addTo(this.listeners.privileged)

                new RoomBroadcasterPrivateStatusTopic(roomUid).onMessage.listen(msg => {
                    let isPremium = false
                    let tokensPerMinute = roomDossierContext.getState().privatePrice
                    if (featureFlagIsActive("PremPrivShow")) {
                        isPremium = msg.isPremium
                        tokensPerMinute = isPremium ? roomDossierContext.getState().premiumPrivatePrice : tokensPerMinute
                    }
                    // all other statuses handled in room:user listener
                    if (msg.status === PrivateRequestStatus.requested) {
                        roomDossierContext.setState({ roomStatus: RoomStatus.PrivateRequesting })
                        if (this.shouldHandleMessage()) {
                            const event = {
                                userRequesting: msg.requester ?? this.chatConn.getPrivateShowUser(),
                                tokensPerMinute: tokensPerMinute,
                                isPremium: isPremium,
                                delayFiringEvent: msg.delayFiringEvent,
                            }
                            handlePrivateShowRequest(this.chatConn, event)
                        }
                    }
                })

                new BroadcasterWarningTopic(roomUid).onMessage.listen(msg => {
                    warningAlert(msg)
                }).addTo(this.listeners.privileged)
            }
        } else {
            this.listeners.privileged.removeAll()
        }
    }

    private setupFanClubListeners(roomUid: string, isInFanClub: boolean): void {
        if (roomUid === "") {
            return
        }
        if (isInFanClub) {
            new RoomFanClubTopic(roomUid).onMessage.listen(msg => {
                if (this.shouldHandleMessage()) {
                    this.chatConn.event.roomNotice.fire(msg)
                }
            }).addTo(this.listeners.fanclub)
        } else {
            this.listeners.fanclub.removeAll()
        }
    }

    // eslint-disable-next-line complexity
    private setupColorGroupListeners(roomUid: string, userColors?: IUserColors, oldColors?: IUserColors): void {
        if (roomUid === "" || userColors === undefined) {
            return
        }
        const fireMsg = (msg: IPushNotice) => {
            if (this.chatConn.inPrivateRoom()) {
                return
            }
            if (this.shouldHandleMessage()) {
                this.chatConn.event.roomNotice.fire(msg)
            }
        }
        if (userColors.hasTokens && (oldColors === undefined || !oldColors.hasTokens)) {
            new RoomLightBlueTopic(roomUid).onMessage.listen(fireMsg).addTo(this.listeners.color)
        }
        if (userColors.tippedRecently && (oldColors === undefined || !oldColors.tippedRecently)) {
            new RoomDarkBlueTopic(roomUid).onMessage.listen(fireMsg).addTo(this.listeners.color)
        }
        if (userColors.tippedAlotRecently && (oldColors === undefined || !oldColors.tippedAlotRecently)) {
            new RoomLightPurpleTopic(roomUid).onMessage.listen(fireMsg).addTo(this.listeners.color)
        }
        if (userColors.tippedTonsRecently && (oldColors === undefined || !oldColors.tippedTonsRecently)) {
            new RoomDarkPurpleTopic(roomUid).onMessage.listen(fireMsg).addTo(this.listeners.color)
        }
    }

    private setupGameListener(roomUid: string): void {
        if (roomUid === "") {
            return
        }
        new GameUpdateTopic(roomUid).onMessage.listen(msg => {
            GameSelection.selectionChange.fire(msg.game)
        }).addTo(this.listeners.room)
    }

    private setupRoomEnterLeaveListener(roomUid: string, isPrivileged: boolean): void {
        this.listeners.enterLeave.removeAll()
        new RoomEnterLeaveTopic(roomUid).onMessage.listen(msg => {
            // mod and broadcaster connect but ignore this topic in favor of Privileged topics
            if (!isPrivileged) {
                if (this.chatConn.inPrivateRoom()) {
                    return
                }
                if (this.shouldHandleMessage()) {
                    handleRoomEnterLeave(this.chatConn, msg)
                }
                if (PushService.isEnabledForUserList()) {
                    this.chatConn.event.roomCountUpdate.fire(msg.viewers)
                }
                PushService.setVerifierRoomCount(msg.viewers)
            }
        }).addTo(this.listeners.enterLeave)
    }

    private joinRoomPresence(roomUid: string, viewerUid: string): void {
        if (roomUid === "") {
            return
        }
        if (isAnonymous()) {
            // empty listener to connect to presence
            new RoomAnonPresenceTopic(roomUid).onSubscribeChange.listen(() => {}).addTo(this.listeners.room)
        } else if (viewerUid !== "") {
            if (this.roomPresence === undefined) {
                this.roomPresence = new RoomUserPresenceTopic(roomUid, viewerUid)
            }
            this.roomPresence.enterPresence()
        }
    }

    private shouldHandleMessage(): boolean {
        // Phase 4 this method will consider each message type and be able to switch individual messages off
        return PushService.isEnabledForUI()
    }

    private setupBackendSwapListener(): void {
        new GlobalPushServiceBackendChangeTopic().onMessage.listen(event => {
            PushService.changeChatHandler(event.backends)
            this.chatConn.wowzaHandler.ensureConnected()
        }).addTo(this.listeners.global)
    }

    private updateColors(user: IUserInfo): void {
        const colors = {
            "hasTokens": user.hasTokens,
            "tippedRecently": user.tippedRecently,
            "tippedAlotRecently": user.tippedALotRecently,
            "tippedTonsRecently": user.tippedTonsRecently,
        }
        roomDossierContext.setState({ userColors: colors })
    }

    public cleanup(): void {
        this.roomPresence?.leavePresence()
        this.privatePresence?.leavePresence()
        Object.values(this.listeners).forEach(l => l.removeAll())
        this.history.dispose()
        this.messageTimeouts.forEach(t => window.clearTimeout(t))
        this.messageTimeouts.clear()
        this.receivedMessageBuffer = []
    }
}
