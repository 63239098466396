import { numberInputToObject, rgbaToHex, rgbToHex, rgbToHsl, rgbToHsv, rgbToLab, rgbToYPbPr, } from "./conversion";
import { names } from "./css-color-names";
import { inputToRGB } from "./format-input";
import { bound01, boundAlpha, clamp01 } from "./util";
export var ColorSpace;
(function (ColorSpace) {
    ColorSpace[ColorSpace["CIELAB"] = 0] = "CIELAB";
    ColorSpace[ColorSpace["HSL"] = 1] = "HSL";
    ColorSpace[ColorSpace["YPbPr"] = 2] = "YPbPr";
})(ColorSpace || (ColorSpace = {}));
export class CbColor {
    /** Set the color space to be used for correction options. */
    static setColorSpace(space) {
        CbColor.globalSpace = space;
    }
    get colorSpace() {
        if (CbColor.globalSpace === ColorSpace.HSL) {
            return this.HSL;
        }
        else if (CbColor.globalSpace === ColorSpace.CIELAB) {
            return this.CIELAB;
        }
        else {
            return this.YPbPr;
        }
    }
    /** Returns the current lightness value according to the global color space. */
    get lightness() {
        return this.colorSpace.lightness;
    }
    /** Match this color with the hue of another. */
    matchHue(color) {
        const hue = CbColor.get(color).toHsl().h;
        const result = this.toHsl();
        result.h = hue;
        return CbColor.get(result);
    }
    constructor(color = "", opts = {}) {
        var _a;
        /** Lightens the color according to the global color space. */
        this.lighten = (amount) => this.colorSpace.lighten(amount);
        /** Darkens the color according to the global color space. */
        this.darken = (amount) => this.colorSpace.darken(amount);
        /** Sets the lightness to a specific value according to the global color space. */
        this.setLightness = (value) => this.colorSpace.setLightness(value);
        /** Matches the lightness of another color. */
        this.matchLightness = (color) => this.colorSpace.matchLightness(color);
        // If input is already a cbcolor, return itself
        if (color instanceof CbColor) {
            return color.clone();
        }
        if (typeof color === "number") {
            color = numberInputToObject(color);
        }
        this.originalInput = color;
        const rgb = inputToRGB(color);
        this.originalInput = color;
        this.r = rgb.r;
        this.g = rgb.g;
        this.b = rgb.b;
        this.a = rgb.a;
        this.roundA = Math.round(100 * this.a) / 100;
        this.format = (_a = opts.format) !== null && _a !== void 0 ? _a : rgb.format;
        this.gradientType = opts.gradientType;
        // Don't let the range of [0,255] come back in [0,1].
        // Potentially lose a little bit of precision here, but will fix issues where
        // .5 gets interpreted as half of the total, instead of half of 1
        // If it was supposed to be 128, this was already taken care of by `inputToRgb`
        if (this.r < 1) {
            this.r = Math.round(this.r);
        }
        if (this.g < 1) {
            this.g = Math.round(this.g);
        }
        if (this.b < 1) {
            this.b = Math.round(this.b);
        }
        this.isValid = rgb.ok;
        this.initHSL();
        this.initCIELAB();
        this.initYPbPr();
    }
    // HSL color space tools
    initHSL() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const c = this;
        this.HSL = {
            lighten: (amount) => {
                const lab = c.toHsl();
                lab.l += amount / 100;
                lab.l = clamp01(lab.l);
                return CbColor.get(lab);
            },
            darken: (amount) => {
                return c.HSL.lighten(-amount);
            },
            setLightness: (value) => {
                const lab = c.toHsl();
                lab.l = value;
                return CbColor.get(lab);
            },
            matchLightness: (color) => {
                const val = CbColor.get(color).HSL.lightness;
                const lab = c.toHsl();
                lab.l = val;
                return CbColor.get(lab);
            },
            get lightness() {
                return c.toHsl().l;
            },
        };
    }
    // CIELAB color space tools
    initCIELAB() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const c = this;
        this.CIELAB = {
            lighten: (amount) => {
                const lab = c.toCieLab();
                lab.l += amount / 100;
                lab.l = clamp01(lab.l);
                return CbColor.get(lab);
            },
            darken: (amount) => {
                return c.CIELAB.lighten(-amount);
            },
            setLightness: (value) => {
                const lab = c.toCieLab();
                lab.l = value;
                return CbColor.get(lab);
            },
            matchLightness: (color) => {
                const val = CbColor.get(color).CIELAB.lightness;
                const lab = c.toCieLab();
                lab.l = val;
                return CbColor.get(lab);
            },
            get lightness() {
                return c.toCieLab().l;
            },
        };
    }
    // YPbPr color space tools
    initYPbPr() {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const c = this;
        this.YPbPr = {
            lighten: (amount) => {
                const ypbpr = c.toYPbPr();
                ypbpr.y += (amount / 100) * 255;
                ypbpr.y = clamp01(ypbpr.y);
                return CbColor.get(ypbpr);
            },
            darken: (amount) => {
                return c.YPbPr.lighten(-amount);
            },
            setLightness: (value) => {
                const ypbpr = c.toYPbPr();
                ypbpr.y = value;
                return CbColor.get(ypbpr);
            },
            matchLightness: (color) => {
                const val = CbColor.get(color).YPbPr.lightness;
                const ypbpr = c.toYPbPr();
                ypbpr.y = val;
                return CbColor.get(ypbpr);
            },
            get lightness() {
                return c.toYPbPr().y;
            },
        };
    }
    // construction method
    static get(color = "", opts = {}) {
        return new CbColor(color, opts);
    }
    isDark() {
        return this.getBrightness() < 128;
    }
    isLight() {
        return !this.isDark();
    }
    /**
     * Returns the perceived brightness of the color, from 0-255.
     */
    getBrightness() {
        // http://www.w3.org/TR/AERT#color-contrast
        const rgb = this.toRgb();
        return (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
    }
    /**
     * Returns the perceived luminance of a color, from 0-1.
     */
    getLuminance() {
        // http://www.w3.org/TR/2008/REC-WCAG20-20081211/#relativeluminancedef
        const rgb = this.toRgb();
        const RsRGB = rgb.r / 255;
        const GsRGB = rgb.g / 255;
        const BsRGB = rgb.b / 255;
        const R = lumAlgorithm(RsRGB);
        const G = lumAlgorithm(GsRGB);
        const B = lumAlgorithm(BsRGB);
        return 0.2126 * R + 0.7152 * G + 0.0722 * B;
    }
    /**
     * Returns the alpha value of a color, from 0-1.
     */
    getAlpha() {
        return this.a;
    }
    /**
     * Sets the alpha value on the current color.
     *
     * @param alpha - The new alpha value. The accepted range is 0-1.
     */
    setAlpha(alpha) {
        this.a = boundAlpha(alpha);
        this.roundA = Math.round(100 * this.a) / 100;
        return this;
    }
    /**
     * Returns the object as a HSVA object.
     */
    toHsv() {
        const hsv = rgbToHsv(this.r, this.g, this.b);
        return { h: hsv.h * 360, s: hsv.s, v: hsv.v, a: this.a };
    }
    toCieLab() {
        const lab = rgbToLab(this.r, this.g, this.b);
        return { l: lab.l, a: lab.a, b: lab.b };
    }
    /**
     * Returns the hsva values interpolated into a string with the following format:
     * "hsva(xxx, xxx, xxx, xx)".
     */
    toHsvString() {
        const hsv = rgbToHsv(this.r, this.g, this.b);
        const h = Math.round(hsv.h * 360);
        const s = Math.round(hsv.s * 100);
        const v = Math.round(hsv.v * 100);
        return this.a === 1
            ? `hsv(${h}, ${s}%, ${v}%)`
            : `hsva(${h}, ${s}%, ${v}%, ${this.roundA})`;
    }
    /**
     * Returns the object as a HSLA object.
     */
    toHsl() {
        const hsl = rgbToHsl(this.r, this.g, this.b);
        return { h: hsl.h * 360, s: hsl.s, l: hsl.l, a: this.a };
    }
    /**
     * Returns the hsla values interpolated into a string with the following format:
     * "hsla(xxx, xxx, xxx, xx)".
     */
    toHslString() {
        const hsl = rgbToHsl(this.r, this.g, this.b);
        const h = Math.round(hsl.h * 360);
        const s = Math.round(hsl.s * 100);
        const l = Math.round(hsl.l * 100);
        return this.a === 1
            ? `hsl(${h}, ${s}%, ${l}%)`
            : `hsla(${h}, ${s}%, ${l}%, ${this.roundA})`;
    }
    /**
     * Returns the hex value of the color.
     * @param allow3Char will shorten hex value to 3 char if possible
     */
    toHex(allow3Char = false) {
        return rgbToHex(this.r, this.g, this.b, allow3Char);
    }
    /**
     * Returns the hex value of the color -with a # appened.
     * @param allow3Char will shorten hex value to 3 char if possible
     */
    toHexString(allow3Char = false) {
        return `#${this.toHex(allow3Char)}`;
    }
    /**
     * Returns the hex 8 value of the color.
     * @param allow4Char will shorten hex value to 4 char if possible
     */
    toHex8(allow4Char = false) {
        return rgbaToHex(this.r, this.g, this.b, this.a, allow4Char);
    }
    /**
     * Returns the hex 8 value of the color -with a # appened.
     * @param allow4Char will shorten hex value to 4 char if possible
     */
    toHex8String(allow4Char = false) {
        return `#${this.toHex8(allow4Char)}`;
    }
    /**
     * Returns the object as a RGBA object.
     */
    toRgb() {
        return {
            r: Math.round(this.r),
            g: Math.round(this.g),
            b: Math.round(this.b),
            a: this.a,
        };
    }
    toYPbPr() {
        return rgbToYPbPr(this.r, this.g, this.b);
    }
    /**
     * Returns the RGBA values interpolated into a string with the following format:
     * "RGBA(xxx, xxx, xxx, xx)".
     */
    toRgbString() {
        const r = Math.round(this.r);
        const g = Math.round(this.g);
        const b = Math.round(this.b);
        return this.a === 1
            ? `rgb(${r}, ${g}, ${b})`
            : `rgba(${r}, ${g}, ${b}, ${this.roundA})`;
    }
    /**
     * Returns the object as a RGBA object.
     */
    toPercentageRgb() {
        const fmt = (x) => `${Math.round(bound01(x, 255) * 100)}%`;
        return {
            r: fmt(this.r),
            g: fmt(this.g),
            b: fmt(this.b),
            a: this.a,
        };
    }
    /**
     * Returns the RGBA relative values interpolated into a string
     */
    toPercentageRgbString() {
        const rnd = (x) => Math.round(bound01(x, 255) * 100);
        return this.a === 1
            ? `rgb(${rnd(this.r)}%, ${rnd(this.g)}%, ${rnd(this.b)}%)`
            : `rgba(${rnd(this.r)}%, ${rnd(this.g)}%, ${rnd(this.b)}%, ${this.roundA})`;
    }
    /**
     * The 'real' name of the color -if there is one.
     */
    toName() {
        if (this.a === 0) {
            return "transparent";
        }
        if (this.a < 1) {
            return false;
        }
        const hex = `#${rgbToHex(this.r, this.g, this.b, false)}`;
        for (const [key, value] of Object.entries(names)) {
            if (hex === value) {
                return key;
            }
        }
        return false;
    }
    // eslint-disable-next-line complexity
    toString(format) {
        const formatSet = Boolean(format);
        format = format !== null && format !== void 0 ? format : this.format;
        let formattedString = false;
        const hasAlpha = this.a < 1 && this.a >= 0;
        const needsAlphaFormat = !formatSet &&
            hasAlpha &&
            (format.startsWith("hex") || format === "name");
        if (needsAlphaFormat) {
            // Special case for "transparent", all other non-alpha formats
            // will return rgba when there is transparency.
            if (format === "name" && this.a === 0) {
                return this.toName();
            }
            return this.toRgbString();
        }
        if (format === "rgb") {
            formattedString = this.toRgbString();
        }
        if (format === "prgb") {
            formattedString = this.toPercentageRgbString();
        }
        if (format === "hex" || format === "hex6") {
            formattedString = this.toHexString();
        }
        if (format === "hex3") {
            formattedString = this.toHexString(true);
        }
        if (format === "hex4") {
            formattedString = this.toHex8String(true);
        }
        if (format === "hex8") {
            formattedString = this.toHex8String();
        }
        if (format === "name") {
            formattedString = this.toName();
        }
        if (format === "hsl") {
            formattedString = this.toHslString();
        }
        if (format === "hsv") {
            formattedString = this.toHsvString();
        }
        return formattedString !== null && formattedString !== void 0 ? formattedString : this.toHexString();
    }
    toNumber() {
        return ((Math.round(this.r) << 16) +
            (Math.round(this.g) << 8) +
            Math.round(this.b));
    }
    clone() {
        return CbColor.get(this.toString());
    }
    /**
     * Brighten the color a given amount, from 0 to 100.
     * @param amount - valid between 1-100
     */
    brighten(amount = 10) {
        const rgb = this.toRgb();
        rgb.r = Math.max(0, Math.min(255, rgb.r - Math.round(255 * -(amount / 100))));
        rgb.g = Math.max(0, Math.min(255, rgb.g - Math.round(255 * -(amount / 100))));
        rgb.b = Math.max(0, Math.min(255, rgb.b - Math.round(255 * -(amount / 100))));
        return CbColor.get(rgb);
    }
    /**
     * Mix the color with pure white, from 0 to 100.
     * Providing 0 will do nothing, providing 100 will always return white.
     * @param amount - valid between 1-100
     */
    tint(amount = 10) {
        return this.mix("#FFFFFF", amount);
    }
    /**
     * Mix the color with pure black, from 0 to 100.
     * Providing 0 will do nothing, providing 100 will always return black.
     * @param amount - valid between 1-100
     */
    shade(amount = 10) {
        return this.mix("#000000", amount);
    }
    /**
     * Desaturate the color a given amount, from 0 to 100.
     * Providing 100 will is the same as calling greyscale
     * @param amount - valid between 1-100
     */
    desaturate(amount = 10) {
        const hsl = this.toHsl();
        hsl.s -= amount / 100;
        hsl.s = clamp01(hsl.s);
        return CbColor.get(hsl);
    }
    /**
     * Saturate the color a given amount, from 0 to 100.
     * @param amount - valid between 1-100
     */
    saturate(amount = 10) {
        const hsl = this.toHsl();
        hsl.s += amount / 100;
        hsl.s = clamp01(hsl.s);
        return CbColor.get(hsl);
    }
    /**
     * Completely desaturates a color into greyscale.
     * Same as calling `desaturate(100)`
     */
    greyscale() {
        return this.desaturate(100);
    }
    /**
     * Spin takes a positive or negative amount within [-360, 360] indicating the change of hue.
     * Values outside of this range will be wrapped into this range.
     */
    spin(amount) {
        const hsl = this.toHsl();
        const hue = (hsl.h + amount) % 360;
        hsl.h = hue < 0 ? 360 + hue : hue;
        return CbColor.get(hsl);
    }
    /**
     * Mix the current color a given amount with another color, from 0 to 100.
     * 0 means no mixing (return current color).
     */
    mix(color, amount = 50) {
        const rgb1 = this.toRgb();
        const rgb2 = CbColor.get(color).toRgb();
        const p = amount / 100;
        const rgba = {
            r: (rgb2.r - rgb1.r) * p + rgb1.r,
            g: (rgb2.g - rgb1.g) * p + rgb1.g,
            b: (rgb2.b - rgb1.b) * p + rgb1.b,
            a: (rgb2.a - rgb1.a) * p + rgb1.a,
        };
        return CbColor.get(rgba);
    }
    analogous(results = 6, slices = 30) {
        const hsl = this.toHsl();
        const part = 360 / slices;
        const ret = [this];
        for (hsl.h = (hsl.h - ((part * results) >> 1) + 720) % 360; 
        // eslint-disable-next-line no-plusplus
        --results;) {
            hsl.h = (hsl.h + part) % 360;
            ret.push(CbColor.get(hsl));
        }
        return ret;
    }
    /**
     * taken from https://github.com/infusion/jQuery-xcolor/blob/master/jquery.xcolor.js
     */
    complement() {
        const hsl = this.toHsl();
        hsl.h = (hsl.h + 180) % 360;
        return CbColor.get(hsl);
    }
    monochromatic(results = 6) {
        const hsv = this.toHsv();
        const { h } = hsv;
        const { s } = hsv;
        let { v } = hsv;
        const res = [];
        const modification = 1 / results;
        // eslint-disable-next-line no-plusplus
        while (results-- > 0) {
            res.push(CbColor.get({ h, s, v }));
            v = (v + modification) % 1;
        }
        return res;
    }
    splitcomplement() {
        const hsl = this.toHsl();
        const { h } = hsl;
        return [
            this,
            CbColor.get({ h: (h + 72) % 360, s: hsl.s, l: hsl.l }),
            CbColor.get({ h: (h + 216) % 360, s: hsl.s, l: hsl.l }),
        ];
    }
    /**
     * Compute how the color would appear on a background
     */
    onBackground(background) {
        const fg = this.toRgb();
        const bg = CbColor.get(background).toRgb();
        return CbColor.get({
            r: bg.r + (fg.r - bg.r) * fg.a,
            g: bg.g + (fg.g - bg.g) * fg.a,
            b: bg.b + (fg.b - bg.b) * fg.a,
        });
    }
    /**
     * Alias for `polyad(3)`
     */
    triad() {
        return this.polyad(3);
    }
    /**
     * Alias for `polyad(4)`
     */
    tetrad() {
        return this.polyad(4);
    }
    /**
     * Get polyad colors, like (for 1, 2, 3, 4, 5, 6, 7, 8, etc...)
     * monad, dyad, triad, tetrad, pentad, hexad, heptad, octad, etc...
     */
    polyad(n) {
        const hsl = this.toHsl();
        const { h } = hsl;
        const result = [this];
        const increment = 360 / n;
        for (let i = 1; i < n; i++) {
            result.push(CbColor.get({
                h: (h + i * increment) % 360,
                s: hsl.s,
                l: hsl.l,
            }));
        }
        return result;
    }
    /**
     * compare color vs current color
     */
    equals(color) {
        return this.toRgbString() === CbColor.get(color).toRgbString();
    }
    // #region Chaturbate-specific methods
    get isCurrentModeBg() {
        return document.body.classList.contains("darkmode")
            ? this.isDarkModeBg
            : this.isLightModeBg;
    }
    static get currentModeBg() {
        return document.body.classList.contains("darkmode")
            ? CbColor.darkModeBg
            : CbColor.lightModeBg;
    }
    get isCurrentModeFg() {
        return document.body.classList.contains("darkmode")
            ? this.isDarkModeFg
            : this.isLightModeFg;
    }
    static get currentModeFg() {
        return document.body.classList.contains("darkmode")
            ? CbColor.darkModeFg
            : CbColor.lightModeFg;
    }
    get isLightModeBg() {
        return this.equals(CbColor.lightModeBg);
    }
    static get lightModeBg() {
        return CbColor.get("#FFFFFF");
    }
    get isLightModeFg() {
        return this.equals(CbColor.lightModeFg);
    }
    static get lightModeFg() {
        return CbColor.get("#494949");
    }
    get isDarkModeBg() {
        return this.equals(CbColor.darkModeBg);
    }
    static get darkModeBg() {
        return CbColor.get("#202C39");
    }
    get isDarkModeFg() {
        return this.equals(CbColor.darkModeFg);
    }
    static get darkModeFg() {
        return CbColor.get("#FFFFFF");
    }
    // #endregion
    // #region Other helper methods
    /**
     *  Returns red, green, and blue numbers in an array.
     */
    toRgbArray(forceAlpha = false) {
        return this.a < 1 || forceAlpha
            ? [this.r, this.g, this.b, this.a]
            : [this.r, this.g, this.b];
    }
    get isBlack() {
        return this.equals("#000000");
    }
    static get black() {
        return CbColor.get("#000000");
    }
    get isWhite() {
        return this.equals("#FFFFFF");
    }
    static get white() {
        return CbColor.get("#FFFFFF");
    }
    get isTransparent() {
        return this.getAlpha() === 0;
    }
    static get transparent() {
        return CbColor.get("transparent");
    }
    isLessLuminousThan(value, inclusive = true) {
        const val = this.getLuminance() - toLum(value);
        return inclusive ? val <= 0 : val < 0;
    }
    isMoreLuminousThan(value, inclusive = true) {
        const val = this.getLuminance() - toLum(value);
        return inclusive ? val >= 0 : val > 0;
    }
    get rgbRange() {
        const arr = this.toRgbArray();
        const max = Math.max(...arr);
        const min = Math.min(...arr);
        return max - min;
    }
}
/** Color matching functions will be called from this color space. */
CbColor.globalSpace = ColorSpace.HSL;
// #region Helper functions
function lumAlgorithm(sRGB) {
    return sRGB <= 0.03928
        ? sRGB / 12.92
        : Math.pow((sRGB + 0.055) / 1.055, 2.4);
}
function toLum(value) {
    return typeof value === "number" ? value : CbColor.get(value).getLuminance();
}
// #endregion
