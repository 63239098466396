// specialoutgoingmessages.ts contains functions to detect if the message a user is sending
// via chat is actually a UI command

import {
    type IShortcodeRoomContext,
    isValidNumberString,
    ShortcodeParser,
} from "@multimediallc/web-utils/shortcodeParsing"
import { OutgoingMessageType, Shortcode } from "@multimediallc/web-utils/types"
import { roomDossierContext } from "../cb/interfaces/context"
import { i18n } from "./translation"
import type { IOutgoingMessage, IOutgoingShortcodeMessage } from "@multimediallc/web-utils/types"

// TODO(CBD-1028) remove this export indirection
export { ShortcodeParser } from "@multimediallc/web-utils/shortcodeParsing"
export {
    type IOutgoingMessage,
    type IOutgoingShortcodeMessage,
} from "@multimediallc/web-utils/types"

export interface ITipRequest {
    amount?: number
    message?: string
    usedCtrlS?: boolean
}

export interface IChatMessage extends IOutgoingMessage {messageData: string}

export interface ITipRequestMessage extends IOutgoingMessage {messageData: ITipRequest}

export interface IOutgoingMessageHandlers {
    onToggleDebugMode(): void
    onChatMessage(message: string): void
    onTipRequest(tipRequest: ITipRequest): void
    onShortcode?(shortcode: IOutgoingShortcodeMessage): void
}

export function pullRoomMetadata(): IShortcodeRoomContext {
    const dossier = roomDossierContext.getState()
    const { hasFanClub, room, userName } = dossier
    const isBroadcaster = userName === room
    return {
        hasFanClub,
        isBroadcaster,
    }
}

// parseTip returns an ITipRequest if this is a tip message, otherwise returns null
function parseDebug(): IOutgoingMessage {
    return { messageType: OutgoingMessageType.ToggleDebugMode }
}

// parseTip returns an ITipRequest if this is a tip message, otherwise returns null
function parseTip(messageParts: string[]): ITipRequestMessage {
    const tipRequest: ITipRequest = {}
    if (messageParts.length > 0) {
        const possibleAmount = Number(messageParts[0])
        if (isNaN(possibleAmount)) {
            tipRequest.message = messageParts.join(" ")
        } else {
            messageParts.shift()
            tipRequest.amount = possibleAmount
            if (messageParts.length > 0) {
                tipRequest.message = messageParts.join(" ")
            }
        }
    }
    return {
        messageData: tipRequest,
        messageType: OutgoingMessageType.TipRequest,
    }
}

const messageParsers = {
    "/tip": parseTip,
    "/debug": parseDebug,
}

export function parseSpecialMessage(message: string): IOutgoingMessage {
    const messageParts = message.match(/(\S+)/g)
    if (messageParts !== null) {
        const action = messageParts.shift() as string
        if (
            Boolean(
                Object.prototype.hasOwnProperty.call(messageParsers, action),
            )
        ) {
            return messageParsers[action as keyof typeof messageParsers](messageParts)
        }
    }
    return { messageType: OutgoingMessageType.Invalid }
}

export function parseOutgoingMessage(
    message: string,
    source = "",
): IOutgoingMessage {
    // Current live site accepts commands like "  /tip"
    if (message.trim().charAt(0) === "/") {
        const outputMessage = parseSpecialMessage(message)
        if (outputMessage.messageType !== OutgoingMessageType.Invalid) {
            return outputMessage
        }
    } else if (
        ShortcodeParser.isShortcodeSyntax(message, source)
    ) {
        return ShortcodeParser.parseShortcodeMessage(message, pullRoomMetadata())
    }
    return {
        messageType: OutgoingMessageType.ChatMessage,
        messageData: message,
    } as IChatMessage
}

export function errorBehindShortcode(message: string): string { // eslint-disable-line complexity
    const { hasFanClub, isBroadcaster } = pullRoomMetadata()

    const shortcodes = message.match(ShortcodeParser.shortcodeRegex)
    if (shortcodes !== null) {
        if (shortcodes.length > 5) {
            return i18n.tooManyShortcodes
        }

        for (const shortcode of shortcodes) {
            if (
                !hasFanClub &&
                new RegExp(/\[cb:fanclub\]/i).test(message)
            ) {
                return i18n.noFanClub
            }
            if (ShortcodeParser.getCode(shortcode) === Shortcode.Tip) {
                const tipError = errorBehindTipShortcode(
                    shortcode,
                    isBroadcaster,
                )
                if (tipError !== undefined) {
                    return tipError
                }
            }
        }
    }
    return i18n.shortcodeEnteredError(message)
}

export function errorBehindTipShortcode(
    shortcode: string,
    isBroadcaster: boolean,
): string | undefined {
    if (!isBroadcaster) {
        return i18n.tipNotBroadcaster
    }
    // check arguments present and any value is included
    if (!ShortcodeParser.hasTipArguments(shortcode) ||
        !ShortcodeParser.amountAnyRegex.test(shortcode) ||
        !ShortcodeParser.messageAnyRegex.test(shortcode)) {
        return i18n.tipShortcodeArgsMissing
    }
    // Check for invalid amount and give error
    const amount = shortcode.match(ShortcodeParser.amountAnyRegex)
    if (amount !== null) {
        const amountString = amount[0].split("=")[1]
        if (!isValidNumberString(amountString)) {
            return i18n.inValidTipAmount
        }
    }
    // Check for invalid message and give error
    if (ShortcodeParser.hasErrorInMessageArg(shortcode)) {
        return i18n.tipShortcodeMessageInDoubleQuotes
    }
    const url = shortcode.match(ShortcodeParser.urlRegex)
    if (url !== null) {
        return i18n.shortcodeURLNotAllowedInTip
    }

    // no error found
    return
}
