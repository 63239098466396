// Function to check if element is present in the current scrollview area of the parent container
export function isScrolledIntoView(element, scrollDiv) {
    if (!scrollDiv.contains(element)) {
        return false;
    }
    const parentViewTop = scrollDiv.scrollTop;
    const parentViewBottom = parentViewTop + scrollDiv.offsetHeight;
    const elemTop = element.offsetTop;
    const elemBottom = elemTop + element.offsetHeight;
    return elemTop <= parentViewBottom && elemBottom >= parentViewTop;
}
export function removeAllChildren(el) {
    while (el.firstChild) {
        el.removeChild(el.firstChild);
    }
}
