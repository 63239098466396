import { UserIgnoreTopic, UserMessageTopic, UserTipAlertTopic, UserTokenUpdateTopic, UserTypingTopic } from "../../pushservicelib/topics/user"
import { DmPopout } from "../pm/dmPopout"
import { DMUnreadData } from "../pm/dmUnreadData"
import { createDmWindowRequest } from "../pm/dmWindowsManager"


const topics = { UserMessageTopic, UserTokenUpdateTopic, UserIgnoreTopic, UserTypingTopic, UserTipAlertTopic }
const interfaces = {
    // Use getter to prevent DMUnreadData from initializing until it's actually needed, avoiding unnecessary API calls on import
    get dmUnreadData() { return DMUnreadData.getInstance() },
    dmPopout: DmPopout,
}
const eventRouters = { createDmWindowRequest }

export const messagingProps = {
    topics,
    interfaces,
    eventRouters,
} as const

export const getCacheBustRoute = (url: string): string => {
    const delimiter = url.includes("?") ? "&" : "?"
    return `${url}${delimiter}cb=${Date.now()}`
}

