import { isMobileDevice } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { isAnonymous } from "../../../common/auth"
import { DropDownComponent } from "../../../common/dropDownComponent"
import { featureFlagIsActive } from "../../../common/featureFlag"
import { isCharacterKey } from "../../../common/ischaracterkey"
import { addPageAction } from "../../../common/newrelic"
import { SIGN_UP_BASE_PATH } from "../../../common/redirectParamUtils"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { addColorClass, removeColorClass } from "../../colorClasses"
import { showLoginOverlay } from "../../ui/loginOverlay"
import { ConversationList } from "./conversationList"
import { ConversationListData } from "./conversationListData"
import { DmPopout } from "./dmPopout"
import { DMUnreadData } from "./dmUnreadData"
import { DmWindow } from "./dmWindow"
import { createDmWindowRequest, DmWindowsManager } from "./dmWindowsManager"

interface IDmListDropdownProps {toggleElement: HTMLElement}

export class DmListDropdown extends DropDownComponent {
    private numUnreadContainer: HTMLSpanElement
    private popoutLink?: HTMLButtonElement
    private dmList: ConversationList
    private convosOpenedWithoutClosingDropdown = 0
    private isRefactorDMFlagActive: boolean
    private conversationListData: ConversationListData | undefined
    toggleElement: HTMLElement

    constructor(props: IDmListDropdownProps) {
        super(props.toggleElement, true, props)
        addEventListenerPoly("keydown", document, (e: KeyboardEvent) => {
            if (!this.isShown()) {
                return
            }

            const dmListFocused = this.dmList.element.contains(document.activeElement)
            const dmWindowFocused = DmWindowsManager.getInstance()?.element.contains(document.activeElement) === true
            if (e.key === "Tab") {
                if (dmListFocused || !this.element.contains(document.activeElement)) {
                    this.popoutLink?.focus()
                } else {
                    this.dmList.focusSearchBar()
                }
                e.stopPropagation()
                e.preventDefault()
            } else if (e.key === "Escape") {
                this.hideElement()
            } else if (!dmListFocused && !dmWindowFocused && (["ArrowUp", "ArrowDown"].includes(e.key) || isCharacterKey(e.keyCode))) {
                this.dmList.focusSearchBar()
            }
        })
        DmPopout.showingEvent.listen(() => this.hideElement())
        createDmWindowRequest.listen(() => {
            if (this.isShown()) {
                this.convosOpenedWithoutClosingDropdown += 1
                addPageAction("OpenedDmFromDropdown", { "seq_convos_opened": this.convosOpenedWithoutClosingDropdown })
            }
        })

        if (this.isRefactorDMFlagActive) {
            DMUnreadData.unreadDataUpdated.listen(({ unread }) => {
                this.updateNumUnread(unread)
            })
        } else {
            ConversationListData.unreadConversationsCountUpdate.listen(({ dmsCount }) => {
                this.updateNumUnread(dmsCount)
            })
        }
    }

    protected initUI(props: IDmListDropdownProps): void {
        const isAnon = isAnonymous()
        const containerStyle: CSSX.Properties = {
            fontFamily: "UbuntuRegular, Tahoma, Arial, Helvetica, sans-serif",
            fontSize: "14px",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "4px 0 4px 4px",
            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.32)",
            height: isAnon ? "" : "383px",
            maxHeight: `calc(var(--vh, 1vh) * 100 - ${DmWindow.expandedHeight} - 44px)`,
            minHeight: "280px",
            padding: "1px",
            position: "absolute",
            right: "0",
            width: "304px",
            display: "none",
            flexDirection: "column",
        }
        const headerStyle: CSSX.Properties = {
            padding: "10px 8px",
            borderBottomWidth: "1px",
            borderBottomStyle: "solid",
        }
        const numUnreadStyle: CSSX.Properties = { marginLeft: "4px" }
        const popoutIconStyle: CSSX.Properties = {
            display: "inline-block",
            position: "absolute",
            right: "8px",
            top: "12px",
            height: "13px",
            width: "13px",
            border: "none",
            cursor: "pointer",
        }
        this.isRefactorDMFlagActive = featureFlagIsActive("RefactorDm")

        this.dmList = new ConversationList({
            isDms: true,
            clearSearchOnSelect: isMobileDevice(),
            isFullVideoMode: false,
            openConversationEvent: createDmWindowRequest,
        })

        this.element = 
            <div style={containerStyle} colorClass="dmListDropdownRoot" data-testid="dm-list-dropdown">
                <div style={headerStyle} colorClass="header">
                    <span>{i18n.directMessageLabel}</span>
                    <span style={numUnreadStyle}
                        ref={(el: HTMLSpanElement) => this.numUnreadContainer = el}
                    />
                    {!isAnon && <button style={popoutIconStyle}
                        title={i18n.popoutInNewWindow}
                        colorClass="popoutIcon"
                        data-testid="popout-dm-icon"
                        ref={(el: HTMLButtonElement) => this.popoutLink = el}
                        onClick={() => {
                            this.hideElement()
                            const lastDM = this.dmList.getLastConversation()
                            // Check for an open conversation with a new user with no messages sent yet
                            if (lastDM !== undefined && lastDM.message === "" && !lastDM.hasMedia) {
                                DmPopout.show(lastDM?.otherUser?.username)
                            } else {
                                DmPopout.show()
                            }
                        }
                        }
                    />}
                </div>
                {isAnon ? <AnonContent /> : this.dmList.element}
            </div>
    }

    protected initData(): void {
        this.isRefactorDMFlagActive = featureFlagIsActive("RefactorDm")
        if (this.isRefactorDMFlagActive && !isAnonymous()) {
            this.conversationListData = ConversationListData.getInstance()
        }
    }

    public showElement(): boolean {
        if (this.isRefactorDMFlagActive && this.conversationListData && !this.conversationListData.isInitialDMFetchComplete()) {
            this.dmList.getMessageThreads()
        }
        addPageAction("DMListOpened", { "source": "desktop" })
        addColorClass(this.toggleElement, "active")
        this.convosOpenedWithoutClosingDropdown = 0
        return super.showElement("flex")
    }

    public hideElement(evt?: Event): boolean {
        if (!isMobileDevice() && evt?.target instanceof HTMLElement) {
            // A number of components in the DmWindow are removed from the dom when clicked, and so won't be detected as
            // contained by DmWindowManager. We could check for them individually, but it's easy to miss cases like that.
            // It is possible to wind up with false positives with !document.body.contains(evt.target), but I think that's
            // ok, it just means that the click's action was to close that element not to close this element
            if (DmWindowsManager.getInstance()?.element.contains(evt.target) === true || !document.body.contains(evt.target)) {
                return false
            }
        }
        const hidden = super.hideElement(evt)
        if (hidden) {
            removeColorClass(this.toggleElement, "active")
        }
        return hidden
    }

    private updateNumUnread(numUnread: number): void {
        const hasUnread = numUnread > 0
        this.numUnreadContainer.innerText = hasUnread ? `(${numUnread})` : ""

        if (hasUnread) {
            this.toggleElement.classList.add("has_unread")
            this.numUnreadContainer.dataset.testid = "unread-conversation-count"
        } else {
            this.toggleElement.classList.remove("has_unread")
        }
    }
}

const AnonContent = (): HTMLDivElement => {
    const containerStyle: CSSX.Properties = {
        fontSize: "12px",
        marginTop: "0",
        padding: "44px 0 16px",
        position: "static",
        textAlign: "center",
        top: "50%",
        width: "100%",
    }
    const sendDmTextStyle: CSSX.Properties = {
        fontSize: "14px",
        lineHeight: "18px",
        margin: "8px 0",
    }
    const loginContainerStyle: CSSX.Properties = { margin: "16px 0 44px" }
    const linkTextStyle: CSSX.Properties = {
        cursor: "pointer",
        fontSize: "12px",
    }
    const emptyChatIconSrc = `${STATIC_URL}pms/empty-chat-state.svg`
    const showLogin = (e: MouseEvent) => {
        e.preventDefault()
        showLoginOverlay({ fromFeature: true })
    }

    return (
        <div style={containerStyle}>
            <img src={emptyChatIconSrc} alt="empty-chat-state" />
            <div style={sendDmTextStyle} colorClass="sendDmPrompt" data-testid="send-dm-prompt">{i18n.sendDirectMessage}</div>
            <div style={loginContainerStyle}>
                <a style={linkTextStyle} colorClass="link" onClick={showLogin} account-redirect data-testid="dm-login">{i18n.loginVerbText}</a>
            </div>
            <div colorClass="signupPrompt" data-testid="signup-prompt">
                {i18n.dontHaveAccount}{" "}
                <a
                    href={SIGN_UP_BASE_PATH}
                    style={linkTextStyle}
                    colorClass="link"
                    onClick={() => { addPageAction("PMSignupClicked") }}
                    account-redirect
                    data-testid="signup-prompt-link"
                >
                    {i18n.signUpLower}
                </a>
            </div>
        </div>
    )
}
