import { RoomStatus } from "@multimediallc/web-utils"
import { Component } from "../defui/component"
import { i18n } from "../translation"
import { dom } from "../tsxrender/dom"
import { HeaderFollowButton } from "./headerFollowButton"
import { userCountUpdate } from "./userList"

export class HeaderRoomInfo extends Component {
    private roomName: string
    private roomStatus: RoomStatus
    private numViewers: number
    private roomNameElement: HTMLSpanElement
    private roomStatusElement: HTMLSpanElement
    private numViewersElement: HTMLSpanElement
    private followButton: HeaderFollowButton

    constructor() {
        super()
    }

    protected initUI(): void {
        const mainContainerStyle: CSSX.Properties = {
            margin: "0 10px",
            display: "flex",
            boxSizing: "border-box",
            minWidth: "inherit",
        }
        const roomInfoContainerStyle: CSSX.Properties = {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            marginRight: "8px",
            flex: 1,
            minWidth: "inherit",
            textAlign: "right",
        }
        const wrapText: CSSX.Properties = {
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            textAlign: "right",
            overflow: "hidden",
            width: "100%",
        }
        const roomNameStyle: CSSX.Properties = {
            fontFamily: "'UbuntuBold', Arial, Helvetica, sans-serif",
            color: "#FFF",
            fontWeight: 700,
            fontSize: "14px",
            display: "block",
            ...wrapText,
        }
        const numViewersStyle: CSSX.Properties = {
            fontSize: "12px",
            color: "#A7A7A7",
            display: "inline-block",
            ...wrapText,
        }

        this.followButton = new HeaderFollowButton()
        this.element =
            <div style={mainContainerStyle}>
                <div style={roomInfoContainerStyle}>
                    <span style={roomNameStyle} ref={(el: HTMLSpanElement) => this.roomNameElement = el} />
                    <span style={numViewersStyle}>
                        <span ref={(el: HTMLSpanElement) => this.roomStatusElement = el} />
                        <span ref={(el: HTMLSpanElement) => this.numViewersElement = el} />
                    </span>
                </div>
                {this.followButton.element}
            </div>


        userCountUpdate.listen((count: number) => {
            this.updateViewerCount(count)
        })
    }

    public updateContext(roomName: string, isFollowing: boolean, roomStatus: RoomStatus): void {
        this.roomName = roomName
        this.followButton.updateContext(this.roomName, isFollowing)
        this.updateRoomNameText()
        this.updateStatus(roomStatus)
    }

    public updateStatus(roomStatus: RoomStatus): void {
        this.roomStatus = roomStatus
        this.updateRoomStatusText()
    }

    public updateViewerCount(count: number): void {
        this.numViewers = count
        this.updateRoomStatusText()
    }

    private updateRoomNameText(): void {
        this.roomNameElement.innerText = this.roomName
    }

    private updateRoomStatusText(): void {
        let statusText = `${i18n.live} • `
        let showViewerCount = true

        switch (this.roomStatus) {
            case RoomStatus.Offline:
            case RoomStatus.Unknown:
                statusText = i18n.offline
                showViewerCount = false
                break
            case RoomStatus.Away:
                statusText = `${i18n.away} • `
                break
            case RoomStatus.NotConnected:
                statusText = i18n.connecting
                showViewerCount = false
                break
        }
        this.roomStatusElement.innerText = statusText
        this.numViewersElement.innerText = showViewerCount ? `${i18n.numViewerOrViewers(this.numViewers)}` : ""
    }
}
