import { pageContext } from "../../cb/interfaces/context"
import { modalAlert } from "../alerts"
import { getChatMode } from "../chatSettings"
import { SubSystemType } from "../debug"
import { applyStyles } from "../DOMutils"
import { featureFlagIsActive } from "../featureFlag"
import { exitFullscreen, fullscreenChange, isFullscreen } from "../fullscreen"
import { addPageAction } from "../newrelic"
import { FullscreenDropdown } from "../theatermodelib/fullscreenDropdown"
import { VIDEO_CONTROLS_ICON_PATH } from "../theatermodelib/theaterVideoControls"
import { i18n } from "../translation"
import { parseQueryString } from "../urlUtil"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import { documentVisibilityChange } from "../windowUtils"
import { HlsPlayer, pictureInPictureChange } from "./hlsPlayer"
import { getQualityInfo, saveQualityInfo } from "./playerSettings"
import { shouldUseCustomControls } from "./utils"
import type { IQualityLevel } from "./playerSettings"
import type { IRoomContext } from "../context"
import type { RoomStatusNotifier } from "../roomStatusNotifier"
import type Hls from "hls.js"
import type { Events, ManifestLoadedData } from "hls.js"

const Videojs = window["videojs"]
const HlsObj = window["Hls"] satisfies Hls
const MaxEmptyChunklistsToHandle = 5

export class VideoJsPlayer extends HlsPlayer {
    private videoJs: videojs.Player | undefined
    private tech: videojs.Tech | undefined
    private lastTimeUpdate = 0
    private isMuted = true
    private neverPlayed = false
    private userUnmuted = false
    private chromecastOverlay = document.createElement("div")
    private chatMode: string
    private setLevels: IQualityLevel[]
    private playerId: string
    private fullscreenDropdownBtn: HTMLElement | undefined
    private shouldExitToIFS = true
    private isFullWindow = false
    private hls: Hls
    private handledEmptyChunklistsCnt = 0
    private currentEmptyChunklistsTimeout: number | undefined

    protected createPlayerOption(): videojs.PlayerOptions {
        return {
            techOrder: ["html5"],
            autoplay: true,
            html5: {
                hlsjsConfig: {
                    capLevelToPlayerSize: pageContext.current.loggedInUser === undefined,
                    debug: false,
                    liveSyncDuration: 3,
                    liveSyncDurationCount: undefined,
                    liveDurationInfinity: Infinity,
                    liveMaxLatencyDuration: 7,
                    liveMaxLatencyDurationCount: undefined,
                    liveBackBufferLength: 30,
                    autoStartLoad: false,
                },
                nativeAudioTracks: false,
            },
            controlBar: {
                playToggle: false,
                progressControl: false,
                remainingTimeDisplay: false,
                durationDisplay: false,
                currentTimeDisplay: false,
                timeDivider: false,
                pictureInPictureToggle: false,
            },
            errorDisplay: false,
        }
    }

    constructor(roomStatusNotifier: RoomStatusNotifier, isEmbed: boolean) {
        super(roomStatusNotifier, "VideoJsPlayer", isEmbed)
        // class needed for videojs to properly set up
        this.videoElement.className = "video-js vjs-default-skin"
        Videojs["log"].level("error")
        this.playerType = "VideoJS"

        this.setupLLHLSButton()

        this.chromecastOverlay.style.position = "absolute"
        this.chromecastOverlay.style.backgroundColor = "#000000"
        this.chromecastOverlay.style.width = "inherit"
        this.chromecastOverlay.style.height = "inherit"

        const playerOptions = this.createPlayerOption()

        this.element.onclick = () => {
            if (this.neverPlayed) {
                if (shouldUseCustomControls()) {
                    this.showCustomControls()
                } else {
                    this.showNativeControls()
                }
                this.neverPlayed = false
            }
        }
        if (featureFlagIsActive("VDPEnblTabSwitch")) {
            documentVisibilityChange.listen((isVisible: boolean) => {
                if (isVisible && this.enableLLHLS) {
                    // Force hls.js to adjust latency
                    this.videoElement.currentTime += 60
                }
            })
        }
        const vjs = Videojs(this.videoElement, playerOptions)
        vjs.qualityPickerPlugin()
        this.videoJs = vjs
        this.playerId = this.videoElement.id

        if (vjs !== undefined) {
            this.videoMetrics.bindAllHTMLMedia(vjs, this.element)
            if (Videojs.Html5Hlsjs !== undefined) {
                Videojs.Html5Hlsjs.addHook("beforeinitialize", (player: object, hls: Hls): void => {
                    if (player === vjs) {
                        this.videoMetrics.bindAllHlsJs(hls)
                    }
                    // eslint-disable-next-line complexity
                    hls.on(HlsObj.Events.MANIFEST_LOADED, (event: Events.MANIFEST_LOADING, data: ManifestLoadedData): void => {
                        const savedInfo = getQualityInfo()
                        const queryQuality = Number(parseQueryString(window.location.search)["quality"])
                        if (queryQuality !== undefined && !isNaN(queryQuality) && data.levels !== undefined && queryQuality <= data.levels.length - 1 && queryQuality >= 0) {
                            hls.startLevel = queryQuality
                            hls.loadLevel = queryQuality
                        } else if(data.levels !== undefined && savedInfo !== "auto"){
                            let startingIndex = -1
                            // loop through Hls.Data.levels, an array of ascending quality levels, to find the highest and closest quality to the saved value without going over
                            for(const level of data.levels){
                                if(level["height"] !== undefined && level["height"] <= parseInt(savedInfo, 10)){
                                    startingIndex = data.levels.indexOf(level)
                                }
                            }
                            hls.startLevel = startingIndex
                        }
                    })
                    hls.on(HlsObj.Events.MANIFEST_LOADED, () => {
                        hls.startLoad()
                    })
                    hls.on(HlsObj.Events.LEVEL_SWITCHED, () => {
                        if(this.setLevels !== undefined && this.setLevels[this.setLevels.length - 1].toggled){
                            saveQualityInfo(this.setLevels, hls.currentLevel)
                        }
                    })
                    this.hls = hls
                    this.setLLHLSConfig()
                    this.listenForEmptyChunklist(hls)
                })
            }
            this.playableHlsPlayer = vjs

            this.setupChromecastStateChange()

            vjs.on("volumechange", () => {
                this.setVolume(this.getVolume(false))
            })
            vjs.on("canplay", () => {
                this.play()
                if (this.isStreamReconnecting) {
                    this.removeReconnecting()
                }
            })
            vjs.on("canplaythrough", () => {
                this.play()
            })
            vjs.on("enterpictureinpicture", () => {
                debug("enterpictureinpicture")
                pictureInPictureChange.fire({ active: true })
            })
            vjs.on("leavepictureinpicture", () => {
                debug("leavepictureinpicture")
                // timeout because video pauses after event on close button leave
                window.setTimeout(() => {
                    pictureInPictureChange.fire({
                        active: false,
                        videoPaused: vjs.paused(),
                    })
                }, 0)
            })
            vjs.on("touchstart", (evt) => {
                const volContainer = document.getElementsByClassName("vjs-volume-panel")
                volContainer[0].classList.add("vjs-hover")
                // VJS stops touch events from firing click events by default, so manually refire the click event
                // don't trigger ghost clicks on control bar or pause when trying to show the control bar
                if (
                    !vjs["controlBar"].el().contains(evt.target) &&
                    !vjs.isFullscreen() ||
                    this.fullscreenDropdownBtn?.contains(evt.target) === true
                ) {
                    evt.target.click()
                }
            })
            this.addFullscreenListeners()
        }

        if (shouldUseCustomControls()) {
            this.showCustomControls()
        } else {
            this.showNativeControls()
        }

        window["videoJsPlayer"] = vjs // for debugging

        getChatMode.listen((mode: string) => {
            this.chatMode = mode
        }).addTo(this.listeners)

        if(vjs["controlBar"] !== undefined) {
            vjs["controlBar"].setAttribute("data-paction", "VideoJSControls") // eslint-disable-line @multimediallc/no-set-attribute
            this.addFullscreenDropdown()
        }
    }

    private listenForEmptyChunklist(hls: Hls): void {
        const originalXHR = XMLHttpRequest
        const handler = (ev: Event) => {
            const xhr = ev.target as XMLHttpRequest
            if (xhr.responseURL.includes("chunklist") && xhr.status === 200 && xhr.responseText.length === 0
                && hls !== undefined) {
                if (this.currentEmptyChunklistsTimeout === undefined) {
                    if (this.handledEmptyChunklistsCnt < MaxEmptyChunklistsToHandle) {
                        this.handledEmptyChunklistsCnt += 1
                        this.currentEmptyChunklistsTimeout = window.setTimeout(() => {
                            this.refreshStreamOnSameEdge(this.context)
                            this.currentEmptyChunklistsTimeout = undefined
                        }, 500)
                    }
                } else {
                    // This may happen e.g. if retry was set up by empty video chunklist, but empty audio chunklist arrived
                    // just after that. In that case there is no need to set up additional timeout.
                    return
                }
            }
        }

        // @ts-ignore - we are modifying the original object
        window.XMLHttpRequest = function() {
            const xhr = new originalXHR()
            xhr.addEventListener("loadend", handler)
            return xhr
        }
    }

    private setupChromecastStateChange(): void {
        let tries = 0
        const MAX_TRIES = 10
        const intervalFunc = window.setInterval(() => {
            if (tries >= MAX_TRIES) {
                clearInterval(intervalFunc)
            }
            if (this.videoJs !== undefined &&
                this.videoJs.chromecastSessionManager !== undefined) {
                const chromecastSessionManager = this.videoJs.chromecastSessionManager
                const castContext = chromecastSessionManager.getCastContext()
                castContext.addEventListener("sessionstatechanged", (event: cast.framework.SessionStateEventData) => {
                    if (event.sessionState !== undefined) {
                        switch (event.sessionState) {
                            case "SESSION_STARTED":
                            case "SESSION_RESUMED":
                                this.removeTech()
                                this.removeEventListeners()
                                this.stop()
                                // CAF Receiver sets vol to mute on start, need to update video control after that
                                window.setTimeout(() => {
                                    this.isCasting = true
                                    this.refreshStream(this.context)
                                    this.setVolume(this.getVolume(false))
                                    this.addChromecastMsgListener()
                                    if (this.videoJs !== undefined && this.videoJs.tech()["_ui"] !== undefined) {
                                        this.videoJs.tech()["_ui"]._findTitleEl().style.visibility = "hidden"
                                    }
                                }, 1000)
                                this.roomStatusNotifier.hide()
                                this.addChromecastOverlay()
                                break
                            case "SESSION_ENDED":
                                // Need to wait until browser's video player is initiated after casting ends and set vol
                                const setupInterval = window.setInterval(() => {
                                    if (document.getElementById(this.playerId) !== null && this.videoJs !== undefined) {
                                        this.videoJs.muted(this.isMuted)
                                        this.videoJs.volume(this.getVolume(false))
                                        window.clearInterval(setupInterval)
                                    }
                                }, 100)
                                window.setTimeout(() => {
                                    window.clearInterval(setupInterval)
                                    if (this.videoJs !== undefined) {
                                        this.videoJs.muted(this.isMuted)
                                        this.videoJs.volume(this.getVolume(false))
                                    }
                                    this.isCasting = false
                                    this.refreshStream(this.context)
                                    this.addTech()
                                    this.addEventListeners()
                                }, 2500)
                                this.removeChromecastOverlay()
                                this.roomStatusNotifier.show()
                                break
                        }
                    }
                })
                clearInterval(intervalFunc)
            }
            tries += 1
        }, 1000)
    }

    protected resetRetryCounter(): void {
        if (this.currentEmptyChunklistsTimeout !== undefined) {
            clearTimeout(this.currentEmptyChunklistsTimeout)
            this.currentEmptyChunklistsTimeout = undefined
        }
        this.handledEmptyChunklistsCnt = 0
    }

    private checkTimeUpdate = (context: IRoomContext) => {
        if (this.videoJs !== undefined) {
            const currentTime = this.videoJs.currentTime()
            if (currentTime - this.lastTimeUpdate > 5) {
                this.lastTimeUpdate = currentTime
                this.setTooMuchWaitingTimeout(context, () => {
                    this.lastTimeUpdate = 0
                })
                this.tryingTimeoutRefresh = false
            }
        }
    }

    protected loadHlsStream(context: IRoomContext, source: string): void {
        if (source === "") {
            this.stop()
            return
        } else {
            this.roomStatusNotifier.hide()
        }

        if (this.isCasting) {
            this.addChromecastOverlay()
            this.roomStatusNotifier.hide()
            this.stopTooMuchWaitingTimeout()
        } else {
            this.setTooMuchWaitingTimeout(context)
        }

        if (this.videoJs !== undefined) {
            if (this.isCasting) {
                this.videoJs.tech().setSource({ src: source, type: "application/x-mpegURL" })
            } else if (!this.isCasting) {
                this.videoJs.on("playing", () => {
                    this.setTooMuchWaitingTimeout(context)
                })
                this.videoJs.on("waiting", () => {
                    this.setTooMuchWaitingTimeout(context)
                })
                this.videoJs.on("timeupdate", (ev) => {
                    this.checkTimeUpdate(context)
                })
                this.videoJs.off("error")
                this.videoJs.on("error", (e) => {
                    // error codes
                    // 0 string MEDIA_ERR_CUSTOM
                    // 1 string MEDIA_ERR_ABORTED
                    // 2 string MEDIA_ERR_NETWORK
                    // 3 string MEDIA_ERR_DECODE
                    // 4 string MEDIA_ERR_SRC_NOT_SUPPORTED
                    // 5 string MEDIA_ERR_ENCRYPTED
                    if (this.videoJs !== undefined) {
                        const thisError = this.videoJs.error()
                        if (thisError !== null && (thisError.code === 2 || thisError.code === 4)) {
                            if (!this.isStreamReconnecting) {
                                this.refreshStreamOnSameEdge(context)
                            }
                            error(thisError.message, e, SubSystemType.Video)
                        }
                    } else {
                        error("Videojs error type undefined", e, SubSystemType.Video)
                    }
                })

                this.videoJs.src({ src: source, type: "application/x-mpegURL" })
                this.videoJs.qualityPickerPlugin()
                this.videoJs.show()
                this.videoJs.muted(this.isMuted)
                this.videoElement.style.display = "inline"
            }
            this.playableHlsPlayer = this.videoJs
            this.addTech()
        } else {
            modalAlert("something went wrong")
            return
        }
    }

    private addFullscreenListeners(): void {
        if (this.videoJs === undefined) {
            return
        }

        // use full window when switching to native fullscreen from IFS
        this.videoJs.on("enterFullWindow", () => {
            this.showNativeControls()
            this.showElement()
            this.isFullWindow = true
            videoModeHandler.setFireVideoMode(VideoMode.Fullscreen)
        })

        this.videoJs.on("exitFullWindow", () => {
            this.isFullWindow = false
            if (!this.shouldExitToIFS) {
                exitFullscreen()
            }
            fullscreenChange.fire()
            this.shouldExitToIFS = true
        })

        fullscreenChange.listen(() => {
            if (this.videoJs !== undefined && !isFullscreen() && this.isFullWindow) {
                this.videoJs.exitFullWindow()
            }
        })
    }

    private addFullscreenDropdown(): void {
        if (this.videoJs === undefined) {
            return
        }
        const fullscreenDropdownBtn  = this.videoJs["controlBar"].addChild("button", {
            controlText: "Toggle Interactive Fullscreen",
            className: "vjs-fullscreen-dropdown",
        })
        this.fullscreenDropdownBtn = fullscreenDropdownBtn.el() as HTMLElement
        const fullscreenEllipsisImg = document.createElement("img")
        fullscreenEllipsisImg.src = `${VIDEO_CONTROLS_ICON_PATH}ellipsis-vertical.svg`
        fullscreenEllipsisImg.title = i18n.switchFullscreenModeLabel
        applyStyles(fullscreenEllipsisImg, {
            height: "16px",
            userSelect: "none",
        })
        applyStyles(this.fullscreenDropdownBtn, { cursor: "pointer" })
        this.fullscreenDropdownBtn.appendChild(fullscreenEllipsisImg)
        const fullscreenDropdown = new FullscreenDropdown({
            toggleElement: this.fullscreenDropdownBtn,
            enterNativeFn: () => {},
            enterInteractiveFn: () => this.exitFullScreenMode(),
        })
        applyStyles(fullscreenDropdown.element, { right: "20px" })
        fullscreenDropdown.nativeOption.classList.add("active")
        this.fullscreenDropdownBtn.appendChild(fullscreenDropdown.element)

        const fullscreenToggle = this.videoJs["controlBar"].getChild("fullscreenToggle") as videojs.Button
        if (fullscreenToggle !== undefined) {
            // differentiate between exiting fullscreen and switching fullscreen modes
            fullscreenToggle.handleClick = () => {
                this.shouldExitToIFS = false
                this.exitFullScreenMode()
            }
        }
    }

    public removeFullscreenDropdown(): void {
        if (this.fullscreenDropdownBtn === undefined) {
            return
        }
        this.fullscreenDropdownBtn.parentNode?.removeChild(this.fullscreenDropdownBtn)
        this.fullscreenDropdownBtn = undefined
    }

    private addChromecastMsgListener(): void {
        if (this.isCasting && this.videoJs !== undefined &&
            this.videoJs["chromecastSessionManager"] !== undefined) {
            const chromecastSessionManager = this.videoJs.chromecastSessionManager
            const castContext = chromecastSessionManager.getCastContext()
            const currentSession = castContext.getCurrentSession()

            if (currentSession !== undefined) {
                let chromecastResetFunc = 0
                currentSession.addMessageListener("urn:x-cast:com.highwebmedia.cast.media", (namespace: string, message: string) => {
                    if (this.isCasting && message.includes("PAUSED")) {
                        clearTimeout(chromecastResetFunc)
                        chromecastResetFunc = window.setTimeout(() => {
                            this.refreshStream(this.context)
                        }, 2500)
                    } else if (this.isCasting && message.includes("BUFFERING")) {
                        clearTimeout(chromecastResetFunc)
                        chromecastResetFunc = window.setTimeout(() => {
                            this.refreshStream(this.context)
                        }, 7500)
                    }  else if (!this.isCasting || message.includes("PLAYING")) {
                        clearTimeout(chromecastResetFunc)
                    } else if (this.isCasting && message.includes("MEDIA_ERROR")) {
                        this.refreshStreamOnNewEdge(this.context)
                    }
                })
            }
        }
    }

    public setVolume(volume: number): void {
        if (this.videoJs !== undefined) {
            const prevIsMuted = this.isMuted
            this.isMuted = volume === 0 || this.videoJs.muted()
            if (prevIsMuted && !this.isMuted && !this.userUnmuted) {
                this.userUnmuted = true
                addPageAction("UserUnmuted", { "chatMode": this.chatMode })
            }
            if (prevIsMuted !== this.isMuted) {
                addPageAction("ToggleMute", { "newState": this.isMuted })
            }
            this.setControlVolume.fire({ volume: volume, save: true })
            this.setControlIsMuted.fire({ isMuted: this.isMuted, save: true })
        }
    }

    public setMuted(muted: boolean): void {
        if (this.isMuted && !muted && !this.userUnmuted) {
            this.userUnmuted = true
            addPageAction("UserUnmuted", { "chatMode": this.chatMode })
        }
        if (muted !== this.isMuted) {
            addPageAction("ToggleMute", { "newState": muted })
        }
        this.isMuted = muted
        this.setControlIsMuted.fire({ isMuted: muted, save: true })
        if (this.videoJs !== undefined) {
            this.videoJs.muted(muted)
        }
    }

    public setVolumeMuted(volume: number, isMuted: boolean): void {
        this.isMuted = isMuted
        if (this.videoJs !== undefined) {
            this.videoJs.volume(volume / 100)
            this.videoJs.muted(isMuted)
        }
    }

    public getVolume(checkMuted = true): number {
        if (this.videoJs !== undefined) {
            if (checkMuted && this.videoJs.muted()) {
                return 0
            }
            return this.videoJs.volume() * 100
        }
        return 0
    }

    public getMuted(): boolean {
        if (this.videoJs !== undefined) {
            return this.videoJs.muted()
        }
        return true
    }

    public stop(): void {
        this.lastTimeUpdate = 0
        if (!this.isCasting && this.videoJs !== undefined) {
            this.videoJs.off("timeupdate", this.checkTimeUpdate)
            this.videoJs.off("waiting", this.setTooMuchWaitingTimeout)
            this.videoJs.off("playing", this.setTooMuchWaitingTimeout)
            this.videoJs.pause()
            this.removeTech()
            this.videoJs.src()
            this.videoJs.hide()
            this.videoJs.muted(this.isMuted)
        } else if (this.isCasting && this.videoJs !== undefined) {
            this.videoJs.pause()
            this.videoJs.src()
            this.videoJs.hide()
            return
        }
        super.stop()
    }

    public addChromecastOverlay(): void {
        if (this.chromecastOverlay !== this.element.firstChild) {
            this.element.insertBefore(this.chromecastOverlay, this.element.firstChild)
        }
    }

    public removeChromecastOverlay(): void {
        if (this.chromecastOverlay.parentElement === this.element) {
            this.element.removeChild(this.chromecastOverlay)
        }
    }

    public addTech(): void {
        if (!this.isCasting && this.tech === undefined && this.videoJs !== undefined) {
            this.tech = this.videoJs.tech()

            this.tech.on("loadedqualitydataTS", (ev: object, data: videojs.LoadedQualityData) => {
                addPageAction("VideoOnloadMuteStatus", { "muted": this.isMuted, "chatMode": this.chatMode })
                if (!this.isMuted) {
                    this.userUnmuted = true
                }
                const levels = data["qualityData"]["video"]
                const qualityLevels: IQualityLevel[] = []
                let selected = "auto"
                for (const level of levels) {
                    qualityLevels.push({
                        label: level["label"],
                        toggled: level["selected"],
                        value: level["id"],
                    })
                    if (level["selected"] === true){
                        selected = level.label
                    }
                }
                const buttons = document.getElementsByClassName("vjs-menu-item vjs-selected")
                for (const button of buttons){
                    if(button.getElementsByClassName("vjs-menu-item-text")[0].innerHTML !== selected){ // eslint-disable-line @multimediallc/no-inner-html
                        button.classList.remove("vjs-selected")
                        button.ariaChecked = "false"
                        button.getElementsByClassName("vjs-control-text")[0].innerHTML = "" // eslint-disable-line @multimediallc/no-inner-html
                    }
                }
                this.setLevels = qualityLevels
                this.possibleQualityLevelsChanged.fire(qualityLevels)
                this.extractLevelSelectionForMetrics(qualityLevels)
                if (data["qualitySwitchCallback"] === undefined) {
                    addPageAction(`clickMenuLink:QualitySelect-${qualityLevels.filter((l) => l.toggled)[0].label}`)
                }
            })
        }
    }

    private removeEventListeners(): void {
        if (!this.isCasting && this.videoJs !== undefined) {
            this.videoJs.off("canplay")
            this.videoJs.off("canplaythrough")
        }
    }

    private addEventListeners(): void {
        if (!this.isCasting && this.videoJs !== undefined) {
            this.videoJs.on("canplay",() => {
                this.play()
                if (this.isStreamReconnecting) {
                    this.removeReconnecting()
                }
            })
            this.videoJs.on("canplaythrough", () => {
                this.play()
            })
        }
    }

    public removeTech(): void {
        if (!this.isCasting && this.tech !== undefined) {
            this.tech.off("loadedqualitydataTS")
            if (this.tech["hlsProvider"] !== undefined) {
                this.tech["hlsProvider"].dispose()
            }
            this.tech.reset()
            this.tech = undefined
        }
    }

    public setQualityLevel(level: number): void {
        if (this.videoJs !== undefined) {
            this.videoJs.selectQualityButton(level)
        }
    }

    handleNeverPlayed(): void {
        super.handleNeverPlayed()
        // There's a bug in VideoJS when the video starts paused (usually because of autoplay policies)
        // which hides the native controls and there's no way to show them until the video starts playing.
        // Show the custom controls temporarily and hide them when the user clicks on the video player
        this.requestControlVisibility.fire(true)
        this.neverPlayed = true
    }

    protected showNativeControls(): void {
        super.showNativeControls()
        if (this.videoJs !== undefined) {
            this.videoJs.controls(true)
        }
    }

    public lockShowingControls(): void {
        if (this.videoJs === undefined) {
            return
        }

        this.showNativeControls()
        this.videoJs["controlBar"].lockShowing()
    }

    public unlockShowingControls(): void {
        if (this.videoJs === undefined) {
            return
        }

        this.videoJs["controlBar"].unlockShowing()
    }

    protected showCustomControls(): void {
        super.showCustomControls()
        this.hideNativeControls()
    }

    protected hideNativeControls(): void {
        if (this.videoJs !== undefined) {
            this.videoJs.controls(false) // hide general native controls
        }
    }

    private repositionFullscreenDropdown(): void {
        // ensure dropdown button is right-most component bc plugins do their own ordering later
        if (this.fullscreenDropdownBtn === undefined) {
            return
        }
        this.fullscreenDropdownBtn.parentNode?.appendChild(this.fullscreenDropdownBtn)
    }

    public enterFullScreenMode(): void {
        if (this.videoJs !== undefined) {
            // when entering fullscreen from IFS, use full window instead
            if (isFullscreen()) {
                this.videoJs.enterFullWindow()
            } else {
                this.videoJs.requestFullscreen()
            }
            this.showNativeControls()
            this.repositionFullscreenDropdown()
        }
    }

    private exitFullScreenMode(): void {
        if (this.videoJs !== undefined) {
            if (this.isFullWindow) {
                this.videoJs.exitFullWindow()
            } else {
                this.videoJs.exitFullscreen()
            }
        }
    }

    public getControlBarHeight(): number {return 0}

    public getVideoElement(): HTMLVideoElement | undefined {
        return this.videoElement
    }

    public getVideoJs(): videojs.Player | undefined {
        return this.videoJs
    }

    private extractLevelSelectionForMetrics(levels: readonly IQualityLevel[]): void {
        for (const level of levels) {
            if (level.toggled) {
                this.videoMetrics.setQualityLevel(level.label) // e.g. "720p"
            }
        }
    }

    private setupLLHLSButton(): void {
        const button = Videojs.getComponent("Button")
        const toggleLLHLS = (llhls: boolean) => {
            this.forceStream(llhls)
        }
        class ModeButton extends button {
            constructor(player: videojs.Player, options: videojs.PlayerOptions) {
                super(player, options)
                this.controlText("Toggle Low Latency Mode")
                if (options["llhls"] === true) {
                    this.addClass("vjs-llhls-enabled")
                    this.contentEl().textContent = "LL-HLS"
                } else {
                    this.contentEl().textContent = "HLS"
                }
            }
            buildCSSClass(): string {
                return `vjs-mode-button ${super.buildCSSClass()}`
            }
            handleClick(): void {
                if (this.hasClass("vjs-llhls-enabled")) {
                    this.removeClass("vjs-llhls-enabled")
                    this.contentEl().textContent = "HLS"
                    toggleLLHLS(false)
                } else {
                    this.addClass("vjs-llhls-enabled")
                    this.contentEl().textContent = "LL-HLS"
                    toggleLLHLS(true)
                }
            }
        }
        Videojs.registerComponent("ModeButton", ModeButton)
    }

    private setLLHLSConfig(): void {
        if (this.hls !== undefined) {
            if (this.enableLLHLS && this.allowLLHLS) {
                if (featureFlagIsActive("VDPLargeBuffer")) {
                    this.hls.config.liveSyncDuration = 0.8
                    this.hls.config.liveMaxLatencyDuration = 7
                } else {
                    this.hls.config.liveSyncDuration = 1.6
                    this.hls.config.liveMaxLatencyDuration = 2.4
                }
            } else {
                this.hls.config.liveSyncDuration = 3
                this.hls.config.liveMaxLatencyDuration = 7
            }
        }
    }

    protected updateStreamToggle(): void {
        super.updateStreamToggle()
    }

    protected setAutoplay(autoplay: boolean): void {
        super.setAutoplay(autoplay)
        if (this.videoJs !== undefined) {
            this.videoJs.autoplay(autoplay)
        }
    }
}
