import { OutgoingMessageType, Shortcode } from "./types";
import { PrivateMessageSource, } from "./types";
// For the messageStrictRegex -- 0 is the full match string, 3 is the third
// capture group which contains the actual message string body
const MESSAGE_CAPTURE_GROUP_INDEX = 3;
export class ShortcodeParser {
    static getCode(shortcode) {
        const match = shortcode.match(ShortcodeParser.codeRegex);
        if (match !== null) {
            // codeRegex is case-insensitive so check against lower
            switch (match[1].toLowerCase()) {
                case Shortcode.Signup:
                    return Shortcode.Signup;
                case Shortcode.Follow:
                    return Shortcode.Follow;
                case Shortcode.Fanclub:
                    return Shortcode.Fanclub;
                case Shortcode.Supporter:
                    return Shortcode.Supporter;
                case Shortcode.Tip:
                    return Shortcode.Tip;
                case Shortcode.Help:
                    return Shortcode.Help;
                default:
                    return undefined;
            }
        }
        return undefined;
    }
    static getShortcodeTitle(shortcodePart) {
        var _a, _b;
        // returns the shortcode normalized (except message arg)
        // and with whitespace removed around message
        const code = shortcodePart.code;
        const msg = (_a = shortcodePart.msg) === null || _a === void 0 ? void 0 : _a.trim();
        const amt = (_b = shortcodePart.amt) === null || _b === void 0 ? void 0 : _b.toString();
        if (code === Shortcode.Tip && amt !== undefined && msg !== undefined) {
            return `[cb:${code} amount=${amt} message="${msg}"]`;
        }
        return `[cb:${code}]`;
    }
    static isShortcodePrefix(prefix) {
        // check if provided string is shortcode prefix, case-insensitive
        return prefix.toLowerCase() === ShortcodeParser.shortcodePrefix;
    }
    static hasShortcodes(message) {
        return message.match(ShortcodeParser.shortcodeRegex) !== null;
    }
    /**
     * Checks if a shortcode was typed even if not a valid shortcode
     * @param message
     * @param source - where the message came from, "roomChat" or "pm"
     * @returns boolean
     */
    static isShortcodeSyntax(message, source = "") {
        if (Object.values(PrivateMessageSource).includes(source)) {
            return false;
        }
        return message.match(this.shortcodeRegex) !== null;
    }
    static isValidTipShortcodeSyntax(input) {
        const shortcodePatternI = new RegExp(/\[cb:tip amount=([\d,.]*) message=(("[^\]"“”]+")|(""|“”)|(“[^\]"“”]+”))\]/i);
        const shortcodePatternII = new RegExp(/\[cb:tip message=(("[^\]"“”]+")|(""|“”)|(“[^\]"“”]+”)) amount=([\d,.]*)\]/i);
        return shortcodePatternI.test(input) || shortcodePatternII.test(input);
    }
    static isValidShortcode(shortcode) {
        return (shortcode.match(ShortcodeParser.shortcodeStrictRegexA) !== null ||
            shortcode.match(ShortcodeParser.shortcodeStrictRegexB) !== null);
    }
    static isValidTipShortcode(shortcode) {
        if (!ShortcodeParser.isValidTipShortcodeSyntax(shortcode)) {
            return false;
        }
        // Check if the tip amount is valid
        const amount = shortcode.match(ShortcodeParser.amountRegex);
        if (amount !== null) {
            const amountString = amount[0].split("=")[1];
            if (!isValidNumberString(amountString)) {
                return false;
            }
        }
        // Check if the tip message has no URLs/links
        const message = shortcode.match(ShortcodeParser.messageStrictRegex);
        if (message !== null) {
            const messageString = message[0].split("=")[1];
            if (messageString.match(ShortcodeParser.urlRegex)) {
                return false;
            }
        }
        return true;
    }
    static isValidShortcodeForRoom(shortcode, roomContext) {
        const { hasFanClub, isBroadcaster } = roomContext;
        if (!ShortcodeParser.isValidShortcode(shortcode)) {
            return false;
        }
        const code = ShortcodeParser.getCode(shortcode);
        switch (code) {
            case Shortcode.Tip:
                return (ShortcodeParser.isValidTipShortcode(shortcode) &&
                    isBroadcaster);
            case Shortcode.Fanclub:
                return /(\[cb:fanclub\])/i.test(shortcode) && hasFanClub;
            case Shortcode.Follow:
            case Shortcode.Supporter:
            case Shortcode.Signup:
            case Shortcode.Help:
                return new RegExp(`\\[cb:${code}\\]`, "i").test(shortcode);
            default:
                return false;
        }
    }
    static isValidShortcodeMessage(message, roomContext) {
        var _a;
        const shortcodes = message.match(ShortcodeParser.shortcodeRegex);
        return ((_a = shortcodes === null || shortcodes === void 0 ? void 0 : shortcodes.every((sc) => ShortcodeParser.isValidShortcodeForRoom(sc, roomContext))) !== null && _a !== void 0 ? _a : false);
    }
    static getShortcodeForPart(shortcode, shortcodes) {
        // get shortcode object corresponding to provided shortcode string
        const code = ShortcodeParser.getCode(shortcode);
        if (code !== undefined) {
            switch (code) {
                case Shortcode.Tip:
                    if (!ShortcodeParser.isValidTipShortcodeSyntax(shortcode)) {
                        break;
                    }
                    return shortcodes.find((s) => {
                        if (s.code !== Shortcode.Tip) {
                            return false;
                        }
                        const hasSameAmt = s.amt ===
                            ShortcodeParser.getAmountWithoutSeparators(shortcode);
                        const hasSameMsg = s.msg ===
                            ShortcodeParser.getMessageContents(shortcode);
                        return hasSameAmt && hasSameMsg;
                    });
                default:
                    return shortcodes.find((s) => s.code === code);
            }
        }
        return undefined;
    }
    static getAmountWithoutSeparators(tipShortcode) {
        const amount = tipShortcode.match(ShortcodeParser.amountRegex);
        if (amount !== null) {
            const amountString = amount[0].split("=")[1];
            if (isValidNumberString(amountString)) {
                return Number(amountString.replace(/\./gi, "").replace(/,/gi, ""));
            }
        }
        return 0;
    }
    static getMessageContents(tipShortcode) {
        const message = tipShortcode.match(ShortcodeParser.messageStrictRegex);
        if (message !== null) {
            // backend form strips whitespace so trim to match
            return message[MESSAGE_CAPTURE_GROUP_INDEX].trim();
        }
        return "";
    }
    // eslint-disable-next-line complexity
    static parseShortcodeMessage(message, roomContext) {
        const validShortcodes = [];
        if (!ShortcodeParser.isValidShortcodeMessage(message, roomContext)) {
            // Return empty shortcodes
            return {
                messageType: OutgoingMessageType.Shortcode,
                shortcodes: [],
                message: message,
            };
        }
        const shortcodes = message.match(ShortcodeParser.shortcodeRegex);
        if (shortcodes !== null) {
            for (const shortcode of shortcodes) {
                const code = ShortcodeParser.getCode(shortcode);
                if (code !== undefined) {
                    if (code === Shortcode.Tip) {
                        const amt = shortcode.match(ShortcodeParser.amountRegex);
                        const msg = shortcode.match(ShortcodeParser.messageStrictRegex);
                        let parsed_msg = "";
                        if (msg !== null) {
                            parsed_msg = msg[MESSAGE_CAPTURE_GROUP_INDEX];
                        }
                        if (amt !== null && msg !== null) {
                            const parsed_amt = amt[0]
                                .split("=")[1]
                                .replace(/\./gi, "")
                                .replace(/,/gi, "");
                            validShortcodes.push({
                                code: Shortcode.Tip,
                                amt: Number(parsed_amt),
                                msg: parsed_msg,
                            });
                        }
                    }
                    else {
                        validShortcodes.push({ code: code });
                    }
                }
            }
        }
        if (validShortcodes.length > 5) {
            // Don't allow if more than 5 shortcodes per message
            // If more is added, return empty for error handling
            return {
                messageType: OutgoingMessageType.Shortcode,
                shortcodes: [],
                message: message,
            };
        }
        // normalize shortcodes to prevent sending duplicates (except tip shortcode since restricted to broadcaster)
        message = message.replace(this.shortcodeNormalizeRegex, (shortcode) => {
            return shortcode.toLowerCase();
        });
        return {
            messageType: OutgoingMessageType.Shortcode,
            shortcodes: validShortcodes,
            // Trim the message of any extra spaces so similar messages
            // register as spam and prevent duplicates from being published
            message: message.trim(),
        };
    }
    static hasTipArguments(shortcode) {
        // check that tip shortcode arguments are
        // present without checking value or validity
        let scWithoutQuotedContent = "";
        let inQuotes = false;
        for (const char of shortcode) {
            if (char === "'" ||
                char === "‘" ||
                char === "’" ||
                char === '"' ||
                char === "“" ||
                char === "”") {
                // ignore content between quotes
                inQuotes = !inQuotes;
                continue;
            }
            if (inQuotes) {
                continue;
            }
            scWithoutQuotedContent += char;
        }
        return (new RegExp(/amount=/).test(scWithoutQuotedContent) &&
            new RegExp(/message=/).test(scWithoutQuotedContent));
    }
    static hasErrorInMessageArg(shortcode) {
        return (!ShortcodeParser.messageStrictRegex.test(shortcode) ||
            ShortcodeParser.messageRegexSingleQuote.test(shortcode) ||
            ShortcodeParser.messageInvalidQuotesRegex.test(shortcode));
    }
}
ShortcodeParser.shortcodePrefix = "[cb:";
ShortcodeParser.shortcodeSuffix = "]";
// within shortcodes, allow everything except braces and ignore anything in between quotes for tip message argument
ShortcodeParser.shortcodeRegex = /(\[cb:(?:[^\]]){0,100}\])/gi;
ShortcodeParser.nonGlobalShortcodeRegex = /(\[cb:(?:[^\]]){0,100}\])/i;
// strict regex checks for valid shortcode and argument syntax
// tip shortcode can have arguments in any order so need A and B
ShortcodeParser.shortcodeStrictRegexA = /\[cb:([a-z]+)((\s[a-z]+?=[\d,.]*){0,1})((\s[a-z]+?=("|“)([^\]"“”]*?)("|”))){0,1}\]/gi;
ShortcodeParser.shortcodeStrictRegexB = /\[cb:([a-z]+)((\s[a-z]+?=("|“)([^\]"“”]*?)("|”))){0,1}((\s[a-z]+?=[\d,.]*){0,1})\]/gi;
// regex for normalizing shortcodes excluding tip
ShortcodeParser.shortcodeNormalizeRegex = /\[cb:(follow|signup|fanclub|supporter|help)\]/gi;
// REGEX FOR TIP SHORTCODE
// check for any value included with arguments regardless of validity
ShortcodeParser.amountAnyRegex = /amount=[^\s\]]+/i;
ShortcodeParser.messageAnyRegex = /message=[^\s\]]+/i;
// check for url in message which makes the message invalid
ShortcodeParser.urlRegex = /\b(?:https?:\/\/)?(?:www\.)?[^/\s]+\.[a-zA-Z]{2,}\b/gi;
// check for usage of unsuppported quotes which makes the message invalid
ShortcodeParser.messageRegexSingleQuote = /(message=)(?:['|‘|’](?:[^\]"“”]+)['|‘|’]|['|‘|’](?:[^\]"“”]+)["|“|”]|["|“|”](?:[^\]"“”]+)['|‘|’])/i;
ShortcodeParser.messageInvalidQuotesRegex = /(message=)(?:"[^\]"“”]*[“”])|(?:“[^\]"“”]*["“])/i;
// check for argument validity with more strict regex
ShortcodeParser.amountRegex = /amount=([,.\d]*)/i;
ShortcodeParser.messageStrictRegex = /(message=)("|“)([^\]"“”]*)("|”)/i;
// Pulls the code from a shortcode - capture anything following : until whitespace or ]
ShortcodeParser.codeRegex = /\[cb:([^\s\]]+)(?:[^\]]*)\]/i;
/**
 * Validate shortcode tip amount string
 * Accepts only 1-4 digit integers with an optional thousands separator
 */
export function isValidNumberString(input) {
    const isRegularNumber = new RegExp(/^(\d{1,4})$/);
    const hasThousandSeperator = new RegExp(/^(\d{1}[.,]\d{3})$/);
    const hasDecimalSeperator = new RegExp(/^[.,]\d{1,2}$/);
    const isValidNumber = isRegularNumber.test(input) || hasThousandSeperator.test(input);
    const hasDecimal = hasDecimalSeperator.test(input);
    if (hasDecimal) {
        return false;
    }
    return isValidNumber;
}
