import { setColors } from "@multimediallc/web-utils/colors"
import { hasWellSupportedEmojis } from "@multimediallc/web-utils/modernizr"
import { RoomNoticeType } from "@multimediallc/web-utils/types"
import twemoji from "@twemoji/api"
import { addColorClass } from "../cb/colorClasses"
import { roomDossierContext } from "../cb/interfaces/context"
import { collapseArrow, expandArrow } from "../cb/ui/svg/expandArrow"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { DarkModeHandler, swapColors } from "./chatcolors/darkModeColors"
import { Debouncer, DebounceTypes } from "./debouncer"
import { Component } from "./defui/component"
import { triggerReflow } from "./DOMutils"
import { combineNoticeParts as combineOverlayNoticeParts, createBaseMessageDiv as createOverlayBaseMessageDiv, styleUsernameMention, theaterModeMessagePaddingPx } from "./fullvideolib/messageToDOM"
import { setPureChatColorData } from "./fullvideolib/pureChatUtil"
import { brighten, createBaseMessageDiv as createMobileBaseMessageDiv } from "./messageToDOM"
import { combineNoticeParts as combineMobileNoticeParts, mobileMessagePaddingPx, styleMobileMention } from "./mobilelib/messageToDOM"
import { addPageAction } from "./newrelic"
import { ignoreCatch } from "./promiseUtils"
import { addDarkModeOptions, combineNoticeParts as combineSplitNoticeParts, createBaseMessageDiv as createSplitBaseMessageDiv, splitModeMessagePaddingPx } from "./theatermodelib/messageToDOM"
import { dom } from "./tsxrender/dom"
import { VideoMode, videoModeHandler } from "./videoModeHandler"
import type { IRoomNotice } from "./messageInterfaces"

const noticeVerticalPaddingPx = 2

interface IUncollapsibleRoomNoticeProps {
    roomNoticeData: IRoomNotice,
    isChatScrolledToBottom?: undefined
    scrollChatToBottom?: undefined
    getChatScrollTop?: undefined
    setChatScrollTop?: undefined
    neverCollapse: true,
    isPureChat?: boolean,
}

interface ICollapsibleRoomNoticeProps {
    roomNoticeData: IRoomNotice,
    isChatScrolledToBottom: () => boolean,
    scrollChatToBottom: () => void,
    getChatScrollTop: () => number,
    setChatScrollTop: (top: number) => void
    neverCollapse?: undefined,
    isPureChat?: boolean,
}

export type RoomNoticeProps = IUncollapsibleRoomNoticeProps | ICollapsibleRoomNoticeProps

export abstract class RoomNotice extends Component<HTMLDivElement, IUncollapsibleRoomNoticeProps | ICollapsibleRoomNoticeProps> {
    public roomNoticeData: IRoomNotice
    protected contentsDiv: HTMLDivElement
    private combinedNoticeViewport: HTMLDivElement
    private combinedNotice: HTMLDivElement
    private isChatScrolledToBottom?: () => boolean
    private scrollChatToBottom?: () => void
    private getChatScrollTop?: () => number
    private setChatScrollTop?: (top: number) => void
    private neverCollapse?: boolean
    private expandArrowDiv?: HTMLDivElement
    private collapseArrowDiv?: HTMLDivElement
    private emHeightDummyDiv?: HTMLDivElement
    private userExpandedNotice = false
    private maxCollapsedHeightEm: number
    private maxCollapsedHeightLeewayEm: number
    private scrollSpeed: number
    private fullNoticeHasDisplayed: boolean
    private scrollStartDebouncer: Debouncer
    private static fallbackEmHeight = 12

    constructor(props: RoomNoticeProps) {
        super("div", props)

        this.element = this.createBaseMessageDiv(this.roomNoticeData.dataNick)
        if (this.roomNoticeData.ts !== undefined) {
            this.element.dataset["ts"] = this.roomNoticeData.ts.toString()
        }
        this.element.style.display = "flex"

        const combinedNoticeViewportStyle: CSSX.Properties = {
            overflow: "hidden",
            flexBasis: "0",
            flexShrink: 0,
            flexGrow: 1,
        }

        this.combinedNotice = this.buildCombinedNotice()

        this.contentsDiv = <div data-testid="room-notice">
            <div ref={(el: HTMLDivElement) => this.combinedNoticeViewport = el} style={combinedNoticeViewportStyle} data-testid="room-notice-viewport">
                {this.combinedNotice}
            </div>
            {this.buildAdditionalContents()}
        </div>

        this.styleContentsDiv()

        this.element.appendChild(this.contentsDiv)
        this.postConstruction()

        if (this.collapseEnabled()) {
            this.collapseHeight().catch(ignoreCatch)
            const images = this.element.querySelectorAll("img")
            images.forEach(image => {
                addEventListenerPoly("load", image, () => this.recalculateCollapse())
            })
        }
    }

    protected initData(props: RoomNoticeProps): void {
        this.roomNoticeData = props.roomNoticeData
        this.isChatScrolledToBottom = props.isChatScrolledToBottom
        this.scrollChatToBottom = props.scrollChatToBottom
        this.getChatScrollTop = props.getChatScrollTop
        this.setChatScrollTop = props.setChatScrollTop
        this.neverCollapse = props.neverCollapse

        this.maxCollapsedHeightEm = 7.1
        this.maxCollapsedHeightLeewayEm = 1.5

        this.scrollSpeed = 20
        this.fullNoticeHasDisplayed = false

        const scrollDelayMS = 1000
        this.scrollStartDebouncer = new Debouncer(() => this.startAnimatedScroll(), { bounceLimitMS: scrollDelayMS, debounceType: DebounceTypes.debounce })
    }

    public recalculateCollapse(): void {
        // This method assumes the notice is present in the DOM and not pending any redraws that will affect height.
        const wasScrolledToBottom = this.isChatScrolledToBottom?.() === true
        const collapse = this.collapseEnabled() && !this.userExpandedNotice && this.needsCollapsing()
        this.setCollapseStyles(collapse)
        this.updateCollapseTogglingStyles()
        this.updateCollapsedScrolling()
        if (wasScrolledToBottom) {
            this.scrollChatToBottom?.()
            // FF and Chrome don't appear to need this timeout, but safari does or else it doesn't scroll all the way
            window.setTimeout(() => {
                this.scrollChatToBottom?.()
            }, 0)
        }
    }

    // Note this intentionally does not include the collapseNotices chat setting in the calculation
    public mayCollapse(): boolean {
        return this.roomNoticeData.noticeType === RoomNoticeType.App && this.neverCollapse !== true
    }

    private collapseEnabled(): boolean {
        return this.mayCollapse() && roomDossierContext.getState().userChatSettings.collapseNotices
    }

    public isRoomSubjectNotice(): boolean {
        return this.roomNoticeData.colorClass?.includes("titleChange") ?? false
    }

    public countsForUnread(): boolean {
        return this.roomNoticeData.countsForUnread ?? true
    }

    protected abstract createBaseMessageDiv(dataNick?: string): HTMLDivElement

    protected styleContentsDiv(): void {
        this.contentsDiv.style.display = "flex"
        this.contentsDiv.style.width = "100%"

        if (this.roomNoticeData.background !== undefined) {
            this.contentsDiv.style.background = this.roomNoticeData.background
            this.contentsDiv.style.textShadow = "none"
        }
        this.contentsDiv.style.fontWeight = this.roomNoticeData.weight !== undefined ? this.roomNoticeData.weight : "normal"

        addColorClass(this.contentsDiv, "roomNotice")
        if (this.roomNoticeData.background === "#ff3") {
            addColorClass(this.contentsDiv, "isTip")
        }
        if (this.roomNoticeData.colorClass !== undefined) {
            addColorClass(this.contentsDiv, this.roomNoticeData.colorClass)
        }

        this.contentsDiv.style.borderRadius = "4px"
    }

    protected abstract buildCombinedNotice(): HTMLDivElement

    private buildAdditionalContents(): HTMLDivElement | undefined {
        if (!this.mayCollapse()) {
            return undefined
        }

        const containerStyle: CSSX.Properties = {
            WebkitAlignSelf: "flex-end",
            flexGrow: 0,
            flexShrink: 1,
            overflow: "hidden",
        }

        const lineClampingArrowStyle: CSSX.Properties = {
            display: "none",
            padding: "0 0 4px 4px",
            cursor: "pointer",
        }

        const emHeightDummyDivStyle: CSSX.Properties = {
            position: "absolute",
            opacity: "0",
            height: "1em",
        }

        const expandArrowSVG = expandArrow()
        expandArrowSVG.style.cssFloat = "right"

        const collapseArrowSVG = collapseArrow()
        collapseArrowSVG.style.cssFloat = "right"

        this.expandArrowDiv = <div style={lineClampingArrowStyle} onClick={() => this.userToggleCollapse()}>
            <div style={{ overflow: "hidden" }}>
                {expandArrowSVG}
            </div>
        </div>

        this.collapseArrowDiv = <div style={lineClampingArrowStyle} onClick={() => this.userToggleCollapse()}>
            <div style={{ overflow: "hidden" }}>
                {collapseArrowSVG}
            </div>
        </div>

        this.emHeightDummyDiv = <div style={emHeightDummyDivStyle}/>

        return <div style={containerStyle}>
            {this.expandArrowDiv}
            {this.collapseArrowDiv}
            {this.emHeightDummyDiv}
        </div>
    }

    protected postConstruction(): void {
        if (!hasWellSupportedEmojis()) {
            twemoji.parse(this.element, { className: "emojiChat" })
        }

        addEventListenerPoly("click", this.contentsDiv, (e) => {
            if (this.isClickableSubcomponent(e.target)) {
                return
            }
            if (!this.isCollapseTogglable() || !this.isCollapsed()) {
                return
            }
            this.userToggleCollapse()
        })

        addEventListenerPoly("animationend", this.combinedNotice, (event) => {
            if (event.animationName === "slide" && this.inActiveVideoMode()) {  // See site/room/roomNotice.scss for "slide" animation
                this.fullNoticeHasDisplayed = true
            }
        })
    }

    protected abstract inActiveVideoMode(): boolean

    private userToggleCollapse(): void {
        addPageAction("NoticeToggled", { action: this.isCollapsed() ? "expanding" : "collapsing" })
        if (this.isCollapsed()) {
            this.userExpandedNotice = true
            this.expandHeight()
        } else {
            this.userExpandedNotice = false
            this.collapseHeight(true).catch(ignoreCatch)
        }
    }

    private getEmPx(): number {
        // This method assumes the notice is present in the DOM
        if (this.emHeightDummyDiv === undefined) {
            error("SplitRoomNotice getting emHeight with no em div")
            return RoomNotice.fallbackEmHeight
        }

        const emHeight = this.emHeightDummyDiv.getBoundingClientRect().height
        // Mobile once in a while incorrectly gives emHeight=0. It's rare and happens despite nonzero
        // this.combinedNotice.scrollHeight, so it's not like we have a bad order of operations here. Rather than
        // hack around with timeouts trying to anticipate browser hiccups, fallback to a previously saved em height
        if (emHeight === 0) {
            return RoomNotice.fallbackEmHeight
        } else {
            RoomNotice.fallbackEmHeight = emHeight
            return emHeight
        }
    }

    protected getCollapsedHeightPx(): number {
        // This method assumes the notice is present in the DOM
        return this.maxCollapsedHeightEm * this.getEmPx() + noticeVerticalPaddingPx * 2
    }

    private needsCollapsing(): boolean {
        // This method assumes the notice is present in the DOM and not pending any redraws that will affect height.
        // We want a bit of leeway so that we don't collapse to this.maxCollapsedHeightEm if doing so will leave just
        // a tiny bit of overflow
        return this.combinedNotice.scrollHeight > (this.maxCollapsedHeightEm + this.maxCollapsedHeightLeewayEm) * this.getEmPx()
    }

    private isCollapseTogglable(): boolean {
        return this.collapseEnabled() && this.needsCollapsing()
    }

    private isCollapsed(): boolean {
        return this.combinedNoticeViewport.classList.contains("collapsed")
    }

    private async collapseHeight(fromUserToggle = false): Promise<void> {
        // non-null assertions (!) in this method are because collapseEnabled() guarantees the values
        if (!this.collapseEnabled()) {
            return
        }

        const wasScrolledToBottom = this.isChatScrolledToBottom!() === true
        if (fromUserToggle) {
            // Keep the bottom of the notice's position fixed rather than browser default of having the top stay fixed
            // Do this before setting maxHeight to avoid flickering scroll
            const chatScrollPosition = this.getChatScrollTop!()
            const collapsedHeightDifference = this.contentsDiv.getBoundingClientRect().height - this.getCollapsedHeightPx()
            this.setChatScrollTop!(chatScrollPosition - collapsedHeightDifference)
        }

        // We want a bit of leeway so that we don't collapse to this.maxCollapsedHeightEm if doing so will leave just
        // a tiny bit of overflow. However, we don't know the true notice height to know we're within the leeway until
        // after the element renders in the DOM. Initially rendering with expanded height would risk weird flickering,
        // so we initially collapse to this.maxCollapsedHeightEm, and then check that that's actually what we want.
        this.setCollapseStyles(true)

        // Set scroll style immediately after collapsing height so we don't flash incorrect scroll while awaiting confirmCollapsedHeight()
        this.updateCollapsedScrolling()

        await this.confirmCollapse()
        this.updateCollapseTogglingStyles()

        // Update scrolling again after confirmCollapse, and call it after updateCollapseTogglingStyles to reflect
        // the requirement that startAnimatedScroll runs after updateCollapseTogglingStyles (even if scrollStartDebouncer
        // makes this technically unnecessary)
        this.updateCollapsedScrolling()

        if (wasScrolledToBottom) {
            // Theoretically we shouldn't need this, but some browsers (cough cough safari) can stay scrolled past the
            // true bottom of the div after shrinking the notice height
            this.scrollChatToBottom!()
        }
    }

    private confirmCollapse(): Promise<void> {
        // This method assumes the notice will be present in the DOM after a 0 timeout
        return new Promise(resolve => {
            window.setTimeout(() => {
                if (!this.needsCollapsing()) {
                    this.setCollapseStyles(false)
                }
                resolve()
            }, 0)
        })
    }

    private expandHeight(): void {
        // This method assumes the notice is present in the DOM and not pending any redraws that will affect height.
        const wasScrolledToBottom = this.isChatScrolledToBottom?.() === true
        const chatScrollPosition = this.getChatScrollTop?.() ?? 0
        this.setCollapseStyles(false)
        this.updateCollapseTogglingStyles()
        this.updateCollapsedScrolling()
        if (wasScrolledToBottom) {
            this.scrollChatToBottom?.()
            // FF and Chrome don't appear to need this timeout, but safari does or else it doesn't scroll all the way
            window.setTimeout(() => {
                this.scrollChatToBottom?.()
            }, 0)
        } else {
            // Keep the bottom of the notice's position fixed rather than browser default of having the top stay fixed
            const collapsedHeightDifference = this.contentsDiv.getBoundingClientRect().height - this.getCollapsedHeightPx()
            this.setChatScrollTop?.(chatScrollPosition + collapsedHeightDifference)
        }
    }

    private updateCollapseTogglingStyles(): void {
        // This method assumes the notice is present in the DOM and not pending any redraws that will affect height.
        if (this.collapseArrowDiv === undefined || this.expandArrowDiv === undefined) {
            error("SplitRoomNotice setting toggling styles with no arrows")
            return
        }

        this.collapseArrowDiv.style.display = "none"
        this.expandArrowDiv.style.display = "none"
        this.contentsDiv.style.cursor = ""
        if (this.isCollapseTogglable()) {
            if (this.isCollapsed()) {
                this.expandArrowDiv.style.display = ""
                this.contentsDiv.style.cursor = "pointer"
            } else {
                this.collapseArrowDiv.style.display = ""
            }
        }
    }

    private setCollapseStyles(collapse: boolean): void {
        if (collapse) {
            this.combinedNoticeViewport.style.maxHeight = `${this.maxCollapsedHeightEm}em`
            this.combinedNoticeViewport.classList.add("collapsed")
        } else {
            // Set very large max-height as a workaround for android having different font boosting behavior depending on whether max-height is set
            this.combinedNoticeViewport.style.maxHeight = "999999999px"
            this.combinedNoticeViewport.classList.remove("collapsed")
            if (this.inActiveVideoMode()) {
                this.fullNoticeHasDisplayed = true
            }
        }
    }

    private updateCollapsedScrolling(): void {
        if (this.isCollapsed()) {
            if (this.fullNoticeHasDisplayed) {
                this.setScrollToEnd()
            } else {
                this.removeAnimatedScroll()
                this.scrollStartDebouncer.callFunc()
            }
        } else {
            this.removeAnimatedScroll()
        }
    }

    private startAnimatedScroll(): void {
        // This method assumes the notice is present in the DOM and not pending any notice content height changes, eg from setting expand arrow display.
        if (!this.isCollapsed()) {
            return
        }

        const noticeViewportHeightPx = this.combinedNoticeViewport.getBoundingClientRect().height
        const contentHeightPx = this.combinedNotice.getBoundingClientRect().height
        const scrollTimeS = (contentHeightPx - noticeViewportHeightPx) / this.scrollSpeed
        this.combinedNotice.style.setProperty("--scroll-time-s", `${scrollTimeS}`)
        this.combinedNotice.style.setProperty("--notice-viewport-height-px", `${noticeViewportHeightPx}`)

        this.removeAnimatedScroll()
        triggerReflow() // So that animation restarts when we add back the animation class
        this.combinedNotice.classList.add("animated-scroll")
    }

    private removeAnimatedScroll(): void {
        this.combinedNotice.classList.remove("animated-scroll")
    }

    private setScrollToEnd(): void {
        // This method assumes the notice is present in the DOM and not pending any redraws that will affect height.
        this.removeAnimatedScroll()
        const noticeViewportHeightPx = this.combinedNoticeViewport.getBoundingClientRect().height
        this.combinedNotice.style.setProperty("--notice-viewport-height-px", `${noticeViewportHeightPx}`)
        this.combinedNotice.classList.add("scroll-to-end")
    }

    private isClickableSubcomponent(eventTarget: EventTarget | null): boolean {
        if (eventTarget instanceof Element) {
            const targetHasOnClick = (eventTarget instanceof HTMLElement || eventTarget instanceof SVGElement) && eventTarget.onclick !== null
            const targetHasClickListenerParent = eventTarget.closest("*[data-listener-count-click]") !== this.contentsDiv
            const targetHasAnchorParent = eventTarget.closest("a") !== null
            if (targetHasOnClick || targetHasClickListenerParent || targetHasAnchorParent) {
                return true
            }
        }
        return false
    }
}

// Split mode notices
export class SplitRoomNotice extends RoomNotice {
    protected createBaseMessageDiv(dataNick?: string): HTMLDivElement {
        const baseMessageDiv = createSplitBaseMessageDiv(dataNick)
        baseMessageDiv.style.width = `calc(100% + ${splitModeMessagePaddingPx}px)`
        return baseMessageDiv
    }

    protected styleContentsDiv(): void {
        super.styleContentsDiv()
        if (this.roomNoticeData.background !== undefined) {
            this.contentsDiv.style.marginTop = "1px"
            if (this.roomNoticeData.background === "#ff8b45") {
                addColorClass(this.contentsDiv, "bright-background")
            }
        }

        this.contentsDiv.style.position = "relative"
        this.contentsDiv.style.left = `-${splitModeMessagePaddingPx}px`
        this.contentsDiv.style.color = this.roomNoticeData.foreground !== undefined ? this.roomNoticeData.foreground : ""
        this.contentsDiv.style.padding = `${noticeVerticalPaddingPx}px ${splitModeMessagePaddingPx}px`
    }

    protected buildCombinedNotice(): HTMLDivElement {
        const combinedNotice = document.createElement("div")
        combineSplitNoticeParts(combinedNotice, this.roomNoticeData.messages, this.roomNoticeData.shortcodes)
        return combinedNotice
    }

    protected postConstruction(): void {
        super.postConstruction()
        addDarkModeOptions(this.element, this.contentsDiv, DarkModeHandler.parseNotice(this.roomNoticeData))
        styleUsernameMention(this.element)
        swapColors(this.element, document.body.classList.contains("darkmode"))
    }

    protected inActiveVideoMode(): boolean {
        return videoModeHandler.getVideoMode() === VideoMode.Split
    }
}

// Theater, fullvideo, and mobile broadcast notices
export class OverlayRoomNotice extends RoomNotice {
    protected createBaseMessageDiv(dataNick?: string): HTMLDivElement {
        const baseDiv = createOverlayBaseMessageDiv(dataNick)
        baseDiv.style.padding = "1px 5px"
        return baseDiv
    }

    protected styleContentsDiv(): void {
        super.styleContentsDiv()

        if (this.roomNoticeData.background !== undefined) {
            this.contentsDiv.style.color = "#000000"
        }
        if (this.roomNoticeData.foreground !== undefined) {
            this.contentsDiv.style.color = this.roomNoticeData.foreground
        }
        this.contentsDiv.style.padding = `${noticeVerticalPaddingPx}px  ${theaterModeMessagePaddingPx}px`
    }

    protected buildCombinedNotice(): HTMLDivElement {
        const combinedNotice = document.createElement("div")
        combineOverlayNoticeParts(combinedNotice, this.roomNoticeData.messages, this.roomNoticeData.shortcodes)
        return combinedNotice
    }

    protected postConstruction(): void {
        super.postConstruction()
        setColors(this.element, this.roomNoticeData.foreground, this.roomNoticeData.background)
        styleUsernameMention(this.element)
    }

    protected inActiveVideoMode(): boolean {
        return videoModeHandler.getVideoMode() !== VideoMode.Split
    }
}

// Mobile viewer notices
export class MobileRoomNotice extends RoomNotice {
    private isPureChat: boolean

    protected createBaseMessageDiv(dataNick?: string): HTMLDivElement {
        return createMobileBaseMessageDiv(dataNick)
    }

    protected initData(props: RoomNoticeProps): void {
        super.initData(props)
        this.isPureChat = props.isPureChat ?? false
    }

    protected styleContentsDiv(): void {
        super.styleContentsDiv()

        if (this.roomNoticeData.background === undefined) {
            const pureChatColor = brighten(this.roomNoticeData.foreground !== undefined ? this.roomNoticeData.foreground : "#aaaaaa")
            setPureChatColorData(this.contentsDiv, pureChatColor)
        }

        this.contentsDiv.style.padding = `${noticeVerticalPaddingPx}px ${mobileMessagePaddingPx}px`
        this.contentsDiv.style.color = this.roomNoticeData.foreground !== undefined ? this.roomNoticeData.foreground : "#000000"
    }

    protected buildCombinedNotice(): HTMLDivElement {
        const combinedNotice = document.createElement("div")
        combineMobileNoticeParts(combinedNotice, this.roomNoticeData.messages, this.roomNoticeData.shortcodes, this.isPureChat)
        return combinedNotice
    }

    protected postConstruction(): void {
        super.postConstruction()
        styleMobileMention(this.element)
    }

    protected inActiveVideoMode(): boolean {
        return true
    }
}
