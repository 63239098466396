import { pageContext } from "../../cb/interfaces/context"
import { performToggleIsActive } from "../featureFlag"
import { addPageAction } from "../newrelic"
import { VideoMode, videoModeHandler } from "../videoModeHandler"

export function shouldUseCustomControls(): boolean {
    if (pageContext.current.isMobile === true) {
        return true
    }
    const videoMode = videoModeHandler.getVideoMode()
    // Not in videoonly embed or non IFS fullscreen
    return ![VideoMode.VideoOnly, VideoMode.Fullscreen].includes(videoMode)
}

export function LLHLSSupported(): boolean {
    return performToggleIsActive("EnblLLHLS") || new URLSearchParams(window.location.search).get("force-llhls") === "true"
}

export function embedLLHLS(): boolean {
    return performToggleIsActive("EmbedLLHLS")
}

export function mobileLLHLS(): boolean {
    return performToggleIsActive("MblLLHLS")
}

export function anonLLHLS(): boolean {
    return performToggleIsActive("AnonLLHLS")
}

export function allEdgesLLHLS(): boolean {
    return performToggleIsActive("AlEgsLLHLS")
}

export function reportBroadcasterBitrate(streamType: string, bitrate: number): void {
    addPageAction("BroadcastBitrate", { "streamType": streamType, "bitrate": bitrate })
}

export function isNRVideoEnabled(): boolean {
    return performToggleIsActive("NRVideo") || new URLSearchParams(window.location.search).get("nrvideo") === "true"
}
