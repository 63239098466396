import { RoomStatus } from "@multimediallc/web-utils"
import { pageContext } from "../cb/interfaces/context"
import { roomCleanup, roomLoaded } from "./context"
import { Component } from "./defui/component"
import { EventRouter, ListenerGroup } from "./events"
import { featureFlagIsActive } from "./featureFlag"
import { TabName } from "./mobilelib/tabList"
import { switchedToHLS, userSwitchedTab } from "./mobilelib/userActionEvents"
import { enterSpyShowAlertChain } from "./privateShow"
import { i18n } from "./translation"
import { roomListRequest } from "./userActionEvents"
import type { IChatConnection, IRoomContext } from "./context"
import type { IPushSettingsUpdateNotification } from "./messageInterfaces"

function createLink(text: string, onClick: (ev: MouseEvent) => void): HTMLDivElement {
    const link = document.createElement("div")
    link.innerText = text
    link.style.color = "#72C0FF"
    link.style.cursor = "pointer"
    link.style.paddingTop = "5px"
    link.onmouseenter = () => {
        link.style.textDecoration = "underline"
    }
    link.onmouseleave = () => {
        link.style.textDecoration = "none"
    }
    link.style.pointerEvents = "auto"
    link.onclick = (ev: MouseEvent) => {
        onClick(ev)
    }
    return link
}

export class RoomStatusNotifier extends Component {
    header: HTMLDivElement
    body: HTMLDivElement
    private text: HTMLSpanElement
    moreRoomsLink = createLink(i18n.moreRoomsText, (ev: MouseEvent) => {
        roomListRequest.fire(undefined)
        ev.stopPropagation()
    })
    private showTextHandlers = new Map<string, (chatConnection: IChatConnection) => void>()
    private joinShowLink = createLink("", () => {})
    private spyDisabledText: HTMLSpanElement
    public shouldShowMoreRoomsLink = true
    private hiddenMessage = ""
    private connectingStatusText = i18n.connecting
    private isConnecting = false
    private videoOffline: boolean
    private currentChatConnection: IChatConnection | undefined
    private isSpyEnabled: boolean
    public displayChanged = new EventRouter<void>("displayChanged")
    private userInRoom = false
    public static readonly Z_INDEX = 1

    constructor(protected positionFixed = true) {
        super()

        // region DOM Creation
        this.element.style.display = "none"
        this.element.style.position = this.positionFixed ? "fixed" : "absolute"
        this.element.style.height = "auto"
        this.element.style.width = "auto"
        this.element.style.boxSizing = "border-box"
        this.element.style.padding = "0 50px"
        this.element.style.left = "0"
        this.element.style.textAlign = "center"
        this.element.style.pointerEvents = "none"
        this.element.style.zIndex = `${RoomStatusNotifier.Z_INDEX}`
        this.element.dataset.testid = "video-overlay-text"

        this.header = document.createElement("div")
        this.header.style.fontSize = "32px"
        this.header.style.lineHeight = "32px"
        this.header.style.color = "#72c0ff"
        this.header.style.marginBottom = "20px"
        this.element.appendChild(this.header)

        this.body = document.createElement("div")
        this.body.style.fontSize = "16px"
        this.body.style.color = "#ffffff"

        this.text = document.createElement("span")
        this.body.appendChild(this.text)

        this.moreRoomsLink.style.display = "none"
        this.moreRoomsLink.dataset.testid = "more-rooms-link"
        this.body.appendChild(this.moreRoomsLink)

        this.joinShowLink.style.display = "none"
        this.body.appendChild(this.joinShowLink)

        this.spyDisabledText = document.createElement("span")
        this.spyDisabledText.innerText = i18n.privateShowSpyDisabled
        this.spyDisabledText.style.display = "none"
        this.spyDisabledText.style.paddingTop = "5px"
        this.body.appendChild(this.spyDisabledText)

        this.element.appendChild(this.body)
        // endregion

        this.showTextHandlers.set(RoomStatus.Away, (chatConnection: IChatConnection) => {
            this.header.innerText = i18n.performerIsAwayText
            this.text.innerText = i18n.roomAwayMessage
            this.joinShowLink.style.display = "none"
            this.spyDisabledText.style.display = "none"
            this.updateMoreRoomsLinkDisplay(true)
        })
        this.showTextHandlers.set(RoomStatus.PrivateNotWatching, (chatConnection: IChatConnection) => {
            this.header.innerText = i18n.privateShowInProgressText
            this.header.dataset.testid = "private-show-in-progress"
            this.text.innerText = i18n.roomPrivateNotWatchingMessage
            this.text.dataset.testid = "continue-chatting-msg"
            this.joinShowLink.innerText = i18n.spyOnThisPrivateShowCAPS
            this.joinShowLink.dataset.testid = "spy-on-this-private-show"
            // Do not show the link if viewer has not yet connected to the room.
            this.joinShowLink.style.display = this.isSpyEnabled && this.userInRoom && chatConnection.status !== RoomStatus.NotConnected ? "block" : "none"
            this.spyDisabledText.style.display = this.isSpyEnabled ? "none" : "block"
            this.joinShowLink.onclick = () => {
                enterSpyShowAlertChain(chatConnection, undefined, {
                    onResolve: () => {
                        if (pageContext.current.isMobile) {
                            userSwitchedTab.fire(TabName.Private)
                        }
                    },
                })
            }
            this.updateMoreRoomsLinkDisplay(true)
        })
        this.showTextHandlers.set(RoomStatus.Hidden, (chatConnection: IChatConnection) => {
            this.header.innerText = i18n.camIsHidden
            this.header.dataset.testid = "cam-is-hidden"
            this.text.innerText = this.hiddenMessage
            this.joinShowLink.style.display = "none"
            this.spyDisabledText.style.display = "none"
            this.updateMoreRoomsLinkDisplay(false)
        })
        this.showTextHandlers.set(RoomStatus.Offline, (chatConnection: IChatConnection) => {
            this.header.innerText = i18n.offline
            this.text.innerText = i18n.roomOfflineSubheader
            this.joinShowLink.style.display = "none"
            this.spyDisabledText.style.display = "none"
            this.updateMoreRoomsLinkDisplay(true)
        })
        this.showTextHandlers.set(RoomStatus.PasswordProtected, (chatConnection: IChatConnection) => {
            this.header.innerText = i18n.passwordRequired
            this.text.innerText = i18n.roomPasswordProtectedMessage
            this.joinShowLink.style.display = "none"
            this.spyDisabledText.style.display = "none"
            this.updateMoreRoomsLinkDisplay(true)
        })

        const listenerGroup = new ListenerGroup()

        roomLoaded.listen((context: IRoomContext) => {
            this.currentChatConnection = context.chatConnection
            this.setDisabledText(context, true, context.dossier.spyPrice)
            context.chatConnection.event.statusChange.listen(roomStatusChangeNotification => {
                this.setDisabledText(context, true, context.dossier.spyPrice)
                this.handleStatusChange(context.chatConnection, roomStatusChangeNotification.currentStatus)
            }).addTo(listenerGroup)

            context.chatConnection.event.hiddenMessageChange.listen((hiddenMessage: string) => {
                this.hiddenMessage = hiddenMessage
            }).addTo(listenerGroup)

            context.chatConnection.event.settingsUpdate.listen((settings: IPushSettingsUpdateNotification) => {
                this.setDisabledText(context, settings.allowPrivateShow, settings.spyPrice)
                // Do not show the link if settings are updated in state that is not PrivateNotWatching
                this.joinShowLink.style.display = this.isSpyEnabled && this.userInRoom && context.chatConnection.status === RoomStatus.PrivateNotWatching ? "block" : "none"
                this.spyDisabledText.style.display = this.isSpyEnabled ? "none" : "block"
            }).addTo(listenerGroup)

            // User may not be in userlist, so don't show spy link until they are
            context.chatConnection.event.roomCountUpdate.listen(() => {
                this.userInRoom = true
                this.joinShowLink.style.display = this.isSpyEnabled && this.userInRoom && context.chatConnection.status === RoomStatus.PrivateNotWatching ? "block" : "none"
            }).addTo(listenerGroup)

            switchedToHLS.listen(() => {
                this.handleStatusChange(context.chatConnection, context.dossier.roomStatus)
            }).addTo(listenerGroup)

            this.hiddenMessage = context.dossier.hiddenMessage
            this.handleStatusChange(context.chatConnection, context.dossier.roomStatus)
        })

        roomCleanup.listen(() => {
            listenerGroup.removeAll()
            this.hiddenMessage = ""
            this.videoOffline = false
            this.userInRoom = false
        })
    }

    public hide(): void {
        this.hideElement()
        this.displayChanged.fire()
    }

    public show(): void {
        this.showElement()
        this.displayChanged.fire()
    }

    public setVideoOffline(videoOffline: boolean): void {
        this.videoOffline = videoOffline
        if (this.currentChatConnection !== undefined) {
            this.handleStatusChange(this.currentChatConnection, this.currentChatConnection.status)
        }
    }

    public displaysForStatus(status: RoomStatus): boolean {
        return this.showTextHandlers.get(status) !== undefined
    }

    // Temporary function until FF is removed
    protected setDisabledText(context: IRoomContext, allowPrivateShow: boolean, spyPrice: number): void {
        if (featureFlagIsActive("PremPrivShow") && context.chatConnection.premiumShowActive) {
            this.spyDisabledText.innerText = i18n.premiumShowDesc
            this.isSpyEnabled = false
        } else {
            this.spyDisabledText.innerText = i18n.privateShowSpyDisabled
            this.isSpyEnabled = spyPrice > 0 && allowPrivateShow
        }
    }

    protected repositionChildren(): void {
        if (this.parent === undefined) {
            return
        }

        const parentRect = this.parent.element.getBoundingClientRect()
        const windowWidth = document.documentElement.clientWidth
        const windowHeight = document.documentElement.clientHeight

        if (parentRect.width <= windowWidth) {
            this.element.style.width = `${parentRect.width}px`
            if (this.positionFixed) {
                this.element.style.left = `${parentRect.left}px`
            }
        } else {
            this.element.style.width = `${windowWidth}px`
            this.element.style.left = "0"
        }
        if (parentRect.height <= windowHeight) {
            if (this.parent.element.style.position === "fixed") {
                this.element.style.top = `${parentRect.top + parentRect.height * 0.5 - this.element.offsetHeight * 0.5}px`
            } else {
                this.element.style.top = `${parentRect.height * 0.5 - this.element.offsetHeight * 0.5}px`
            }
        } else {
            this.element.style.top = `${windowHeight * 0.5 - this.element.offsetHeight * 0.5}px`
        }

        this.element.style.maxHeight = `${parentRect.height}px`

        this.resizeRoomStatusText(parentRect.width)
    }

    protected resizeRoomStatusText(parentWidth: number): void {
        if (parentWidth < 400) {
            this.header.style.fontSize = "24px"
            this.header.style.lineHeight = "24px"
            this.header.style.marginBottom = "14px"
            this.body.style.fontSize = "14px"
        } else {
            this.header.style.fontSize = "32px"
            this.header.style.lineHeight = "32px"
            this.header.style.marginBottom = "20px"
            this.body.style.fontSize = "16px"
        }
    }

    protected handleStatusChange(chatConnection: IChatConnection, status: RoomStatus): void {
        if (this.videoOffline) {
            status = RoomStatus.Offline
        }
        const handler = this.showTextHandlers.get(status)
        if (handler !== undefined) {
            handler(chatConnection)
            this.show()
            this.repositionChildrenRecursive()
            return
        } else if (!this.isConnecting) {
            this.moreRoomsLink.style.display = "none"
            this.hide()
        }

        // This switch is for statuses we don't have handlers for but don't want to report errors on.
        switch (status) {
            case RoomStatus.Public:
            case RoomStatus.PrivateRequesting:
            case RoomStatus.PrivateWatching:
            case RoomStatus.PrivateSpying:
            case RoomStatus.NotConnected:
            case RoomStatus.HiddenWatching:
                break

            default:
                warn(`unexpected status: ${status}`)
        }
    }

    protected updateMoreRoomsLinkDisplay(shouldDisplay = true): void {
        const hideForConnectingStatus = this.isConnecting  && !this.videoOffline
        if (!shouldDisplay || hideForConnectingStatus || !this.shouldShowMoreRoomsLink) {
            this.moreRoomsLink.style.display = "none"
        } else {
            this.moreRoomsLink.style.display = "block"
        }
    }

    public setShowMoreRoomsLink(showRooms: boolean): void {
        this.shouldShowMoreRoomsLink = showRooms
        this.updateMoreRoomsLinkDisplay()
    }

    public showConnectingStatus(): void {
        this.header.innerText = this.connectingStatusText
        this.text.innerText = i18n.connectingToBroadcastMessage
        this.show()
        this.joinShowLink.style.display = "none"
        this.spyDisabledText.style.display = "none"
        this.moreRoomsLink.style.display = "none"
        this.isConnecting = true
    }

    public isConnectingStatusShowing(): boolean {
        return this.header.innerText === this.connectingStatusText
    }

    public hideConnectingStatus(): void {
        if (this.isConnecting) {
            // only clear text if `Connecting` text is actually showing and
            // a text handler hasn't changed the UI already
            if (this.isConnectingStatusShowing()) {
                this.header.innerText = ""
                this.text.innerText = ""
                this.hide()
            }

            this.isConnecting = false
        }
    }
}
