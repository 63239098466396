import { isAnonymous } from "../../common/auth"
import { HTMLComponent } from "../../common/defui/htmlComponent"
import { followRoom, unfollowRoom } from "../../common/follow"
import { i18n } from "../../common/translation"
import { dom } from "../../common/tsxrender/dom"
import { pageContext } from "../interfaces/context"
import { showLoginOverlay } from "../ui/loginOverlay"

interface IFollowStarProps {
    slug: string
    isFollowing?: boolean
    location?: string
    allowPropagation?: boolean
    hideTitle?: boolean
}

// Undefined means "updating"
interface IFollowStarState { isActive?: boolean }

const ACTIVE_CLASS = "icon_following"
const INACTIVE_CLASS = "icon_not_following"
const UPDATING_CLASS = "icon_update_following"

export abstract class FollowStar extends HTMLComponent<HTMLDivElement, IFollowStarProps, IFollowStarState> {
    protected readonly isMobile = pageContext.current.isMobile
    protected hideTitle: boolean

    protected abstract callFollowApi(setActive: boolean): Promise<void>

    constructor(protected props: IFollowStarProps) {
        super(props)
    }

    protected initData(props: IFollowStarProps): void {
        super.initData(props)
        this.setState({ isActive: props.isFollowing === true })
    }


    protected createElement(props: IFollowStarProps): HTMLDivElement {
        this.hideTitle = props.hideTitle !== undefined ? props.hideTitle : false
        return (
            <div
                bind={{
                    className: () => `follow_star ${this.getIconClass()}`,
                    title: () => this.getTitle(),
                    style: () => this.getStyle(),
                }}
                data-slug={props.slug}
                onClick={(e: Event) => {
                    this.handleClick()
                    if (props.allowPropagation !== true) {
                        e.stopPropagation()
                    }
                    e.preventDefault()
                }}
                data-testid="follow-star"
            />
        )
    }

    protected getIconClass(): string {
        return this.state.isActive === undefined ? UPDATING_CLASS : this.state.isActive ? ACTIVE_CLASS : INACTIVE_CLASS
    }

    protected getTitle(): string {
        if (this.hideTitle || this.state.isActive === undefined) {
            return ""
        }
        return this.state.isActive ? i18n.unfollowText : i18n.followText
    }

    protected getStyle(): CSSX.Properties {
        return {}
    }

    private setHover(): void {
        this.element.classList.add("no_hover")
        window.setTimeout(() => {
            this.element.classList.remove("no_hover")
        }, 3000)
    }

    public setFollowing(isFollowing: boolean): void {
        this.setState({ isActive: isFollowing })
    }

    private handleClick(): void {
        if (isAnonymous()) {
            showLoginOverlay({ fromFeature: true })
            return
        }

        const wasActive = this.state.isActive === true
        this.setState({ isActive: undefined })
        this.callFollowApi(!wasActive).then(() => {
            this.setState({ isActive: !wasActive })
            this.setHover()
        }).catch(() => {
            this.setState({ isActive: wasActive })
        })
    }
}

export class RoomFollowStar extends FollowStar {
    callFollowApi(setActive: boolean): Promise<void> {
        const result = setActive ? followRoom(this.props.slug, isAnonymous())
            : unfollowRoom(this.props.slug, isAnonymous())
        return result?.then((success) => success ? Promise.resolve() : Promise.reject()) ?? Promise.reject()
    }
}
