// this can theoretically be in basePlayer.ts, but closure-compiler doesn't like the cyclic dependency
import { isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { getLocalStorageWithExpiration, getSessionStorageWithExpiration, setLocalStorageWithExpiration, setSessionStorageWithExpiration } from "@multimediallc/web-utils/storage"

export interface IQualityLevel {
    label: string
    toggled: boolean
    value: number
}

export const sdRatio = 0.75
export const wsRatio = 0.5625

const storageKey = "defaultVideoQuality"

const llhlsKey = "defaultLLHLSSetting"

const llhlsFallbackKey = "llhlsFallback"

function privateShowKey(key: string): string {
    return `privateShow_${key}`
}

export function saveQualityInfo(levels: IQualityLevel[], position: number): void {
    if (isLocalStorageSupported()) {
        for (const level of levels){
            if (level.value === position){
                window.localStorage.setItem(storageKey, level.label)
            }
        }
    }
}

export function getQualityInfo(): string {
    let position = "auto"
    if (isLocalStorageSupported()) {
        const savedSettings = window.localStorage.getItem(storageKey)
        if (savedSettings !== null) {
            position = savedSettings
        }
    }
    return position
}

export function saveLLHLSSetting(setting: boolean): void {
    setLocalStorageWithExpiration(llhlsKey, setting ? "llhls" : "hls", { days: 60 })
}

export function getLLHLSSetting(): string {
    return getLocalStorageWithExpiration(llhlsKey) ?? ""
}

export function setFallback(fallback: boolean): void {
    setSessionStorageWithExpiration(llhlsFallbackKey, fallback ? "true" : "false", { days: 1 })
}

export function getFallback(): boolean {
    return getSessionStorageWithExpiration(llhlsFallbackKey) === "true"
}

export function storePrivateShowInfo(room: string, price: number, end: number): void {
    const key = privateShowKey(room)
    const data = {
        "price": price,
        "end": end,
    }
    window.localStorage.setItem(key, JSON.stringify(data))
}

export function getPrivateShowInfo(room: string): string {
    const key = privateShowKey(room)
    const savedSettings = window.localStorage.getItem(key)
    if (savedSettings !== null) {
        return savedSettings
    }
    return ""
}
