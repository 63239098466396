import { RoomListSource } from "@multimediallc/web-utils/types"
import { addEventListenerPoly, onceEventListenerPoly, removeEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { getCb } from "../../../common/api"
import type { EventRouter } from "../../../common/events"
import type { IUserInfo } from "../../../common/messageInterfaces"
import type { ArgJSONMap } from "@multimediallc/web-utils"

// OverlayComponents are zIndex 1001, DmWindowsManager is 1003
export const baseZIndex = 1003

export function createCornerViewerControlsContainer(): HTMLDivElement {
    const controls = document.createElement("div")
    controls.style.position = "absolute"
    controls.style.width = "100%"
    controls.style.height = "100%"
    controls.style.top = "0px"
    controls.style.left = "0px"
    controls.style.display = "none"
    controls.style.zIndex = `${baseZIndex + 1}`
    controls.style.backgroundColor = "rgba(0, 0, 0, .3)"
    controls.style.boxShadow = "0px 0px 4px rgba(0, 0, 0, 0.5)"
    return controls
}

interface ICornerViewerConfig {
    iconAsset: string
    tooltipText: string
    iconSize: number
    centerTop: string
    onClick?: () => void
    hideAllTooltips?: EventRouter<boolean>
}
export function createCornerViewerButton(config: ICornerViewerConfig): HTMLDivElement {
    const halfSize = Math.floor(config.iconSize / 2)
    const div = document.createElement("div")
    div.style.display = "inline-block"
    div.style.position = "absolute"
    div.style.width = div.style.height = `${config.iconSize}px`
    div.style.top = `calc(${config.centerTop} - ${halfSize}px)`

    const icon = document.createElement("img")
    icon.src = `${STATIC_URL_ROOT}${config.iconAsset}`
    icon.style.position = "relative"
    icon.style.height = "100%"
    icon.style.width = "100%"
    icon.style.zIndex = "1"
    div.appendChild(icon)

    const label: HTMLSpanElement = document.createElement("div")
    label.style.position = "absolute"
    label.style.display = "none"
    label.style.width = "39px"
    label.style.height = "39px"
    label.style.top = `${-19 + halfSize}px`
    label.style.left = `${-19 + halfSize}px`
    label.style.borderRadius = "4px"
    label.style.zIndex = "0"
    label.style.backgroundColor = "rgba(255, 255, 255, .25)"

    const tooltip = document.createElement("div")
    tooltip.textContent = config.tooltipText
    tooltip.style.position = "relative"
    tooltip.style.display = "inline-block"
    tooltip.style.top = "42px"
    tooltip.style.left = "50%"
    tooltip.style.transform = "translateX(-50%)"
    tooltip.style.padding = "0px 7px"
    tooltip.style.whiteSpace = "nowrap"
    tooltip.style.fontFamily = "UbuntuRegular"
    tooltip.style.fontSize = "12px"
    tooltip.style.color = "#FFF"
    tooltip.style.backgroundColor = "rgba(0, 0, 0, .9)"
    tooltip.style.border  = "1px solid #8A8A8A"

    label.appendChild(tooltip)
    div.appendChild(label)

    if (config.hideAllTooltips !== undefined) {
        config.hideAllTooltips.listen(() => {
            label.style.display = "none"
        })
    }

    if (config.onClick !== undefined) {
        icon.style.cursor = "pointer"
        addEventListenerPoly("click", icon, config.onClick)
    }
    addEventListenerPoly("mouseenter", icon, () => {
        label.style.display = "block"
    })
    addEventListenerPoly("mouseleave", icon, () => {
        label.style.display = "none"
    })

    let iconReady = false
    const iconTouchListener = (event: Event) => {
        if (iconReady) {
            if (config.onClick !== undefined) {
                onceEventListenerPoly("touchend", icon, config.onClick)
            }
        } else {
            label.style.display = "block"
            iconReady = true
            addEventListenerPoly("touchstart", document, outsideTouchListener, true)
            event.preventDefault()
            event.stopPropagation()
        }
    }

    const outsideTouchListener = (event: Event) => {
        if (event.target !== icon) {
            label.style.display = "none"
            iconReady = false
            removeEventListenerPoly("touchstart", document, outsideTouchListener, true)
        }
    }

    addEventListenerPoly("touchstart", icon, iconTouchListener)

    return div
}

export function checkOnline(username: string): Promise<boolean> {
    return getCb(`api/online/${username}/`).then((xhr) => {
        return JSON.parse(xhr.responseText)["online"]
    })
}

export function getCurrentSMCRoom(): Promise<string> {
    return getCb("api/ts/chat/share-my-cam/").then((xhr) => {
        // TODO Update this when supporting sharing with multiple rooms. For now this list can have at most one member
        const rooms: string[] = JSON.parse(xhr.responseText)["cam_room"]
        return rooms.length > 0 ? rooms[0] : ""
    })
}

export function onNoSMCRoom(callback: () => void): void {
    const pollSMCRoom = () => {
        getCurrentSMCRoom().then((room) => {
            if (room === "") {
                callback()
            } else {
                window.setTimeout(pollSMCRoom, 5000)
            }
        }).catch(() => {})
    }
    pollSMCRoom()
}

export function smcAPIDataAsUser(userData: ArgJSONMap): IUserInfo {
    return {
        username: userData.getString("username"),
        isBroadcaster: false,
        inFanclub: userData.getBoolean("in_fanclub"),
        hasTokens: userData.getBoolean("has_tokens"),
        isMod: userData.getBoolean("is_mod"),
        tippedRecently: userData.getBoolean("tipped_recently"),
        tippedALotRecently: userData.getBoolean("tipped_alot_recently"),
        tippedTonsRecently: userData.getBoolean("tipped_tons_recently"),
        exploringHashTag: "",
        sourceName: RoomListSource.Default,
    }
}
