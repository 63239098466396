import { applyStyles } from "../DOMutils"
import { styleTransition } from "../safeStyle"
import { i18n } from "../translation"
import { MenuComponent } from "./menuComponent"
import { MobileChatSettings } from "./mobileChatSettings"
import { maxSettingsWidth } from "./mobileChatSettingsStyles"
import { getViewportWidth } from "./viewportDimension"

export class ChatSettingsComponent extends MenuComponent {
    public chatSettings = new MobileChatSettings()
    constructor() {
        super(Math.min(getViewportWidth() - 50, maxSettingsWidth), undefined, true)

        applyStyles(this.chatSettings.element, { height: "calc(100% - 45px)", overflowY: "scroll" })
        this.addChild(this.chatSettings)

        this.element.style.backgroundColor = "rgb(250, 250, 250)"

        const saveNotice = document.createElement("div")
        saveNotice.innerText = i18n.chatSettingsSavedText
        saveNotice.dataset.testid = "save-notice"
        saveNotice.style.position = "absolute"
        saveNotice.style.top = "2px"
        saveNotice.style.right = "2px"
        saveNotice.style.fontFamily = "UbuntuRegular, Helvetica, Arial, sans-serif"
        saveNotice.style.color = "#000000"
        saveNotice.style.backgroundColor = "#d8deea"
        saveNotice.style.border = "1px solid #acacac"
        saveNotice.style.borderRadius = "4px"
        saveNotice.style.padding = "4px 6px"
        saveNotice.style.visibility = "hidden"
        this.element.appendChild(saveNotice)

        let saveNoticeTimeout: number
        this.chatSettings.chatSettingsSavedRequest.listen(() => {
            clearTimeout(saveNoticeTimeout)
            styleTransition(saveNotice, "250ms")
            saveNotice.style.visibility = "visible"
            saveNoticeTimeout = window.setTimeout(() => {
                styleTransition(saveNotice, "1000ms")
                saveNotice.style.visibility = "hidden"
            }, 2500)
        })
    }

    show(): void {
        super.show()
        this.chatSettings.getTipVolumeSlider().handleResize()
    }

    repositionChildren(): void {
        this.width = Math.min(getViewportWidth() - 50, maxSettingsWidth)
    }
}
