import { isAnonymous } from "../../../common/auth"
import { Component } from "../../../common/defui/component"
import { isMobileDmActive } from "../../../common/featureFlagUtil"
import { dom } from "../../../common/tsxrender/dom"
import { ConversationListData } from "../pm/conversationListData"
import { DMUnreadData } from "../pm/dmUnreadData"
import { ReactComponentRegistry } from "../ReactRegistry"
import type { ReactComponent } from "../ReactRegistry"

const MESSAGING_ICON_ACTIVE = `${STATIC_URL_ROOT}tsdefaultassets/messaging.svg`
const MESSAGING_ICON_INACTIVE = `${STATIC_URL_ROOT}tsdefaultassets/messaging-inactive.svg`

export abstract class MessagingIcon extends Component<HTMLDivElement> {
    protected messagingComponent?: ReactComponent
    protected messagingElement?: HTMLDivElement
    protected unreadBadgeComponent?: ReactComponent
    protected unreadBadgeDiv: HTMLDivElement
    protected imgElement?: HTMLImageElement

    constructor() {
        super("div")
    }

    protected initData(): void {
        if (isMobileDmActive() && !isAnonymous()) {
            ConversationListData.getInstance()
        }
    }

    protected initUI(): void {
        const styles = this.getStyles()

        this.messagingElement = document.createElement("div")
        this.unreadBadgeDiv = document.createElement("div")

        this.element =
            <div
                onClick={() => this.handleClick()}
                style={styles.container}
                data-testid="messaging-icon"
            >
                <div style={styles.imgContainer}>
                    <img
                        ref={(el: HTMLImageElement | null) => this.imgElement = el || undefined}
                        src={MESSAGING_ICON_INACTIVE}
                        style={styles.img}
                        alt="Messages"
                        data-testid="messaging-icon-img"
                    />
                    {this.unreadBadgeDiv}
                </div>
            </div>

        this.bindUnreadEvents()
    }

    protected getBaseStyles(): Record<string, CSSX.Properties> {
        return {
            container: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                height: "100%",
                position: "relative",
                width: "100%",
                pointerEvents: "auto",
            },
            imgContainer: {
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
        }
    }

    protected abstract getStyles(): Record<string, CSSX.Properties>

    protected getBadgeProps(unread: number): Record<string, unknown> {
        return { count: unread }
    }

    protected updateUnreadBadge(unread: number): void {
        if (!this.unreadBadgeComponent) {
            const UnreadBadgeClass = ReactComponentRegistry.get("UnreadBadge")
            this.unreadBadgeComponent = new UnreadBadgeClass(this.getBadgeProps(unread), this.unreadBadgeDiv)
        } else {
            this.unreadBadgeComponent.update(this.getBadgeProps(unread))
        }
        this.updateIconColor(unread)
    }

    protected updateIconColor(unread: number): void {
        if (this.imgElement) {
            this.imgElement.src = unread === 0 || isAnonymous()
                ? MESSAGING_ICON_INACTIVE
                : MESSAGING_ICON_ACTIVE
        }
    }

    protected bindUnreadEvents(): void {
        if(!isMobileDmActive() || isAnonymous()) {
            return
        }
        DMUnreadData.unreadDataUpdated.listen(({ unread }) => {
            this.updateUnreadBadge(unread)
        })
        this.updateUnreadBadge(DMUnreadData.getInstance().getUnreadCount())
    }

    protected abstract handleClick(): void
}
