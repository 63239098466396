import { mockRoomDossier } from "../../common/chatconnection/mockRoomDossier"
import { EventRouter } from "../../common/events"
import { type IRoomDossier } from "../../common/roomDossier"
import { MutableStore, SetOnceStore } from "../../common/store"
import { type ISocialAuth } from "./socialAuth"
import { type ILoggedInUser } from "./user"

export interface IPageContext {
    current_logo: string,
    isMobile: boolean,
    dmsEnabled: boolean,
    loggedInUser?: ILoggedInUser,
    isAgeGated: boolean,
    animateThumbnails: boolean,
    socialAuths?: ISocialAuth[],
    forceSocialAuth?: string,
    sample_metrics_off: boolean,
    qualityMessage: boolean,
    isBroadcast: boolean,
    darkModeEnabled?: boolean,
    themeName?: string,
    contextID: string,
    csrftoken?: string,
    isLoadedFromCache: boolean | undefined,  // undefined for users with localstorage blocked
    showPaxumNotice: boolean,
    realtimeUserlistEnabled: boolean,
    isInternal: boolean,
    isDevstage: boolean,
    showNpsSentimentSurveyGroup: string | boolean;
    showBroadcasterAppSurvey: boolean,
    refreshFrequency?: number,
    showLocation: boolean,
    regions: string
    pageLocation?: string,
    PurchaseEventSources: Record<string, string>,
    throttleTopicPublish: boolean,
    isNoninteractiveUser: boolean,
    languageCode: string,
    isTestbed: boolean,
    noImage: boolean,
    mergePmDm: boolean,
    noAnalytics: boolean,
    showMobileSiteBannerLink?: boolean,
    twemojiBase: string,
    noindexOnWhitelabels: boolean,
}

export enum PageLocation {
    RoomlistPage = "roomlistPage",
    RoomPage = "chatroom",
}

interface ISpaPageContext {
    pageLocation?: PageLocation
    showLocation?: boolean
    refreshFrequency?: number
}

class PageContextStore extends SetOnceStore<IPageContext> { }

class RoomDossierContextStore extends MutableStore<IRoomDossier> {
    public onUpdate = new EventRouter<IRoomDossier>("RoomDossierContextUpdate", { maxHistorySize: 1 })
}

class SPAPageContextStore extends MutableStore<ISpaPageContext> {
    public onUpdate = new EventRouter<ISpaPageContext>("SPAPageContextUpdate", { maxHistorySize: 1 })
}

export const pageContext = new PageContextStore()

// TODO have room and profile views put the dossier into ts_context and set this thing's initial value in the
//  entrypoints before the other code runs and builds the page. Currently only the broadcast view does this.
export const roomDossierContext = new RoomDossierContextStore(mockRoomDossier)


export const spaPageContext = new SPAPageContextStore({})
