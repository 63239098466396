import { t } from "@lingui/macro"
import { isMobileDmActive } from "../../../common/featureFlagUtil"
import { i18n } from "../../../common/translation"

export const getNewDirectMessageNotice = (username: string): string => {
    if (isMobileDmActive()) {
        return t`New message from ${username}`
    } else {
        return i18n.newDirectMessageNotice(username)
    }
}
