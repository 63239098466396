import { getLocalStorageWithExpiration, setLocalStorageWithExpiration } from "@multimediallc/web-utils/storage"

export const BROADCAST_SETTINGS_EXPIRATION = { days: 60 }
const BROADCAST_MUTE_KEY = "broadcast_muted"

export function setBroadcastMuteLocalStorage(muted: boolean): void {
    setLocalStorageWithExpiration(BROADCAST_MUTE_KEY, String(muted), BROADCAST_SETTINGS_EXPIRATION)
}

export function getBroadcastMuteLocalStorage(): boolean {
    return getLocalStorageWithExpiration(BROADCAST_MUTE_KEY) === "true"
}
