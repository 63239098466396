import { performToggleIsActive } from "./featureFlag"
import { isChatursafeActive } from "./featureFlagUtil"

type CustomToastTypes =
    | "low-balance-common"
    | "low-balance-private"
    | "success-autorefill"
    | "chatursafe-filter-updated"

type GenericToastTypes = "success" | "error" | "info" | "warning"

type ToastTypes = GenericToastTypes | CustomToastTypes
type ContainerIdType =
    | "global-toast"
    | "video-player-toast-container"
    | "purchase-success-toast"
    | "chatursafe-filter-toast-container"

declare global {
    interface Window {
        showToast: ({
            message,
            type,
            containerId,
        }: {
            message: string
            type: ToastTypes
            containerId?: ContainerIdType
        }) => void
        dismissToast: (toastId: string) => void
    }
}

export const showToast = (args: {
    message: string
    type?: ToastTypes
    containerId?: ContainerIdType
    [key: string]: unknown
}): void => {
    const flagActive = args.type === "chatursafe-filter-updated" ? isChatursafeActive() : performToggleIsActive("TknRflTopUp")
    if (window.showToast === undefined || !flagActive) {
        return
    }
    window.showToast({ ...args, type: args.type ?? "success" })
}

export const dismissToast = (toastId: string): void => {
    if (!performToggleIsActive("TknRflTopUp") || window.dismissToast === undefined) {
        return
    }
    window.dismissToast(toastId)
}
