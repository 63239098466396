import { debug, error } from "@multimediallc/logging";
const newrelic = window["newrelic"];
export function newRelicEnabled() {
    return newrelic !== undefined;
}
export function addPageAction(name, attributes = {}) {
    try {
        if (newrelic === undefined) {
            debug(`New Relic is disabled, not sending metric: ${name}`);
            return;
        }
        if (Object.keys(attributes).length === 0) {
            newrelic["addPageAction"](name);
        }
        else {
            // @ts-ignore newrelic is not typed correctly, since it definitely accepts booleans
            newrelic["addPageAction"](name, attributes);
        }
    }
    catch (e) {
        error(`New Relic Error in addPageAction: ${e}`);
    }
}
export function noticeError(name, attributes = {}) {
    try {
        if (newrelic === undefined) {
            debug(`New Relic is disabled, not sending metric: ${name}`);
            return;
        }
        if (Object.keys(attributes).length === 0) {
            newrelic["noticeError"](name);
        }
        else {
            // @ts-ignore newrelic is not typed correctly, since it definitely accepts booleans
            newrelic["noticeError"](name, attributes);
        }
    }
    catch (e) {
        error(`New Relic Error in noticeError: ${e}`);
    }
}
// This affects all PageActions, past and present, do not use for data that needs to be updated/changed
export function setCustomAttribute(name, value) {
    try {
        if (newrelic === undefined) {
            return;
        }
        newrelic["setCustomAttribute"](name, value);
    }
    catch (e) {
        error(`New Relic Error in setCustomAttribute: ${e}`);
    }
}
