import type { ArgJSONMap } from "@multimediallc/web-utils"
import type { IChatursafeFilterSettings } from "@multimediallc/web-utils/types"

export function parseChatursafeFilterSettings(filterSettings: ArgJSONMap): IChatursafeFilterSettings {
    const result = {
        chat_enabled: filterSettings.getBoolean("chat_enabled"),
        messaging_enabled: filterSettings.getBoolean("messaging_enabled"),
        pm_enabled: filterSettings.getBoolean("pm_enabled"),
        tip_enabled: filterSettings.getBoolean("tip_enabled"),
        toxic_enabled: filterSettings.getBoolean("toxic_enabled"),
        toxic_value: filterSettings.getNumber("toxic_value"),
        derogatory_enabled: filterSettings.getBoolean("derogatory_enabled"),
        derogatory_value: filterSettings.getNumber("derogatory_value"),
        violent_enabled: filterSettings.getBoolean("violent_enabled"),
        violent_value: filterSettings.getNumber("violent_value"),
        sexual_enabled: filterSettings.getBoolean("sexual_enabled"),
        sexual_value: filterSettings.getNumber("sexual_value"),
        insult_enabled: filterSettings.getBoolean("insult_enabled"),
        insult_value: filterSettings.getNumber("insult_value"),
        profanity_enabled: filterSettings.getBoolean("profanity_enabled"),
        profanity_value: filterSettings.getNumber("profanity_value"),
        death_harm_tragedy_enabled: filterSettings.getBoolean(
            "death_harm_tragedy_enabled",
        ),
        death_harm_tragedy_value: filterSettings.getNumber(
            "death_harm_tragedy_value",
        ),
        firearms_weapons_enabled: filterSettings.getBoolean(
            "firearms_weapons_enabled",
        ),
        firearms_weapons_value: filterSettings.getNumber("firearms_weapons_value"),
        public_safety_enabled: filterSettings.getBoolean("public_safety_enabled"),
        public_safety_value: filterSettings.getNumber("public_safety_value"),
        health_enabled: filterSettings.getBoolean("health_enabled"),
        health_value: filterSettings.getNumber("health_value"),
        religion_belief_enabled: filterSettings.getBoolean(
            "religion_belief_enabled",
        ),
        religion_belief_value: filterSettings.getNumber("religion_belief_value"),
        illicit_drugs_enabled: filterSettings.getBoolean("illicit_drugs_enabled"),
        illicit_drugs_value: filterSettings.getNumber("illicit_drugs_value"),
        war_conflict_enabled: filterSettings.getBoolean("war_conflict_enabled"),
        war_conflict_value: filterSettings.getNumber("war_conflict_value"),
        finance_enabled: filterSettings.getBoolean("finance_enabled"),
        finance_value: filterSettings.getNumber("finance_value"),
        politics_enabled: filterSettings.getBoolean("politics_enabled"),
        politics_value: filterSettings.getNumber("politics_value"),
        legal_enabled: filterSettings.getBoolean("legal_enabled"),
        legal_value: filterSettings.getNumber("legal_value"),
        poaching_enabled: filterSettings.getBooleanOrUndefined("poaching_enabled"),
        poaching_value: filterSettings.getNumberOrUndefined("poaching_value"),
    }
    
    if (result.poaching_enabled === undefined) {
        delete result.poaching_enabled
        delete result.poaching_value
    }
    
    return result
}
